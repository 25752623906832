// Create a hotspot
const createHotspot = function (x, y, mode) {
  const hotspot = createButton('hotspot', x, y, MTLG.getOptions().height * 0.06, MTLG.getOptions().height * 0.06);

  if (mode == 1) { hotspot.alpha = 0.05; }

  return hotspot;
};

// Create a hotspot over the specified shape (must be an image)
var createHotspot_Shape = function(shape) {

  var new_bounds = getGlobalBounds(shape);
  new_bounds.x = new_bounds.x + shape.image.width/2;
  new_bounds.y = new_bounds.y + shape.image.height/2;
  const hotspot = createButton('hotspot', new_bounds.x, new_bounds.y, MTLG.getOptions().height * 0.06, MTLG.getOptions().height * 0.06);

  if (mode == 1) { hotspot.alpha = 0.5; }
  return hotspot;
}

module.exports = {
  createHotspot,
  createHotspot_Shape
};
