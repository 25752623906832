function evaluation_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  evaluationControl();
}

// check wether tclevel 1 is choosen or not
function checkEvaluation(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "evaluation"){
    return 1;
  }
  return 0;
}

// LOGICAL PART DETERMINATING LEVELPROGRESS

// check which step is the next to draw
function evaluationControl(){

  // check if the introduction ist already completed
  var flagProgress = sessionControl.getValueToKey("completionEvaluationScreen");
  var flagStatus = sessionControl.getValueToKey("retestGame");

  if(flagProgress === 1 && flagStatus === false) {
    MTLG.lc.levelFinished({
      nextLevel: "pretest"
    });
  } else if(flagProgress === 1 && flagStatus === true) {
    MTLG.lc.levelFinished({
      nextLevel: "retest"
    });
  } else {
    drawStatusQuestion();
  }

}

// START CONTENT OF EVALUATION SCREEN

function drawStatusQuestion(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  // make sure, that there are no old elements in the background
  // reset the general game background - to remove images etc
  MTLG.clearBackground();

  // set currentLevel
  sessionControl.setCurrentLevel("Evaluation -");

  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  var headline = newText(l("headlineStatus"), {textAlign: "center", fontSize: "36px", font: "Bahnschrift", lineWidth: 1080});
  headline.x = options.width / 2;
  headline.y = 200;
  stage.addChild(headline);

  var mainText = newText(l("statusMainText"), {textAlign: "center", lineWidth: 1080});
  mainText.x = options.width / 2;
  mainText.y = 300;
  stage.addChild(mainText);

  var userIdText = newText("User-ID:" + sessionControl.getValueToKey("userID"), {textAlign: "center", lineWidth: 500, fontSize:"18px"});
  userIdText.x = 20;
  userIdText.y = options.height / 2;
  userIdText.rotation = 90;
  stage.addChild(userIdText);

  // button for pretest start
  var yesButton = newButton(l('btYesEval'), 27, "rgb(170, 203, 242)", 1, (options.width / 6) - 30, 60, options.width * 0.3, options.height * 0.4 , function(){
    sessionControl.setValueToKey("retestGame", true);
    sessionControl.setValueToKey("completionEvaluationScreen", 1);
    phishingAcademy.retestGame = true;

    MTLG.lc.levelFinished({
      nextLevel: "retest"
    });

    xapiButton({en: "Evaluation - Retest start", de: "Evaluation - Pretest starten"});
  });

  // button for pretest start
  var noButton = newButton(l('btNoEval'), 27, "rgb(170, 203, 242)", 1, (options.width / 6) - 30, 60, options.width * 0.7, options.height * 0.4 , function(){
    stage.removeAllChildren();
    drawEvaluation();
  });

  stage.addChild(yesButton, noButton);
}

/**
 * This function draws the beginning of the evaluation mode, all functions inside are only for internal use
 * @function
 */
function drawEvaluation(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  // make sure, that there are no old elements in the background
  // reset the general game background - to remove images etc
  MTLG.clearBackground();

  // set currentLevel
  sessionControl.setCurrentLevel("Evaluation -");

  /*// add old standard background
  var generalBackground = new createjs.Shape();
  generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
  generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
  MTLG.getBackgroundStage().addChild(generalBackground);*/

  // INDIVIDUAL BACKGROUND
  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  var headline = newText(l("headlineEvaluation"), {textAlign: "center", fontSize: "36px", font: "Bahnschrift", lineWidth: 1080});
  headline.x = options.width / 2;
  headline.y = 200;
  stage.addChild(headline);

  var mainText = newText(l("evaluationMainText"), {textAlign: "center", lineWidth: 1080});
  mainText.x = options.width / 2;
  mainText.y = 300;
  stage.addChild(mainText);

  var userIdText = newText("User-ID:" + sessionControl.getValueToKey("userID"), {textAlign: "center", lineWidth: 500, fontSize:"18px"});
  userIdText.x = 20;
  userIdText.y = options.height / 2;
  userIdText.rotation = 90;
  stage.addChild(userIdText);

  // button for pretest start
  var pretestButton = newButton(l('btPretest'), 27, "rgb(170, 203, 242)", 1, (options.width / 6) - 30, 60, options.width / 2, options.height / 2 , function(){
    sessionControl.setValueToKey("completionEvaluationScreen", 1);
    MTLG.lc.levelFinished({
      nextLevel: "pretest"
    });

    xapiButton({en: "Evaluation - Pretest start", de: "Evaluation - Pretest starten"});
  });

  // button for test on second date
  var gameButton = newButton(l('btEvaluationPlay'), 27, "rgb(170, 203, 242)", 1, (options.width / 6) - 30, 60, options.width / 2, options.height * 0.6, function(){
    //
  });
  gameButton.visible = false;

  // button for postposttest - Not visible in playable version before second testdate
  // button for game start after pretest
  var postpostButton = newButton(l('btPostPosttest'), 27, "rgb(170, 203, 242)", 1, (options.width / 6) - 30, 60, options.width / 2, options.height * 0.7, function(){
    //
  });
  postpostButton.visible = false;

  stage.addChild(pretestButton, gameButton);

}
