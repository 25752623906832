/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T13:29:20+02:00
 */



function tclevel3_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawTclevel3();
}

// check wether tclevel 3 is choosen or not
function checkTclevel3(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "tclevel3"){
    return 1;
  }
  return 0;
}

// START DRAWING LEVEL 3

/**
 * This function contains the drawing of the third level of the trainingscenter
 * @function
 */
function drawTclevel3(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // clear stage rom all objects and listeners
  stage.removeAllEventListeners();
  stage.removeAllChildren();

  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  background.visible = false;
  stage.addChild(background);

  // Demo Delfi buttons
  if(phishingAcademy.demoMode === true){
    demoButtons();
  }


  // LOGICAL PART DETERMINATING LEVELPROGRESS

  // check which step is the next to draw
  function level3Control(){
    // function returns an array
    var levelProgress = sessionControl.determineLevelProgress(3);
    /**
      * because there not that much steps, call them directly instead of searching per recursion
      * next Step to do is stored in levelProgress[3] due to the return array
      */
    switch (levelProgress[3]){
      case 1:
        drawStep1();
        break;
      case 2:
        drawStep2();
        break;
      case 3:
        drawStep3();
        break;
      case 4:
        drawStep4();
        break;
      case 5:
        drawStep5();
        break;
      default:
        // xapi call
        xapiLevelEnd({en:"Trainingscenter - Level 3", de:"Trainingscenter - Level 3"});
        sessionControl.levelComplete(3);
        MTLG.lc.levelFinished({
          nextLevel: "trainingscenter"
        });
        break;
    }
  }

  // END LOGICAL PART DETERMINATING LEVELPROGRESS

  // GENERAL BUTTONS
  stage.addChild(levelChoiceButton());


  // CONTENT PART OF THE LEVEL - EACH STEP IS ONE INTERNAL FUNCTION

  // FIRST STEP
  function drawStep1(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 3 - Step 1 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 3 - Step 1", de:"Trainingscenter - Level 3 - Step 1"});

    // add button container
    var buttonContainer = new createjs.Container();
    stage.addChild(buttonContainer);

    // save choosen answer
    var choosenSite = "";

    // save active tab to stop breaking url text
    var activeTab = 1;
    var noChange = 1;

    function changeURLText(url, tabNumber){
        //getNavbarStage().getChildByName("navbar").getChildByName("url").text = url;
        MTLG.getStageContainer().getChildByName("navbar").getChildByName("url").text = url;
        if(/*getNavbarStage()*/MTLG.getStageContainer().getChildByName("navbar").getChildByName("url").text != url){
          changeURLText(url, tabNumber);
        } else {
          noChange = 1;
        }
    }

    const tab1 = createTab(l('tab1'), true);
    tab1.addEventListener('click', function(){
      // Change the displayed url in the adress bar
      //changeURL("https://login.wikipedia.de.1001100110011001.de");

      // TEST 25.02
      if(noChange === 1 && activeTab !== 1){
        noChange = 0;
        activeTab = 1;
        changeURLText("https://login.wikipedia.de.1001100110011001.de", 2);
      }

      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 1 - Tab 1", de:"Trainingscenter - Level 3 - Step 1 - Tab 1"});

      // add choose button
      buttonContainer.removeAllChildren();
      buttonContainer.addChild(newButton(l('level3step1buttonReal'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
        // save actual site
        choosenSite = "tab1";

        // xapi call
        xapiButton({en:"Trainingscenter - Level 3 - Step 1 - Choose Tab 1", de:"Trainingscenter - Level 3 - Step 1 - Wähle Tab 1"});
        xapiStepResult({en:"Trainingscenter - Level 3 - Step 1", de:"Trainingscenter - Level 3 - Step 1"}, false);

        addFeedbackForStep(false, l('level3step1failed'), nextStep);
      }));
    });
    const tab2 = createTab('Zweiter Tab', false);
    tab2.addEventListener('click', function(){
      // Change the displayed url in the adress bar
      //changeURL("https://login.wikipedia.de");

      // TEST 25.02
      if(noChange === 1 && activeTab !== 2){
        noChange = 0;
        activeTab = 2;
        changeURLText("https://login.wikipedia.de", 2);
      }

      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 1 - Tab 2", de:"Trainingscenter - Level 3 - Step 1 - Tab 2"});

      // add choose button
      buttonContainer.removeAllChildren();
      buttonContainer.addChild(newButton(l('level3step1buttonReal'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
        // save actual site
        choosenSite = "tab2";

        // xapi call
        xapiButton({en:"Trainingscenter - Level 3 - Step 1 - Choose Tab 2", de:"Trainingscenter - Level 3 - Step 1 - Wähle Tab 2"});
        xapiStepResult({en:"Trainingscenter - Level 3 - Step 1", de:"Trainingscenter - Level 3 - Step 1"}, true);

        addFeedbackForStep(true, l('level3step1complete'), nextStep);
      }));
    });

    // Create a tabbar by passing the three tabs
    const tabbar = createTabbar(MTLG.getOptions().width * 0.01, tab1, tab2);

    // Create the standard navbar
    const standard_navbar = createStandardNavbar('https://login.wikipedia.de.1001100110011001.de');

    // Remove all Event-Listeners from the Navigation Buttons, because they call failing iframe functions
    standard_navbar.children[1].children.forEach(function(element){element.removeAllEventListeners();});

    // Add the tab- and navbar to the navbar stage
    //getNavbarStage().addChild(standard_navbar, tabbar); Test 18.11.2020
    MTLG.getStageContainer().addChild(standard_navbar, tabbar);
    console.log(MTLG.getStageContainer());

    //add normal background
    const background = new createjs.Shape();
    background.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    background.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(background);

    // add the screenshot to the background, no iframe needed
    var backgroundWebsite = MTLG.assets.getBitmap('img/phishing/wikipedia.png');
    var backgroundContainer = new createjs.Container();
    backgroundContainer.x = 0;
    backgroundContainer.y = options.height * 0.12 - 2;


    // scale image so it fits to the page
    var oldWidth = backgroundWebsite.getBounds().width;
    var newWidth = options.width;
    backgroundWebsite.scale = (newWidth / oldWidth);

    backgroundContainer.addChild(backgroundWebsite);

    MTLG.getBackgroundStage().addChild(backgroundContainer);
    console.log(MTLG.getBackgroundStage());

    // add button for first start
    buttonContainer.addChild(newButton(l('level3step1buttonReal'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
      // save actual site
      choosenSite = "tab1";

      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 1 - Choose Tab 1", de:"Trainingscenter - Level 3 - Step 1 - Wähle Tab 1"});
      xapiStepResult({en:"Trainingscenter - Level 3 - Step 1", de:"Trainingscenter - Level 3 - Step 1"}, false);

      addFeedbackForStep(false, l('level3step1failed'), nextStep);
    }));

    // step dialogs
    var completeStepDialogs = [l('level3step1dialog1'), l('level3step1dialog2'), l('level3step1task')];
    var stepTask = l('level3step1task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

    /**
      * function to start the new step
      */
    function nextStep(){
      MTLG.clearBackground();
      getNavbarStage().removeAllChildren();
      getLayerStage().removeAllChildren();

      // add old standard background
      var generalBackground = new createjs.Shape();
      generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
      generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
      MTLG.getBackgroundStage().addChild(generalBackground);


      // save progress
      sessionControl.stepComplete(3,1);
      // restart level by callback
      MTLG.lc.levelFinished({
        nextLevel: "tclevel3"
      });
    }

  }

  // SECOND STEP
  function drawStep2(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 3 - Step 2 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 3 - Step 2", de:"Trainingscenter - Level 3 - Step 2"});

    // wrong url from step 1
    var phishingURL = JSON.parse('[{"urltext" : {'
        + '"protocol": "https://",'
        + '"fifthleveldomain": "login",'
        + '"forthleveldomain": "wikipedia",'
        + '"thirdleveldomain": "de",'
        + '"secondleveldomain": "sqwcsufa6mv1f",'
        + '"toplevel": "de/"'
      + '},'
        + '"urldescription": {}'
        + '}, {"urltext" : {'
        + '"protocol": "https://",'
        + '"forthleveldomain": "wikipedia",'
        + '"thirdleveldomain": "de",'
        + '"secondleveldomain": "safelogin",'
        + '"toplevel": "de/"'
        + '},'
          + '"urldescription": {}'
      + '}]');

    // add the wrong URL to stage
    var urlDisplay = addURL([phishingURL[0]], 3, 0);
    urlDisplay.getChildByName("wrapperURL").y = 0;

    // TODO Container einfärben der secondleveldomain
    var urlTextContainer = urlDisplay.getChildByName("wrapperURL").getChildByName("containerURL").getChildByName("text").getChildByName("secondleveldomain");
    var oldHighlightShape = urlTextContainer.getChildByName("highlightShape");
    var boundWidth = oldHighlightShape.getBounds().width;
    var boundHeight = oldHighlightShape.getBounds().height;

    // new shape to highlight the part in a different color
    var markerShape = new createjs.Shape();
    markerShape.graphics.beginFill("rgb(225, 74, 79)").drawRoundRect(0, 0, boundWidth, boundHeight, 5);
    urlTextContainer.addChild(markerShape);
    urlTextContainer.setChildIndex(markerShape, 0);

    urlTextContainer.removeChild(oldHighlightShape);


    stage.addChild(urlDisplay);
    stage.setChildIndex(urlDisplay, 2);

    var urlDisplay2 = addURL([phishingURL[1]], 3, 0);
    //urlDisplay2.getChildByName("wrapperURL");

    var urlTextContainer2 = urlDisplay2.getChildByName("wrapperURL").getChildByName("containerURL").getChildByName("text").getChildByName("secondleveldomain");
    var oldHighlightShape2 = urlTextContainer2.getChildByName("highlightShape");
    var boundWidth2 = oldHighlightShape2.getBounds().width;
    var boundHeight2 = oldHighlightShape2.getBounds().height;

    // new shape to highlight the part in a different color
    var markerShape2 = new createjs.Shape();
    markerShape2.graphics.beginFill("rgb(225, 74, 79)").drawRoundRect(0, 0, boundWidth2, boundHeight2, 5);
    urlTextContainer2.addChild(markerShape2);
    urlTextContainer2.setChildIndex(markerShape2, 0);

    urlTextContainer2.removeChild(oldHighlightShape2);

    stage.addChild(urlDisplay2);

    // explanation
    var explanationContainer = new createjs.Container();
    var explanationShape = new createjs.Shape();
    explanationShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape.x = 0;
    explanationShape.y = 0;
    explanationShape.graphics.setStrokeStyle(1);
    explanationShape.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer.x = (options.width-explanationContainer.getBounds().width) / 2;
    explanationContainer.y = options.height * 0.35;

    // content of speechbubble
    var explanationText = MTLG.utils.gfx.getText(l('level3step2explanation'), "27px Arial", "black");
    explanationText.lineWidth = 650;
    explanationText.lineHeight = 35;
    explanationText.x =  15;
    explanationText.y = 15;

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();
    noteContainer.x = explanationContainer.getBounds().width * 0.535;
    noteContainer.y = 0;

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scaleX = 0.3;
    noteBitmap.scaleY =0.3;
    noteBitmap.alpha = 1;
    noteContainer .addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level3step2noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 490;
    noteHeadline.textAlign = "center";

    var textLengthHeadline = noteHeadline.getBounds().width;
    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(45, options.height * 0.06).lt(noteContainer.getBounds().width - 45, options.height * 0.06);

    // rule
    var noteText = MTLG.utils.gfx.getText(l('level3step2note'), "27px Arial", "black");
    noteText.lineWidth = 490;
    noteText.lineHeight = 35;
    noteText.x = 45;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);
    explanationContainer.addChild(explanationShape, explanationText, noteContainer);

    // add gotIt-button
    var gotItButton = newButton(l('level3step2gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // remove all the other children from stage
      // save progess
      sessionControl.stepComplete(3,2);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 2 - Got it", de:"Trainingscenter - Level 3 - Step 2 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 3 - Step 2", de:"Trainingscenter - Level 3 - Step 2"});

      // go to step 3
      MTLG.lc.levelFinished({
        nextLevel: "tclevel3"
      });

    });

    // add explanation and button to stage
    stage.addChild(explanationContainer, gotItButton);

    // step dialogs
    var completeStepDialogs = [l('level3step2dialog1'), l('level3step2task')];
    var stepTask = l('level3step2task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // THIRD STEP
  function drawStep3(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 3 - Step 3 -");

    // add button container
    var buttonContainer = new createjs.Container();
    stage.addChild(buttonContainer);

    // save choosen answer
    var choosenSite = "";

    // save active tab to stop breaking url text
    var activeTab = 1;
    var noChange = 1;

    function changeURLText(url, tabNumber){
        //getNavbarStage().getChildByName("navbar").getChildByName("url").text = url;
        MTLG.getStageContainer().getChildByName("navbar").getChildByName("url").text = url;
        if(/*getNavbarStage()*/MTLG.getStageContainer().getChildByName("navbar").getChildByName("url").text != url){
          changeURLText(url, tabNumber);
        } else {
          noChange = 1;
        }
    }

    const tab1 = createTab(l('tab1'), true);
    tab1.addEventListener('click', function(){
      // Change the displayed url in the adress bar
      // Change the displayed url in the adress bar
      //changeURL("https://www.pegasus.de");

      // TEST 25.02
      if(noChange === 1 && activeTab !== 1){
        noChange = 0;
        activeTab = 1;
        changeURLText("https://www.pegasus.de", 1);
      }

      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 3 - Tab 1", de:"Trainingscenter - Level 3 - Step 3 - Tab 1"});

      // Old version
      //changeURL("https://www.schueler-netzwerk.de");
      // add choose button
      buttonContainer.removeAllChildren();
      buttonContainer.addChild(newButton(l('level3step3buttonReal'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
        // save actual site
        choosenSite = "tab1";
        // xapi call
        xapiButton({en:"Trainingscenter - Level 3 - Step 3 - Choose Tab 1", de:"Trainingscenter - Level 3 - Step 3 - Wähle Tab 1"});
        xapiStepResult({en:"Trainingscenter - Level 3 - Step 3", de:"Trainingscenter - Level 3 - Step 3"}, true);

        addFeedbackForStep(true, l('level3step3complete'), nextStep);
      }));
    });

    // TEST 25.02
    console.log(getNavbarStage());

    const tab2 = createTab('Zweiter Tab', false);
    tab2.addEventListener('click', function(){
      // Change the displayed url in the adress bar
      //changeURL("https://www.pegasus-login.de");

      // TEST 25.02
      if(noChange === 1 && activeTab !== 2){
        noChange = 0;
        activeTab = 2;
        changeURLText("https://www.pegasus-login.de", 2);
      }

      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 3 - Tab 2", de:"Trainingscenter - Level 3 - Step 3 - Tab 2"});

      //changeURL("https://www.schüler-netzwerk.de");
      // add choose button
      buttonContainer.removeAllChildren();
      buttonContainer.addChild(newButton(l('level3step3buttonReal'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
        // save actual site
        choosenSite = "tab2";
        // xapi call
        xapiButton({en:"Trainingscenter - Level 3 - Step 3 - Choose Tab 2", de:"Trainingscenter - Level 3 - Step 3 - Wähle Tab 2"});
        xapiStepResult({en:"Trainingscenter - Level 3 - Step 3", de:"Trainingscenter - Level 3 - Step 3"}, false);

        addFeedbackForStep(false, l('level3step3failed'), nextStep);
      }));
    });

    // Create a tabbar by passing the three tabs
    const tabbar = createTabbar(MTLG.getOptions().width * 0.01, tab1, tab2);

    // Create the standard navbar
    const standard_navbar = createStandardNavbar('https://www.pegasus.de');

    // Remove all Event-Listeners from the Navigation Buttons, because they call failing iframe functions
    standard_navbar.children[1].children.forEach(function(element){element.removeAllEventListeners();});

    // Add the tab- and navbar to the navbar stage
    //getNavbarStage().addChild(standard_navbar, tabbar);
    MTLG.getStageContainer().addChild(standard_navbar, tabbar);

    //add normal background
    const background = new createjs.Shape();
    background.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    background.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(background);

    // design the fake website with createjs
    var websiteContainer = new createjs.Container();
    websiteContainer.x = 0;
    websiteContainer.y = 0;

    // website frame
    var websiteFrame = new createjs.Shape();
    websiteFrame.graphics.beginFill("rgb(251, 251, 251)").drawRect(options.width * 0.175, options.height * 0.175, options.width * 0.65, options.height * 0.6);

    // logo
    var logoWebsite = MTLG.assets.getBitmap('img/phishing/pegasus-schuhe.png');
    logoWebsite.scale = 0.1;
    logoWebsite.x = options.width * 0.2;
    logoWebsite.y = options.height * 0.2;

    var contentCatchPhrase = new createjs.Text(l('contentCatchPhraselevel3step3'), "32px Arial", "black");
    contentCatchPhrase.x = options.width * 0.4;
    contentCatchPhrase.y = options.height * 0.275;


    // main content with login
    var containerContentWebsite = new createjs.Container();
    containerContentWebsite.x = options.width * 0.2;
    containerContentWebsite.y = options.height * 0.35;
    var loginPhrase = new createjs.Text(l('loginPhrase'), "35px Arial", "black");
    loginPhrase.x = 0;
    loginPhrase.y = 0;
    var loginUsernameField = new createjs.Shape();
    loginUsernameField.graphics.setStrokeStyle(1).beginStroke("black");
    loginUsernameField.graphics.beginFill(color).drawRect(0, options.height * 0.075, options.width * 0.175, options.width * 0.025);
    var loginPasswordField = new createjs.Shape();
    loginPasswordField.graphics.setStrokeStyle(1).beginStroke("black");
    loginPasswordField.graphics.beginFill(color).drawRect(0, options.height * 0.175, options.width * 0.175, options.width * 0.025);
    var loginUsernameText = new createjs.Text(l('loginUsername'), "27px Arial", "black");
    loginUsernameText.x = 15;
    loginUsernameText.y = options.height * 0.075 + 15;
    var loginPasswordText = new createjs.Text(l('loginPassword'), "27px Arial", "gray");
    loginPasswordText.x = 15;
    loginPasswordText.y= options.height * 0.175 + 15;
    var registrationPhrase = new createjs.Text(l('registrationPhrase'), "30px Arial", "black");
    registrationPhrase.x = options.width * 0.1;
    registrationPhrase.textAlign = "center";
    registrationPhrase.y = options.height * 0.25;
    var contentPicture = MTLG.assets.getBitmap('img/phishing/schuhe-example-new.png');
    contentPicture.scale = 0.4;
    contentPicture.x = options.width * 0.215;
    contentPicture.y = 0;

    containerContentWebsite.addChild(loginUsernameField, loginPasswordField, loginUsernameText, loginPasswordText, loginPhrase, registrationPhrase, contentPicture);

    // footer of website
    var containerFooterWebsite = new createjs.Container();
    containerFooterWebsite.x = options.width * 0.2;
    containerFooterWebsite.y = options.height * 0.7;
    var footerBox = new createjs.Shape();
    footerBox.graphics.beginFill("rgb(176,196,222)").drawRoundRect(0, 0, options.width * 0.6, options.height * 0.05, 10);
    var imprintText = new createjs.Text(l('imprintText'), "27px Arial", "black");
    imprintText.x = 15;
    imprintText.y= 15;
    var dataprivacyText = new createjs.Text(l('dataprivacyText'), "27px Arial", "black");
    dataprivacyText.x= 15 + options.width * 0.15;
    dataprivacyText.y= 15;
    var contactText = new createjs.Text(l('contactText'), "27px Arial", "black");
    contactText.x= 15 + options.width * 0.4;
    contactText.y= 15;

    containerFooterWebsite.addChild(footerBox, imprintText, dataprivacyText, contactText);

    // menu of website
    var containerMenuWebsite = new createjs.Container();
    containerMenuWebsite.x = options.width * 0.4;
    containerMenuWebsite.y = options.height * 0.2;
    var menuBox = new createjs.Shape();
    menuBox.graphics.beginFill("rgb(84, 128, 185)").drawRoundRect(0, 0, options.width * 0.4, options.height * 0.05, 10);
    var menText = new createjs.Text(l('menText'), "27px Arial", "black");
    menText.x = 15;
    menText.y= 15;
    var womenText = new createjs.Text(l('womenText'), "27px Arial", "black");
    womenText.x= 15 + options.width * 0.15;
    womenText.y= 15;
    var kidsText = new createjs.Text(l('kidsText'), "27px Arial", "black");
    kidsText.x= 15 + options.width * 0.3;
    kidsText.y= 15;

    containerMenuWebsite.addChild(menuBox, menText, womenText, kidsText);

    websiteContainer.addChild(websiteFrame, logoWebsite, containerContentWebsite, containerFooterWebsite, containerMenuWebsite, contentCatchPhrase);

    MTLG.getBackgroundStage().addChild(websiteContainer);

    // add button for first start
    buttonContainer.addChild(newButton(l('level3step3buttonReal'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
      // save actual site
      choosenSite = "tab1";
      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 3 - Choose Tab 1", de:"Trainingscenter - Level 3 - Step 3 - Wähle Tab 1"});
      xapiStepResult({en:"Trainingscenter - Level 3 - Step 3", de:"Trainingscenter - Level 3 - Step 3"}, true);

      addFeedbackForStep(true, l('level3step3complete'), nextStep);
    }));

    // step dialogs
    var completeStepDialogs = [l('level3step3dialog1'), l('level3step3task')];
    var stepTask = l('level3step3task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

    /**
      * function to start the new step
      */
    function nextStep(){
      // save progress
      MTLG.clearBackground();
      getNavbarStage().removeAllChildren();
      getLayerStage().removeAllChildren();

      // add old standard background
      var generalBackground = new createjs.Shape();
      generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
      generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
      MTLG.getBackgroundStage().addChild(generalBackground);

      sessionControl.stepComplete(3,3);
      // restart level by callback
      MTLG.lc.levelFinished({
        nextLevel: "tclevel3"
      });
    }

  }

  // FOURTH STEP
  function drawStep4(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 3 - Step 4 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 3 - Step 4", de:"Trainingscenter - Level 3 - Step 4"});

    // wrong url from step 1
    var phishingURL = JSON.parse('{"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "pegasus-login",'
        + '"toplevel": "de/"'
      + '},'
        + '"urldescription": {}'
    + '}');

    // add the wrong URL to stage
    var urlDisplay = addURL([phishingURL], 3, 0);
    urlDisplay.getChildByName("wrapperURL").y = 0;

    // TODO Container einfärben der secondleveldomain
    var urlTextContainer = urlDisplay.getChildByName("wrapperURL").getChildByName("containerURL").getChildByName("text").getChildByName("secondleveldomain");
    var oldHighlightShape = urlTextContainer.getChildByName("highlightShape");
    var boundWidth = oldHighlightShape.getBounds().width;
    var boundHeight = oldHighlightShape.getBounds().height;

    // new shape to highlight the part in a different color
    var markerShape = new createjs.Shape();
    markerShape.graphics.beginFill("rgb(225, 74, 79)").drawRoundRect(0, 0, boundWidth, boundHeight, 5);
    urlTextContainer.addChild(markerShape);
    urlTextContainer.setChildIndex(markerShape, 0);

    urlTextContainer.removeChild(oldHighlightShape);


    stage.addChild(urlDisplay);
    stage.setChildIndex(urlDisplay, 2);

    // explanation
    var explanationContainer = new createjs.Container();
    var explanationShape = new createjs.Shape();
    explanationShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape.x = 0;
    explanationShape.y = 0;
    explanationShape.graphics.setStrokeStyle(1);
    explanationShape.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer.x = (options.width-explanationContainer.getBounds().width) / 2;
    explanationContainer.y = options.height * 0.35;

    // content of speechbubble
    var explanationText = MTLG.utils.gfx.getText(l('level3step4explanation'), "27px Arial", "black");
    explanationText.lineWidth = 650;
    explanationText.lineHeight = 35;
    explanationText.x =  15;
    explanationText.y = 15;

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();
    noteContainer.x = explanationContainer.getBounds().width * 0.535;
    noteContainer.y = 0;

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scaleX = 0.3;
    noteBitmap.scaleY =0.3;
    noteBitmap.alpha = 1;
    noteContainer .addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level3step4noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 490;
    noteHeadline.textAlign = "center";

    var textLengthHeadline = noteHeadline.getBounds().width;
    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(45, options.height * 0.06).lt(noteContainer.getBounds().width - 45, options.height * 0.06);

    // rule
    var noteText = MTLG.utils.gfx.getText(l('level3step4note'), "27px Arial", "black");
    noteText.lineWidth = 490;
    noteText.lineHeight = 35;
    noteText.x = 45;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);
    explanationContainer.addChild(explanationShape, explanationText, noteContainer);

    // add gotIt-button
    var gotItButton = newButton(l('level3step4gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // remove all the other children from stage
      // save progess
      sessionControl.stepComplete(3,4);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 3 - Step 4 - Got it", de:"Trainingscenter - Level 3 - Step 4 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 3 - Step 4", de:"Trainingscenter - Level 3 - Step 4"});

      // go to step 3
      MTLG.lc.levelFinished({
        nextLevel: "tclevel3"
      });

    });

    // add explanation and button to stage
    stage.addChild(explanationContainer, gotItButton);

    // step dialogs
    var completeStepDialogs = [l('level3step4dialog1'), l('level3step4task')];
    var stepTask = l('level3step4task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // STEP 5 - old step 3
  function drawStep5(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 3 - Step 5 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 3 - Step 5", de:"Trainingscenter - Level 3 - Step 5"});

    // add multiple-choice question and feedback
    var question = new MultipleChoiceQuestion(l('level3step5Question'), [l('level3step5QuestionAnswer1'), l('level3step5QuestionAnswer2'), l('level3step5QuestionAnswer3'), l('level3step5QuestionAnswer4')], l('level3step5QuestionAnswer3'), l('level3step5QuestionReasonRight'), l('level3step5QuestionReasonFalse'));

    var visualQuestion = addMultipleChoice(question, function(){
      // callbackRight
      // save progress
      sessionControl.stepComplete(3,5);

      detectiveSpeech([l('level3complete1'), l('level3complete2')], true, function(){
        // callback right
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 3 - Step 5", de:"Trainingscenter - Level 3 - Step 5"});
        xapiStepResult({en:"Trainingscenter - Level 3 - Step 5", de:"Trainingscenter - Level 3 - Step 5"}, true);
        MTLG.lc.levelFinished({
          nextLevel: "tclevel3"
        })});}, function(){
        // callbackFalse
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 3 - Step 5", de:"Trainingscenter - Level 3 - Step 5"});
        xapiStepResult({en:"Trainingscenter - Level 3 - Step 5", de:"Trainingscenter - Level 3 - Step 5"}, false);
        detectiveSpeech([l('level3step5failed')], true, function(){
          // reset progress
          sessionControl.resetStep(3,2);
          sessionControl.resetStep(3,4);
          // restart level by callback
          MTLG.lc.levelFinished({
            nextLevel: "tclevel3"
          })});
      });

    stage.addChild(visualQuestion);
  }

  // END CONTENT PART OF THE LEVEL


  //********** START THE LEVEL **********
    // Call levelcontrol to begin the level
    level3Control();
}
