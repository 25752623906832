/**
 * @Date:   2018-10-17T11:26:39+02:00
 * @Last modified time: 2019-09-08T17:38:15+02:00
 */



function firstlevel_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawLevel1();
}

// check wether level 1 is choosen or not
function checkLevel1(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "level1"){
    return 1;
  }
  return 0;
}


function drawLevel1(){
  // set stage and option shortcut
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();

  const tab1 = createTab(l('tab1'), true);
  tab1.on('pressup', function({localX, localY}){
    // If there is no gray layer, create a gray layer
    if(getLayerStage().getChildByName("layer") == null) {
      createLayer();
      //createWebsiteFrame();
    }
    // Change the website in the iframe
    //changeWebsite('https://www.wikipedia.org');
    // Change the displayed url in the adress bar

    // reset the general game background - to remove the image
    MTLG.clearBackground();

    // add old standard background
    var generalBackground = new createjs.Shape();
    generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(generalBackground);

    // add background image from website
    var backgroundWebsite1 = MTLG.assets.getBitmap('img/wikiWebsite1.png');

    var backgroundContainer1 = new createjs.Container();
    backgroundContainer1.x = 0;
    backgroundContainer1.y = options.height * 0.12 - 2;

    backgroundContainer1.addChild(backgroundWebsite1);

    MTLG.getBackgroundStage().addChild(backgroundContainer1);
    MTLG.getBackgroundStage().update();

    changeURL("https://www.wikipedia.org");
  });
  const tab2 = createTab('Zweiter Tab', false);
  tab2.on('pressup', function({localX, localY}){
    if(getLayerStage().getChildByName("layer") == null) {
      createLayer();
      //createWebsiteFrame();
    }
    //changeWebsite('https://de.wikipedia.org');

    // reset the general game background - to remove the image
    MTLG.clearBackground();

    // add old standard background
    var generalBackground = new createjs.Shape();
    generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(generalBackground);

    // add background image from website
    var backgroundWebsite2 = MTLG.assets.getBitmap('img/wikiWebsite2.png');

    var backgroundContainer2 = new createjs.Container();
    backgroundContainer2.x = 0;
    backgroundContainer2.y = options.height * 0.12 - 2;

    backgroundContainer2.addChild(backgroundWebsite2);

    MTLG.getBackgroundStage().addChild(backgroundContainer2);
    MTLG.getBackgroundStage().update();

    changeURL("https://de.wikipedia.org");
  });
  const tab3 = createTab('Dritter Tab', false);
  tab3.on('pressup', function({localX, localY}){
    //deleteWebsiteFrame();
    //deleteLayer();

    // reset the general game background - to remove the image
    MTLG.clearBackground();

    // add old standard background
    var generalBackground = new createjs.Shape();
    generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(generalBackground);
    MTLG.getBackgroundStage().update();

    changeURL("Leere Seite");

  });

  // Create a tabbar by passing the three tabs
  const tabbar = createTabbar(MTLG.getOptions().width * 0.01, tab1, tab2, tab3);

  // Create the standard navbar
  const standard_navbar = createStandardNavbar('https://www.wikipedia.org/');

  // Add the tab- and navbar to the navbar stage
  getNavbarStage().addChild(standard_navbar, tabbar);

  // Background
  const background = new createjs.Shape();
  background.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
  background.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
  MTLG.getBackgroundStage().addChild(background);

  // Load the first website
  createLayer();
  //changeWebsite("https://wikipedia.org");

  // Demo-Delfi Background image
  // add background image from website
  var backgroundWebsite1 = MTLG.assets.getBitmap('img/wikiWebsite1.png');

  var backgroundContainer1 = new createjs.Container();
  backgroundContainer1.x = 0;
  backgroundContainer1.y = options.height * 0.12 - 2;

  backgroundContainer1.addChild(backgroundWebsite1);

  MTLG.getBackgroundStage().addChild(backgroundContainer1);


  // START CREATING HOTSPOTS FOR ALL GAMES

  // save hotspots in arrays
  const hotspots = [];
  const hotspot_fields = [];
  let hotspot_x; let
    hotspot_y;

  /*
  for (let i = 0; i <= 4; i++) {
    hotspot_x =  MTLG.getOptions().width * 0.1 + MTLG.getOptions().width * 0.1 * i + Math.floor(Math.random() *  MTLG.getOptions().width * 0.1)*i;
    hotspot_y = Math.floor(Math.random() * MTLG.getOptions().height * 0.6) + MTLG.getOptions().height * 0.05;
    hotspots[i] = createHotspot(hotspot_x, hotspot_y);

    // Create demo fields for each hotspot
    hotspot_fields[i] = new createjs.Shape();
    hotspot_fields[i].graphics.beginStroke('grey');
    hotspot_fields[i].graphics.setStrokeStyle(1);
    hotspot_fields[i].graphics.beginFill('white').drawRect(0, 0, MTLG.getOptions().width * 0.2, MTLG.getOptions().width * 0.2);
    hotspot_fields[i].x = hotspot_x - MTLG.getOptions().width * 0.1;
    hotspot_fields[i].y = hotspot_y + hotspots[i].getBounds().height;
    hotspot_fields[i].visible = false;

    hotspots[i].on('pressup', ({ localX, localY }) => {
      console.log('Geklickt');
      hotspots[i].alpha = 1;
      for (let j = 0; j <= 4; j++) {
        if (j != i) {
          hotspot_fields[j].visible = false;
        }
      }

      if (hotspot_fields[i].visible == false) {
        hotspot_fields[i].visible = true;
      } else {
        hotspot_fields[i].visible = false;
      }
    });
    getHotspotStage().addChild(hotspots[i]);
  }

  for (let k = 0; k <= 4; k++) {
    getHotspotStage().addChild(hotspot_fields[k]);
  }
  */

  // draws a standard box for the start button and icon of the games
  function backgroundHotspotField(){
    backgroundShape = new createjs.Shape();
    backgroundShape.graphics.beginStroke('grey');
    backgroundShape.graphics.setStrokeStyle(1);
    backgroundShape.graphics.beginFill('white').drawRect(0, 0, MTLG.getOptions().width * 0.2, MTLG.getOptions().width * 0.2);
    backgroundShape.setBounds(0, 0, MTLG.getOptions().width * 0.2, MTLG.getOptions().width * 0.2);
    backgroundShape.x = 0; //hotspot_x - MTLG.getOptions().width * 0.1;
    backgroundShape.y = 0; //hotspot_y + hotspotHeight;//hotspots[i].getBounds().height;

    return backgroundShape;
  }

  // CUSTOM HOTSPOT PHISHING ACADEMY

  hotspotPhishingAcademy_x = 550;
  hotspotPhishingAcademy_y = 90;
  hotspots[0] = createHotspot(hotspotPhishingAcademy_x, hotspotPhishingAcademy_y);

  // Create demo fields for each hotspot
  var containerPhishingAcademy = new createjs.Container();
  containerPhishingAcademy.x = hotspotPhishingAcademy_x - MTLG.getOptions().width * 0.1;
  containerPhishingAcademy.y = hotspotPhishingAcademy_y + hotspots[0].getBounds().height;

  // backgroundShape
  var backgroundPhishingAcademy = backgroundHotspotField();
  // logo
  var iconPhishingAcademy = MTLG.assets.getBitmap("img/phishing/phishingAcademy_logo.png");
  iconPhishingAcademy.scale = .12;
  iconPhishingAcademy.x = (backgroundPhishingAcademy.getBounds().width - iconPhishingAcademy.getBounds().width * 0.12) / 2;
  iconPhishingAcademy.y = 10;

  // add start button
  var startButtonPhishingAcademy = newButton(l('startPhishingAcademyButton'), 27, "rgb(6, 225, 17)", 1, options.width / 6, options.height / 18, backgroundPhishingAcademy.getBounds().width / 2, backgroundPhishingAcademy.getBounds().height - (options.height / 15), function(){
    // delete all the other stuff from all extra layers
    //deleteWebsiteFrame();
    deleteLayer();
    getHotspotStage().removeAllChildren();
    getNavbarStage().removeAllChildren();
    getToolbarStage().removeAllChildren();

    MTLG.lc.levelFinished({
      nextLevel: "phishingmenu"
    });
  });

  containerPhishingAcademy.addChild(backgroundPhishingAcademy, iconPhishingAcademy, startButtonPhishingAcademy);
  hotspot_fields[0] = containerPhishingAcademy;
  hotspot_fields[0].visible = false;

  hotspots[0].on('pressup', ({ localX, localY }) => {
    console.log('Geklickt');
    hotspots[0].alpha = 1;
    for (let j = 0; j < hotspot_fields.length; j++) {
      if (j != 0) {
        hotspot_fields[j].visible = false;
      }
    }

    if (hotspot_fields[0].visible == false) {
      hotspot_fields[0].visible = true;
    } else if (hotspot_fields[0].visible == true){
      hotspot_fields[0].visible = false;
    }
  });

  getHotspotStage().addChild(hotspots[0]);
  getHotspotStage().addChild(hotspot_fields[0]);

  // END CUSTOM HOTSPOT PHISHING ACADEMY

  // END HOTSPOTS

  // Create the toolbar
  var standard_toolbar = createToolbar(MTLG.getOptions().width*0.7, MTLG.getOptions().height*0.7, hotspots);
  getToolbarStage().addChild(standard_toolbar);

  // Create the detective
  var detective = createDetective(MTLG.getOptions().width*0.06, MTLG.getOptions().height*0.63, l('tipp1'));
  getToolbarStage().addChild(detective);

  // add objects to stage

}
