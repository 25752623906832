/**
 * @Date:   2018-12-24T15:31:31+01:00
 * @Last modified time: 2019-09-03T13:42:25+02:00
 */



function introduction_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawIntroduction();
}

// check wether introduction is choosen or not
function checkIntroduction(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "introduction"){
    return 1;
  }
  return 0;
}


/**
 * This function draws the whole game introduction, all functions inside are only for internal use
 * @function
 */
function drawIntroduction(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // background
  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  // set currentLevel
  sessionControl.setCurrentLevel("Einführung -");

  // LOGICAL PART DETERMINATING LEVELPROGRESS

  // check which step is the next to draw
  function introductionControl(){

    // check if the introduction ist already completed
    var flag = sessionControl.getValueToKey("firstStartIntroduction");

    if(flag === 0){
      showIntro();
    } else {
      MTLG.lc.levelFinished({
        nextLevel: "playermenu"
      });
    }

  }

  // END LOGICAL PART DETERMINATING LEVELPROGRESS


  // START CONTENT OF INTRODUCTION

  function showIntro(){
    // police logo
    var policeLogo = MTLG.assets.getBitmap("img/phishing/police_logo.png");
    policeLogo.scale = .20;
    policeLogo.x = (options.width - policeLogo.getBounds().width * 0.20) / 2;
    policeLogo.y = (options.height - policeLogo.getBounds().height * 0.20) / 2;
    stage.addChild(policeLogo);

    // operating system interface
    var osTaskbar;
    if(MTLG.lang.getLanguage() === "de"){
      osTaskbar = MTLG.assets.getBitmap("img/phishing/os_taskbar.png");
    } else {
      osTaskbar = MTLG.assets.getBitmap("img/phishing/os_taskbar_en.png");
    }
    osTaskbar.x = 0;
    osTaskbar.y = options.height - osTaskbar.getBounds().height;
    stage.addChild(osTaskbar);

    // Initional displaying the e-mail
    showEmail(osTaskbar);

  }

  // SERVICE FUNCTIONS TO SHOW THE E-MAIL
  function showEmail(oldTaskbar){

    // notification container
    var notificationContainer = new createjs.Container();
    notificationContainer.setBounds(0, 0, options.width / 3, options.height / 12);
    notificationContainer.x = (options.width - notificationContainer.getBounds().width) / 2;
    notificationContainer.y = -300;

    // background shape
    var backgroundShapeNotification = new createjs.Shape();
    backgroundShapeNotification.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 3, options.height / 12, 20);
    backgroundShapeNotification.setBounds(0, 0, options.width / 3, options.height / 12);

    // headline box trainingscenter
    var textNotification = MTLG.utils.gfx.getText(l('notificationBoxHeadline'), "36px Arial", "black");
    textNotification.lineWidth = 450;
    textNotification.x = notificationContainer.getBounds().width * 0.275;
    textNotification.y = notificationContainer.getBounds().width / 25;

    // icon notification
    var notificationIcon = MTLG.assets.getBitmap("img/phishing/mail_icon.png");
    notificationIcon.scale = .045;
    notificationIcon.x = notificationContainer.getBounds().width / 15;
    notificationIcon.y = 0;

    notificationContainer.addChild(backgroundShapeNotification, notificationIcon, textNotification);
    stage.addChild(notificationContainer);

    // cursor
    var cursor = MTLG.assets.getBitmap("img/phishing/mouse_cursor.png");
    cursor.scale = .08;
    cursor.x = options.width * 0.25;
    cursor.y = options.height * 0.75;

    stage.addChild(cursor);

    // email container
    var emailContainer = new createjs.Container();

    // email gui
    var emailGui = MTLG.assets.getBitmap("img/phishing/mail_gui.png");
    emailGui.scale = .4;
    emailGui.x = 0;
    emailGui.y = 0;

    emailContainer.setBounds((options.width - emailGui.getBounds().width * 0.4) / 2, (options.height - emailGui.getBounds().height * 0.4) / 2, emailGui.getBounds().width * 0.4, emailGui.getBounds().height * 0.4);
    emailContainer.x = (options.width - emailGui.getBounds().width * 0.4) / 2;
    emailContainer.y = (options.height - emailGui.getBounds().height * 0.4) / 2;

    // email headlines
    var emailHeadlines = MTLG.utils.gfx.getText(l('emailHeadlines'), "32px Arial", "black");
    emailHeadlines.lineWidth = 850;
    emailHeadlines.x = 50;
    emailHeadlines.y = 75;

    // email text
    var emailText = MTLG.utils.gfx.getText(l('emailText'), "27px Arial", "black");
    emailText.lineWidth = 900;
    emailText.lineHeight = 35;
    emailText.x = 50;
    emailText.y = emailHeadlines.getBounds().height + 105;

    // email divider
    var emailDivider = new createjs.Shape();
    emailDivider.graphics.beginFill("black").drawRoundRect(50,  emailHeadlines.getBounds().height + 85, emailGui.getBounds().width * 0.4 - 100, 5, 2);
    emailDivider.setBounds(0, 0, options.width / 3, options.height / 12);

    // newsarticle attachment on mail
    // newsarticle attachment text
    var newsAttachmentText = MTLG.utils.gfx.getText(l('newsAttachmentText'), "27px Arial", "black");
    newsAttachmentText.lineWidth = 420;
    newsAttachmentText.lineHeight = 35;
    newsAttachmentText.x = 65;
    newsAttachmentText.y = emailText.y + emailText.getBounds().height + 30;

    // newsarticle attachment shape
    var newsAttachmentShape = new createjs.Shape();
    newsAttachmentShape.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(50, emailText.y + emailText.getBounds().height + 15, options.width / 4, options.height / 20, 5);
    newsAttachmentShape.setBounds(0, 0, options.width / 4, options.height / 12);

    // add possibility to open newspaper about the attachment shape
    newsAttachmentShape.addEventListener('click', function(){
      tweenOpenNews();
    })

    // button open newsarticle
    var openButtonNews = newButton(l('introductionButtonOpenNews'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), tweenOpenNews);
    openButtonNews.visible = false;
    stage.addChild(openButtonNews);

    emailContainer.addChild(emailGui, emailHeadlines, emailDivider, emailText, newsAttachmentShape, newsAttachmentText);
    emailContainer.visible = false;
    stage.addChild(emailContainer);

    // newsarticle container
    var newsContainer = new createjs.Container();

    // newsarticle background
    var newsBackground;
    if(MTLG.lang.getLanguage() === "de"){
      newsBackground = MTLG.assets.getBitmap("img/phishing/news_background.png");
    } else {
      newsBackground = MTLG.assets.getBitmap("img/phishing/news_background_en.png");
    }
    newsBackground.scale = 0.66;
    newsBackground.x = 0;
    newsBackground.y = 0;

    newsContainer.setBounds(0, 0, newsBackground.getBounds().width * 0.66, newsBackground.getBounds().height * 0.66);
    newsContainer.x = (options.width - newsContainer.getBounds().width ) / 2;
    newsContainer.y = (options.height - newsContainer.getBounds().height ) / 6;

    // newsarticle headline
    var newsHeadline = MTLG.utils.gfx.getText(l('newsHeadline'), "36px Arial", "black");
    newsHeadline.lineWidth = 850;
    newsHeadline.x = 40;
    newsHeadline.y = newsContainer.getBounds().height * 0.33;

    // newsarticle text
    var newsText = MTLG.utils.gfx.getText(l('newsText'), "27px Arial", "black");
    newsText.lineWidth = 755;
    newsText.lineHeight = 39;
    newsText.x = 40;
    newsText.y = newsContainer.getBounds().height * 0.39;

    // newsarticle picture
    var newsPicture = MTLG.assets.getBitmap("img/phishing/news_picture.jpg");
    newsPicture.scale = 0.2;
    newsPicture.x = newsContainer.getBounds().width * 0.65;
    newsPicture.y = newsContainer.getBounds().height * 0.45;

    //button close newsarticle
    var closeButtonNews = newButton(l('introductionButtonCloseNews'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), closeNews);
    closeButtonNews.visible = false;
    stage.addChild(closeButtonNews);

    newsContainer.addChild(newsBackground, newsHeadline, newsText, newsPicture);
    newsContainer.visible = false;
    stage.addChild(newsContainer);

    // button start phishing academy
    var openAcademyButton = newButton(l('introductionButtonAcademy'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2 , options.height - (options.height / 6), function(){
      sessionControl.setValueToKey("firstStartIntroduction", 1);

      // xapi call
      xapiButton({en: "Introduction - Start Phishing Academy", de: "Einführung - Phishing Academy starten"});
      xapiLevelEnd({en: "Introduction", de: "Einführung"})

      // start phishing academy
      MTLG.lc.levelFinished({
        nextLevel: "playermenu"
      }
    )});
    openAcademyButton.visible = false;
    stage.addChild(openAcademyButton);

    // start tween
    tweenOpenMail(notificationContainer, cursor);

    // service function to tween the mail opnening scene
    function tweenOpenMail(notification, cursor) {
      createjs.Tween.get(notification)
        .to({
          alpha: 1,
          x: notification.x,
          y: options.height * 0.04
        }, 2500, createjs.Ease.quartOut)
        .call(
          createjs.Tween.get(cursor)
            .wait(1250)
            .to({
              x: options.width / 2,
              y: options.height * 0.06
            }, 1500, createjs.Ease.linear)
            .to({
              scale: .05
            }, 100)
            .wait(100)
            .to({
              scale: .1
            }, 100)
            .call(showMailTween)
        )
    }

    // function to show the e-mail
    function showMailTween(){
      emailContainer.visible = true;
      stage.removeChild(notificationContainer);
      openButtonNews.visible = true;

      // update operating system interface
      var osTaskbarMail;
      if(MTLG.lang.getLanguage() === "de"){
        osTaskbarMail = MTLG.assets.getBitmap("img/phishing/os_taskbar_withMail.png");
      } else {
        osTaskbarMail = MTLG.assets.getBitmap("img/phishing/os_taskbar_withMail_en.png");
      }
      osTaskbarMail.x = 0;
      osTaskbarMail.y = options.height - osTaskbarMail.getBounds().height;
      stage.addChild(osTaskbarMail);
      stage.removeChild(oldTaskbar);
    }

    // tween for opening the newsarticle
    function tweenOpenNews() {
      // remove button and eventListener on shape
      openButtonNews.visible = false;
      newsAttachmentShape.removeAllEventListeners();

      // xapi call
      xapiButton({en: "Introduction - Open newspaper", de: "Einführung - Zeitung öffnen"});

      stage.setChildIndex(emailContainer, 2);
      createjs.Tween.get(cursor)
        .wait(1000)
        .to({
          x: (options.width - emailGui.getBounds().width * 0.4) / 2 + 120,
          y: (options.height - emailGui.getBounds().height * 0.4) / 2 + emailText.y + emailText.getBounds().height + 30
        }, 1300, createjs.Ease.linear)
        .to({
          scale: .05
        }, 100)
        .wait(100)
        .to({
          scale: .1
        }, 100)
        .wait(250)
        .call(showNews)
    }

    // function to show the newspaper
    function showNews(){
      cursor.x = (options.width - emailGui.getBounds().width * 0.4) / 2 + 800;
      cursor.y = (options.height - emailGui.getBounds().height * 0.4) / 2 + emailText.y + emailText.getBounds().height;
      newsContainer.visible = true;
      closeButtonNews.visible = true;
    }

    // function to close the newspaper
    function closeNews(){
      // xapi call
      xapiButton({en: "Introduction - Close newspaper", de: "Einführung - Zeitung schließen"});

      newsContainer.visible = false;
      closeButtonNews.visible = false;
      openAcademyButton.visible = true;
    }


  }

  // END SERVICE FUNCTIONS TO SHOW THE E-MAIL

  // Call levelcontrol to begin the introduction
  introductionControl();

}

// Export the functions
module.exports = {
  drawIntroduction
};
