/**
 * @Date:   2019-01-08T13:30:45+01:00
 * @Last modified time: 2019-01-28T19:47:36+01:00
 */


 /**
  * This function creates a new Exam for the criminalcases
  * @Constructor
  * @param {Object[]} givenQuestions Questions to test on the exam
  */
function Exam(givenQuestions){
  this.questions = givenQuestions;
  this.userScore = 0;
  this.maximumScore = givenQuestions.length;
}


/**
 * This function creates an new Question for an Exam in the criminalcases
 * @Constructor
 * @param {number} urlId ID of the selected URL from the database
 * @param {string} urlText The textual URL representation
 * @param {string} reason Reason for Phishing or not
 * @param {string} answer Solution Yes/No if the URL is Phishing
 */
function Question(urlId, urlText, target, reason, answer, confidenceLevel = 0){
  this.urlId = urlId;
  this.urlText = urlText;
  this.target = target;
  this.reason = reason;
  this.phishingAnswer = answer;
  this.userAnswer = "";
  this.userCorrect= "";
  this.confidenceLevel= confidenceLevel;
}

/**
 * This function generates a "random" ID and checks if the ID is already in use
 * @function
 * @param {Array} existingIds Array of IDs already in use
 * @param {number} maximum Maximum for a new "random" ID
 * @return {Object}  Under "array" you find the Array of the old existing IDs, under "newId" you find the new generated ID
 */
function randomId(existingIds, maximum){
  var flag = true;
  while(flag){
  var randomId = 1 + Math.floor(Math.random() * (maximum - 1));
    if(existingIds.indexOf(randomId) === -1){
      existingIds.push(randomId);
      flag = false;
      return {array: existingIds, newId:randomId}
    }
  }
}



/**
 * This function re-implements the hitdetection and mag-glass on hotspots for the usage on the NORMAL stage in the criminalcases
 * @function
 * @param {Array} hotspots Array of hotspots
 */
function createMagGlassHotspot(hotspots){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // DESIGN OF MAGGLASS AND AREA

  var magGlass = MTLG.assets.getBitmap('img/search.png');
  magGlass.x = options.width * 0.935;
  magGlass.y = options.height - (options.height / 5) - 25;
  magGlass.regX = magGlass.getBounds().width / 2;
  magGlass.regY = magGlass.getBounds().height / 2 - 25;
  magGlass.scaleX = 1.2;
  magGlass.scaleY = 1.2;

  function getPositionMagGlass(){
    return {x: magGlass.x, y: magGlass.y}
  }

  // advice for mag-glass
  // speechbubble
   var bubbleContainerGlass = new createjs.Container();
  bubbleContainerGlass.x = options.width * 0.835;
  bubbleContainerGlass.y = options.height - (options.height / 2);

  var thinkbubble = MTLG.assets.getBitmap("img/phishing/thinkbubble2.png");
  thinkbubble.x = 0;
  thinkbubble.y = 0;
  thinkbubble.scaleX = .36;
  thinkbubble.scaleY = .36;
  thinkbubble.alpha = 1;
  bubbleContainerGlass.addChild(thinkbubble);

  // content of speechbubble
  var thinkbubbleText = MTLG.utils.gfx.getText(l('magGlassAdvice'), "25px Arial", "black");
  thinkbubbleText.textAlign = 'center';
  thinkbubbleText.textBaseline = "middle";
  thinkbubbleText.lineWidth = 180;
  thinkbubbleText.x = bubbleContainerGlass.getBounds().width / 2;
  thinkbubbleText.y = (bubbleContainerGlass.getBounds().height * 0.275) ;
  thinkbubbleText.alpha = 1;


  bubbleContainerGlass.addChild(thinkbubbleText);

  // area for magnifying glass
  var magGlassArea = new createjs.Shape();
  magGlassArea.color = magGlassArea.graphics.s("rgb(170, 203, 242)").command;
  magGlassArea.graphics.ss(3, "round").sd([12,12]).f("rgb(170, 203, 242)").dr(options.width * 0.935, options.height - (options.height / 5), (magGlass.getBounds().width * 1.2) + 20, (magGlass.getBounds().height * 1.2) + 20);
  magGlassArea.regX = (magGlass.getBounds().width * 1.2) / 2;
  magGlassArea.regY = (magGlass.getBounds().height * 1.2) / 2;

  // create a ticker for the advice
  createjs.Ticker.on("tick", tickGlass);

  function tickGlass(event) {
  bubbleContainerGlass.visible = false;
    var p = stage.localToLocal(getPositionMagGlass().x,getPositionMagGlass().y, magGlassArea);
    if (magGlassArea.hitTest(p.x, p.y)) {
        bubbleContainerGlass.visible = true;
    }
    MTLG.getStage().update(event);
  }

  // HOTSPOT HITDETECTION
  magGlass.on('pressmove', function({localX, localY}){
    magGlass.set(magGlass.localToLocal(localX, localY, magGlass.parent));
    for(let i = 0; i < hotspots.length; i++) {
      if(stage.getChildIndex(hotspots[i].visual) !== -1){
        if (getGlobalBounds(magGlass).x - magGlass.getBounds().width/3 >= getGlobalBounds(hotspots[i].visual).x-magGlass.getBounds().width && getGlobalBounds(magGlass).x + magGlass.getBounds().width/6 <= getGlobalBounds(hotspots[i].visual).x+hotspots[i].visual.getBounds().width
            && getGlobalBounds(magGlass).y - magGlass.getBounds().height/3 >= getGlobalBounds(hotspots[i].visual).y-magGlass.getBounds().height && getGlobalBounds(magGlass).y + magGlass.getBounds().height/12 <= getGlobalBounds(hotspots[i].visual).y+hotspots[i].visual.getBounds().width) {
              hotspots[i].visual.alpha = 1;
            }
      }
    }
  });

  stage.addChild(magGlassArea, bubbleContainerGlass, magGlass);


}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

// Generate array with n numbers from 1 to n
function generateArray(n){
  var x = [], i = 1, endInt = n;
  while (i <= endInt) {
    x.push(i);
    i++;
  }
  return x;
}

// Export the functions
module.exports = {
  Exam,
  Question,
  randomId,
  createMagGlassHotspot,
  shuffleArray,
  generateArray,
};
