/**
 * @Date:   2018-11-09T15:51:43+01:00
 * @Last modified time: 2019-09-02T22:57:45+02:00
 */

/**
 * This function reveals functions to stores/writes/load the progress into the saveObject
 * @function
 */
var sessionControl = (function(){

  // stores the progress
  let saveGame;

  // set store location
  var storeLocation = localStorage;

  // variable for storing the current game situation in trainingscenter
  var currentLevel = " ";


  // function creates a new saveGame
  let newSession = function(){
      var json = '{"gameName":"Phishing-Game", "saveName": "", "userID": "", "demoGame": false, "firstStartIntroduction": 0, "firstStartPlayerMenu": 0, "firstStartTrainingcenter": 0, "firstStartCriminalcases":0,'
        + '"completionTrainingscenter": 0, "dialogCompletionTrainingscenter": 0, "dialogCompletionTrainingscenterPlayermenu": 0,'
        + '"level1":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level1Complete": 0, "restartedAfterCompletion": 0},'
        + '"level2":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}], "level2Complete": 0, "restartedAfterCompletion": 0},'
        + '"level3":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level3Complete": 0, "restartedAfterCompletion": 0},'
        + '"level4":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level4Complete": 0, "restartedAfterCompletion": 0},'
        + '"level5":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level5Complete": 0, "restartedAfterCompletion": 0},'
        + '"level6":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level6Complete": 0, "restartedAfterCompletion": 0},'
        + '"criminalcases": []}';
    var saveGameObject = JSON.parse(json);

    // set the session saveGame to the newly generated object
    this.saveGame =  saveGameObject;

    // generate randomSaveName
    this.saveGame.saveName = this.randomSaveName();
    this.saveGame.userID = this.saveGame.saveName.toString();

    this.storeSaveGame();
  };

  // DemoDelfi - function to create three demo savegames
  let newDemoSession = function(){
    // delete complete localstorage
    sessionControl.resetLocalStorage();

    var jsonDone = '{"gameName":"Phishing-Game", "saveName": "1-Done", "userID": "1-Done", "demoGame": true, "firstStartIntroduction": 1, "firstStartPlayerMenu": 1, "firstStartTrainingcenter": 1, "firstStartCriminalcases":1,'
      + '"completionTrainingscenter": 1, "dialogCompletionTrainingscenter": 1, "dialogCompletionTrainingscenterPlayermenu": 1,'
      + '"level1":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}, {"savePoint5": 1}], "level1Complete": 1, "restartedAfterCompletion": 0},'
      + '"level2":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}], "level2Complete": 1, "restartedAfterCompletion": 0},'
      + '"level3":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}, {"savePoint5": 1}], "level3Complete": 1, "restartedAfterCompletion": 0},'
      + '"level4":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}, {"savePoint5": 1}], "level4Complete": 1, "restartedAfterCompletion": 0},'
      + '"level5":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}], "level5Complete": 1, "restartedAfterCompletion": 0},'
      + '"level6":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}], "level6Complete": 1, "restartedAfterCompletion": 0},'
      + '"criminalcases": []}';

    var jsonPlayed = '{"gameName":"Phishing-Game", "saveName": "2-Played", "userID": "2-Played", "demoGame": true, "firstStartIntroduction": 1, "firstStartPlayerMenu": 1, "firstStartTrainingcenter": 1, "firstStartCriminalcases":0,'
      + '"completionTrainingscenter": 0, "dialogCompletionTrainingscenter": 0, "dialogCompletionTrainingscenterPlayermenu": 0,'
      + '"level1":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 0}, {"savePoint5": 0}], "level1Complete": 0, "restartedAfterCompletion": 0},'
      + '"level2":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}], "level2Complete": 0, "restartedAfterCompletion": 0},'
      + '"level3":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level3Complete": 0, "restartedAfterCompletion": 0},'
      + '"level4":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level4Complete": 0, "restartedAfterCompletion": 0},'
      + '"level5":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level5Complete": 0, "restartedAfterCompletion": 0},'
      + '"level6":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level6Complete": 0, "restartedAfterCompletion": 0},'
      + '"criminalcases": []}';

    var jsonEmpty = '{"gameName":"Phishing-Game", "saveName": "3-Empty", "userID": "3-Done", "demoGame": true, "firstStartIntroduction": 0, "firstStartPlayerMenu": 0, "firstStartTrainingcenter": 0, "firstStartCriminalcases":0,'
      + '"completionTrainingscenter": 0, "dialogCompletionTrainingscenter": 0, "dialogCompletionTrainingscenterPlayermenu": 0,'
      + '"level1":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level1Complete": 0, "restartedAfterCompletion": 0},'
      + '"level2":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}], "level2Complete": 0, "restartedAfterCompletion": 0},'
      + '"level3":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level3Complete": 0, "restartedAfterCompletion": 0},'
      + '"level4":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level4Complete": 0, "restartedAfterCompletion": 0},'
      + '"level5":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level5Complete": 0, "restartedAfterCompletion": 0},'
      + '"level6":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level6Complete": 0, "restartedAfterCompletion": 0},'
      + '"criminalcases": []}';

    var jsonEvalDone = '{"gameName":"Phishing-Game", "saveName": "4-Eval", "userID": "4-Eval", "demoGame": true, "evaluationGame": true, "retestGame": false,'
      + '"completionEvaluationScreen": 1, "completionPretest": 1, "completionPosttest": 0, "completionRetest": 0,'
      + '"firstStartIntroduction": 1, "firstStartPlayerMenu": 1, "firstStartTrainingcenter": 1, "firstStartCriminalcases":1,'
      + '"completionTrainingscenter": 1, "dialogCompletionTrainingscenter": 1, "dialogCompletionTrainingscenterPlayermenu": 1,'
      + '"level1":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}, {"savePoint5": 1}], "level1Complete": 1, "restartedAfterCompletion": 0},'
      + '"level2":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}], "level2Complete": 1, "restartedAfterCompletion": 0},'
      + '"level3":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}, {"savePoint5": 1}], "level3Complete": 1, "restartedAfterCompletion": 0},'
      + '"level4":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}, {"savePoint4": 1}, {"savePoint5": 1}], "level4Complete": 1, "restartedAfterCompletion": 0},'
      + '"level5":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}], "level5Complete": 1, "restartedAfterCompletion": 0},'
      + '"level6":{"savePoints": [{"savePoint1": 1}, {"savePoint2": 1}, {"savePoint3": 1}], "level6Complete": 1, "restartedAfterCompletion": 0},'
      + '"criminalcases": [],'
      + '"pretest": [],'
      + '"posttest": [],'
      + '"retentiontest": []}';

  var saveGameObjectDone = JSON.parse(jsonDone);
  var saveGameObjectPlayed = JSON.parse(jsonPlayed);
  var saveGameObjectEmpty = JSON.parse(jsonEmpty);
  var saveGameObjectEvalDone = JSON.parse(jsonEvalDone);

  var jsonStringDone = JSON.stringify(saveGameObjectDone);
  var jsonStringPlayed = JSON.stringify(saveGameObjectPlayed);
  var jsonStringEmpty = JSON.stringify(saveGameObjectEmpty);
  var jsonStringEvalDone = JSON.stringify(saveGameObjectEvalDone);

  // store all three to the localStorage
  storeLocation.setItem(saveGameObjectDone.saveName, jsonStringDone);
  storeLocation.setItem(saveGameObjectPlayed.saveName, jsonStringPlayed);
  storeLocation.setItem(saveGameObjectEmpty.saveName, jsonStringEmpty);
  storeLocation.setItem(saveGameObjectEvalDone.saveName, jsonStringEvalDone);

};

// function creates a new evaluation saveGame
let newEvaluationSession = function(userID){
    var json = '{"gameName":"Phishing-Game", "saveName": "", "userID": "", "demoGame": false, "evaluationGame": true, "retestGame": false,'
      + '"completionEvaluationScreen": 0, "completionPretest": 0, "completionPosttest": 0, "completionRetest": 0,'
      + '"firstStartIntroduction": 0, "firstStartPlayerMenu": 0, "firstStartTrainingcenter": 0, "firstStartCriminalcases":0,'
      + '"completionTrainingscenter": 0, "dialogCompletionTrainingscenter": 0, "dialogCompletionTrainingscenterPlayermenu": 0,'
      + '"level1":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level1Complete": 0, "restartedAfterCompletion": 0},'
      + '"level2":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}], "level2Complete": 0, "restartedAfterCompletion": 0},'
      + '"level3":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level3Complete": 0, "restartedAfterCompletion": 0},'
      + '"level4":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}, {"savePoint4": 0}, {"savePoint5": 0}], "level4Complete": 0, "restartedAfterCompletion": 0},'
      + '"level5":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level5Complete": 0, "restartedAfterCompletion": 0},'
      + '"level6":{"savePoints": [{"savePoint1": 0}, {"savePoint2": 0}, {"savePoint3": 0}], "level6Complete": 0, "restartedAfterCompletion": 0},'
      + '"criminalcases": [],'
      + '"pretest": [],'
      + '"posttest": [],'
      + '"retest": []}';
  var saveGameObject = JSON.parse(json);

  // set the session saveGame to the newly generated object
  this.saveGame =  saveGameObject;

  // set user id and save name (equals)
  this.saveGame.userID = userID;
  this.saveGame.saveName = userID;

  this.storeSaveGame();
};


  // START - FUNCTIONS ONLY FOR INTERNAL USE, DO NOT EXPORT

  // function returns random saveName by maximum 4 characters
  var randomSaveName = function(){
    var random = Math.floor(Math.random()*1000);
    // check if randomSaveName already exists
    for (var k=0,len=storeLocation.length;k<len;k++){
      if(random == storeLocation.key(k)){
        randomSaveName();
      }
    }
    return random;
  };

  // function to get the saveGame
  var getSaveGame = function(){
    return this.saveGame;
  }

  // END - FUNCTIONS ONLY FOR INTERNAL USE, DO NOT EXPORT



  // START - FUNCTIONS FOR PUBLIC USE

  /**
   * This function changes the saveGame, for exmaple after loading an old saveGame. Call via sessionControl.
   * @function
   * @param {JSON} jsonString SaveGame to load as JSON-String
   */
  var  changeSaveGame = function(jsonString){
    this.saveGame = JSON.parse(jsonString);
  }

  /**
   * This function stores the saveGame to the localStorage
   * @function
   */
  var storeSaveGame = function(){
    var jsonString = JSON.stringify(this.saveGame);
    storeLocation.setItem(this.saveGame.saveName, jsonString);
  }

  /**
   * This function resets the localStorage - DemoDelfi
   * @function
   */
  var resetLocalStorage = function(){
    storeLocation.clear();
  }


  /**
   * Service function to evaluate the level progress and give the exact progress back in savepoints. Call via sessionControl.
   * @function
   * @param {number} levelNumber The level for which you want to know the progress
   * @return {Array} Returns the properties for the level progress: [levelStatus, stepsAlreadyDone, stepsAtAll, nextStepToDo]
   */
  var determineLevelProgress = function(levelNumber){
    var searchKey1 = 'level' + levelNumber;
    var searchKey2 = 'level' + levelNumber + 'Complete';
    var levelBefore1 = 'level' + (levelNumber-1);
    var levelBefore2 =  'level' + (levelNumber-1) + 'Complete';

    function getSearchKey1(){
      return searchKey1;
    }
    function getSearchKey2(){
      return searchKey2;
    }

    // DETERMINE LEVEL PROGRESS FROM HERE

    if(this.saveGame[searchKey1][searchKey2] === 1 && this.saveGame[searchKey1]["restartedAfterCompletion"] === 0){
      // Level is completed, eventhough all savePoints
      return [1, this.saveGame[searchKey1].savePoints.length, this.saveGame[searchKey1].savePoints.length, undefined];
    }
    else if (this.saveGame[levelBefore1] != undefined){
      // level is locked, because the level before is not complete, completed savePoints is also zero
      if(this.saveGame[levelBefore1][levelBefore2] === 0){
        return [3, 0, this.saveGame[searchKey1].savePoints.length, 1];
      }
    }

    // level is not completed or is not locked because the level before has not been completed yet
    // Check now: incomplete level, restarted Level, restarted level but incomplete in the replay and: level is freshly unlocked

    let savePointsDoneCount = 0;

    // search for next step per one time change
    var checked = 0;
    var nextStep = 0;

    this.saveGame[searchKey1].savePoints.forEach(function(key){
      if(Object.values(key)[0] === 1){
        savePointsDoneCount += 1;
      }
      else if(Object.values(key)[0] === 0 && checked === 0){
        nextStep = savePointsDoneCount + 1;
        checked = 1;
      }
    }, determineLevelProgress);

    // if the there are completed steps
    if(savePointsDoneCount > 0){
      if(this.saveGame[searchKey1]["restartedAfterCompletion"] === 1){
        // level has been completed before but is now incompleted
        return [1, this.saveGame[searchKey1].savePoints.length, this.saveGame[searchKey1].savePoints.length, nextStep]
      }
      else {
        // level has NOT been completed before, but has incompleted steps
        return [2, savePointsDoneCount, this.saveGame[searchKey1].savePoints.length, nextStep];
      }
    }

    // level is freshly restarted after it had been completed before
    if(savePointsDoneCount === 0 && this.saveGame[searchKey1]["restartedAfterCompletion"] === 1){
      return [1, this.saveGame[searchKey1].savePoints.length, this.saveGame[searchKey1].savePoints.length, nextStep]
    }

    // level was restarted and is completly replayed
    if(savePointsDoneCount === this.saveGame[searchKey1].savePoints.length && this.saveGame[searchKey1]["restartedAfterCompletion"] === 1){
      return [1, this.saveGame[searchKey1].savePoints.length, this.saveGame[searchKey1].savePoints.length, undefined]
    }

    // none of the cases above match, level is unplayed and unlocked
    return [undefined, 0, this.saveGame[searchKey1].savePoints.length, nextStep];
  }



  // GETTER UND SETTER FOR LEVEL- AND STEPPROGRESS

  /**
   * Service function to reset level progress aka savePoints, needed for restart level. Call via sessionControl.
   * @function
   * @param {number} levelNumber The level for which you want reset the progress
   */
  var resetLevel = function(levelNumber){
      var searchKey = 'level' + levelNumber;
      // set restart label
      this.saveGame[searchKey]["restartedAfterCompletion"] = 1;
      // reset the savePoints, old completion ist saved through levelXcomplete
      this.saveGame[searchKey].savePoints.forEach(function(key, index, array){
          array[index][Object.keys(key)[0]] = 0;

      });
      // store new progress
      this.storeSaveGame();
  }


  /**
   * Service function to reset the progress of a step if the user failed the examination. Call via sessionControl.
   * @function
   * @param {number} levelNumber The level for which you want to reset a step
   * @param {number} stepNumber The step you want to reset
   */
  var resetStep = function(levelNumber, stepNumber){
    var searchKey = 'level' + levelNumber;
    var key = Object.keys(this.saveGame[searchKey].savePoints[(stepNumber-1)])[0];
    this.saveGame[searchKey].savePoints[(stepNumber-1)][key] = 0;
    this.storeSaveGame();
  }

  /**
   * Service function to save the progress of a step in case of user completion. Call via sessionControl.
   * @function
   * @param {number} levelNumber The level for which a step has been completed
   * @param {number} stepNumber The step completed
   */
  var stepComplete = function(levelNumber, stepNumber){
    var searchKey = 'level' + levelNumber;
    var key = Object.keys(this.saveGame[searchKey].savePoints[(stepNumber-1)])[0]
    this.saveGame[searchKey].savePoints[(stepNumber-1)][key] = 1;
    this.storeSaveGame();
  }

  /**
   * Service function to mark a level as complete. Call via sessionControl.
   * @function
   * @param {number} levelNumber The level which has been completed
   */
  var levelComplete = function(levelNumber){
    var searchKey = 'level' + levelNumber;
    var setKey = 'level' + levelNumber + 'Complete';
    // set completion level
    this.saveGame[searchKey][setKey] = 1;

    //check for trainingscenter completion
    if(levelNumber === phishingAcademy.tcLevelAmount){
      this.saveGame['completionTrainingscenter'] = 1;
    }
    this.storeSaveGame();
  }

  /**
   * Service function to get a value to a key inside of the saveGame. Call via sessionControl.
   * @function
   * @param {string} searchKey The string representation of the searched Key
   */
  var getValueToKey = function(searchKey){
    if(this.saveGame){
      if(this.saveGame[searchKey] == undefined){
        console.log("undefined");
        return 0;
      } else{
        return this.saveGame[searchKey];
      }
    }else{
      return 0;
    }
  }

  /**
   * Service function to set a value to a key inside of the saveGame. Call via sessionControl.
   * @function
   * @param {string} searchKey The string representation of the searched Key
   * @param {number} value The value to be set (Most time: 0 or 1)
   */
  var setValueToKey = function(searchKey, value){
    if(this.saveGame[searchKey] == undefined){
      return 0;
    } else{
      this.saveGame[searchKey] = value;
      this.storeSaveGame();
    }
  }

  /**
  * Service function to get the currentLevel-String
  *  @function
   * @param {string} newLevel The string representation of the level
   */
  var setCurrentLevel = function(newLevel){
    this.currentLevel = newLevel + " ";
  }

  /**
   * Service function to get the currentLevel-String
   * @function
   */
  var getCurrentLevel = function(){
    return this.currentLevel;
  }

  // END - FUNCTIONS FOR PUBLIC USE

  // publicate the methods to the outer space
  return {
    newSession: newSession,
    newDemoSession: newDemoSession,
    newEvaluationSession: newEvaluationSession,
    randomSaveName: randomSaveName,
    storeSaveGame: storeSaveGame,
    resetLocalStorage: resetLocalStorage, // DemoDelfi
    determineLevelProgress: determineLevelProgress,
    resetLevel: resetLevel,
    resetStep: resetStep,
    stepComplete: stepComplete,
    levelComplete: levelComplete,
    changeSaveGame: changeSaveGame,
    getValueToKey: getValueToKey,
    setValueToKey: setValueToKey,
    getCurrentLevel: getCurrentLevel,
    setCurrentLevel: setCurrentLevel
  }
})();

// Export the functions
module.exports = {
  sessionControl,
}
