/**
 * @Date:   2018-10-04T17:09:14+02:00
 * @Last modified time: 2018-11-29T15:36:25+01:00
 */



MTLG.loadOptions({
  "zoll":27, //screen size in inches
  "mockLogin":true, //set to true to skip login procedure
  "mockNumber":2, //number of generated fake logins if mockLogin is true
  "loginMode":6, //Determines the login procedure for userLogin
  "language":"de", //Highest priority language setting, device dependant
});
