function retest_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  retestControl();
}

// check wether tclevel 1 is choosen or not
function checkRetest(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "retest"){
    return 1;
  }
  return 0;
}

// LOGICAL PART DETERMINATING LEVELPROGRESS

// check which step is the next to draw
function retestControl(){

  // check if the introduction ist already completed - check not necessary for retest
  /*var flag = 0;//sessionControl.getValueToKey("completionPretest");

  if(flag === 0){
    drawRetest();
  } else {
    MTLG.lc.levelFinished({
      nextLevel: "introduction"
    });
  }*/

  drawRetest();

}

// START CONTENT OF PRETEST

/**
 * This function draws the pretest, all functions inside are only for internal use
 * @function
 */
function drawRetest(){

  // set currentLevel
  sessionControl.setCurrentLevel("Retest -");

  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // variables to save round progress
  let questionsToPlay = 48;
  let questionsAlreadyPlayed = 0;
  let questionArray = [];
  let randomIdArray = shuffleArray(generateArray(questionsToPlay)); //[];
  let correctCount = 0;

  // LOAD CURRENT JSON FILE WITH URLs
  var allURLs = require('../../urlretest.json');

  // CREATE THE QUESTION OBJECTS
  for(var i = 0; i < questionsToPlay;i++){
    let newRandom = randomIdArray[i];
    if(newRandom !== null){
      let randomURL;
      allURLs.urls.forEach(function(key, index, array){
        if(array[index].id === newRandom){
            randomURL = array[index];
        }
      });
      var question = new Question(newRandom, randomURL.url, randomURL.target, randomURL.reason, randomURL.phishing)
      questionArray.push(question);
    }
  }

  // CREATE EXAM OBJECT
  var exam = new Exam(questionArray);

  // DRAW FUNCTION FOR START SCREEN

  function drawRetestScreen(){
    // get stage, options, store location and set language shortie
    var stage = MTLG.getStageContainer();
    var options = MTLG.getOptions();
    var l = MTLG.lang.getString;

    // background
    var background = new createjs.Shape();
    background.graphics.beginFill("rgb(197, 235, 245)");
    background.graphics.drawRect(0, 0, options.width, options.height);
    stage.addChild(background);

    // Demo Delfi buttons
    if(phishingAcademy.demoMode === true){
      demoButtons();
    }

    // container for pretest details
    var retestContainer = new createjs.Container();
    // place container
    retestContainer.x = options.width * 0.25;
    retestContainer.y = options.height * 0.25;

    var retestHeadline = MTLG.utils.gfx.getText(l('retestHeadline'), "36px Bahnschrift", "black");
    retestHeadline.textAlign = "center";
    retestHeadline.x = options.width / 4;
    retestHeadline.y = 20;

    var retestExplanation = MTLG.utils.gfx.getText(l('retestExplanation'), "27px Arial", "black");
    retestExplanation.lineWidth = 940;
    retestExplanation.lineHeight = 30;
    retestExplanation.x = 15;
    retestExplanation.y = retestHeadline.getMeasuredHeight() + 35;

    // shape for headline
    var headlineShape = new createjs.Shape();
    headlineShape.x = 15;
    headlineShape.y = 15;
    headlineShape.graphics.s("black").mt(0, options.height * 0.04).lt(options.width / 2 - 30, options.height * 0.04);

    // general background shape
    var backgroundShape = new createjs.Shape();
    backgroundShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width / 2, options.height / 2, 5);
    backgroundShape.x = 0;
    backgroundShape.y = 0;

    // button to start
    var startButton = newButton(l('btRetest'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.25, 430, function(){
      stage.removeChild(retestContainer);
      //xapi call
      xapiButton({en: "Retest - Test start", de: "Retest - Test starten"});

      drawExam();
    });

    retestContainer.addChild(backgroundShape, headlineShape, retestHeadline, retestExplanation, startButton);
    stage.addChild(retestContainer);

  }

  function drawExam(){

    if(questionsAlreadyPlayed <= exam.questions.length - 1){
      // display the urls in the given order by the array
      drawQuestion(exam.questions[questionsAlreadyPlayed])
      showProgress()
      questionsAlreadyPlayed +=1;
    } else{

      // evaluate correctness for each url
      /*var correctCount;
      exam.questions.forEach(function(question){
        if(question.userAnswer === question.phishingAnswer){
          question.userCorrect = true;
        } else{
          question.userCorrect = false;
        }
        if(question.userCorrect === true){
          correctCount += 1;
        }
      });*/
      exam.userScore = correctCount;

      // if all urls done log the events in sessioncontrol, show short ending text and start into the game
      sessionControl.setValueToKey("retest", sessionControl.getValueToKey("retest").concat(exam));
      sessionControl.setValueToKey("completionRetest", 1);
      stage.removeChild(stage.getChildByName("progressText"));

      // xapi call
      xapiTestResult({en:"Retest", de:"Retest"}, correctCount);

      //drawPretestEnd()
      drawExamFeedback();

    }


  }


  // DRAW FUNCTION FOR THE PRETEST URLS

  function drawQuestion(question){
    var url = question.urlText;
    // container
    var urlContainer = new createjs.Container();
    urlContainer.x = 0;
    urlContainer.y = options.height / 3;

    drawURL();

    function drawURL(){

      // background shape
      var backgroundShape = new createjs.Shape();
      backgroundShape.graphics.beginFill("rgb(234, 234, 234)").drawRect(0, 0, options.width, options.height / 3);

      // url
      var urlText = MTLG.utils.gfx.getText(url, "32px Arial", "black");
      urlText.textAlign = 'center';
      //console.log(urlText.getBounds());
      //urlText.maxWidth = urlText.getBounds().width - 100;
      //urlText.scaleX = 0.85;
      urlText.x = options.width / 2;
      urlText.y = 30;

      // question text
      var urlTarget = MTLG.utils.gfx.getText(l('target') + ": " + question.target, "32px Arial", "black");
      urlTarget.textAlign = 'center';
      urlTarget.x = options.width / 2;
      urlTarget.y = 110;

      // question text
      var urlQuestion = MTLG.utils.gfx.getText(l('preQuestionPhishing'), "27px Arial", "black");
      urlQuestion.textAlign = 'center';
      urlQuestion.x = options.width / 2;
      urlQuestion.y = 190;

      // answer button
      var phishingTrueButton = newButton(l('phishingTrueButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.35, 250, function(){

        for(var j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === question.urlId){
            exam.questions[j].userAnswer = true;
            if(question.userAnswer === question.phishingAnswer){
              question.userCorrect = true;
              exam.questions[j].userCorrect = true;
            } else{
              question.userCorrect = false;
              exam.questions[j].userCorrect = false;
            }
            if(question.userCorrect === true){
              correctCount += 1;
            }
          }
        }

        // xapi call
        xapiURLResult({en:"Retest Phishing - URL-ID: " + question.urlId, de:"Retest Phishing - URL-ID: " + question.urlId}, question.userCorrect);

        // draw confidence
        urlContainer.removeChild(urlQuestion, phishingTrueButton, phishingFalseButton)

        // call confidence
        drawConfidence();
      });

      var phishingFalseButton = newButton(l('phishingFalseButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.65, 250, function(){

        for(let j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === question.urlId){
            exam.questions[j].userAnswer = false;
            if(question.userAnswer === question.phishingAnswer){
              question.userCorrect = true;
              exam.questions[j].userCorrect = true;
            } else{
              question.userCorrect = false;
              exam.questions[j].userCorrect = false;
            }
            if(question.userCorrect === true){
              correctCount += 1;
            }
          }
        }

        // xapi call
        xapiURLResult({en:"Retest Phishing - URL-ID: " + question.urlId, de:"Retest Phishing - URL-ID: " + question.urlId}, question.userCorrect);

        // draw confidence
        urlContainer.removeChild(urlQuestion, phishingTrueButton, phishingFalseButton)

        // call confidence
        drawConfidence();
      });


      // add all elements to container
      urlContainer.addChild(backgroundShape, urlText, urlTarget, urlQuestion, phishingTrueButton, phishingFalseButton);
      stage.addChild(urlContainer);
    }

    function drawConfidence(){
      // question confidence
      var confidenceQuestion = MTLG.utils.gfx.getText(l('preQuestionConfidence'), "27px Arial", "black");
      confidenceQuestion.textAlign = 'center';
      confidenceQuestion.x = options.width / 2;
      confidenceQuestion.y = 180;

      // answer button
      var confidence1 = newButton(l('confidence1'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 5 / 42, 250, function(){
        setConfidence(1);
      });
      var confidence2 = newButton(l('confidence2'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 13 / 42, 250, function(){
        setConfidence(2);
      });
      var confidence3 = newButton(l('confidence3'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 0.5, 250, function(){
        setConfidence(3);
      });
      var confidence4 = newButton(l('confidence4'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 8 / 14 + options.width * 5 / 42, 250, function(){
        setConfidence(4);
      });
      var confidence5 = newButton(l('confidence5'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 8 / 14  + options.width * 13 / 42, 250, function(){
        setConfidence(5);
      });

      urlContainer.addChild(confidenceQuestion, confidence1, confidence2, confidence3, confidence4, confidence5);

      function setConfidence(confidence){
        for(var j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === question.urlId){
            exam.questions[j].confidenceLevel = confidence;
          }
        }

        // xapi call
        xapiConfidenceResult({en:"Retest Confidence - URL-ID: " + question.urlId, de:"Retest Confidence - URL-ID: " + question.urlId}, confidence);

        stage.removeChild(urlContainer);

        // draw next url
        drawExam();
      }

    }


  }

  // Function to draw the complete Feedback
  function drawExamFeedback(){
    // remove all old things from the stages
    stage.removeAllChildren();

    // reset the general game background - to remove the image
    MTLG.clearBackground();

    // add old standard background
    var generalBackground = new createjs.Shape();
    generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(generalBackground);

    // individual background of exam feedback
    var background = new createjs.Shape();
    background.graphics.beginFill("rgb(197, 235, 245)");
    background.graphics.drawRect(0, 0, options.width, options.height);
    stage.addChild(background);

    // feedback container
    var feedbackContainer = new createjs.Container();
    feedbackContainer.x = options.width * 0.025;
    feedbackContainer.y = options.height * 0.25;

    // save the used lang key
    var langString = MTLG.lang.getLanguage();
    var count = 0;
    var previousHeight = 80;

    // Function to display the feedback of each url - internal use only in drawExamFeedback()
    function drawQuestionFeedback(index){
      var question = exam.questions[index];
      var number = index + 1;

      var correctAnswer;
      if(question.phishingAnswer === true){
        correctAnswer = l('phishingTrue');
      } else{
        correctAnswer = l('phishingFalse');
      }

      var userAnswer;
      if(question.userAnswer === true){
        userAnswer = l('phishingTrue');
      } else if(question.userAnswer === false){
        userAnswer = l('phishingFalse');
      } else {
        userAnswer = l('noUserAnswer');
      }

      // url carousel container
      var urlContainer = new createjs.Container

      // url feedback as one text
      var urlFeedback = MTLG.utils.gfx.getText(number + ". " + question.urlText + "\nZiel: " + question.target + "\n" + question.reason[langString] + "\n" + l('correctAnswer') + correctAnswer + "       " + l('userAnswer') + userAnswer, "27px Arial", "black");
      urlFeedback.lineHeight = 30;
      urlFeedback.lineWidth = 1450;
      urlFeedback.x = 15;
      urlFeedback.y = 15;

      // background shape
      var backgroundShape = new createjs.Shape();
      backgroundShape.graphics.beginFill("rgb(106, 198, 247)");
      backgroundShape.graphics.drawRoundRect(0, 0, options.width * 0.78, urlFeedback.getMeasuredHeight() + 25, 5);

      urlContainer.addChild(backgroundShape, urlFeedback);
      return urlContainer

    }

    var startPositionElements = {x: options.width * 0.085, y: 120};
    var startPositionNavigation = {leftx: options.width * 0.065, lefty: 160, rightx: options.width * 0.885, righty: 160};
    var startPositionPageDisplay ={x: options.width * 0.475, y: 400};
    carousel(exam.questions.length, 1, startPositionElements, startPositionNavigation, startPositionPageDisplay, drawQuestionFeedback, feedbackContainer)

    // mark completetion retest
    if(sessionControl.getValueToKey("completionRetest") === 0){
      sessionControl.setValueToKey("completionRetest", 1);

    }

    // exam feedback headline
    var examHeadline = MTLG.utils.gfx.getText(l('examFeedbackHeadline'), "36px Bahnschrift", "black");
    examHeadline.textAlign = 'center';
    examHeadline.x = options.width * 0.475;
    examHeadline.y = 15;

    // exam score
    var examScore = MTLG.utils.gfx.getText(l('examScore') + correctCount + " / " + questionsToPlay, "32px Arial", "black");
    examScore.textAlign = 'center';
    examScore.x = options.width * 0.475;
    examScore.y = 65;

    // exit button
    var exitFeedbackButton = newButton(l('exitFeedbackRetestButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.475, 440, function(){
      // xapi call
      xapiButton({en: "Retest - Close Feedback", de: "Retest - Feedback schließen"});

      stage.removeAllChildren();
      drawRetestEnd();

    });

    // background shape
    var backgroundShape = new createjs.Shape();
    backgroundShape.graphics.beginFill("rgb(234, 234, 234)");
    backgroundShape.graphics.drawRect(0, 0, options.width * 0.95, options.height * 0.5);

    feedbackContainer.addChild(backgroundShape, examHeadline, examScore, exitFeedbackButton);
    feedbackContainer.setChildIndex(backgroundShape, 0);

    // add container to stage
    stage.addChild(feedbackContainer);
  }

  function drawRetestEnd(){

    // add old standard background
    var generalBackground = new createjs.Shape();
    generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(generalBackground);

    // background
    var background = new createjs.Shape();
    background.graphics.beginFill("rgb(197, 235, 245)");
    background.graphics.drawRect(0, 0, options.width, options.height);
    stage.addChild(background);

    //draw small text and display start button for game
    var mainText = MTLG.utils.gfx.getText(l('afterReMainText'), "32px Arial", "black");
    mainText.textAlign = 'center';
    mainText.x = options.width / 2;
    mainText.y = 380;

    stage.addChild(mainText);
  }

  // Function to show the number of answered URLs
  function showProgress(){
    var progressText = stage.getChildByName("progressText");
    if(progressText === null){
      progressText = MTLG.utils.gfx.getText("Fortschritt:\n" + (questionsAlreadyPlayed) + " / " + questionsToPlay, "32px Arial", "black");
      progressText.name = "progressText";
      progressText.textAlign = 'center';
      progressText.x = options.width / 2;
      progressText.y = options.height * 0.75;
      stage.addChild(progressText);
    } else {
      progressText.text = "Fortschritt:\n" + (questionsAlreadyPlayed) + " / " + questionsToPlay;
    }
  }

  //********** START PRETEST **********
    // Call the start screen for retest to begin
    drawRetestScreen();



}
