/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T13:28:59+02:00
 */



function tclevel1_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawTclevel1();
}

// check wether tclevel 1 is choosen or not
function checkTclevel1(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "tclevel1"){
    return 1;
  }
  return 0;
}


// START DRAWING LEVEL 1

/**
 * This function contains the drawing of the first level of the trainingscenter
 * @function
 */
function drawTclevel1(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // background
  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  // Demo Delfi buttons
  if(phishingAcademy.demoMode === true){
    demoButtons();
  }


  // LOGICAL PART DETERMINATING LEVELPROGRESS

  // check which step is the next to draw
  function level1Control(){

    // function returns an array
    var levelProgress = sessionControl.determineLevelProgress(1);
    /**
      * because there not that much steps, call them directly instead of searching per recursion
      * next Step to do is stored in levelProgress[3] due to the return array
      */
    switch (levelProgress[3]){
      case 1:
        drawStep1();
        break;
      case 2:
        drawStep2();
        break;
      case 3:
        drawStep3();
        break;
      case 4:
        drawStep4();
        break;
      case 5:
        drawStep5();
        break;
      default:
        sessionControl.levelComplete(1);
        // xapi call
        xapiLevelEnd({en:"Trainingscenter - Level 1", de:"Trainingscenter - Level 1"});
        MTLG.lc.levelFinished({
          nextLevel: "trainingscenter"
        });
        break;
    }
  }

  // END LOGICAL PART DETERMINATING LEVELPROGRESS

  // GENERAL BUTTONS
  stage.addChild(levelChoiceButton());

  // CONTENT PART OF THE LEVEL - EACH STEP IS ONE INTERNAL FUNCTION

  // FIRST STEP
  function drawStep1(){
    var urlObject = require('../../urllevel1.json');
    var urlDisplay = addURL(urlObject.step1, 1);
    urlDisplay.x = urlDisplay.x - 150;
    stage.addChild(urlDisplay);
    stage.setChildIndex(urlDisplay, 1);

    // build in emergency button, if the tracking of the hits is not tracked
    var emergencyCounter = 0;
    // add gotIt-button
    var emergencyButton = newButton(l('btEmergency'), 16, "rgb(6, 225, 17)", 1, 30, 30, options.width * 0.98, 20, function(){
      var passphrase = MTLG.getOptions().passphrase_emergency;
      var check = window.prompt(l('alertEmergency'));

      if(check === passphrase){
        // save progess
        sessionControl.stepComplete(1,1);
        // xapi call
        xapiButton({en:"Trainingscenter - Level 1 - Step 1 - Got it", de:"Trainingscenter - Level 1 - Step 1 - Verstanden"});
        xapiStepEnd({en:"Trainingscenter - Level 1 - Step 1", de:"Trainingscenter - Level 1 - Step 1"});
        // go to step 2
        MTLG.lc.levelFinished({
          nextLevel: "tclevel1"
        });
      }else{
        window.alert(l('emergencyCheckFail'))
      }
    });
    emergencyButton.visible = false;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 1 - Step 1 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 1 - Step 1", de:"Trainingscenter - Level 1 - Step 1"});

    // add gotIt-button
    stage.addChild(newButton(l('urlAufbauVerstanden'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
      var checkShownComplete = true;
      console.log(phishingAcademy.arrayShownOnHover);
      Object.keys(phishingAcademy.arrayShownOnHover).forEach(function (key){
        if(phishingAcademy.arrayShownOnHover[key] === false){
          checkShownComplete = false;
        };
      })
      console.log(phishingAcademy.arrayShownOnHover);

      if(checkShownComplete){
        // save progess
        sessionControl.stepComplete(1,1);
        // xapi call
        xapiButton({en:"Trainingscenter - Level 1 - Step 1 - Got it", de:"Trainingscenter - Level 1 - Step 1 - Verstanden"});
        xapiStepEnd({en:"Trainingscenter - Level 1 - Step 1", de:"Trainingscenter - Level 1 - Step 1"});
        // go to step 2
        MTLG.lc.levelFinished({
          nextLevel: "tclevel1"
        });
      } else{
        // increase counter and check for emergency
        emergencyCounter++;
        if(emergencyCounter > 9){
          emergencyButton.visible = true;
        }
        // show hint that not all parts have been shown
        detectiveSpeech([l('level1step1partsMissing')], true);
      }
    }));

    stage.addChild(emergencyButton);

    // step dialogs
    var completeStepDialogs = [l('level1step1dialog1'), l('level1step1dialog2'), l('level1step1dialog3'), l('level1step1task'), l('level1step1dialog4')];
    var stepTask = l('level1step1task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // SECOND STEP
  function drawStep2(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 1 - Step 2 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 1 - Step 2", de:"Trainingscenter - Level 1 - Step 2"});

    // start drag and drop
    addDragAndDropUrl(["Subdomain","Domain","Top-Level-Domain"], function(){
      // callbackRight
      // save progress
      sessionControl.stepComplete(1,2);
      // xapi call
      xapiStepEnd({en:"Trainingscenter - Level 1 - Step 2", de:"Trainingscenter - Level 1 - Step 2"});
      xapiStepResult({en:"Trainingscenter - Level 1 - Step 2", de:"Trainingscenter - Level 1 - Step 2"}, true);
      // go to step 3
      MTLG.lc.levelFinished({
        nextLevel: "tclevel1"
      });}, function(){
        // callbackFalse
        sessionControl.resetStep(1,1);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 1 - Step 2", de:"Trainingscenter - Level 1 - Step 2"});
        xapiStepResult({en:"Trainingscenter - Level 1 - Step 2", de:"Trainingscenter - Level 1 - Step 2"}, false);
        // go to step 1 again
        MTLG.lc.levelFinished({
          nextLevel: "tclevel1"
        });
      });

    // step dialogs
    var completeStepDialogs = [l('level1step2dialog1'), l('level1step2task')];
    var stepTask = l('level1step2task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // THIRD STEP
  function drawStep3(){
    var urlObject = require('../../urllevel1.json');
    var textToBeClicked = ["toplevel"];

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 1 - Step 3 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 1 - Step 3", de:"Trainingscenter - Level 1 - Step 3"});

    // amount of rounds to be played
    let rounds = 3;

    function callbackPassed(){
      addFeedbackForStep(true, l('level1step3complete'), function(){
        // save progress
        sessionControl.stepComplete(1,3);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 1 - Step 3", de:"Trainingscenter - Level 1 - Step 3"});
        xapiStepResult({en:"Trainingscenter - Level 1 - Step 3", de:"Trainingscenter - Level 1 - Step 3"}, true);
        // go to level1
        MTLG.lc.levelFinished({
          nextLevel: "tclevel1"
        });
      });
    }

    function callbackFailed(){
      // user perfomance was not good enough
      addFeedbackForStep(false, l('level1step3failed'), function(){
        // remove the completion of first step
        sessionControl.resetStep(1,1);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 1 - Step 3", de:"Trainingscenter - Level 1 - Step 3"});
        xapiStepResult({en:"Trainingscenter - Level 1 - Step 3", de:"Trainingscenter - Level 1 - Step 3"}, false);
        // start level new to go to step 1
        MTLG.lc.levelFinished({
          nextLevel: "tclevel1"
        })
      });
    }

    // call the oursourced examination
    clickURL(0, rounds, urlObject.step3, textToBeClicked, callbackPassed, callbackFailed);

    // step dialogs
    var completeStepDialogs = [l('level1step3task')];
    var stepTask = l('level1step3task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // FOURTH STEP
  function drawStep4(){

    var urlObject = require('../../urllevel1.json');

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 1 - Step 4 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 1 - Step 4", de:"Trainingscenter - Level 1 - Step 4"});

    // add graphics to show
    var arrowRead = MTLG.assets.getBitmap('img/phishing/arrow-readdirection.png');
    var containerArrow = new createjs.Container();
    containerArrow.addChild(arrowRead);

    // arrow read direction
    var signArrow = new Sign(containerArrow, "above", ["all"], 1, 5, undefined, undefined, undefined, undefined);

    // info box
    var infoBoxContainer = new createjs.Container();
    var infoBoxShape = new createjs.Shape();
    infoBoxShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.75, options.height * 0.5, 5);
    infoBoxShape.x = 0;
    infoBoxShape.y = 0;
    infoBoxShape.graphics.setStrokeStyle(1);
    infoBoxShape.graphics.beginStroke("rgb(7, 103, 152)");
    infoBoxShape.setBounds(0,0, options.width * 0.75, options.height * 0.5);

    infoBoxContainer.setBounds(0,0, options.width * 0.75, options.height * 0.5);

    // content of speechbubble
    var infoBoxText = MTLG.utils.gfx.getText(l('level1step4infobox'), "27px Arial", "black");
    infoBoxText.lineWidth = 1421;
    infoBoxText.lineHeight = 35;
    infoBoxText.x =  15;
    infoBoxText.y = 15;

    // add domain resolve tree bitmap
    var domainTree = MTLG.assets.getBitmap('img/phishing/treeDNSwiki.png');
    domainTree.scale =  (infoBoxShape.getBounds().width / domainTree.getBounds().width) * 0.375;
    domainTree.x = infoBoxShape.getBounds().width * 0.35;//0.175;
    domainTree.y = (infoBoxShape.getBounds().height * 0.44);

    infoBoxContainer.addChild(infoBoxShape, infoBoxText, domainTree);

    var signInfoBox = new Sign(infoBoxContainer, "under", ["all"]);

    // save all signs in an array
    var signs = [signArrow, signInfoBox];
    // draw complete url and add arrow over the url
    var urlDisplay = addURL(urlObject.step4, 5, 0, signs);
    stage.addChild(urlDisplay);
    stage.setChildIndex(urlDisplay, 1);

    // add gotIt-button
    var gotItButton = newButton(l('urlLeserichtung'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // save progess
      sessionControl.stepComplete(1,4);
      // xapi call
      xapiButton({en:"Trainingscenter - Level 1 - Step 4 - Got it", de:"Trainingscenter - Level 1 - Step 4 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 1 - Step 4", de:"Trainingscenter - Level 1 - Step 4"});
      // go to step 2
      MTLG.lc.levelFinished({
        nextLevel: "tclevel1"
      });
    });

    stage.addChild(gotItButton);

    // step dialogs
    var completeStepDialogs = [l('level1step4dialog1'), l('level1step4task')];
    var stepTask = l('level1step4task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // FIFTH STEP
  function drawStep5(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 1 - Step 5 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 1 - Step 5", de:"Trainingscenter - Level 1 - Step 5"});

    // add multiple-choice question and feedback
    var question = new MultipleChoiceQuestion(l('level1step4Question'), [l('level1step4QuestionAnswer1'), l('level1step4QuestionAnswer2'), l('level1step4QuestionAnswer3'), l('level1step4QuestionAnswer4')], l('level1step4QuestionAnswer2'), l('level1step4QuestionReasonRight'), l('level1step4QuestionReasonFalse'));

    var visualQuestion = addMultipleChoice(question, function(){
      // callbackRight
      // save progress
      sessionControl.stepComplete(1,5);

      // xapi call
      xapiStepEnd({en:"Trainingscenter - Level 1 - Step 5", de:"Trainingscenter - Level 1 - Step 5"});

      detectiveSpeech([l('level1complete1'), l('level1complete2')], true, function(){
        // xapi call
        xapiStepResult({en:"Trainingscenter - Level 1 - Step 5", de:"Trainingscenter - Level 1 - Step 5"}, true);
        // restart level by callback
        MTLG.lc.levelFinished({
          nextLevel: "tclevel1"
        })});}, function(){
        // xapi call
        xapiStepResult({en:"Trainingscenter - Level 1 - Step 5", de:"Trainingscenter - Level 1 - Step 5"}, false);
        // callbackFalse
		    sessionControl.resetStep(1,4);
        // start step again
        MTLG.lc.levelFinished({
          nextLevel: "tclevel1"
        });
      });

    stage.addChild(visualQuestion);

  }

  // END CONTENT PART OF THE LEVEL


  //********** START THE LEVEL **********
    // Call levelcontrol to begin the level
    level1Control();

}
