/**
 * @Date:   2018-10-04T17:09:14+02:00
 * @Last modified time: 2019-09-10T20:23:32+02:00
 */



MTLG.lang.define({
  en: {
    welcome: 'https://welcome-to-browse-n-play.de/choose-a-mode',
    magnifier_on: 'Magnifiermode on',
    magnifier_off: 'Magnifiermode off',
    tab0: 'Main menu',
    tab1: 'First tab',
    url1: 'https://en.wikipedia.org',
    one_player: 'One Player',
    two_player: 'Two Players',
    title_login: 'Type in User and password',
    title_field1: 'Take the rectangle to the white area',
    title_field2: 'Press the fields with the numbers in the right order...',
    temp_feedback: 'Your needed time in milliseconds is: ',
    feedback_text: 'ms needed',
    settings: 'Settings',
    start: 'Start',
    return_to_menu: 'Return to Menu',
    tipp1: 'Try to find the marks with the magnifying glass!',
    // Phishing Academy
    // Buttons
    btNewGame: 'New Game',
    btLevelauswahl: 'Back to levelchoice',
    btMainGame: 'Back to the Main Game',
    btPlayerMenu: 'Back',
    btPhishingStart: 'Back to Menu',
    btRestartIntro: 'Restart Intro',
    btOptions: 'Options',
    btLoadGame: 'Load Game',
    btCopyright: 'Copyright-Advices',
    bestaetigen: 'Okay',
    btPlayLevel: 'Play Level',
    btPlayAgain: 'Play Again',
    btPlayOn: 'Play Level on',
    btGameSettings: 'Open Settings',
    // Start Buttons in Level 1 for starting the different games
    startPhishingAcademyButton: 'Start Game',
    // Phishingmenu
    languageSelection: 'Sprache wählen / Select language:',
    copyrightText: 'The Educational Game \"Phishing Academy\" was produced in the context of a Bachelor Thesis by Sven Schöbel at the Lehr- und Forschungsgebiet Informatik 9 at RWTH Aachen University.\n\nThe Game is licensed under CC-BY-SA 4.0.\nYou can find a detailed information concerning the license at:\nhttps://creativecommons.org/licenses/by-sa/4.0/',
    headlineCopyright: 'Copyright Advices:',
    pictureSources: 'All used graphics oder pictures are licensed under CC0 and have been published at pixabay.com or openclipart.com, except for the following sources.\n\nPicturesources:\nDNS-Baum Wikiepdia: https://de.wikipedia.org/wiki/Datei:Dns-raum.svg - Author: Hank von Helvete (CC-BY-SA)',
    headlineLoad: 'Loadable Savegames:',
    cancelLoading: 'Exit',
    cancelCopyright: 'Close',
    headlineSettings: 'Settings',
    cancelSettings: 'Close',
    settingsDemoText: 'Click the following button to activate demo mode.',
    btDemo: 'Activate demo-mode',
    demoCheck: 'Please provide the correct passphrase for starting the demo-mode. CAUTION: All existing save games will be deleted!',
    demoCheckFail: 'Inccorrect passphrase',
    // Playermenu
    trainingscenterBoxHeadline: 'Trainingscenter',
    criminalcasesBoxHeadline: 'Criminalcases',
    saveGameName: 'Name of your savegame: ',
    criminalcasesLocked: 'You have to finish the Trainingscenter at first, before you can work on Criminalcases.',
    // Introduction
    notificationBoxHeadline: 'You got a new E-Mail',
    emailHeadlines: 'From: polizei@tech-city.de\nSubject: Become a Phishing-Detective now!',
    emailText: 'Detectives watch out!\nIn reaction to the attached newspaper article in the Tech City Anzeiger\nthe Police of Tech City decided to train new Phishing-Detectives to protect the citizens of Tech City on behalf of the increasing number of Phishing-Attacks. To train the Detectives, the Police of Tech City founded the Phishing-Academy.\nCome and visit the Phishing-Academy.\nBest regards\nExecutive Board - Police of Tech City',
    newsHeadline: 'Increasing number of Phishing-Attacks on Tech City!',
    newsText: 'Tech City | Even more of the citizens of Tech City got targeted by Phishing-Attacks in the last time. Through a faked E-Mail, Website or Chatmessage the attackers simulate the identity of a big company, to lure the victims through a link on a identically looking Website. On this faked Website the Attackers try to scam private informations like passwords from the victims. The Police of Tech City is incapable of handling the increasing number of Phishing-Attacks, so the official warning was publicated too late. According to the informations of this newspaper the Police of Tech City founded a Phishing Academy to train the existing Detectives for Phishing-Attacks.',
    introductionButtonOpenNews: 'Open newspaper article',
    introductionButtonCloseNews: 'Done reading the newspaper article',
    introductionButtonAcademy: 'Push to start the Phishing-Academy',
    newsAttachmentText: 'Attachment: newspaper article',
    // Levelnamen
    tclevelhead1: 'Website-URL',
    tclevelhead2: 'Website-URL',
    tclevelhead3: 'Phishing',
    tclevelhead4: 'Phishing',
    tclevelhead5: 'Phishing',
    tclevelhead6: 'Phishing',
    tclevel1subline: '- Structure and more -',
    tclevel2subline: '- Paths -',
    tclevel3subline: '- Subdomains -',
    tclevel4subline: '- Similar hosts -',
    tclevel5subline: '- Confusing hosts -',
    tclevel6subline: '- Prevention -',
    // Tutorial - Playermenu
    tutorial1: 'Hey You!\nDown here! Touch me to proceed!',
    tutorial2: 'Welcome to the Phishing Academy! I am Detective Dot and your mentor during the Phishing Academy at the Police Department of Tech City!',
    tutorial3: 'At first I want to explain a few things to you. On your right you see a number. With this number you can reload your actual game progress. Your progress will be saved automatically.',
    tutorial4: 'The Phishing Academy consists of two parts. At the beginning you have to complete the Trainingscenter. After passing you can work with other participants on old Criminalcases.',
    tutorial5: 'At the Trainingscenter you will have a look into the world of Phishing. Do not wait: Start the Trainingscenter through the button ahead now.',
    // Feedback
    clickFeedback: 'Click-Feedback',
    stepFeedback: 'Feedback',
    okay: 'Okay!',
    topleveldomain: 'Top-Level-Domain',
    secondleveldomain: 'Domain',
    thirdleveldomain: 'Subdomain',
    fourthleveldomain: 'Subsubdomain or Fourth-Level-Domain',
    fifthleveldomain: 'Subsubsubdomain or Fifth-Level-Domain',
    sixthleveldomain: 'Subsubsubsubdomain or Sixth-Level-Domain',
    protocol: 'Protocol',
    path:'Part of path',
    point:'Separation point',
    slash:'Slash',
    // URL MODUL
    urlDot: 'A Dot separates the different parts of a domain.',
    // MultipleChoice
    correctMulti: 'Correct! ',
    falseMulti: 'This is unfortunately wrong.',
    // Step task display
    stepTaskHeadline: 'Current task',
    showDetectiveSpeech: 'Repeat Detective-Dialog',
    stepTaskButtonShow: 'Show task',
    stepTaskButtonRemove: 'Hode task',
    // Utilities
    magGlassAdvice: 'Mag.-Glass stays at the drop point.',
    // Level 1
    urlAufbauVerstanden: 'Okay, I have discovered the URL-structure!',
    urlClickPruefen: 'Check answer',
    urlZuordnungPruefen: 'Check attribution',
    level1step3failed: 'You are not confident enough in Recognizing the Top-Level-Domain of a Website-URL. Please have another look on the URL-structure.',
    level1step3complete: 'Well done! Move on!',
    correctlevel1step3: 'Correct! You have choosen the correct elements of the Website-URL: ',
    falselevel1step3: 'Unfortunately you have chooden an incorrect element. The incorrect elements are marked red, all the required elements are marked green.',
    level1step3false1: '. This is not one of the required elements.',
    mixedlevel1step3: 'You have choosen both correct and inccorect elements. The incorrect elements are marked red, all the required elements are marked green.',
    urlLeserichtung: 'All clear!',
    level1step4infobox: 'Top-Level-Domain, Domain and Subdomain all together are known as \"Host\". While a normal text is read from left to right, the \"Host\" of the Website is read from right to read by the computer. The reason is the storing system of Website adresses. For opening a Website the computer searches in a sort of a tree directory for the route to the save location of the Website in the Internet. At the bottom you see a graphical example, how to find the Website of the german Wikipedia. After the Top-Level-Domain actually follows another Dot. On daily basis we omit this Dot because for more comfort, the Webbrowser will add the point automatically.',
    level1step4Question: 'Which direction of reading is used by the computer to process the Host of a Website-URL?',
    level1step4QuestionAnswer1: 'Left to right',
    level1step4QuestionAnswer2: 'Right to left',
    level1step4QuestionAnswer3: 'Undetermined',
    level1step4QuestionAnswer4:  'From outer to inner parts',
    level1step4QuestionReasonRight: 'The computer processes the Host from right to left.',
    level1step4QuestionReasonFalse: 'Please have another look at the latest informations.',
    level1step4failed:'Please have another look at the direction of read and the graphic.',
    level1step4complete: 'Well done! Let\'s move on!',
    level1complete1: 'Congratulations! You completed the first level\n and did the first step of working as a Phishing-Detective!',
    level1complete2: 'More levels are already waitiing for you, go for it!',
    // Dialogues and tasks
    level1step1dialog1: 'If we want to access a website, we enter the address of the Website in the address bar of the browser.',
    level1step1dialog2: 'The address of a website is called a Website-URL. Every day we visit websites on our smartphones or laptops.',
    level1step1dialog3: 'But what components does such a Website-URL consist of at all? So that we can look at phishing attacks later, we must first deal with the structure.',
    level1step1task: 'Use the magnifying glass on the right to move over the Website-URL and discover the structure. If you think you have discovered all the elements, you can press the green button.',
    level1step1dialog4: 'Have fun Detective! And never forget the first Detective rule: Even small elements like a \"/\" can have a big meaning.',
    level1step2dialog1: 'All right, Detective! You now know the first parts of a Website-URL. To be able to work as a phishing detective, we need to make sure you really know your stuff.',
    level1step2task: 'So drag the three elements to the right place on the empty Website-URL structure.',
    level1step2dialog2: 'Once you have assigned all the elements, you can have your answer checked with a green button. Good luck!',
    level1step3task: 'Please select the Top-Level-Domain in the displayed Website-URL. You can confirm your answer with the green button. After three displayed Website-URLs, we see further.',
    level1step3dialog1: 'But beware: Don\'t get confused and try to apply your discoveries. Good luck!',
    level1step4dialog1: 'Very good! Recognizing the Top-Level-Domain no longer presents you with any problems. But there is more to know about a Website-URL. What is the computer doing with the URL?',
    level1step4task: 'You can use the magnifying glass to discover again. When you are done discovering, go on with the green button.',
    // Level 2
    level2step1complete: 'That\'s right. You did not let yourself get confused by unknown elements.',
    level2step1failed: 'The unknown elements have led you astray. Look why.',
    level2step1dialog1: 'A Detective colleague just gave me an unknown Website-URL, because he could not determine the Top-Level-\nDomain himself. Maybe you can help me already!',
    level2step1task: 'Select the Top-Level-Ddomain and confirm again with the green button.',
    level2step2dialog1: 'In order to transfer the contents of a website, various protocols exist. See which of the rules change for a Website-URL.',
    level2step2task: 'Use the magnifying glass for discovery. Once you have discovered all the new elements, you can continue by clicking the green button.',
    level2step3dialog1: 'So far, we have only discovered the elements of a URL with which we can access a Website. But how do we get to the other pages of a website apart from the home page? ',
    level2step3dialog2: 'Furthermore, search queries or input from you must be transferred to the website. \"Path\" and \"Query\" exist for these interactions.',
    level2step3task: 'Use the magnifying glass to discover the \"Paths\". Once you have discovered all the new elements, you can continue by clicking the green button.',
    level2step4dialog1: 'All right, all right! You now know all possible elements of a URL. Let\'s do a little test, then you are ready to get to know phishing attacks.',
    level2step4dialog2: 'I am looking forward to telling you all about it, then I will have a new colleague and Tech City will be a bit safer again.',
    level2step4task: 'Click on the first (!) element of the Path in the following Website-URLs. Think hard and use your knowledge. ',
    level2step4complete: 'Good nose. You have passed the test.',
    level2step4failed: 'Unfortunately, you did not recognize enough elements correctly. Look again at the explanation of \"Paths\".',
    level2complete: 'Very well solved! Let\'s go to the world of phishing! ',
    protokollVerstanden: 'Okay, let\'s go!',
    pathAufbauVerstanden: 'Okay, let\'s go!',
    // Level 3
    level3step1dialog1: 'After we have dealt with the structure of a Website-URL, we can look at various phishing attacks now.',
    level3step1dialog2: 'Here you can see an old phishing attack on the citizens of Tech City. In the two tabs above, two different Websites are open. But which one is the real? ',
    level3step1task: 'The website you are looking for is the login page of the German Wikipedia. Look at both websites and click the green button on the page you think is original.',
    level3step1buttonReal: 'This is the real Wikipedia page to log in to.',
    level3step1complete: 'You recognized the real Website. Your data would not have been transmitted to the attackers.',
    level3step1failed: 'This is the phishing page and not the real login page of Wikipedia. See why! ',
    level3step2explanation: 'For their attack, the phishing attackers use the domain of real websites as a subdomain under their own long, meaningless domain, for example a string of numbers (marked red above) or a confusing sequence of letters. The attackers want to confuse you, because you might think that you are really on the login\npage of Wikipedia. Other users, who do not know the URL structure, do not know how to identify the Top-Level-Domain in order to determine the actually accessed website via the domain. ',
    level3step2note: 'For users who know the URL structure, it is easy to see through this phishing attack:\nBy searching for the Top-Level-\nDomain, this can be identified and thus the actually called domain can also be found. ',
    level3step2noteHeadline: 'Detect Phishing!',
    level3step2gotIt: 'Let\'s go!',
    level3step2Question: 'To disguise phishing attacks specifically via subdomains, attackers use which of the following tricks?\n'
      + '\n1. Use of the domain of real websites as subdomain \n'
      + '\n2. Use of multiple (sub)subdomains to confuse the phishing victim by the length of the host \n'
      + '\n3. Long and meaningless domain names (e. g. with numbers)\n',
    level3step2QuestionAnswer1: '1. and 3.',
    level3step2QuestionAnswer2: '1. and 2.',
    level3step2QuestionAnswer3: '1., 2. and 3.',
    level3step2QuestionAnswer4: 'None of the three above',
    level3step2QuestionReasonRight: 'All three tricks are used by the attackers.',
    level3step2QuestionReasonFalse: 'Take a closer look at the latest information. ',
    level3complete1: 'Congratulations, you have learned the first tricks from phishing attackers and will soon be able to defend Tech City against such phishing attacks!',
    level3complete2: 'Let\'s go to the next level, where the next phishing technique awaits you.',
    level3step2failed: 'Your choice was unfortunately wrong. Please have another look at the phishing attack.',
    level3step2dialog1: 'It\'s getting tricky. Recognizing which website you are actually on, is the main point of phishing.',
    level3step2dialog2: 'There are several techniques to disguise the actual origin of the phishing Website. The first one is explained here.',
    level3step2task: 'Look at how this phishing technique works. The green button lets you continue as soon as you are ready. ',
    // Level 4
    loginUsername: 'Username',
    loginPassword: 'Enter password...',
    contentCatchPhrase: 'Join the network now!',
    imprintText: 'Imprint',
    dataprivacyText: 'Data privacy',
    contactText: 'Contact',
    loginPhrase: 'Login:',
    registrationPhrase: 'Register now!',
    level4step1dialog1: 'Yesterday we stopped a phishing attack that mainly affected the students in Tech City. The attackers were targeting a popular social network.',
    level4step1dialog2: 'An attempt was made to lure users to a phishing website in order to steal their login data.',
    level4step1dialog3: 'You can use the tabs to view both the wrong and the real login page of the social network. But can you determine the real login page?',
    level4step1task: 'We are looking for the real login page of the social network. Use the tabs above to view both sites and click the green button on what you think is the real login page.',
    level4step1buttonTab: 'This is the real login page.',
    level4step1complete: 'You recognized the real login page. Your data would not have been transmitted to the attackers.',
    level4step1failed: 'This is the phishing page and not the real login page of the social network. See why!',
    level4step2explanation: 'Each language has its own rules and sometimes its own signs. In German, for example, these are the umlauts ä, ö and ü. So that all characters can be used everywhere, the uniform code \"Punycode\" was developed. The uniqueness of the coding is particularly important, so that there is no duplication for different characters from different languages. The Website-URL is converted by the browser. Therefore, the above Website-URLs are used to access different websites. Phishing attackers also use this possibility, because for example a change from \"ue\" to \"ü\" can easily be overlooked.',
    level4step2noteHeadline: 'Detect phishing!',
    level4step2note: 'Since attackers often copy the look and feel of real Websites down to the last detail, only finding the correct host via a search engine helps to compare this host with the open Website. In some cases you can tell by the logo which website is the phishing attempt because \"ue\" and not the \"ü\" in the logo is used. ',
    level4step2gotIt:'Alright!',
    level4step2dialog1: 'Both Websites looked deceptively alike, didn\'t they? Hardly any person has noticed the difference. A small change in the domain and you will end up on a phishing Website!',
    level4step2task: 'Discover how the phishing attack works. The green button lets you continue as soon as you are ready.',
    level4step3dialog1: 'We can recognize the characters of our own languages immediately. But in the world there are many languages and therefore also many different characters, which can be very similar.',
    level4step3dialog2: 'So it gets really tricky to detect a phishing attack.',
    level4step3task: 'Discover the new phishing attack. Use the magnifying glass to search for differences between the two displayed Website-URLs and click the green button when you are done.',
    level4step3gotIt:'Alright!',
    level4step3explanation: 'This poses another problem. In the previous step you learned that the website URL is converted by punycode if necessary. In other languages, characters exist that look very similar to our Latin letters. For example, the Cyrillic \"a\" looks exactly like the Latin \"a\". The respective use of the two characters in a Website-URL thus leads to the call of two different websites. This can also be seen at the example Website-URLs above. ',
    level4step3noteHeadline: 'Detect phishing!',
    level4step3note: 'This phishing attack cannot be detected by the Host part of the Website-URL. The only thing that helps here is caution! Using a search engine, the correct Host must be found to manually retype the Host to reduce the risk of a phishing attack. But now a point has been reached where further features of a Website like \"certificates\" have to be checked, even if the Website-URL seems to be real.\nAs a general rule: It is better to take a detour by manually opening a Website in the browser than to fall for a phishing attack.',
    level4step4Question: 'Which of the language tricks for changing the Host are used to disguise phishing attacks?\n'
      + '\n1. Display options for umlauts of the German language\n'
      + '\n2. Emptying the Host\n'
      + '\n3. Similar looking characters of other languages\n',
    level4step4QuestionAnswer1: '1. and 3.',
    level4step4QuestionAnswer2: '1. and 2.',
    level4step4QuestionAnswer3: '1., 2. und 3.',
    level4step4QuestionAnswer4: 'None of the three above',
    level4step4QuestionReasonRight: 'Leaving the Host blank is not correct, this is not possible because the host is the essential part of a Website-URL.',
    level4step4QuestionReasonFalse: 'Check out the phishing attack again.',
    level4complete1: 'You now know that phishing cannot be detected with the eyes! So be vigilant yourself later.',
    level4complete2: 'But now let\'s go to the next level, there the next phishing technique is waiting for you. ',
    level4step4failed: 'Your choice was unfortunately wring. Please have a look at the phishing technique again.',
    // Level 5
    level5step1dialog1: 'We use the Website-URL to call up a website, but the computer internally uses a different representation for parts of the website URL.',
    level5step1task: 'Explore the second way to display the Host of a website and the resulting possibilities of a phishing attack. Click the green button when you\'re done.',
    level5step1gotIt: 'Alright!',
    level5step1explanation: 'In Level 1 you learned that the right website for a Host is searched in a kind of tree. However, the textual representation of the Host is only used by humans to remember the Host of a website. The computer uses the \"Internet Protocol\" (IP) service to visit a Website. The IP address of a website consists of four to eight character blocks, which in turn consist of numbers and letters. The \"Domain Name System\" service translates between the two representations. The \"detour\" via the textual form can also be omitted if the IP address of a website is known and this enables the call via IP address. The three websites above, for example, can be accessed directly via the IP address.',
    level5step1explanation2: 'In the past, attackers have tried to disguise the phishing attack by using IP addresses as the displayed Host. That there is no textual representation can be new and confusing for inexperienced people. In the end, it can mislead you into mistakenly trusting the website you are visiting.',
    level5step1noteHeadline: 'Detect phishing!',
    level5step1note: 'If you get to a website whose Host is only displayed as an IP address, you can instantly consider this Website-URL as a phishing attempt. No reputable website uses the IP address as its Host.',
    level5step2dialog1: 'Here you can see another possibility of a phishing attack. With your discoveries so far, you can easily defend yourself against this attack.',
    level5step2dialog2: 'Two apparently complete Hosts suddenly appear here. But which page is actually called? ',
    level5step2task: 'Click on the Domain of the called Website. You can check your answer by pressing the green button.',
    level5step2gotIt: 'Alright!',
    level5step2failed: 'Too bad, you don\'t seem to have considered the rules from Level 1. But don\'t worry: It follows an explanation along with a repetition.',
    level5step2complete: 'Well done! You detected the real Host',
    level5step3dialog1: 'For sure, you discovered that the second Host element was part of the \"Path\", not the real Host part. If not, here is the explanation.',
    level5step3task: 'Discover the background to this phishing attack. Click the green button when you are done. ',
    level5step3gotIt: 'Alright!',
    level5step3explanation: 'The attackers try to confuse inexperienced users with the specification of a second apparent Host. The Host element of a Website-URL to a popular Website such as Amazon (www.amazon.de) is inserted in the \"Path\. This could create the impression that a real Amazon page is being called.',
    level5step3noteHeadline: 'Detect phishing!',
    level5step3note: 'Always look for the real Host in the Website-URL! With the discovered rules from Level 1 this succeeds quite easily.\nRemember: Before the first single slash stands the Top-Level-\nDomain and the Domain. So you don\'t get confused by any more Hosts in the \"Path\".',
    level5complete1: 'You have already discovered many possibilities for phishing attacks. In the next level you\'ll get more tips and tricks on how not to get to a phishing Website in the first place.',
    level5complete2: 'Soon you will have completed the Trainingscenter. I am proud of you already! And now let\'s move on to the last level!',
    // Level 6
    level6step1dialog1: 'To avoid opening a phishing Website in the first place, messengers, Website browsers and e-mail programs offer the possibility to display or copy the target Website of a link.',
    level6step1task: 'Discover here the display possibility in an e-mail program and in a Messenger\nWhatsApp.',
    level6step1gotIt: 'Alright!',
    level6step2dialog1: 'In the common website browsers for electronic devices there is also the possibility to view a link in advance.',
    level6step2dialog2: 'You see here the link display of Website-\nbrowsers for the operating\nsystems Android, iOS und Windows. For display on the respective operating\nsystems other actions are partly necessary.',
    level6step2task: 'Again discover the display options.',
    level6step2gotIt: 'Alright!',
    level6step1mail: 'Functionality:\nIn order to be able to see the target website of a link in an e-mail, you only have to keep the mouse pointer over the link itself (also called \"Hovering\"). After a short time the Website-URL will be displayed automatically.',
    level6step1MailHeadline: 'E-mail programm (Windows)',
    level6step1Messenger: 'Functionality:\nWhatsApp automatically displays the complete Website-URL for each link. Here the Website-URL can be checked directly without further actions.',
    level6step1MessengerHeadline: 'WhatsApp',
    level6step2Browser: 'Functionality:\nAlso here you remain with the mouse pointer simply on the link. In the lower left corner of the browser window, the Website-URL appears.',
    level6step2BrowserHeadline: 'Windows/MacOS',
    level6step2Android: 'Functionality:\nBy pressing and holding the link, the above window appears on the display after a short time.',
    level6step2AndroidHeadline: 'Android',
    level6step2Ios: 'Functionality:\nBy pressing and holding the link, the link is framed after a short time. If you release it, the above display appears together with the Website-URL of the link.',
    level6step2IosHeadline: 'iOS',
    level6step3dialog1: 'Here is a summary of the Discoveries you made.',
    level6step3task: 'Look at the summary and click the green button when you are done. ',
    level6step3gotIt: 'Alright!',
    level6step3noteHeadline: 'Summary: Detect phishing!',
    level6step3note: 'In any case to be observed:\n\n1. Determine the target Website of a link and examine whether the link could lead to a phishing Website.\n2. The correctness of a Website-URL is not sufficient to ward off an attack from another source (such as cross-site scripting). In addition to the Website-URL, other security features must also be checked.\n\nAttackers use the following tricks for phishing:\n\n1. Host of a real Website as a subdomain.\n2. Changing the domain of a Host by umlauts.\n3. Character substitution in Website-URLs by similar symbols of other languages.\n4. Display an IP address instead of a Host with text representation\n5. Real Host as part of the \"Path\" under an incorrect host.',
    level6complete1: 'Besides the just shown programs, there are many more. For other apps and programs on your smartphone and computer there is often also the possibility to display the target of links.',
    level6complete2: 'Look it up the next time you use a program. So no attacker can direct you to phishing sites to steal your credentials or other private information!',
    level6complete3: 'Always heed the points of the summary just shown.',
    // Trainingscenter Dialogs
    trainingscenterStartDialog1: 'Welcome to the Phishing Academy Trainingscenter. Here you will find six levels about the structure of a Website-URL and phishing.',
    trainingscenterStartDialog2: 'Once you have successfully completed a Level, you can play the next Level. You can also replay a completed Level at any time. Good luck in the first Level!',
    trainingscenterCompletionDialog1: 'You did it! Now you\'re already an expert on phishing attacks and you can fend off phishing attacks. You now have the opportunity to investigate old Criminalcases from Tech City.',
    trainingscenterCompletetionDialog2: 'Your newly discovered knowledge will be put to the test and you can compete with other participants of the Phishing Academy. So give it all and have fun!',
    // PlayerMenu Dialogs
    speechPlayermenuRepeat: 'Speak Detective Dot',
    playermenuCompletionTrainingscenterDialog1: 'The old Criminalcases are now available and you can start them with the button to the upper right corner. Give your best!',
    // Criminalcases
    criminalcasesStartDialog1: 'Here you can train\nfurther with the help of old Criminalcases and measure yourself against the other participants. Your result will be saved for later evaluation. Have fun!',
    criminalcasesEndDialog: 'You can train as often as you want on old Criminalcases.',
    criminalcasesEndDialogMaxFour: 'If you are having trouble with individual phishing techniques, you can retrieve the levels from the training center at any time. And even better, you will meet me again.',
    criminalcasesEndDialogMaxSix: 'More than half! If you still have problems with the recognition of individual phishing techniques, you can repeat the levels in the training center again.',
    criminalcasesEndDialogMaxEight: 'A very good result. If you keep training, I might congratulate you on a perfect round soon!',
    criminalcasesEndDialogMaxNine: 'Terrific! You only missed once. Maybe you can make a perfect round with further training?',
    criminalcasesEndDialogMaxTen: 'A perfect round! Great job. Can you do this again with other selected Website-URLs? Give it a try! ',
    criminalcasesHeadline: 'Criminalcases',
    criminalcasesExplanation: 'Choose a game mode first. In the first mode, the website URLs are displayed one after the other for you to discover. In the second mode all website URLs appear directly at the start and you can choose freely. Click on the desired game mode and then start the game round via the button that appears.\nA game round lasts 120 seconds, after which it is automatically finished if you have not finished by then.',
    onlyOneHotspotButton: 'Only one Website-URL',
    allHotspotButton: 'All Website-URLs',
    startButton: 'Start Gameround!',
    ccQuestionPhishing: 'Is the above website URL suspicious of phishing?',
    phishingTrueButton: 'Yes',
    phishingFalseButton: 'No',
    answerLaterButton: 'Answer later',
    examFeedbackHeadline: 'Feedback and Score',
    exitFeedbackButton: 'Exit Criminalcases',
    phishingTrue: 'Yes',
    phishingFalse: 'No',
    correctAnswer: 'Solution: ',
    userAnswer: 'Your Solution: ',
    noUserAnswer: 'No answer given by you',
    examScore: 'Your Score: ',
    criminalcasesStopButton: 'Stop round',
    //DragAndDrop-Module
    dragAndDropComplete:'You have assigned the elements correctly. Keep it up!',
    dragAndDropFailed: 'You have not assigned all elements correctly. Take another look at the structure of a Website-URL.',
    //Demo Delfi
    btDemoDone: 'Done',
    btDemoPlayed: 'Played',
    btDemoEmpty: 'Empty',
    btDemoReset: 'Reset'
  },
  de: {
    welcome: 'https://herzlich-willkommen-bei-browse-n-play.de/waehle-einen-modus',
    magnifier_on: 'Lupenmodus an',
    magnifier_off: 'Lupenmodus aus',
    tab0: 'Hauptmenü',
    tab1: 'Erster Tab',
    url1: 'https://de.wikipedia.org',
    one_player: 'Ein Spieler',
    two_player: 'Zwei Spieler',
    title_login: 'Bitte Namen und Passwort eingeben',
    title_field1: 'Ziehe das Rechteck auf das weiße Feld',
    title_field2: 'Drücke die Nummern in der richtigen Reihenfolge...',
    temp_feedback: 'Deine benötigte Zeit (in ms) beträgt: ',
    feedback_text: 'ms gebraucht',
    settings: 'Einstellungen',
    start: 'Start',
    return_to_menu: 'Zurück zum Menü',
    tipp1: 'Ich brauche dringend deine Hilfe! Versuche die geheimen Spuren mit der Lupe zu finden!',
    // Phishing Academy
    // Buttons
    btNewGame: 'Neues Spiel',
    btLevelauswahl: 'Zurück zur Levelauswahl',
    btMainGame: 'Zurück zum Haupspiel',
    btPlayerMenu: 'Zurück',
    btPhishingStart: 'Zum Spielmenü',
    btRestartIntro: 'Intro wiederholen',
    btOptions: 'Optionen',
    btLoadGame: 'Spiel laden',
    btCopyright: 'Copyright-Hinweise',
    bestaetigen: 'Okay',
    btPlayLevel: 'Level spielen',
    btPlayAgain: 'Nochmal spielen',
    btPlayOn: 'Level weiterspielen',
    btGameSettings: 'Spieleinstellungen öffnen',
    btEvaluationGame: 'Evaluation starten', //  EN
    btcancelEvaluation: 'Abbrechen', //EN
    btEvaluationEntries: 'Weiter', // EN
    btPretest: 'Starten!', // EN
    btEvaluationPlay: 'Spiel starten!', // EN
    btRetest: 'Starten!', // EN
    btTabletMode: 'Tablet-Modus',
    // Start Buttons in Level 1 for starting the different games
    startPhishingAcademyButton: 'Spiel starten',
    // Phishingmenu
    languageSelection: 'Sprache wählen / Select language:',
    copyrightText: 'Das Lernspiel \"Phishing Academy\" ist 2019 im Rahmen der Bachelorarbeit von Sven Schöbel am Lehr- und Forschungsgebiet Informatik 9 an der RWTH Aachen entstanden und wurde 2020/21 in einer anschließenden Masterarbeit weiterentwickelt.\n\nDas Lernspiel steht unter der Lizenz CC-BY-SA 4.0.\nEine genaue Beschreibung der Lizenz ist auf folgender Seite einzusehen:\nhttps://creativecommons.org/licenses/by-sa/4.0/',
    headlineCopyright: 'Copyright Hinweise',
    pictureSources: 'Alle im Lernspiel genutzten Grafiken und Bilder außer den folgenden genannten Bilderquellen wurden unter CCO Lizenz auf pixabay.com oder openclipart.com veröffentlicht.\n\nBilderquellen:\nDNS-Baum Wikiepdia: https://de.wikipedia.org/wiki/Datei:Dns-raum.svg - Autor: Hank von Helvete (CC-BY-SA)',
    headlineLoad: 'Ladbare Spielstände:',
    cancelLoading: 'Abbrechen',
    cancelCopyright: 'Hinweis schließen',
    headlineSettings: 'Einstellungen',
    cancelSettings: 'Schließen',
    settingsDemoText: 'Klicke auf den folgenden Knopf, um den Demo-Modus zu aktivieren.',
    btDemo: 'Demo-Modus aktivieren.',
    demoCheck: 'Bitte gib den entsprechenden Code ein, um den Demo-Modus zu aktivieren. ACHTUNG: Alle vorhandenen Spielstände werden gelöscht!',
    demoCheckFail: 'Falscher Code!',
    // Evaluation
    headlineEvaluation: 'Evaluation der Phishing Academy', // EN
    evaluationMainText: 'Danke für deine Teilnahme an der Evaluation! Viel Spaß beim Spielen! \n\nSolltest du ausversehen den Browser schließen, ist das kein Problem. Du kannst deinen Fortschritt über den Knopf \"Spiel laden\" im Hauptmenü wieder laden.\n\nBei Fragen oder Problemen einfach die Hand heben.', // EN
    evaluationMenuText: 'Um später einzelne Personen identifizieren zu können, braucht es einen eindeutigen Identifikationscode aus vier einzelnen Teilen. \n\nDieser Identifikationscode wird nun durch dich bestimmt. So kannst du identifiziert werden, ohne das wir wissen, wer genau du bist.\n\nDu brauchst dir dabei nichts merken.\nBitte gib in das weiße Textfeld folgendes ein (ob in Groß- oder Kleinbuchstaben ist egal):', //EN
    evaluationEntry1: '1. Deine Jahrgangsstufe (z. B. 6 oder 8).', //EN
    evaluationEntry2: '2. Der Tag deines Geburtstags (z. B. 21).', //EN
    evaluationEntry3: '3. Der Monat deines Geburtstags als Zahl (z. B. April = 4).', //EN
    evaluationEntry4: '4. Deine Hausnummer (z. B. 26, wenn man in der Steinstr. 26 wohnt).', //EN
    evaluationInputDummy: 'Bitte anklicken und eingeben!',// EN
    evaluationWarning: 'Noch nichts eingegeben!', // EN
    target: 'Ziel', //EN
    headlineStatus: 'Evaluation der Phishing Academy',
    statusMainText: 'Hast du dieses Spiel schon einmal gespielt?',
    btYesEval: 'Ja',
    btNoEval: 'Nein',
    afterPreMainText: 'Du hast den Vortest erfolgreich absolviert. Jetzt kannst du die Phishing Academy spielen!', //EN
    afterReMainText: 'Danke für deine Teilnahme! Du hast den Nachtest erfolgreich absolviert. Du kannst nun den Fragebogen beantworten unter:\n\nwww.t1p.de/nachtest2021', //EN
    // Pretest //EN
    pretestHeadline: 'Vortest',
    pretestExplanation:'Dir werden einzeln insgesamt 24 Website-URLs gezeigt. Jede Website-URL stellt entweder eine Phishing-Website oder eine reale, echte Website dar. Zur Hilfe wird dir zudem das richtige Ziel (z. B. wikipedia.org) angezeigt.\n\nEntscheide zu jeder Website-URL, ob es sich um eine Phishing-Website handelt oder nicht. Im Anschluss wirst du zu jeder Website-URL befragt, wie sicher du dir bist.\n\nDu brauchst die Website-URLs NICHT im Browser öffnen!', //EN
    preQuestionPhishing: 'Führt die obige Website-URL auf eine betrügerische Phishing-Website?',
    preQuestionConfidence: 'Wie sicher bist du dir?',
    confidence1: 'Sehr unsicher',
    confidence2: 'Eher unsicher',
    confidence3: 'Weder noch',
    confidence4: 'Eher sicher',
    confidence5: 'Sehr sicher',
    // Posttest //EN
    evalcriminalcasesHeadline: 'Kriminalfälle',
    evalcriminalcasesExplanation: 'Wie im Vortest werden dir nun einzeln insgesamt 36 Website-URLs gezeigt. Hier bei den Kriminalfällen musst du diese aber erst mit der Lupe suchen, da sich die Website-URLs wie bei richtigen Kriminalfällen als Hinweise (kleine Kreise) verstecken. Sobald du einen Kreis gefunden hast, kannst du diesen anklicken.\n\nEntscheide dann wie im Vortest zu jeder Website-URL, ob es sich um einen Phishing-Angriff handelt oder nicht. Im Anschluss wirst du zu jeder Website-URL befragt, wie sicher du dir bist. Aber Achtung: Nun läuft ein Countdown mit zur Lösung wie bei echten Kriminalfällen. ',
    criminalcasesEndDialogEvaluation: 'Danke fürs Spielen! Das war die Phishing Academy. Du kannst jetzt den Online-Fragebogen ausfüllen!',
    criminalcasesNotPlayable: 'Der Test kann nur einmal absolviert werden im Evaluationsmodus!',
    // Retest //EN
    retestHeadline: 'Nachtest',
    retestExplanation:'Dir werden einzeln insgesamt 48 Website-URLs gezeigt. Jede Website-URL stellt entweder eine Phishing-Website oder eine reale, echte Website dar. Zur Hilfe wird dir zudem das richtige Ziel (z. B. wikipedia.org) angezeigt.\n\nEntscheide zu jeder Website-URL, ob es sich um eine Phishing-Website handelt oder nicht. Im Anschluss wirst du zu jeder Website-URL befragt, wie sicher du dir bist.\n\nDu brauchst die Website-URLs NICHT im Browser öffnen!', //EN
    reQuestionPhishing: 'Führt die obige Website-URL auf eine betrügerische Phishing-Website?',
    reQuestionConfidence: 'Wie sicher bist du dir?',
    exitFeedbackRetestButton: 'Feedback verlassen',
    tabletIdentification0: 'Gib deine Jahrgangsstufe ein (z. B. 6 oder 8).', //EN
    tabletIdentification1: 'Gib den Tag deines Geburtstags ein (z. B. 21).', //EN
    tabletIdentification2: 'Gib den Monatdeines Geburtstags als Zahl ein (z. B. April = 4).', //EN
    tabletIdentification3: 'Gib deine Hausnummer ein (z. B. 26, wenn man in der Steinstr. 26 wohnt).', //EN
    // Playermenu
    trainingscenterBoxHeadline: 'Trainingscenter',
    criminalcasesBoxHeadline: 'Kriminalfälle',
    saveGameName: 'Speichername deines Spielstands: ',
    criminalcasesLocked: 'Du musst erst das Trainingscenter abschließen, bevor du Kriminalfälle bearbeiten kannst.',
    // Introduction
    notificationBoxHeadline: 'Du hast eine neue E-Mail',
    emailHeadlines: 'Von: polizei@tech-city.de\nBetreff: Jetzt Phishing-Detektiv werden!',
    emailText: 'Alle Detektivinnen und Detektive aufgepasst!\nAls Reaktion auf die zunehmenden Phishing-Angriffe und den Zeitungsartikel hat die Leitung der Polizei von Tech City beschlossen, sofort neue Phishing-Detektive auszubilden. Die Phishing-Detektive sollen helfen, die stark ansteigende Anzahl von Phishing-Angriffen auf die Bürger und Bürgerinnen von Tech City abzuwehren.\nEigens dafür hat die Polizei von Tech City eine Phishing Academy ins Leben gerufen. Schau doch mal rein!\n\nViele Grüße\nDie Leitung der Polizei von Tech City',
    newsHeadline: 'Immer mehr Phishing-Angriffe in Tech City!',
    newsText: 'Tech City | In letzter Zeit werden immer mehr Bürgerinnen und Bürger von Tech City Opfer von Phishing-Angriffen. Dabei wird durch eine gefälschte E-Mail, Website oder auch Chatnachricht die Identität eines großen Unternehmens vorgespielt.\nDie Opfer werden über eine verlinkte Website-URL auf eine Website gelockt, die fast so aussieht wie die Originalwebsite. Auf dieser gefälschten Website ergaunern die Angreifer dann private Daten der Nutzer wie beispielsweise Passwörter. Die Polizei von Tech City kann die Flut von Phishing-Angriffen momentan nicht mehr kontrollieren.\nNach Informationen dieser Zeitung entwickelt die Polizei momentan einen Plan, um die jetzigen Detektivinen und Detektive weiterzubilden.',
    introductionButtonOpenNews: 'Zeitungsartikel öffnen',
    introductionButtonCloseNews: 'Zeitungsartikel gelesen',
    introductionButtonAcademy: 'Drücken zum Starten der Phishing-Academy',
    newsAttachmentText: 'Anhang: Zeitungsartikel',
    // Levelnamen
    tclevelhead1: 'Website-URL',
    tclevelhead2: 'Website-URL',
    tclevelhead3: 'Phishing',
    tclevelhead4: 'Phishing',
    tclevelhead5: 'Phishing',
    tclevelhead6: 'Phishing',
    tclevel1subline: '- Aufbau & co. -',
    tclevel2subline: '- Paths & Query -',
    tclevel3subline: '- Subdomains & Wörter -',
    tclevel4subline: '- Ähnliche Hosts -',
    tclevel5subline: '- Verwirrende Hosts -',
    tclevel6subline: '- Vorbeugen -',
    // Tutorial - Playermenu
    tutorial1: 'Hey Du!\nHier unten! Berühre mich, um fortzufahren!',
    tutorial2: 'Willkommen in der Phishing Academy! Ich bin Detektiv Dot und dein Mentor während der Phishing Academy hier auf dem Polizeirevier von Tech City!',
    tutorial3: 'Die Phishing Academy besteht aus zwei Teilen. Den Start macht das Trainingscenter. Danach kannst du dein Geschick an alten Kriminalfällen testen.',
    tutorial4: 'Im Trainingscenter entdeckst du nun die Welt des Phishings, also nichts wie los: Starte das Trainingscenter über den obigen Knopf.',
    // Feedback
    clickFeedback: 'Anklick-Feedback',
    stepFeedback: 'Feedback',
    okay: 'Okay!',
    topleveldomain: 'Top-Level-Domain',
    secondleveldomain: 'Domain',
    thirdleveldomain: 'Subdomain',
    fourthleveldomain: 'Subsubdomain oder auch Fourth-Level-Domain',
    fifthleveldomain: 'Subsubsubdomain oder auch Fifth-Level-Domain',
    sixthleveldomain: 'Subsubsubsubdomain oder auch Sixth-Level-Domain',
    protocol: 'Protokoll',
    path:'Teil des Paths',
    point:'Trennpunkt',
    slash:'Slash',
    // URL MODUL
    urlDot: 'Ein Punkt trennt die jeweiligen Bestandteile der Domain voneinender ab.',
    // MultipleChoice
    correctMulti: 'Richtig! ',
    falseMulti: 'Das ist leider falsch. ',
    // Step task display
    stepTaskHeadline: 'Aktuelle Aufgabenstellung',
    showDetectiveSpeech: 'Detektiv-Dialog wiederholen',
    stepTaskButtonShow: 'Aufgabe anzeigen',
    stepTaskButtonRemove: 'Aufgabe verbergen',
    // Utilities
    magGlassAdvice: 'Die Lupe bleibt dort, wo du sie loslässt.',
    // Level 1
    urlAufbauVerstanden: 'Okay, ich habe den URL-Aufbau entdeckt!',
    urlClickPruefen: 'Antwort prüfen',
    urlZuordnungPruefen: 'Zuordnung prüfen',
    btEmergency: 'E',
    alertEmergency: 'Code eingeben, um Level zu überpringen:',
    emergencyCheckFail: 'Falscher Code!',
    level1step3failed:'Du bist noch nicht sicher genug beim Erkennen der Top-Level-Domain einer Website-URL. Schau dir bitte nocheinmal den URL-Aufbau an.',
    level1step3complete: 'Gut gemacht! Weiter gehts!',
    correctlevel1step3: 'Richtig! Du hast die folgenden Elemente der Website-URL korrekt ausgewählt: ',
    falselevel1step3: 'Du hast leider ein falsches Element ausgewählt. Rot markiert sind die falschen Elemente, grün markiert die geforderten Elemente.',
    level1step3false1: '. Dieses Element ist nicht eines der geforderten Elemente.',
    mixedlevel1step3: 'Du hast sowohl falsche als auch richtige Elemente ausgewählt. Rot markiert die angelickten falschen Elemente, grün markiert alle geforderten Elemente.',
    urlLeserichtung: 'Alles klar!',
    level1step4infobox: 'Die Top-Level-Domain, Domain und Subdomain nennt man zusammengefasst \"Host\". Während wir einen Text von links nach rechts lesen, löst der Computer den \"Host\" einer Website von rechts nach links auf. Dies liegt an der Speicherung von Website-Adressen. Um eine Website aufzurufen sucht der Computer in einer Art Baumverzeichnis nach dem Weg zum Speicherort der Website im Internet. Unten siehst du eine Grafik, wie beispielsweise die Website der deutschen Wikipedia gefunden wird. Nach der Top-Level-Domain folgt eigentlich noch ein weiterer Punkt. Im Alltag lassen wir diesen Punkt aus Bequemlichkeit weg, der Webbrowser fügt diesen unsichtbar für uns ein.',
    level1step4Question: 'In welcher Leserichtung wird der Host-Anteil einer Website-URL durch den Computer verarbeitet?',
    level1step4QuestionAnswer1: 'Links nach Rechts',
    level1step4QuestionAnswer2: 'Rechts nach Links',
    level1step4QuestionAnswer3: 'Ist nicht festgelegt',
    level1step4QuestionAnswer4:  'Von Außen nach Innen',
    level1step4QuestionReasonRight: 'Der Hostanteil wird von rechts nach links verarbeitet.',
    level1step4QuestionReasonFalse: 'Schau dir die letzten Informationen noch einmal genauer an.',
    level1step4failed:'Schau dir bitte die Leserichtung und die Grafik nochmal an.',
    level1step4complete: 'Gut gemacht! Weiter gehts!',
    level1complete1: 'Glückwunsch! Du hast das erste Level ab-\ngeschlossen und damit den ersten Schritt zum Phishing-Detektiv gemeistert!',
    level1complete2: 'Auf dich warten noch weitere Level, also nichts wie los!',
    // Dialogues and tasks
    level1step1dialog1: 'Wenn wir eine Website aufrufen möchten, geben wir die Adresse der Website in die Adressleiste des Browsers ein.',
    level1step1dialog2: 'Die Adresse einer Website heißt Website-URL. Wir rufen jeden Tag Websites auf dem Smartphone oder dem Laptop auf.',
    level1step1dialog3: 'Doch aus welchen Bestandteilen besteht so eine Website-URL überhaupt? Damit wir später Phishing-Angriffe betrachten können, müssen wir uns erst mit dem Aufbau beschäftigen.',
    level1step1task: 'Fahre mit der Lupe rechts über die Website-URL und entdecke den Aufbau. Wenn du denkst, du hast alle Elemente entdeckt, kannst du den grünen Knopf drücken.',
    level1step1dialog4: 'Viel Spaß Detektiv! Und vergiss nie die erste Detektivregel: Auch kleine Elemente wie ein \"/\" können eine große Bedeutung haben.',
    level1step2dialog1: 'Alles klar, Detektiv! Du kennst nun die ersten Bestandteile einer Website-URL. Damit du als Phishing-Detektiv arbeiten kannst, müssen wir sicherstellen, dass du dich wirklich auskennst.',
    level1step2task: 'Ziehe daher die drei Elemente an die richtige Stelle der leeren Website-URL. Überprüfe deine Antwort mit dem grünen Knopf!',
    level1step3task: 'Wähle bitte in der jeweils angezeigten Website-URL die Top-Level-Domain aus. Deine Antwort kannst du mit dem grünen Button bestätigen. Nach drei angezeigten Website-URLs, sehen wir weiter.',
    level1step4dialog1: 'Sehr gut! Das Erkennen der Top-Level-Domain stellt für dich kein Problem mehr dar. Aber wie verarbeitet der Computer eine Website-URL?',
    level1step4task: 'Du kannst die Lupe wieder zum Entdecken nutzen. Weiter geht es über den grünen Knopf.',
    level1step1partsMissing: 'Du hast dir noch nicht alle fünf unterschiedlichen Elemente angeschaut! Detektiven darf aber nichts entgehen! Schau noch einmal genauer.',
    // Level 2
    level2step1complete: 'Richtig! Du hast dich nicht verwirren lassen von unbekannten Elementen.',
    level2step1failed: 'Die unbekannten Elemente haben dich in die Irre geleitet. Schau dir an warum.',
    level2step1dialog1: 'Ein Detektiv-Kollege hat mir gerade eine unbekannte Website-URL gegeben, da er selber die Top-Level-\nDomain nicht ermitteln konnte. Vielleicht kannst du mir ja schon helfen!',
    level2step1task: 'Wähle die Top-Level-Domain aus und bestätige wieder über den grünen Button.',
    level2step2task: 'Damit die Inhalte einer Website übertragen werden können, existieren verschiedene Protokolle. Nutze die Lupe zum Entdecken. Weiter geht es über den grünen Knopf!',
    level2step3dialog1: 'Bis jetzt haben wir nur die Elemente einer URL entdeckt, mit denen wir eine Website aufrufen können. Doch wie gelangen wir auf die anderen Seiten einer Website abseits der Startseite?',
    level2step3dialog2: 'Weiterhin müssen Suchanfragen oder Eingaben von dir an die Website übertragen werden. Für diese beiden Interaktionen existieren \"Path\" und \"Query\".',
    level2step3task: 'Nutze die Lupe zum Entdecken von \"Path\" und \"Query\". Hast du alle neuen Elemente entdeckt, kannst du über den grünen Button fortfahren.',
    level2step4dialog1: 'Alles klar! Testen wir dein Wissen! Danach bist du bereit, Phishing-Angriffe kennenzulernen.',
    level2step4task: 'Klicke in den folgenden Website-URLs jeweils das erste (!) Element des Paths an. Denke gut nach!',
    level2step4complete: 'Gute Spürnase. Den Härtetest hast du erfolgreich erledigt.',
    level2step4failed: 'Du hast leider nicht genug Elemente richtig erkannt. Schau die die Erklärung zum \"Path\" nochmal an.',
    level2complete: 'Sehr gut gelöst! Auf geht\'s in die Welt des Phishings!',
    protokollVerstanden: 'Okay, weiter gehts!',
    pathAufbauVerstanden: 'Okay, weiter gehts!',
    // Level 3 (muss noch ins Englische eingefügt werden)
    level3step1dialog1: 'Nachdem wir uns mit dem Aufbau einer Website-URL auseinandergesetzt haben, können wir nun verschiedene Phishing-\nAngriffe betrachten.',
    level3step1dialog2: 'Hier siehst du nun einen alten Phishing-Angriff auf die Einwohnerinnen und Einwohner von Tech City. In den zwei obigen Tabs sind unterschiedliche Websites geöffntet. Doch welche ist die Reale?',
    level3step1task: 'Die gesuchte Website ist die Anmelden-Seite der deutschen Wikipedia. Schau dir beide Websites an und klicke den grünen Knopf auf der deiner Meinung nach originalen Seite.',
    level3step1buttonReal: 'Dies ist die reale Wikipedia-Seite zum Anmelden.',
    level3step1complete: 'Du hast die reale Website erkannt. Deine Daten wären nicht an die Angreifer übermittelt worden.',
    level3step1failed: 'Dies ist die Phishing-Seite und nicht die reale Anmelde-Seite von Wikipedia. Schau nach warum!',
    level3step2explanation: 'Die Phishing-Angreifer nutzen für ihren Angriff die Domain realer Websites als Subdomain. Für den Phishing-Angriff gibt es dann zwei Möglichkeiten.\n1.) Verwendung einer langen nichtssagenden Domain. Zum Beispiel: Wirre Zeichenketten (siehe oben 1. Website-URL) oder sinnlose Zahlenfolgen wie im Schritt zuvor 1001...\n2.) Die Domain wird durch Wörter ersetzt, die Vertrauen erwecken sollen. Dies können Wörter sein, wie \"login\", \"safe\" oder auch Kombinationen von Wörtern (siehe oben 2. Website-URL).',
    level3step2note: 'Für Nutzer, die den URL-Aufbau kennen, ist es leicht diesen Phishing-Angriff zu durchschauen:\nDurch eine Suche nach dem ersten einzelnen(!) Slash kann die Top-Level-Domain identifiziert und anschließend die davorstehende aufgerufene Domain gefunden werden.',
    level3step2noteHeadline: 'Phishing erkennen!',
    level3step2gotIt: 'Weiter gehts!',
    level3step2failed: 'Du hast leider daneben gelegen. Schau dir den Phishing-Angriff nochmal in Ruhe an.',
    level3step2dialog1: 'Langsam wird es knifflig. Das Erkennen auf welcher Website man sich eigentlich befindet ist der Hauptpunkt beim Phishing.',
    level3step2task: 'Es gibt mehrere Techniken die eigentliche Herkunft der Phishing-Website zu verschleiern. Schau dir die erste Erklärung an. Weiter geht es über den grünen Knopf! ',
    level3step3dialog1: 'Ein weiterer Fall hat sogar mich als Sneaker-Fan erreicht. Ich konnte daher sofort meine Kollegen anrufen, damit keiner seine Login-Daten auf der Seite eingibt.',
    level3step3task: 'Die gesuchte Website ist die Startseite des Herstellers Pegasus. Schau dir beide Websites an und klicke den grünen Knopf auf der deiner Meinung nach originalen Seite.',
    level3step3buttonReal: 'Dies ist die reale Pegasus-Seite zum Anmelden.',
    level3step3complete: 'Du hast die reale Website erkannt. Deine Daten wären nicht an die Angreifer übermittelt worden.',
    level3step3failed: 'Dies ist die Phishing-Seite und nicht die reale Seite von Pegasus. Schau nach warum!',
    menText: 'Herren',
    womenText: 'Damen',
    kidsText: 'Kinder',
    contentCatchPhraselevel3step3: 'SALE: 20% auf alle Schuhe der letzten Kollektion!',
    level3step4explanation: 'Dieser Phishing-Angriff nutzt aus, dass man an einen bekannten Namen eines Unternehmens auch noch weitere Wörter anhängen kann. Wie im Schritt zuvor sollen diese Wörter vertrauenswürdig klingen und werden meist per Bindestrich angefügt.',
    level3step4note: 'Der Phishing-Angriff kann erkannt werden, indem zunächst die Top-\nLevel-Domain gesucht wird.\nDann wird überprüft, ob an den bekannten Namen in der Domain (z. B. google) verdächtige Wörter angehangen worden sind.\nDies können wie im Schritt zuvor Wörter sein, wie \"login\", \"secure\" oder auch passende Wörter wie \"stream\" bei Videoplattformen.',
    level3step4noteHeadline: 'Phishing erkennen!',
    level3step4gotIt: 'Weiter gehts!',
    level3step4failed: 'Du hast leider daneben gelegen. Schau dir den Phishing-Angriff nochmal in Ruhe an.',
    level3step4dialog1: 'Hier war das schnelle Erkennen echt wichtig. Pegasus ist eine sehr beliebte Schuhmarke in Tech City.',
    level3step4task: 'Schau dir an, wie diese Phishing-Technik funktioniert. Weiter geht es über den grünen Knopf!',
    level3step5Question: 'Um Phishing-Angriffe zu verschleiern, nutzen die Angreifer welche der folgenden Tricks?\n'
      + '\n1. Nutzung der Domain realer Websites als Subdomain\n'
      + '\n2. Anhängen von vertrauenswürdigen Wörtern an die Domain\n'
      + '\n3. Lange und nichtssagende Domains (z. B. eine Zeichenkette)\n',
    level3step5QuestionAnswer1: '1. und 3.',
    level3step5QuestionAnswer2: '1. und 2.',
    level3step5QuestionAnswer3: '1., 2. und 3.',
    level3step5QuestionAnswer4: 'Keine der obigen Drei',
    level3step5QuestionReasonRight: 'Alle drei Tricks werden durch die Angreifer genutzt.',
    level3step5QuestionReasonFalse: 'Schau dir die letzten Informationen nocheinmal genauer an.',
    level3complete1: 'Glückwunsch, du hast die ersten Tricks von Phishing-Angreifern kennengerlernt und kannst Tech City gegen solche Phishing-Angriffe bald verteidigen!',
    level3complete2: 'Ab mit dir in das nächste Level, dort wartet die nächste Phishing-Technik auf dich.',
    // Level 4 (muss noch ins Englische eingefügt werden)
    loginUsername: 'Nutzername',
    loginPassword: 'Password eingeben...',
    contentCatchPhrase: 'Trete jetzt dem Netzwerk bei!',
    imprintText: 'Impressum',
    dataprivacyText: 'Datenschutzerklärung',
    contactText: 'Kontakt',
    loginPhrase: 'Einloggen:',
    registrationPhrase: 'Jetzt registrieren!',
    level4step1dialog1: 'Die nächsten beiden Texte erfordern etwas Konzentration. Die Buchstaben wurden vermischt oder durch andere Zeichen ersetzt.',
    level4step1firstText: 'Ehct ksras! Dmait wri enein Txet lseen knneön, ist es nchit so witihcg, in wlecehr Rneflogheie die Bstachuebn in eneim Wort snid, das ezniige was wcthiig ist, dsas der estre und der leztte Bstabchue an der ritihcegn Pstoiin snid. Der Rset knan ein ttoaelr Bsinöldn sein, tedztorm knan man ihn onhe Pemoblre lseen. Das ist so, wiel wir nciht jeedn Bstachuebn enzelin leesn, snderon das Wrot als gzeans enkreenn. Jdecoh knan man deise Fhägikiet des Ghenirs acuh für Pihsnhig Agnrffie nzuetn!',
    level4step1secondText: 'N0CH 3R5T4UNL1CH3R W1RD 35, W3NN M4N BUCH5T4B3N DURCH 4ND3R3 Z31CH3N 4U5T4U5CHT. D1353 M1TT31LUNG Z31GT D1R, ZU W3LCH3N GR0554RT1G3N L315TUNG3N UN53R G3H1RN F43H1G 15T! 4M 4NF4NG 15T 35 51CH3R N0CH 5CHW3R, D45 ZU L353N, 483R M1TTL3W31L3 K4NN5T DU D45 W4HR5CH31NL1ICH 5CH0N G4NZ GUT L353N, 0HN3 D455 35 D1CH W1RKL1CH 4N5TR3NGT. 8331NDRUCK3ND, 0D3R? UND 4UCH D1353 3R5T4UNL1CH3 F43H1GK31T W1RD FU3R PH15H1NG 4NGR1FF3 V3RW3ND3T!',
    level4step1gotIt:'Ja, ich konnte mindestens einen der Text lesen.',
    level4step1gotItDetective: 'Super Leistung! Für die nächste Art von Phishing-Angriffen musst du daher aber besonders wachsam sein!',
    level4step1noChance: 'Nein, ich verstehe keinen der Texte.',
    level4step1noChanceDetective: 'Nicht schlimm! Es kann bei der folgenden Art von Phishing-Angriffen sogar von Vorteil sein.',
    level4step1task: 'Versuch beide Texte zu lesen und drücke anschließend einen der beiden Knöpfe!',
    level4step2task: 'Diese Art von Phishing-Angriffen ist besonders gemein und kann nur schwer entdeckt werden. Schau dir die Erklärung an! Weiter geht es über den grünen Knopf!',
    level4step2explanation: 'Bei diesem Phishing Angriff werden zwei verschiedene Techniken eingesetzt. Bei der ersten Technik enthält die Website-URL absichtlich Tippfehler. Bei der zweiten Technik werden Buchstaben durch andere Buchstaben oder Zahlen ersetzt, die sehr ähnlich aussehen. Die Angreifer hoffen bei beiden Techniken, dass die Fehler und Ersetzungen einfach überlesen werden. ',
    level4step2noteHeadline: 'Phishing erkennen!',
    level4step2note: 'Die Website-URL sollte auf verdächtige Zeichen und Tippfehler überprüft werden.\nManche Zeichen eignen sich für eine Ersetzung besonders gut. Zum Beispiel wird ein \"w\" durch zwei \"v\" oder ein kleines \"o\" durch die Zahl \"0\" ersetzt. Weitere Beispiele: Für ein kleines \"l\" die Zahl \"1\" oder für ein \"m\" zwei \"n\".',
    level4step2gotIt: 'Weiter gehts!',
    level4step3dialog1: 'Gestern haben wir einen Angriff entdeckt, der vorallem die Schulen betraf. Dabei wurde versucht, die SchülerInnen auf eine Phishing-Website zu locken, um die Login-Daten für ein soziales Netzwerk zu stehlen.',
    level4step3task: 'Gesucht ist die reale Anmelde-Seite des sozialen Netzwerks. Nutze die Tabs und klicke den grünen Knopf auf der deiner Meinung nach realen Anmelde-Seite.',
    level4step3buttonTab: 'Diese Seite ist die reale Anmelde-Seite.',
    level4step3complete: 'Du hast die reale Anmelde-Seite erkannt. Deine Daten wären nicht an die Angreifer übermittelt worden.',
    level4step3failed: 'Dies ist die Phishing-Seite und nicht die reale Anmelde-Seite des sozialen Netzwerks. Schau nach warum!',
    level4step4explanation: 'Jede Sprache hat ihre eigenen Regeln und teilweise auch eigene Zeichen. Im Deutschen sind das beispielsweise die Umlaute ä, ö und ü. Auch in anderen Sprachen existieren besondere Zeichen. So wurden bei Weiterentwicklungen auch diese besonderen Zeichen hinzugefügt und können daher für Websites genutzt werden. Aus diesem Grund werden durch die oben angezeigten Website-URLs verschiedene Websites aufgerufen. Phishing-Angreifer nutzen diese Möglichkeit ebenfalls, da beispielsweise eine Änderung von \"ue\" zu \"ü\" leicht übersehen werden kann.',
    level4step4noteHeadline: 'Phishing erkennen!',
    level4step4note: 'Da die Angreifer oft das Aussehen der realen Websites bis in das letzte Detail kopieren, hilft nur das Heraussuchen des korrekten Hosts über eine Such-\nmaschine, um diesen Host mit der geöffneten Website zu vergleichen. In manchen Fällen kann man am Logo oder dem Namen der Firma erkennen, welche Website der Phishing-Versuch ist, da \"ue\" und nicht das \"ü\" im Logo genutzt wird.',
    level4step4gotIt:'Alles klar!',
    level4step4dialog1: 'Beide Websites sahen täuschend gleich aus, oder? Kaum eine Person hat den Unterschied bemerkt. Eine kleine Änderung der Domain und schon landet man auf einer Phishing-Website! ',
    level4step4task: 'Entdecke wie der Phishing-Angriff funktioniert. Weiter geht es über den grünen Knopf!',
    level4step5Question: 'Welche der sprachlichen Tricks zur Veränderung des Hosts werden genutzt um Phishing-Angriffe zu verschleiern? \n'
      + '\n1. Darstellungsmöglichkeiten von Umlauten der deutschen Sprache\n'
      + '\n2. Keine Domain angeben\n'
      + '\n3. Ähnlich aussehende Schriftzeichen oder Tippfehler\n',
    level4step5QuestionAnswer1: '1. und 3.',
    level4step5QuestionAnswer2: '1. und 2.',
    level4step5QuestionAnswer3: '1., 2. und 3.',
    level4step5QuestionAnswer4: 'Keine der obigen Drei',
    level4step5QuestionReasonRight: 'Die Möglichkeit keine Domain angeben ist nicht korrekt. Dies ist nicht möglich, da die Domain ein essentieller Bestandteil einer Website-URL ist.',
    level4step5QuestionReasonFalse: 'Dur wirst noch einmal zu den Erklärungen zurückgebracht. Schau dir die Phishing-Angriffe nochmal an.',
    level4complete1: 'Du weißt jetzt, dass man Phishing sehr leicht übersehen kann! Sei also selbst wachsam. Aber jetzt erstmal ab mit dir in das nächste Level!',
    level4step4failed: 'Du hast leider daneben gelegen. Schau dir den Phishing-Angriff nochmal in Ruhe an.',
    // Level 5 (muss noch ins Englische eingefügt werden)
    level5step1dialog1: 'Wir benutzen für den Aufruf einer Website die Website-URL, doch der Computer nutzt intern eine andere Darstellung für Teile der Website-URL.',
    level5step1task: ' Entdecke die zweite Darstellungsmöglichkeit für den Host einer Website und die resultierenden Möglichkeiten eines Phishing-Angriffs. Klicke den grünen Knopf, wenn du fertig bist.',
    level5step1gotIt: 'Alles klar!',
    level5step1explanation: 'In Level 1 hast du gelernt, dass die passende Website zu einem Host in einer Art Baum gesucht wird. Die Website-URL benutzt aber nur der Mensch, um sich den Host einer Website merken zu können. Der Computer nutzt den Dienst \"Internet Protocol\" (IP), um eine Website aufzurufen. Die IP-Adresse einer Website ergibt sich aus vier bis acht Zeichenblöcken, die wiederum aus Zahlen und Buchstaben bestehen. Der Dienst \"Domain Name System\" übersetzt dabei zwischen den beiden Darstellungen. Der \"Umweg\" über die Website-URL kann aber auch ausgelassen werden. Die drei Websites oben können zum Beispiel direkt über die IP-Adresse aufrufen.',
    level5step1explanation2: 'In der Vergangenheit haben Angreifer durch die Nutzung von IP-Adressen als angezeigten Host versucht, den Phishing-Angriff zu verschleiern. Das keine textuelle Darstellung vorliegt, kann für unerfahrene Personen neu sein und verwirrend wirken. Am Ende kann es dazu verleiten, der aufgerufenen Website irrtümlich zu vertrauen.',
    level5step1noteHeadline: 'Phishing erkennen!',
    level5step1note: 'Wenn du auf eine Website gelangst, deren Host nur als IP-Adresse angezeigt wird, kannst du diese Website-URL sofort als Phishing-Versuch werten. Keine seriöse Website nutzt die IP-Adresse als Host.',
    level5step2dialog1: 'Hier siehst du eine weitere Möglichkeit eines Phishing-Angriffs. Mit deinen bisherigen Entdeckungen kannst du diesen Angriff aber leicht abwehren.',
    level5step2task: 'Hier tauchen auf einmal zwei vollständige Hosts auf. Aber welche Seite wird tatsächlich aufgerufen? Klicke die Domain der aufgerufenen Website an und prüfe deine Antwort mit dem grünen Knopf.',
    level5step2gotIt: 'Alles klar!',
    level5step2failed: 'Schade, du scheinst die Regeln aus Level 1 nicht bedacht zu haben. Aber keine Sorge: Es folgt einer Erklärung zusammen mit einer Wiederholung.',
    level5step2complete: 'Sehr gut! Du hast den richtigen Host erkannt.',
    level5step3dialog1: 'Mit Sicherheit hast du entdeckt, dass das zweite Host-Element ein Teil des \"Paths\" war und somit nicht der reale Host. Wenn nicht, folgt hier die Erklärung.',
    level5step3task: 'Entdecke den Hintergund zu diesem Phishing-Angriff. Klicke den grünen Knopf, wenn du fertig bist.',
    level5step3gotIt: 'Alles klar!',
    level5step3explanation: 'Die Angreifer versuchen unerfahrene Nutzer und Nutzerinnen mit der Angabe eines zweiten augenscheinlichen Hosts zu verwirren. Dabei wird im \"Path\" das Host-Element einer Website-URL zu einer beliebten Website wie Amazon (www.amazon.de) eingefügt. Dadurch könnte der Eindruck enstehen, dass wirkliche eine Amazon-Seite aufgerufen wird.',
    level5step3noteHeadline: 'Phishing erkennen!',
    level5step3note: 'Immer nach dem realen Host in der Website-URL suchen! Mit den entdeckten Regeln aus Level 1 gelingt dies ganz leicht.\nZur Erinnerung: Vor dem ersten einzelnen Slash steht die Top-Level-\nDomain und davor die Domain. So lässt du dich nicht von weiteren Hosts im \"Path\" verwirren.',
    level5complete1: 'Du hast jetzt schon viele Möglichkeiten für Phishing-\nAngriffe kennengelernt. Im nächsten Level erhälst du noch weitere Tipps und Tricks, wie du erst gar nicht auf eine Phishing-Website gerätst.',
    level5complete2: 'Bald hast du das Trainingscenter komplett absolviert. Ich bin jetzt schon stolz auf dich! Und jetzt nichts wie ab in das letzte Level!',
    // Level 6 (muss noch ins Englische eingefügt werden)
    level6step1dialog1: 'Damit man erst gar nicht eine Phishing-Website öffnet, gibt in Messengern, Website-Browsern und E-Mail Programmen die Möglichkeit die Zielwebsite eines Links anzuzeigen oder zu kopieren.',
    level6step1task: 'Entdecke hier die Anzeigemöglichkeit in einem E-Mail Programm und im Messenger\nWhatsApp.',
    level6step1gotIt: 'Alles klar!',
    level6step2dialog1: 'In den gängigen Website-Browsern für elektronische Geräte gibt es ebenfalls die Möglichkeit einen Link vorher zu betrachten.',
    level6step2task: 'Du siehst hier die Linkanzeige von Website-\nBrowsern für die Betriebs-\nsysteme Android, iOS und Windows. Zur Anzeige auf den jeweiligen Betriebs-\nsystemen sind zum Teil andere Aktionen notwendig.',
    level6step2gotIt: 'Alles klar!',
    level6step1mail: 'Funktionsweise:\nUm bei einer E-Mail die Zielwebsite eines Links sehen zu können, muss man nur mit dem Mauszeiger auf dem Link selbst stehen bleiben (auch \"Hovern\" genannt). Nach kruzer Zeit wird die Website-URL automatisch angezeigt.',
    level6step1MailHeadline: 'E-Mail Programm (Windows)',
    level6step1Messenger: 'Funktionsweise:\nWhatsApp zeigt bei jedem Link automatisch die komplette Website-URL an. Hier kann die Website-URL ohne weitere Aktionen direkt überprüft werden.',
    level6step1MessengerHeadline: 'WhatsApp',
    level6step2Browser: 'Funktionsweise:\nAuch hier bleibst du mit dem Mauszeiger einfach auf dem Link stehen. Unten links in der Ecke des Browserfensters erscheint daraufhin die Website-URL.',
    level6step2BrowserHeadline: 'Windows/MacOS',
    level6step2Android: 'Funktionsweise:\nDurch Drücken und Halten des Links erscheint nach kurzer Zeit obiges Fenster auf dem Display.',
    level6step2AndroidHeadline: 'Android',
    level6step2Ios: 'Funktionsweise:\nDurch Drücken und Halten des Links wird der Link nach kurzer Zeit umrahmt. Lässt man darafhin los, erscheint obige Anzeige zusammen mit der Website-URL des Links.',
    level6step2IosHeadline: 'iOS',
    level6step3task: 'Hier eine Zusammenfassung der bisherigen Erkenntnisse. Betrachte die Zusammenfassung und klicke den grünen Knopf, wenn du fertig bist.',
    level6step3gotIt: 'Alles klar!',
    level6step3noteHeadline: 'Zusammenfassung: Phishing erkennen!',
    level6step3note: 'Was du immer zuerst tun solltest:\n\n1. Die Zielwebsite eines Links ermitteln und untersuchen, ob der Link auf eine Phishing-Website führen könnte. Dazu identifiziert du mit deinem neuen Wissen zunächst die Top-Level-Domain und die Domain. \n2. Wenn du dir unsicher bist beim einer Website-URL, nutze eine Suchmaschine (z. B. Google) und rufe darüber die Website auf! \n\nAngreifer nutzen für Phishing unter anderem folgende Tricks:\n\n1. Host einer realen Website als Subdomain.\n2. Vertrauenswürdige Wörter (z. B. \"account\") an die Domain anhängen.\n3. Ersetzung von Zeichen durch ähnlich aussehende Zeichen.\n4. Anzeigen einer IP-Adresse.\n5. Realer Host im \"Path\" unter einem falschen Host.',
    level6complete1: 'Neben den eben gezeigten Programmen, gibt es noch viele weitere. Für andere Smartphone-Apps und Programme auf dem Computer gibt es oft auch die Möglichkeit das Ziel von Links anzeigen zu lassen.',
    level6complete2: 'Schau doch bei der nächsten Benutzung direkt mal nach. So kann dich kein Angreifer mehr auf Phishing-Websites leiten, um deine Anmeldedaten oder andere private Informationen zu stehlen!',
    level6complete3: 'Beherzige immer die Punkte der gerade gezeigten Zusammenfassung.',
    // Trainingscenter Dialogs (muss noch ins Englische eingefügt werden)
    trainingscenterStartDialog1: 'Willkommen im Trainingscenter der Phishing-Academy. Hier erwarten dich sechs Level rund um den Website-URL Aufbau und Phishing.',
    trainingscenterCompletionDialog1: 'Geschafft! Jetzt bist du bereits ein Experte für Phishing-Angriffe und kannst auch Phishing-\nAngriffe abwehren. Du hast nun die Möglichkeit alte Kriminalfälle aus Tech City zu erforschen.',
  // PlayerMenu Dialogs (muss noch ins Englische eingefügt werden)
    speechPlayermenuRepeat: 'Detektiv Dot sprechen',
    playermenuCompletionTrainingscenterDialog1: 'Die alten Kriminalfälle sind jetzt freigeschaltet und können über den Knopf oben rechts gespielt werden. Gib dein Bestes!',
    // Criminalcases (muss noch ins Englische eingefügt werden)
    criminalcasesStartDialog1: 'Hier kannst du mit Hilfe\nalter Kriminalfälle weiter trainieren und dich mit den anderen Teilnehmern und Teilnehmerinnen messen. Dein Ergebnis wird zur späteren Evaluation gespeichert. Viel Spaß!',
    criminalcasesEndDialog: 'Du kannst beliebig oft an alten Kriminalfällen trainieren. ',
    criminalcasesEndDialogMaxFour: 'Wenn dir einzelne Phishing-Techniken Probleme bereiten, kannst du jederzeit die Level im Trainingscenter wieder-\nholen. Und noch besser: Mich triffst du dann auch wieder.',
    criminalcasesEndDialogMaxSix: 'Mehr als die Hälfte! Hast du noch Probleme mit der Erkennung von einzelnen Phishing-Techniken, kannst du die Level im Trainingscenter nocheinmal wiederholen.',
    criminalcasesEndDialogMaxEight: 'Ein sehr gutes Ergebnis. Trainierst du weiter, beglückwünsche ich dich vielleicht bald zu einer perfekten Runde!',
    criminalcasesEndDialogMaxNine: 'Spitzenmäßig! Du hast nur einmal daneben gelegen. Vielleicht schaffst du durch weiteres Training auch eine perfekte Runde?',
    criminalcasesEndDialogMaxTen: 'Eine perfekte Runde! Super gemacht. Schaffst du dieses Ergebnis auch nochmal mit anderen ausgewählten Website-URLs? Versuch es doch einmal!',
    criminalcasesHeadline: 'Kriminalfälle',
    criminalcasesExplanation: 'Wähle zuerst einen Spielmodus. Im ersten Modus werden dir die Website-URLs nacheinander zum Entdecken angezeigt. Im zweiten Modus erscheinen alle Website-URLs direkt zum Start und du kannst frei wählen. Klicke den gewünschten Spielmodus an und starte danach die Spielrunde über den erscheinenden Button.\nEine Spielrunde dauert 120 Sekunden, danach wird diese automatisch beendet, wenn du bis dahin nicht fertig geworden bist.',
    onlyOneHotspotButton: 'Nur eine Website-URL',
    allHotspotButton: 'Alle Website-URLs',
    startButton: 'Starten!',
    ccQuestionPhishing: 'Führt die obige Website-URL auf eine betrügerische Phishing-Website?',
    phishingTrueButton: 'Ja',
    phishingFalseButton: 'Nein',
    answerLaterButton: 'Später beantworten',
    examFeedbackHeadline: 'Feedback und Bewertung',
    exitFeedbackButton: 'Kriminalfälle verlassen',
    phishingTrue: 'Ja',
    phishingFalse: 'Nein',
    correctAnswer: 'Phishing-Lösung: ',
    userAnswer: 'Deine Lösung: ',
    noUserAnswer: 'Keine Antwort abgegeben',
    examScore: 'Deine Bewertung: ',
    criminalcasesStopButton: 'Runde abbrechen',
    //DragAndDrop-Module
    dragAndDropComplete:'Du hast die Elemente richtig zugeordnet. Weiter so!',
    dragAndDropFailed: 'Du hast nicht alle Elemente richtig zugeordnet. Du wirst nochmal zum URL-Aufbau zurückgesetzt. Schau dir dort den Aufbau nochmal genau an!', // EN
    //Demo Delfi
    btDemoDone: 'Done',
    btDemoPlayed: 'Played',
    btDemoEmpty: 'Empty',
    btDemoReset: 'Reset'
  },
});
