/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T13:29:25+02:00
 */



function tclevel4_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawTclevel4();
}

// check wether tclevel 4 is choosen or not
function checkTclevel4(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "tclevel4"){
    return 1;
  }
  return 0;
}

/**
 * This function contains the drawing of the fourth level of the trainingscenter
 * @function
 */
function drawTclevel4(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // clear stage rom all objects and listeners
  stage.removeAllEventListeners();
  stage.removeAllChildren();

  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  background.visible = false;
  stage.addChild(background);

  // Demo Delfi buttons
  if(phishingAcademy.demoMode === true){
    demoButtons();
  }


  // LOGICAL PART DETERMINATING LEVELPROGRESS

  // check which step is the next to draw
  function level4Control(){
    // function returns an array
    var levelProgress = sessionControl.determineLevelProgress(4);
    /**
      * because there not that much steps, call them directly instead of searching per recursion
      * next Step to do is stored in levelProgress[3] due to the return array
      */
    switch (levelProgress[3]){
      case 1:
        drawStep1();
        break;
      case 2:
        drawStep2();
        break;
      case 3:
        drawStep3();
        break;
      case 4:
        drawStep4();
        break;
      case 5:
        drawStep5();
        break;
      default:
        sessionControl.levelComplete(4);
        // xapi call
        xapiLevelEnd({en: "Trainingscenter - Level 4", de: "Trainingscenter - Level 4"});
        MTLG.lc.levelFinished({
          nextLevel: "trainingscenter"
        });
        break;
    }
  }

  // END LOGICAL PART DETERMINATING LEVELPROGRESS

  // GENERAL BUTTONS
  stage.addChild(levelChoiceButton());


  // CONTENT PART OF THE LEVEL - EACH STEP IS ONE INTERNAL FUNCTION

  // FIRST STEP
  // TWO TEXTS AND BUTTONS IF USER CAN READ BOTH TEXTS OR NOT INCLUDING DIALOGUES
  function drawStep1(){

    background.visible = true;

    // first text with letter changing excluding first and last letter
    var firstText = MTLG.utils.gfx.getText(l('level4step1firstText'), "27px Arial", "black");
    firstText.lineWidth = 600;
    firstText.lineHeight = 35;
    firstText.x =  240;
    firstText.y = 200;

    // second text with some letters replaced by numbers
    var secondText = MTLG.utils.gfx.getText(l('level4step1secondText'), "27px Arial", "black");
    secondText.lineWidth = 600;
    secondText.lineHeight = 35;
    secondText.x =  1080;
    secondText.y = 200;

    // got it button with dialogue
    var gotItButton = newButton(l('level4step1gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 4), function(){
      // remove all the other children from stage
      stage.removeChild(firstText, secondText, gotItButton, noChanceButton);
      // save game progress
      sessionControl.stepComplete(4,1);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 1 - Got it", de:"Trainingscenter - Level 4 - Step 1 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 4 - Step 1", de:"Trainingscenter - Level 4 - Step 1"});

      detectiveSpeech([l('level4step1gotItDetective')], true, function(){
        // restart level by callback
        MTLG.lc.levelFinished({
          nextLevel: "tclevel4"
        })
      });
    });

    // no chance button with dialogue
    var noChanceButton = newButton(l('level4step1noChance'), 27, "rgb(255, 250, 205)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 8), function(){
      // remove all the other children from stage
      stage.removeChild(firstText, secondText, gotItButton, noChanceButton);
      // save game progress
      sessionControl.stepComplete(4,1);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 1 - Got it", de:"Trainingscenter - Level 4 - Step 1 - Nicht Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 4 - Step 1", de:"Trainingscenter - Level 4 - Step 1"});

      detectiveSpeech([l('level4step1noChanceDetective')], true, function(){
        // restart level by callback
        MTLG.lc.levelFinished({
          nextLevel: "tclevel4"
        })
      });
    });

    stage.addChild(firstText, secondText, gotItButton, noChanceButton);

    // step dialogs
    var completeStepDialogs = [l('level4step1dialog1'), l('level4step1task')];
    var stepTask = l('level4step1task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // SECOND STEP
  // BEISPIELE UND TEXT ZU STEP 1
  function drawStep2(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 4 - Step 2 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 4 - Step 2", de:"Trainingscenter - Level 4 - Step 2"});

    //add both urls to the stage
    var stepURL = JSON.parse('[{"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "spakrasse-koelnbonn",'
        + '"toplevel": "de/"'
      + '},'
        + '"urldescription": {}'
      + '}, {"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "de",'
        + '"secondleveldomain": "vvikipedia.de",'
        + '"toplevel": "org/"'
      + '},'
        + '"urldescription": {}'
    + '}]');

    // add both urls
    var url1Display = addURL([stepURL[0]], 3, 0, 0);
    url1Display.getChildByName("wrapperURL").y = 0;
    var url2Display = addURL([stepURL[1]], 3, 0, 0);

    stage.setChildIndex(url1Display, 2);
    stage.setChildIndex(url2Display, 2);

    // explanation
    var explanationContainer = new createjs.Container();
    var explanationShape = new createjs.Shape();
    explanationShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape.x = 0;
    explanationShape.y = 0;
    explanationShape.graphics.setStrokeStyle(1);
    explanationShape.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer.x = (options.width-explanationContainer.getBounds().width) / 2;
    explanationContainer.y = options.height * 0.35;

    // content of speechbubble
    var explanationText = MTLG.utils.gfx.getText(l('level4step2explanation'), "27px Arial", "black");
    explanationText.lineWidth = 650;
    explanationText.lineHeight = 35;
    explanationText.x =  15;
    explanationText.y = 15;

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();
    noteContainer.x = explanationContainer.getBounds().width * 0.535;
    noteContainer.y = 0;

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scaleX = 0.3;
    noteBitmap.scaleY =0.3;
    noteBitmap.alpha = 1;
    noteContainer .addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level4step2noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 490;
    noteHeadline.textAlign = "center";

    var textLengthHeadline = noteHeadline.getBounds().width;
    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(45, options.height * 0.06).lt(noteContainer.getBounds().width - 45, options.height * 0.06);

    // rule
    var noteText = MTLG.utils.gfx.getText(l('level4step2note'), "27px Arial", "black");
    noteText.lineWidth = 490;
    noteText.lineHeight = 35;
    noteText.x = 45;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);
    explanationContainer.addChild(explanationShape, explanationText, noteContainer);

    // add gotIt-button
    var gotItButton = newButton(l('level4step2gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // remove all the other children from stage
      stage.removeChild(url1Display, url2Display, explanationContainer, background);
      // save game progress
      sessionControl.stepComplete(4,2);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 2 - Got it", de:"Trainingscenter - Level 4 - Step 2 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 4 - Step 2", de:"Trainingscenter - Level 4 - Step 2"});

      // restart level by callback
      MTLG.lc.levelFinished({
        nextLevel: "tclevel4"
      })});

    // add the elements to the stage
    stage.addChild(explanationContainer, gotItButton, url1Display, url2Display);

    // step dialogs
    var completeStepDialogs = [l('level4step2task')];
    var stepTask = l('level4step2task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }


  // THIRD STEP
  function drawStep3(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 4 - Step 3 -");

    // add button container
    var buttonContainer = new createjs.Container();
    stage.addChild(buttonContainer);

    // save choosen answer
    var choosenSite = "";

    // save active tab to stop breaking url text
    var activeTab = 1;
    var noChange = 1;

    function changeURLText(url, tabNumber){
        //getNavbarStage().getChildByName("navbar").getChildByName("url").text = url;
        MTLG.getStageContainer().getChildByName("navbar").getChildByName("url").text = url;
        if(/*getNavbarStage()*/MTLG.getStageContainer().getChildByName("navbar").getChildByName("url").text != url){
          changeURLText(url, tabNumber);
        } else {
          noChange = 1;
        }
    }

    const tab1 = createTab(l('tab1'), true);
    tab1.addEventListener('click', function(){
      // Change the displayed url in the adress bar


      // TEST 25.02
      if(noChange === 1 && activeTab !== 1){
        noChange = 0;
        activeTab = 1;
        changeURLText("https://www.schüler-netzwerk.de", 1);
      }

      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 3 - Tab 1", de:"Trainingscenter - Level 4 - Step 3 - Tab 1"});

      // Old version
      //changeURL("https://www.schueler-netzwerk.de");
      // add choose button
      buttonContainer.removeAllChildren();
      buttonContainer.addChild(newButton(l('level4step3buttonTab'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
        // save actual site
        choosenSite = "tab1";
        // xapi call
        xapiButton({en:"Trainingscenter - Level 4 - Step 3 - Choose Tab 1", de:"Trainingscenter - Level 4 - Step 3 - Wähle Tab 1"});
        xapiStepResult({en:"Trainingscenter - Level 4 - Step 3", de:"Trainingscenter - Level 4 - Step 3"}, false);

        addFeedbackForStep(false, l('level4step3failed'), nextStep);
      }));
    });

    // TEST 25.02
    console.log(getNavbarStage());

    const tab2 = createTab('Zweiter Tab', false);
    tab2.addEventListener('click', function(){
      // Change the displayed url in the adress bar

      // TEST 25.02
      if(noChange === 1 && activeTab !== 2){
        noChange = 0;
        activeTab = 2;
        changeURLText("https://www.schueler-netzwerk.de", 2);
      }

      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 3 - Tab 2", de:"Trainingscenter - Level 4 - Step 3 - Tab 2"});

      //changeURL("https://www.schüler-netzwerk.de");
      // add choose button
      buttonContainer.removeAllChildren();
      buttonContainer.addChild(newButton(l('level4step3buttonTab'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
        // save actual site
        choosenSite = "tab2";
        // xapi call
        xapiButton({en:"Trainingscenter - Level 4 - Step 3 - Choose Tab 2", de:"Trainingscenter - Level 4 - Step 3 - Wähle Tab 2"});
        xapiStepResult({en:"Trainingscenter - Level 4 - Step 3", de:"Trainingscenter - Level 4 - Step 3"}, true);

        addFeedbackForStep(true, l('level4step3complete'), nextStep);
      }));
    });

    // Create a tabbar by passing the three tabs
    const tabbar = createTabbar(MTLG.getOptions().width * 0.01, tab1, tab2);

    // Create the standard navbar
    const standard_navbar = createStandardNavbar('https://www.schüler-netzwerk.de');

    // Remove all Event-Listeners from the Navigation Buttons, because they call failing iframe functions
    standard_navbar.children[1].children.forEach(function(element){element.removeAllEventListeners();});

    // Add the tab- and navbar to the navbar stage
    //getNavbarStage().addChild(standard_navbar, tabbar);
    MTLG.getStageContainer().addChild(standard_navbar, tabbar);

    //add normal background
    const background = new createjs.Shape();
    background.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    background.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(background);

    // design the fake website with createjs
    var websiteContainer = new createjs.Container();
    websiteContainer.x = 0;
    websiteContainer.y = 0;

    // website frame
    var websiteFrame = new createjs.Shape();
    websiteFrame.graphics.beginFill("rgb(251, 251, 251)").drawRect(options.width * 0.175, options.height * 0.175, options.width * 0.65, options.height * 0.6);

    // logo
    var logoWebsite = MTLG.assets.getBitmap('img/phishing/schueler-netzwerk_logo.png');
    logoWebsite.scale = 0.1;
    logoWebsite.x = options.width * 0.2;
    logoWebsite.y = options.height * 0.2;

    // main content with login
    var containerContentWebsite = new createjs.Container();
    containerContentWebsite.x = options.width * 0.2;
    containerContentWebsite.y = options.height * 0.35;
    var loginPhrase = new createjs.Text(l('loginPhrase'), "35px Arial", "black");
    loginPhrase.x = 0;
    loginPhrase.y = 0;
    var loginUsernameField = new createjs.Shape();
    loginUsernameField.graphics.setStrokeStyle(1).beginStroke("black");
    loginUsernameField.graphics.beginFill(color).drawRect(0, options.height * 0.075, options.width * 0.2, options.width * 0.025);
    var loginPasswordField = new createjs.Shape();
    loginPasswordField.graphics.setStrokeStyle(1).beginStroke("black");
    loginPasswordField.graphics.beginFill(color).drawRect(0, options.height * 0.175, options.width * 0.2, options.width * 0.025);
    var loginUsernameText = new createjs.Text(l('loginUsername'), "27px Arial", "black");
    loginUsernameText.x = 15;
    loginUsernameText.y = options.height * 0.075 + 15;
    var loginPasswordText = new createjs.Text(l('loginPassword'), "27px Arial", "gray");
    loginPasswordText.x = 15;
    loginPasswordText.y= options.height * 0.175 + 15;
    var registrationPhrase = new createjs.Text(l('registrationPhrase'), "30px Arial", "black");
    registrationPhrase.x = options.width * 0.1;
    registrationPhrase.textAlign = "center";
    registrationPhrase.y = options.height * 0.25;
    var contentCatchPhrase = new createjs.Text(l('contentCatchPhrase'), "45px Arial", "black");
    contentCatchPhrase.x = options.width * 0.25;
    contentCatchPhrase.y = 0;
    var contentPicture = MTLG.assets.getBitmap('img/phishing/schueler-netzwerk_content.png');
    contentPicture.scale = 0.33;
    contentPicture.x = options.width * 0.25;
    contentPicture.y = options.height * 0.1;

    containerContentWebsite.addChild(loginUsernameField, loginPasswordField, loginUsernameText, loginPasswordText, loginPhrase, registrationPhrase, contentCatchPhrase, contentPicture);

    // footer of website
    var containerFooterWebsite = new createjs.Container();
    containerFooterWebsite.x = options.width * 0.2;
    containerFooterWebsite.y = options.height * 0.7;
    var footerBox = new createjs.Shape();
    footerBox.graphics.beginFill("rgb(255, 204, 102)").drawRoundRect(0, 0, options.width * 0.6, options.height * 0.05, 10);
    var imprintText = new createjs.Text(l('imprintText'), "27px Arial", "black");
    imprintText.x = 15;
    imprintText.y= 15;
    var dataprivacyText = new createjs.Text(l('dataprivacyText'), "27px Arial", "black");
    dataprivacyText.x= 15 + options.width * 0.15;
    dataprivacyText.y= 15;
    var contactText = new createjs.Text(l('contactText'), "27px Arial", "black");
    contactText.x= 15 + options.width * 0.4;
    contactText.y= 15;

    containerFooterWebsite.addChild(footerBox, imprintText, dataprivacyText, contactText);

    websiteContainer.addChild(websiteFrame, logoWebsite, containerContentWebsite, containerFooterWebsite);

    MTLG.getBackgroundStage().addChild(websiteContainer);

    // add button for first start
    buttonContainer.addChild(newButton(l('level4step3buttonTab'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
      // save actual site
      choosenSite = "tab1";
      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 3 - Choose Tab 1", de:"Trainingscenter - Level 4 - Step 3 - Wähle Tab 1"});
      xapiStepResult({en:"Trainingscenter - Level 4 - Step 3", de:"Trainingscenter - Level 4 - Step 3"}, false);

      addFeedbackForStep(false, l('level4step3failed'), nextStep);
    }));

    // step dialogs
    var completeStepDialogs = [l('level4step3dialog1'), l('level4step3task')];
    var stepTask = l('level4step3task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

    /**
      * function to start the new step
      */
    function nextStep(){
      // save progress
      MTLG.getBackgroundStage().removeAllChildren();
      getNavbarStage().removeAllChildren();
      getLayerStage().removeAllChildren();

      // add old standard background
      var generalBackground = new createjs.Shape();
      generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
      generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
      MTLG.getBackgroundStage().addChild(generalBackground);

      sessionControl.stepComplete(4,3);
      // restart level by callback
      MTLG.lc.levelFinished({
        nextLevel: "tclevel4"
      });
    }

  }

  // FOURTH STEP
  function drawStep4(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 4 - Step 4 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 4 - Step 4", de:"Trainingscenter - Level 4 - Step 4"});

    //add both urls to the stage
    var networkURL = JSON.parse('[{"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "schueler-netzwerk",'
        + '"toplevel": "de/"'
      + '},'
        + '"urldescription": {}'
      + '}, {"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "schüler-netzwerk",'
        + '"toplevel": "de/"'
      + '},'
        + '"urldescription": {}'
    + '}]');

    // add both urls and the logo and the logo
    var logoWebsite = MTLG.assets.getBitmap('img/phishing/schueler-netzwerk_logo.png');
    logoWebsite.scale = 0.1;
    logoWebsite.x = (options.width - logoWebsite.getBounds().width * 0.1) / 2;
    logoWebsite.y = options.height / 40;

    var url1Display = addURL([networkURL[0]], 3, 0, 0);
    url1Display.getChildByName("wrapperURL").y = 0;
    var url2Display = addURL([networkURL[1]], 3, 0, 0);

    stage.setChildIndex(url1Display, 2);
    stage.setChildIndex(url2Display, 2);

    // explanation
    var explanationContainer = new createjs.Container();
    var explanationShape = new createjs.Shape();
    explanationShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape.x = 0;
    explanationShape.y = 0;
    explanationShape.graphics.setStrokeStyle(1);
    explanationShape.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer.x = (options.width-explanationContainer.getBounds().width) / 2;
    explanationContainer.y = options.height * 0.35;

    // content of speechbubble
    var explanationText = MTLG.utils.gfx.getText(l('level4step4explanation'), "27px Arial", "black");
    explanationText.lineWidth = 650;
    explanationText.lineHeight = 35;
    explanationText.x =  15;
    explanationText.y = 15;

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();
    noteContainer.x = explanationContainer.getBounds().width * 0.535;
    noteContainer.y = 0;

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scaleX = 0.3;
    noteBitmap.scaleY =0.3;
    noteBitmap.alpha = 1;
    noteContainer .addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level4step4noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 490;
    noteHeadline.textAlign = "center";

    var textLengthHeadline = noteHeadline.getBounds().width;
    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(45, options.height * 0.06).lt(noteContainer.getBounds().width - 45, options.height * 0.06);

    // rule
    var noteText = MTLG.utils.gfx.getText(l('level4step4note'), "27px Arial", "black");
    noteText.lineWidth = 490;
    noteText.lineHeight = 35;
    noteText.x = 45;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);
    explanationContainer.addChild(explanationShape, explanationText, noteContainer);

    // add gotIt-button
    var gotItButton = newButton(l('level4step4gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // remove all the other children from stage
      stage.removeChild(url1Display, url2Display, explanationContainer, background);
      // save game progress
      sessionControl.stepComplete(4,4);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 4 - Got it", de:"Trainingscenter - Level 4 - Step 4 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 4 - Step 4", de:"Trainingscenter - Level 4 - Step 4"});

      // restart level by callback
      MTLG.lc.levelFinished({
        nextLevel: "tclevel4"
      })});

    // add the elements to the stage
    stage.addChild(explanationContainer, gotItButton, url1Display, url2Display, logoWebsite);

    // step dialogs
    var completeStepDialogs = [l('level4step4dialog1'), l('level4step4task')];
    var stepTask = l('level4step4task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // THIRD STEP
  /* Change of level no 4 due to other mor important phishing attacks
  function drawStep3(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 4 - Step 3 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 4 - Step 3", de:"Trainingscenter - Level 4 - Step 3"});

    // two urls to display
    var utfURL = JSON.parse('[{"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "wikipedia",'
        + '"toplevel": "org"'
      + '},'
        + '"urldescription": {'
            + '"de":{'
              + '"thirdleveldomain": "Der Computer liest:\\nwww",'
              + '"secondleveldomain": "Der Computer liest:\\nwikipedia",'
              + '"toplevel": "Der Computer liest:\\norg"'
            + '},'
            + '"en":{'
              + '"thirdleveldomain": "The computer processes:\\nwww",'
              + '"secondleveldomain": "The computer processes:\\nwikipedia",'
              + '"toplevel": "The computer processes:\\norg"'
            + "}"
        +'}'
      + '}, {"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "wikipedia",'
        + '"toplevel": "org"'
      + '},'
        + '"urldescription": {'
            + '"de":{'
              + '"thirdleveldomain": "Der Computer liest:\\nwww",'
              + '"secondleveldomain": "Der Computer liest:\\nxn--wikipedi-86g",'
              + '"toplevel": "Der Computer liest:\\norg"'
            + '},'
            + '"en":{'
              + '"thirdleveldomain": "The computer processes:\\nwww",'
              + '"secondleveldomain": "The computer processes:\\nxn--wikipedi-86g",'
              + '"toplevel": "The computer processes:\\norg"'
            + '}'
      +'}'
    + '}]');

    // add the urls to stage
    var urlDisplay = addURL(utfURL, 4, 0, 0);
    urlDisplay.getChildByName("wrapperURL").x = 0;
    urlDisplay.getChildByName("wrapperURL").y = - options.height / 7;
    var urlContainer = urlDisplay.getChildByName("wrapperURL").children;
    console.log("urlContainer", urlContainer);

    // shorten the description shapes and sum up the textlengths
    var completeLength = 0;
    for(var i = 0;i < urlContainer.length; i++){
      completeLength += urlContainer[i].getChildByName("text").textLength;
      for(var j = 0; j < urlContainer[i].getChildByName("text").children.length; j++){
        console.log(urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription"));
        if(urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription") != undefined){
          var partShape = urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription").getChildByName("partShape");
          // shorten the description shapes
          var boundHeight = partShape.getBounds().height;
          var shorterShape = new createjs.Shape();
          shorterShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.2, boundHeight, 5);
          shorterShape.setBounds(0, 0, options.width * 0.2, boundHeight);
          urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription").regX = shorterShape.getBounds().width / 2;
          // remove old shape and add new shape
          urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription").removeChild(partShape)
          urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription").addChild(shorterShape);
          urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription").setChildIndex(shorterShape, 0);
        }
      }
    }

    var spaceBetween = (options.width - completeLength) / 3;

    // determine new positions for two urls in one line
    urlContainer[0].x = spaceBetween;
    urlContainer[1].x = 2 * spaceBetween + urlContainer[0].getChildByName("text").textLength;//urlContainer[0].x + secondPosition;
    urlContainer[1].y = 0;

    stage.setChildIndex(urlDisplay, 2);

    // explanation
    var explanationContainer = new createjs.Container();
    var explanationShape = new createjs.Shape();
    explanationShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape.x = 0;
    explanationShape.y = 0;
    explanationShape.graphics.setStrokeStyle(1);
    explanationShape.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer.x = (options.width-explanationContainer.getBounds().width) / 2;
    explanationContainer.y = options.height * 0.35;

    // content of speechbubble
    var explanationText = MTLG.utils.gfx.getText(l('level4step3explanation'), "27px Arial", "black");
    explanationText.lineWidth = 550;
    explanationText.lineHeight = 35;
    explanationText.x =  15;
    explanationText.y = 15;

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();
    noteContainer.x = explanationContainer.getBounds().width * 0.42;
    noteContainer.y = 0;

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scaleX = 0.38;
    noteBitmap.scaleY =0.315;
    noteBitmap.alpha = 1;
    noteContainer .addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level4step3noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 625;
    noteHeadline.textAlign = "center";

    var textLengthHeadline = noteHeadline.getBounds().width;
    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(45, options.height * 0.06).lt(noteContainer.getBounds().width - 45, options.height * 0.06);

    // rule
    var noteText = MTLG.utils.gfx.getText(l('level4step3note'), "27px Arial", "black");
    noteText.lineWidth = 630;
    noteText.lineHeight = 35;
    noteText.x = 60;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);
    explanationContainer.addChild(explanationShape, explanationText, noteContainer);


    // add gotIt-button
    var gotItButton = newButton(l('level4step3gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // save game progress
      sessionControl.stepComplete(4,3);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 4 - Step 3 - Got it", de:"Trainingscenter - Level 4 - Step 3 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 4 - Step 3", de:"Trainingscenter - Level 4 - Step 3"});

      MTLG.lc.levelFinished({
        nextLevel: "tclevel4"
      });
    });

    // add the elements to the stage
    stage.addChild(explanationContainer, gotItButton, urlDisplay);

    // step dialogs
    var completeStepDialogs = [l('level4step3dialog1'), l('level4step3dialog2'), l('level4step3task')];
    var stepTask = l('level4step3task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }*/

  // FIFTH STEP
  function drawStep5(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 4 - Step 5 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 4 - Step 5", de:"Trainingscenter - Level 4 - Step 5"});

    // add multiple-choice question and feedback
    var question = new MultipleChoiceQuestion(l('level4step5Question'), [l('level4step5QuestionAnswer1'), l('level4step5QuestionAnswer2'), l('level4step5QuestionAnswer3'), l('level4step5QuestionAnswer4')], l('level4step5QuestionAnswer1'), l('level4step5QuestionReasonRight'), l('level4step5QuestionReasonFalse'));

    var visualQuestion = addMultipleChoice(question, function(){
      //callbackRight
      detectiveSpeech([l('level4complete1')], true, function(){
        // save progress
        sessionControl.stepComplete(4, 5);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 4 - Step 5", de:"Trainingscenter - Level 4 - Step 5"});
        xapiStepResult({en:"Trainingscenter - Level 4 - Step 5", de:"Trainingscenter - Level 4 - Step 5"}, true);
        MTLG.lc.levelFinished({
          nextLevel: "tclevel4"
        })});}, function(){
        //callbackFalse
        detectiveSpeech([l('level4step4failed')], true, function(){
          // save progress
          sessionControl.resetStep(4, 2);
          sessionControl.resetStep(4, 4);
          // xapi call
          xapiStepEnd({en:"Trainingscenter - Level 4 - Step 5", de:"Trainingscenter - Level 4 - Step 5"});
          xapiStepResult({en:"Trainingscenter - Level 4 - Step 5", de:"Trainingscenter - Level 4 - Step 4"}, false);
          // restart level by callback
          MTLG.lc.levelFinished({
            nextLevel: "tclevel4"
          })});
      });

    stage.addChild(visualQuestion);
  }

  // END CONTENT PART OF THE LEVEL


  //********** START THE LEVEL **********
    // Call levelcontrol to begin the level
    level4Control();

}
