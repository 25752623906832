/* Create the toolbar. You need to pass an array of all the hotspots that 
can be found by the magnifying glass. This does not necessarily have to be 
all your hotspots. */
var createToolbar = function(x, y, hotspots) {
    var dragged = true;

    // Toolbar container
    var toolbar = new createjs.Container();
    toolbar.x = x;
    toolbar.y = y;
  
    var buttons = new createjs.Container();
  
    // Toolbar background
    var notepad =  MTLG.assets.getBitmap("img/notepad.png");
    notepad.scaleX=0.75;
    notepad.scaleY=0.75;
    notepad.x = 0;
    notepad.y = 0;
    notepad.setBounds(0, 0, notepad.image.width* 0.75, notepad.image.height * 0.75);
  
    var notepad_closed = MTLG.assets.getBitmap("img/notepad_closed.png");
    notepad_closed.scaleX=0.75;
    notepad_closed.scaleY=0.75;
    notepad_closed.x = 0;
    notepad_closed.y = notepad.getBounds().height/2;
    notepad_closed.visible = false;
  
    toolbar.addChild(notepad);
    toolbar.addChild(notepad_closed);

    // Settings button
    var settings = createButton("settings", 0, 0, notepad.getBounds().height/2, notepad.getBounds().height/2);
    settings.x = notepad.getBounds().width * 0.225  + settings.getBounds().width/2 + mode * notepad.getBounds().width * 0.2;
    settings.y = settings.getBounds().height/2;
    buttons.addChild(settings);

     settings.on('pressup', function({localX, localY}){
         
         getHotspotStage().removeAllChildren();
         getToolbarStage().removeAllChildren();
         getNavbarStage().removeAllChildren();
        if(getWebsiteFrame() != null) {
            deleteWebsiteFrame();
        };
         deleteLayer();
         MTLG.lc.goToMenu();
     });


    // Magnifying glass 
    if(mode == 1) {
    var search = createButton("search", 0, 0, notepad.getBounds().height/2, notepad.getBounds().height/2);
    search.x = search.getBounds().width/2 + search.getBounds().width/4;
    search.y = search.getBounds().height/2 + search.getBounds().height/4;
    search.regX = search.getBounds().width/2;
    search.regY = search.getBounds().height/2;

    // Area where you can put the magnifying glass back in
    var dragArea = new createjs.Shape().set({x:0, y:0});
    dragArea.color = dragArea.graphics.s("#FFF599").command;
    dragArea.graphics.ss(3, "round").sd([12,12]).f("#FFF599").dr(0,0,search.getBounds().width,search.getBounds().height);
  
    // Hotspot hit detection
    search.on('pressmove', function({localX, localY}){
          search.set(search.localToLocal(localX, localY, search.parent));

            if (search.x - search.getBounds().width/4 >= dragArea.x-search.getBounds().width/2 && search.x - search.getBounds().width/4<= dragArea.x+ search.getBounds().width + search.getBounds().width/2 
            && search.y - search.getBounds().height/4 >= dragArea.y-search.getBounds().height/2 && search.y - search.getBounds().height/4 <= dragArea.y+search.getBounds().height + search.getBounds().height/2) {
      dragArea.color.style = "green";    
    } else {
        dragArea.color.style = "#6E2E0F";
    }
        for(let i = 0; i < hotspots.length; i++) {
            
            if (getGlobalBounds(search).x - search.getBounds().width/3 >= getGlobalBounds(hotspots[i]).x-search.getBounds().width && getGlobalBounds(search).x + search.getBounds().width/6 <= getGlobalBounds(hotspots[i]).x+hotspots[i].getBounds().width
                && getGlobalBounds(search).y - search.getBounds().height/3 >= getGlobalBounds(hotspots[i]).y-search.getBounds().height && getGlobalBounds(search).y + search.getBounds().height/12 <= getGlobalBounds(hotspots[i]).y+hotspots[i].getBounds().width) {
                hotspots[i].alpha = 1;    
            }
        }

      
      });
  
      search.on('pressup', function({localX, localY}){
            search.set(search.localToLocal(localX, localY, search.parent));

            if(dragArea.visible == true) {
                if (search.x - search.getBounds().width/4 >= dragArea.x-search.getBounds().width/2 && search.x - search.getBounds().width/4 <= dragArea.x+ search.getBounds().width + search.getBounds().width/2 
                && search.y - search.getBounds().height/4 >= dragArea.y-search.getBounds().height/2 && search.y - search.getBounds().height/4 <= dragArea.y+search.getBounds().height + search.getBounds().height/2) {
                    dragArea.color.style = "#FFF599";
                    search.x = dragArea.x + search.getBounds().width/2 + search.getBounds().width/4;
                    search.y = dragArea.y + search.getBounds().height/2 + search.getBounds().height/4;
                    dragged = true;  
                } else {
                    dragArea.color.style = "#6E2E0F";
                    dragged = false;
                }
            }
      });

      buttons.addChild(dragArea);
  
      buttons.addChild(search);
     }
  
    buttons.x = notepad.getBounds().width * 0.1;
    buttons.y = notepad.getBounds().height * 0.3;

    toolbar.addChild(buttons);

    // Open and close the notepad
    notepad.on('pressup', function({localX, localY}){
        if (mode == 1) {
            dragArea.visible = false;
            if(dragged == true)
                search.visible = false;
         }
          notepad.visible = false;
          notepad_closed.visible = true;
          settings.visible = false;
     });
   
     notepad_closed.on('pressup', function({localX, localY}){
        if (mode == 1) {
            dragArea.visible = true;
            if(dragged == true)
                search.visible = true;
         }
          notepad.visible = true;
          notepad_closed.visible = false;
          settings.visible = true;
     });
  
    return toolbar;
  }

  // Create the helper figure
  var createDetective = function(x, y, text = "") {
    var text_open = true;
    var detective = new createjs.Container();
    detective.name = "detective";
    detective.x = x;
    detective.y = y;

    var detective_button =  createButton("detective",0,0,MTLG.getOptions().width*0.075,MTLG.getOptions().width*0.075);
    detective_button.y = MTLG.getOptions().height*0.28;

    // Open and close the speech bubble
    detective_button.on('pressup', function({localX, localY}){
        if (text_open == true) {
            speechBubble.visible = false;
            spitze.visible = false;
            detective_speech.visible = false;
            text_open = false;
         }
        else {
            speechBubble.visible = true;
            spitze.visible = true;
            detective_speech.visible = true;
            text_open = true;
        }
     });

    var speechBubble = new createjs.Shape();
    speechBubble.graphics.beginFill("white").drawRoundRect(10, 10, MTLG.getOptions().width*0.2, MTLG.getOptions().height*0.2, 10, 10);
    speechBubble.x = MTLG.getOptions().width*0.03;

    var bubbleSpitze = new createjs.Graphics;
    var Point = createjs.Point;

    var a = new Point(-60, 30);
    var b = new Point(-90, 60);
    var c = new Point(-80, 30);

    bubbleSpitze.beginFill("white").moveTo(a.x, a.y).arcTo(b.x, b.y, c.x, c.y, 0).lineTo(c.x, c.y).endStroke();

    var spitze = new createjs.Shape(bubbleSpitze);
    spitze.x = MTLG.getOptions().width*0.10;
    spitze.y = MTLG.getOptions().height*0.18;
    spitze.visible = true;

    var detective_speech = new createjs.Text(text, Math.ceil(MTLG.getOptions().height * 0.03) + 'px Arial', 'black');
    detective_speech.name = "detective_speech";
    detective_speech.x = MTLG.getOptions().width*0.05;
    detective_speech.y = MTLG.getOptions().height*0.06;
    detective_speech.textBaseline = 'alphabetic';
    detective_speech.lineWidth = MTLG.getOptions().width*0.2*0.9;
    detective_speech.lineHeight = MTLG.getOptions().height*0.03*1.25;

    detective.addChild(detective_button, speechBubble, spitze, detective_speech);

    return detective;
  }

  // Change the text in the speech bubble
  const changeDetectiveText = function(text) {
      getToolbarStage().getChildByName("detective").getChildByName("detective_speech").text = text;
  }

  module.exports = {
    createToolbar,
    createDetective,
    changeDetectiveText
  };