/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T13:28:35+02:00
 */



function playermenu_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawPlayermenu();
}

// check wether playermenu is choosen or not
function checkPlayermenu(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "playermenu"){
    return 1;
  }
  return 0;
}

// START CONTENT OF PLAYER MENU

/**
 * This function draws the player menu of the Phishing Academy, all functions inside are only for internal use
 * @function
 */
function drawPlayermenu(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // BACKGROUND
  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  // set currentLevel
  sessionControl.setCurrentLevel("Spielermenü -");

  // logo
  var gameLogo = MTLG.assets.getBitmap("img/phishing/phishingAcademy_logo.png");
  gameLogo.scale = .20;
  gameLogo.x = (options.width - gameLogo.getBounds().width * 0.20) / 2;
  gameLogo.y = (options.height - gameLogo.getBounds().height * 0.20) / 2;
  stage.addChild(gameLogo);

  // trainingscenter box
  var trainingscenterContainer = new createjs.Container();
  trainingscenterContainer.setBounds(0, 0, options.width / 4, options.height / 4);
  trainingscenterContainer.x = options.width * 0.025;
  trainingscenterContainer.y = options.width * 0.025;

  // background shape
  var backgroundShapeTrainingscenter = new createjs.Shape();
  backgroundShapeTrainingscenter.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 4, options.height / 4, 25);
  backgroundShapeTrainingscenter.setBounds(0, 0, options.width / 4, options.height / 4);

  // headline box trainingscenter
  var textTrainingscenter = MTLG.utils.gfx.getText(l('trainingscenterBoxHeadline'), "42px Arial", "black");
  textTrainingscenter.textAlign = 'center';
  textTrainingscenter.lineWidth = 450;
  textTrainingscenter.x = trainingscenterContainer.getBounds().width / 2;
  textTrainingscenter.y = trainingscenterContainer.getBounds().height / 8;

  // icon trainingscenter
  var trainingscenterLogo = MTLG.assets.getBitmap("img/phishing/trainingscenter_logo.png");
  trainingscenterLogo.scale = .075;
  trainingscenterLogo.x = (trainingscenterContainer.getBounds().width - trainingscenterLogo.getBounds().width * 0.075) / 2;
  trainingscenterLogo.y = (trainingscenterContainer.getBounds().height - trainingscenterLogo.getBounds().height * 0.075) * 0.75;

  trainingscenterContainer.addChild(backgroundShapeTrainingscenter, trainingscenterLogo, textTrainingscenter);

  trainingscenterContainer.addEventListener("click", function(){
    // xapi call
    xapiButton({en: "Playermenu - Trainingscenter", de: "Spielermenü - Trainingscenter"});
    xapiLevelEnd({en: "Playermenu", de: "Spielermenü"});

    MTLG.lc.levelFinished({
      nextLevel: "trainingscenter"
    });
  });

  // criminalcases box
  var criminalcasesContainer = new createjs.Container();
  criminalcasesContainer.setBounds(0, 0, options.width / 4, options.height / 4);
  criminalcasesContainer.x = options.width - criminalcasesContainer.getBounds().width - options.width * 0.025;
  criminalcasesContainer.y = options.width * 0.025;

  // background shape
  var backgroundShapeCriminalcases = new createjs.Shape();
  backgroundShapeCriminalcases.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 4, options.height / 4, 25);
  backgroundShapeCriminalcases.setBounds(0, 0, options.width / 4, options.height / 4);

  // headline box criminalcases
  var textCriminalcases = MTLG.utils.gfx.getText(l('criminalcasesBoxHeadline'), "42px Arial", "black");
  textCriminalcases.textAlign = 'center';
  textCriminalcases.lineWidth = 450;
  textCriminalcases.x = criminalcasesContainer.getBounds().width / 2;
  textCriminalcases.y = criminalcasesContainer.getBounds().height / 8;

  // icon criminalcases
  var criminalcasesLogo = MTLG.assets.getBitmap("img/phishing/criminalcases_logo.png");
  criminalcasesLogo.scale = .075;
  criminalcasesLogo.x = (criminalcasesContainer.getBounds().width - criminalcasesLogo.getBounds().width * 0.075) / 2;
  criminalcasesLogo.y = (criminalcasesContainer.getBounds().height - criminalcasesLogo.getBounds().height * 0.075) * 0.75;

  criminalcasesContainer.addChild(backgroundShapeCriminalcases, criminalcasesLogo, textCriminalcases);

  if(sessionControl.getValueToKey("completionTrainingscenter") == 1){
    criminalcasesContainer.alpha = 1;
    criminalcasesContainer.addEventListener("click", function(){
      // xapi call
      xapiButton({en: "Playermenu - Criminalcases", de: "Spielermenü - Kriminalfälle"});
      xapiLevelEnd({en: "Playermenu", de: "Spielermenü"});

      MTLG.lc.levelFinished({
        nextLevel: "criminalcase"
      });
    });
  } else {
    criminalcasesContainer.alpha = 0.5;
    criminalcasesContainer.addEventListener("click", function(){
      detectiveSpeech([l('criminalcasesLocked')], true);
    });
  }

  // other buttons

  stage.addChild(newButton(l('btPhishingStart'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width - options.width * 0.025 - options.width / 12, options.height * 0.85, function(){
    // xapi call
    xapiButton({en: "Playermenu - Back to Menu", de: "Spielermenü - Hauptmemü"});
    xapiLevelEnd({en: "Playermenu", de: "Spielermenü"});

    MTLG.lc.goToMenu();
  }));

  stage.addChild(newButton(l('btRestartIntro'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width - options.width * 0.025 - options.width / 12, options.height * 0.75, function(){
    sessionControl.setValueToKey("firstStartIntroduction", 0);

    // xapi call
    xapiButton({en: "Playermenu - Restart Introduction", de: "Spielermenü - Wiederholung Einführung"});
    xapiLevelEnd({en: "Playermenu", de: "Spielermenü"});

    MTLG.lc.levelFinished({
      nextLevel: "introduction"
    });
  }));

  stage.addChild(newButton(l('speechPlayermenuRepeat'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width / 12 + options.width * 0.025, options.height * 0.85, function(){
    // character says hi again
    // xapi call
    xapiButton({en: "Playermenu - Speak Detective Dot", de: "Spielermenü - Detektiv Dot"});

    detectiveSpeech([l('tutorial1'), l('tutorial2'), l('tutorial3'), l('tutorial4')], true);
  }));



  // display saveGame name for the actual session
  var saveNameDisplay = MTLG.utils.gfx.getText(l('saveGameName') + sessionControl.getValueToKey("userID"), "27px Arial", "black");
  saveNameDisplay.textAlign = "center";
  saveNameDisplay.x = options.width / 2;
  saveNameDisplay.y = options.height * 0.95;


  // add buttons to stage
  stage.addChild(trainingscenterContainer, criminalcasesContainer, gameLogo, saveNameDisplay);

  // Demo Delfi buttons
  if(phishingAcademy.demoMode === true){
    demoButtons();
  }


  // DETECTIVE DIALOGUES IF THIS IS THE FIRST START OR THE TRAININGSCENTER IS COMPLETED

  // if this is the first start display the motivator
  if(sessionControl.getValueToKey("firstStartPlayerMenu") == 0){

    // character says hi for the first time, aks for a playername and explains the different game mode
    detectiveSpeech([l('tutorial1'), l('tutorial2'), l('tutorial3'), l('tutorial4')], true);

    // fill with a start dialogue, when game is entered for the first time
    sessionControl.setValueToKey("firstStartPlayerMenu", 1);
  }

  // user finished trainingscenter, get his attention to the criminalcases
  if(sessionControl.getValueToKey("completionTrainingscenter") === 1 && sessionControl.getValueToKey("dialogCompletionTrainingscenterPlayermenu") === 0){
    // update saveGame
    sessionControl.setValueToKey("dialogCompletionTrainingscenterPlayermenu", 1);
    // add detective speech
    detectiveSpeech([l('playermenuCompletionTrainingscenterDialog1')], true);
  }

}

// Export the functions
module.exports = {
  drawPlayermenu
};
