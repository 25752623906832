/**
 * @Date:   2018-10-04T17:09:14+02:00
 * @Last modified time: 2019-02-04T05:38:24+01:00
 */


// expose framework for debugging
window.MTLG = MTLG;
// ignite the framework
document.addEventListener("DOMContentLoaded", MTLG.init);

// add moduls
require('mtlg-modul-menu');
require('mtlg-moduls-utilities');
require('mtlg-modul-lightparse');
// xapi data collector
require('mtlg-datacollector-xapi');

// load configuration
require('../manifest/device.config.js');
require('../manifest/game.config.js');
require('../manifest/game.settings.js');

// load translations
require('../lang/lang.js');


// START - REQUIRED FILES FOR: PHISHING ACADEMY

// load namespace with global variales and constructors
require('./level/phishingAcademy/functions/namespace.js');
// load sessionobject
require('./level/phishingAcademy/sessioncontrol.js');
// load levelchoice
require('./level/phishingAcademy/functions/levelchoice.js');

// END - REQUIRED FILES FOR: PHISHING ACADEMY

/**
 * Init function for the game.
 * @params options: The options parsed from the manifest files/ Defaults.
 */
var initGame = function(pOptions){
  //Initialize game

  // Define l as abbreveation  for translations.
  l = MTLG.lang.getString;

  //Initialize levels and Menus:
  //The MTLG framework uses the lifecycle manager (lc) to handle level progression,
  //resetting levels and to define a starting point for the game.
  //Use the functions registerMenu and registerLevel to define your levels.
  //The starting point of the game will be the registered menu, if one is present,
  //or the game with the highest value when passed the empty game state.
  //Register Menu

  // Initialize canvas stages
  createStages();

  // register menu
  MTLG.lc.registerMenu(academymenu_init);

  //Register levels
  //Levels consist of two parts: the first parameter is the entrance function,
  //that sets the level up and renders it. The second parameter as a check function,
  //that receives the current gamestate as paramter and returns a value between 0 and 1.
  //The lifecycle manager starts the level with the highest return value in the next step.

  // levels of the Phishing-game
  MTLG.lc.registerLevel(introduction_init, checkIntroduction);
  MTLG.lc.registerLevel(playermenu_init, checkPlayermenu);
  MTLG.lc.registerLevel(trainingscenter_init, checkTrainingscenter);
  MTLG.lc.registerLevel(criminalcases_init, checkCriminalcases);
  MTLG.lc.registerLevel(evaluation_init, checkEvaluation);
  MTLG.lc.registerLevel(pretest_init, checkPretest);
  MTLG.lc.registerLevel(retest_init, checkRetest);
  MTLG.lc.registerLevel(tclevel1_init, checkTclevel1);
  MTLG.lc.registerLevel(tclevel2_init, checkTclevel2);
  MTLG.lc.registerLevel(tclevel3_init, checkTclevel3);
  MTLG.lc.registerLevel(tclevel4_init, checkTclevel4);
  MTLG.lc.registerLevel(tclevel5_init, checkTclevel5);
  MTLG.lc.registerLevel(tclevel6_init, checkTclevel6);


  // Remove the edge buttons from the canvas
  MTLG.menu.getMenuStage().removeAllChildren();



  //Init is done
  console.log("Game Init function called");
}

//Register Init function with the MTLG framework
//The function passed to addGameInit will be used to initialize the game.
//Use the initialization to register levels and menus.
MTLG.addGameInit(initGame);
