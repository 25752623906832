/**
 * @Date:   2019-01-26T22:41:29+01:00
 * @Last modified time: 2019-09-10T20:06:01+02:00
 */


/**
 * This function reveals a namespace for saving global variables so you do not loose them (in the other code)
 * @namespace
 */
var phishingAcademy = (function(){

  // GLOBAL VARIABLES FOR THE GAME

  // global variable for the amount of levels in the trainingscenter
  var tcLevelAmount = 6;

  // global variables used for the feedback of clickURL() (mode 3)
  var arrayFalse = [];
  var arrayRight = [];
  var arrayOfUserClickedElements = [];
  var arrayURLparts = [];

  // object for shown url parts on hover in addURL
  var arrayShownOnHover = {};

  // global variable if this is a new game - DemoDelfi (eventeully obsolete through new demo mode)
  var newStart = 1;

  // global array of verified savegames
  let verifiedSaveGames = [];

  // global array for the intervals defined in criminalcases
  var criminalcasesIntervals = [];

  // demo mode activated
  var demoMode = false;

  // retest game
  var retestGame = false;

  return {
    tcLevelAmount,
    arrayFalse,
    arrayRight,
    arrayOfUserClickedElements,
    arrayURLparts,
    arrayShownOnHover,
    newStart,
    verifiedSaveGames,
    criminalcasesIntervals,
    demoMode,
    retestGame
  }
})();

// Export the functions
module.exports = {
phishingAcademy
}
