 /**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T13:28:39+02:00
 */



function trainingscenter_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawTrainingscenter();
}

// check wether trainingscenter is choosen or not
function checkTrainingscenter(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "trainingscenter"){
    return 1;
  }
  return 0;
}

/**
 * This function contains the drawing of the trainingscenter
 * @function
 */
function drawTrainingscenter(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // background
  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  // set currentLevel
  sessionControl.setCurrentLevel("Trainingscenter -");

  // xapi call
  xapiLevelStart({en: "Trainingscenter", de: "Trainingscenter"});


  // GENERAL BUTTONS
  stage.addChild(newButton(l('btPhishingStart'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.60, options.height - (options.height / 12), function(){
    // xapi call
    xapiButton({en: "Trainingscenter - Menu", de: "Trainingscenter - Hauptmenü"});
    xapiLevelEnd({en: "Trainingscenter", de: "Trainingscenter"});

    MTLG.lc.levelFinished({
      nextLevel: "phishingmenu"
    });
  }));

  stage.addChild(newButton(l('btPlayerMenu'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.8, options.height - (options.height / 12), function(){
    // xapi call
    xapiButton({en: "Trainingscenter - Playermenu", de: "Trainingscenter - Spielermenü"});
    xapiLevelEnd({en: "Trainingscenter", de: "Trainingscenter"});

    MTLG.lc.levelFinished({
      nextLevel: "playermenu"
    });
  }));

  // Demo Delfi buttons
  if(phishingAcademy.demoMode === true){
    demoButtons();
  }

  // call the levelChoice to display the carousel of levels
  levelChoice();


  // CONTENT

  // DIALOGS WITH THE USER

  // first start trainingcenter
  if(sessionControl.getValueToKey("firstStartTrainingcenter") === 0){
    // update saveGame
    sessionControl.setValueToKey("firstStartTrainingcenter", 1);
    // add detective speech
    detectiveSpeech([l('trainingscenterStartDialog1')], true);
  }

  /* check if trainingcenter is completed
   * check if the completion dialogue was displayed before,
   * so it won't be displayed if the player just played the last level again
   */
  if(sessionControl.getValueToKey("completionTrainingscenter") === 1 && sessionControl.getValueToKey("dialogCompletionTrainingscenter") === 0){
    // update saveGame
    sessionControl.setValueToKey("dialogCompletionTrainingscenter", 1);
    // add detective speech
    detectiveSpeech([l('trainingscenterCompletionDialog1')], true, function(){
      MTLG.lc.levelFinished({
        nextLevel: "playermenu"
      });
    });
  }

}
