/**
 * @Date:   2019-01-08T00:45:34+01:00
 * @Last modified time: 2019-01-28T20:04:44+01:00
 */


/**
  * This function function shows directly (No return!) a list on more than one site, so you can page browse to all options
  * @function
  * @param {number} elementsToDisplay Amount of elements to display by the carousel
  * @param {number} displayLimit Maximum amount of elements displayed on one site
  * @param {number} startPositionElements Coordinate (x,y) to set a position for the carousel
  * @param {number} startPositionNavigation Coordinate (x,y) to set a position for the navigation buttons
  * @param {number} startPositionPageDisplay Coordinate (x,y) to set a position for the page display to show on which page you are
  * @param {function} elementdrawFunction Function transmitted to draw one element of the carousel
  * @param {Object} whereToDisplay Object where you want to add the carousel (Mandatory Object method: addChild())
  */
var carousel = function(elementsToDisplay, displayLimit, startPositionElements, startPositionNavigation, startPositionPageDisplay, elementdrawFunction, whereToDisplay){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  /* Compute the amount of pages needed to display all levels
   * Maximum capacity per page is saved in displaylimit
   */
  var MaxPages;
  if(elementsToDisplay == 0){
    MaxPages = 1;
  }else{
    MaxPages = Math.ceil(elementsToDisplay/displayLimit);
  }

  /* Current page
   * default: 1
   */
  var currentPage = 1;

  // create an general accessable container for the levelchoice
  var containerCarousel = new createjs.Container();
  whereToDisplay.addChild(containerCarousel);


  // function to draw the complete levelchoice - internal use only, do not export
  var drawCarousel = function(){
    containerCarousel.removeAllChildren();
    var containerAllElementsBoxes = new createjs.Container();

    // determine positions
    containerCarousel.x = 0;
    containerCarousel.y = 0;

    containerCarousel.addChild(containerAllElementsBoxes);
    containerAllElementsBoxes.x = startPositionElements.x;
    containerAllElementsBoxes.y = startPositionElements.y;

    // compute which boxes will be shown
    if(displayLimit*currentPage > elementsToDisplay){
      var lastingElements = elementsToDisplay-(displayLimit*(currentPage-1));
      var maxIteration = displayLimit*(currentPage-1)+lastingElements;
      var k = displayLimit*(currentPage-1);
    } else {
        var k=displayLimit*(currentPage-1);
        var maxIteration= (displayLimit*currentPage);
        var lastingElements = displayLimit;
      }

    for(var i = k; i < maxIteration;i++){
      // draw for each element the representation
      containerAllElementsBoxes.addChild(elementdrawFunction(i));
    }

    //Bottom page display
    var pageDisplay = new createjs.Text(currentPage + " / " + MaxPages, "27px Arial", "black");
    pageDisplay.x = startPositionPageDisplay.x;
    pageDisplay.y = startPositionPageDisplay.y;
    pageDisplay.textBaseline = "middle";
    pageDisplay.textAlign = "center";

    // add navigation and page display to stage
    containerCarousel.addChild(pageDisplay);
    containerCarousel.addChild(pageDepencies());
  }

  // This function displays the navigation buttons you use for switching through the pages
  var pageDepencies = function(){

    // new container for all objects
    var pageContainer = new createjs.Container();
    pageContainer.x = 0;
    pageContainer.y = 0;

    // Buttons for switching pages
    var goLeft = MTLG.assets.getBitmap("img/phishing/arrow_left.png")
    goLeft.scale = 1 / 5;
    goLeft.x= startPositionNavigation.leftx - goLeft.getBounds().width * 1 / 5;
    goLeft.y= startPositionNavigation.lefty;
    if(currentPage >> 1){
      pageContainer.addChild(goLeft);
    } else {
      pageContainer.removeChild(goLeft);
    }
    goLeft.addEventListener('click', function(){
      currentPage = currentPage - 1;

      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiButton({en: curlvl + "Choices left", de: curlvl + "Auswahl links"});

      // Update page
      drawCarousel();

    });

    var goRight = MTLG.assets.getBitmap("img/phishing/arrow_right.png")
    goRight.scale = 1 / 5;
    goRight.x= startPositionNavigation.rightx;
    goRight.y= startPositionNavigation.righty;
    if(currentPage < MaxPages){
      pageContainer.addChild(goRight);
    } else {
      pageContainer.removeChild(goRight);
    }
    goRight.addEventListener('click', function(){

      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiButton({en: curlvl + "Choices right", de: curlvl + "Auswahl rechts"});

      currentPage = currentPage + 1;
      // Update page
      drawCarousel();

    });

    return pageContainer;
  }

  // CALL INITIAL DRAWING FUNCTION
  drawCarousel();
}

// Export the functions
module.exports = {
  carousel
};
