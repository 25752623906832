/**
 * @Date:   2018-12-17T15:40:51+01:00
 * @Last modified time: 2019-01-28T19:46:41+01:00
 */


/**
 * This function is used to display the examination mode to clicking some url elements
 * @function
 * @param {number} startRound Number to clarify the start URL of the set
 * @param {number} rounds How much rounds should be played (do not transmit a bigger number than your set of URLs - will crash)
 * @param {string[]} textToBeClicked Which parts of the URL should the user click
 * @param {function} callbackPassed Function to run, if the user passes the test
 * @param {function} callbackFailed Function ro run, if the user fails the test
 */
var clickURL = function(startRound, rounds, urlObject, textToBeClicked, callbackPassed, callbackFailed){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // save the amount of correct answers
  let correctAnswers = 0;

  var clickRound = function (number){
    // check after the given amount of rounds the user performance
    if(number > (rounds - 1)){
      if(correctAnswers >= Math.ceil(rounds / 2)){
        callbackPassed();
      } else {
        callbackFailed();
      }
    }
    if(number <= (rounds - 1)){
      // display new url per round
      var urlDisplay = addURL([urlObject[number]], 3, 1);
      stage.addChild(urlDisplay);
      stage.setChildIndex(urlDisplay, 1);

      var checkButton = newButton(l('urlClickPruefen'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
        if(phishingAcademy.arrayOfUserClickedElements.length !== 0){
          for(var i = 0; i < phishingAcademy.arrayOfUserClickedElements.length; i++){
            let check = 0;
            if(textToBeClicked.indexOf(phishingAcademy.arrayOfUserClickedElements[i][1]) !== -1){
              phishingAcademy.arrayRight.push(phishingAcademy.arrayOfUserClickedElements[i]);
              check = 1;
            }
            if(check === 0){
              // search for wrong object
              var keys = Object.keys(urlObject[number].urltext);
              if(textToBeClicked.indexOf(phishingAcademy.arrayOfUserClickedElements[i][1]) === -1){
                phishingAcademy.arrayFalse.push(phishingAcademy.arrayOfUserClickedElements[i]);
              }
            }
          }

          // if the given answer is correct, increase correct counter
          if(phishingAcademy.arrayFalse.length === 0){
            correctAnswers += 1;
          }

          // remove actual url
          stage.removeChild(urlDisplay);
          stage.removeChild(checkButton);

          // xapi call
          var curlvl = sessionControl.getCurrentLevel();
          xapiButton({en: curlvl + "Check clicked URL Part", de: curlvl + "Prüfe angeklicktes URL Element"});

          // give feedback for each URL round and reset the arrays
          addFeedBackClickableURL(phishingAcademy.arrayFalse, phishingAcademy.arrayRight, number, urlObject[number], textToBeClicked, clickRound);
        }
      });

      stage.addChild(checkButton);
    }
  }

  // start the examination
  clickRound(startRound);
}

// Export the functions
module.exports = {
  clickURL
};
