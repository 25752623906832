/**
 * @Date:   2018-11-14T10:31:03+01:00
 * @Last modified time: 2019-01-28T19:45:50+01:00
 */


/**
  * This function creates and returns a new button
  * @function
  * @param {string} text Text to display in the button
  * @param {number} textSize Size of text
  * @param {string} color Color
  * @param {number} status If 1: alpha = 1, if 0: alpha 0.5
  * @param {number} width Width of the displayed button
  * @param {number} height Height of the displayed button
  * @param {number} posX x-position (plade make sure number fits to the display size)
  * @param {number} posY y-position (plade make sure number fits to the display size)
  * @return {Container} Finished button container, can be directly added to stage
  */
function newButton(text, textSize, color, status, width, height, posX, posY, callback){
  var buttonContainer = new createjs.Container();
  buttonContainer.x = posX;
  buttonContainer.y = posY;

  var buttonSizeX = width;
  var buttonSizeY = height;
  var button = new createjs.Shape();
  button.name = "button";

  button.color = button.graphics.beginFill(color).command;
  button.graphics.drawRoundRect(0, 0, buttonSizeX, buttonSizeY, 10);

  button.regX = (buttonSizeX / 2);
  button.addEventListener('click', callback);
  buttonContainer.addChild(button)

  // play button text
  var buttonText = new createjs.Text(text, textSize + "px Arial", "black");
  buttonText.name = "buttonText";
  buttonText.x = 0 ;
  buttonText.y = buttonSizeY / 2;
  buttonText.textBaseline = "middle";
  buttonText.textAlign = "center";
  buttonContainer.addChild(buttonText);

  // change alpha according status
  if(status != 1){
    button.alpha = 0.5;
    buttonText.alpha = 0.5;
  }


  return buttonContainer;

}


/**
  * This function creates special buttons for the levelChoice, there is no need to call this function outside of this context
  * @function
  */
function levelChoiceButton(){
  var options = MTLG.getOptions();

  var button = newButton(l('btLevelauswahl'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.9, options.height - (options.height / 12), function(){
    // catch the removal of the navbar module
    if(getNavbarStage() !== undefined){
      getNavbarStage().removeAllChildren();
    }

    // xapi call
    var curlvl = sessionControl.getCurrentLevel();
    xapiButton({en: curlvl + "Back to levelchoice", de: curlvl + "Zurück zur Levelauswahl"});

    MTLG.lc.levelFinished({
      nextLevel: "trainingscenter"
    });
  });

  return button;
}

/**
 * Creates Box
 */
function newBox(x, y, width, height, options = null) {
  let defaultOptions = {
    bgColor: "rgb(255, 255, 255)",
    alpha: 1,
  }
  options = Object.assign(defaultOptions, options);

  let retCont = new createjs.Container();
  let shape = new createjs.Shape();
  shape.graphics.beginFill(options.bgColor).drawRect(0, 0, width, height);
  shape.myType = "Rect";
  shape.alpha = options.alpha;
  retCont.addChild(shape);
  retCont.x = x;
  retCont.y = y;
  return retCont;
}

/**
 * Creates Text
 */
function newText(label, options = null) {

  let defaultOptions = {
    txtColor: "rgb(0, 0, 0)",
    padding: {
      lr: 25, //left-right padding
      tb: 8 //top-bottom padding
    },
    fontSize: "27px",
    font: "Arial",
    lineWidth: 500,
    textAlign: "left",
    textBaseline: "middle",
  }
  options = Object.assign(defaultOptions, options);

  let retCont = new createjs.Container();
  let text = new createjs.Text(label+" ", options.fontSize+" "+options.font, options.txtColor);
  text.set({lineWidth: (options.lineWidth-2*options.padding.lr)});
  text.textBaseline = options.textBaseline;//"top";
  text.textAlign = options.textAlign;
  text.x = options.padding.lr;
  text.y = options.padding.tb;
  text.myType = "Text";
  //text.outline = 1;
  retCont.text = text;
  let bounds = text.getBounds();
  bounds.width += options.padding.lr*2;
  bounds.height += options.padding.tb*2;

  retCont.addChild(text);
  retCont.setBounds(bounds.x, bounds.y, bounds.width, bounds.height);

  /**
   * Appends character to text in input bar
   */
  retCont.addChar = function (newChar) {
    this.text = this.text.trim()+newChar.trim();
  }.bind(text);

  retCont.setText = function (newText) {
    this.text = newText;
  }.bind(text);

  /**
   * Removes last character of text in input bar
   */
  retCont.removeChar = function () {
    this.text = this.text.slice(0, -1);
  }.bind(text);

  /**
   * Returns text in text element
   */
  retCont.getText = function () {
    return this.text;
  }.bind(text);

  /**
   * Resets text in text element
   */
  retCont.resetText = function () {
    this.text = "";
    this.color = "#000000"
  }.bind(text);

  return retCont;
}


module.exports = {
  newButton,
  levelChoiceButton,
  newBox,
  newText,
};
