/**
 * @Date:   2018-10-04T17:09:14+02:00
 * @Last modified time: 2019-01-27T00:47:07+01:00
 */



let url;

// Create a tab
const createTab = function (text, active, width = MTLG.getOptions().width * 0.1, height = MTLG.getOptions().height * 0.05) {
  // Tab background (passive)
  const background_passive = new createjs.Shape();
  background_passive.graphics.beginStroke('grey');
  background_passive.graphics.setStrokeStyle(1);
  background_passive.graphics.beginLinearGradientFill(['#d9d9d9', '#f2f2f2'], [0, 1], 0, 0, 0, height).drawRoundRectComplex(0, 0, width, height, 15, 15, 0, 0);

  // Tab title (passive)
  var title_passive = createText(text, Math.ceil(height * 0.4), width / 2, height / 2, 'grey');
  title_passive.maxWidth = width * 0.8; // Compress font size to make the text fit

  // Tab background (active)
  const background_active = new createjs.Shape();
  background_active.graphics.beginStroke('grey');
  background_active.graphics.setStrokeStyle(1);
  background_active.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, height).drawRoundRectComplex(0, 0, width, height, 15, 15, 0, 0);

  // Tab title (active)
  var title_active = createText(text, Math.ceil(height * 0.4), width / 2, height / 2);
  title_active.maxWidth = width * 0.8; // Compress font size to make the text fit

  // Current tab line
  var current_tab_line = new createjs.Shape();
  current_tab_line.graphics.beginStroke('#d9d9d9');
  current_tab_line.graphics.setStrokeStyle(3);
  current_tab_line.graphics.moveTo(0.5, 0);
  current_tab_line.graphics.lineTo(width - 0.5, 0);
  current_tab_line.graphics.endStroke();
  current_tab_line.y = height;

  // Tab title
  if (active == true) {
    background_passive.visible = false;
    title_passive.visible = false;
  }

  else {
    background_active.visible = false;
    title_active.visible = false;
    current_tab_line.visible = false;
  }

  // Tab container
  const tab = new createjs.Container();
  tab.addChild(background_passive, title_passive, background_active, title_active, current_tab_line);
  tab.setBounds(0, 0, width, height);

  return tab;
};

// Create a tabbar by passing all of your created tabs
const createTabbar = function (space = 0, ...tabs) {
  const tabbar = new createjs.Container();
  tabbar.y = space / 2;
  let width = 0;

  let i = 0;

  for (const tab of tabs) {
    width = tab.getBounds().width;
    tab.x = i * (width + space) + space;
    tab.on('pressup', function({localX, localY}){
        for(const tab of tabs) {
            if(tab.getChildAt(0).isVisible() == false) {
                for(let k = 0; k <= 4; k++)
                    tab.getChildAt(k).visible = !tab.getChildAt(k).isVisible();
            }
        }

        if(tab.getChildAt(0).isVisible() == true) {
            for(let j = 0; j <= 4; j++)
                tab.getChildAt(j).visible = !tab.getChildAt(j).isVisible();
    }
 });
    tabbar.addChild(tab);
    i += 1;
  }

  return tabbar;
};

/* Create an individual navbar. Use createButtonContainer() from the browser module
to create a left and right button container (buttons_left = button container and
buttons_right = right button container). */
const createNavbar = function (link, buttons_left = createEmptyShape(), buttons_right = createEmptyShape(), space = MTLG.getOptions().width * 0.01, width = MTLG.getOptions().width, height = MTLG.getOptions().height * 0.06) {
  // Navbar background
  const navbar_background = new createjs.Shape();
  navbar_background.graphics.beginStroke('grey');
  navbar_background.graphics.setStrokeStyle(1);
  navbar_background.graphics.beginFill('#d9d9d9').drawRect(0, 0, width, height);

  // Buttons (left) positioning
  buttons_left.x = space;
  buttons_left.y = height / 2;

  // Buttons (right) positioning
  buttons_right.x = buttons_left.getBounds().width + (MTLG.getOptions().width - buttons_left.getBounds().width - buttons_right.getBounds().width) + space;
  buttons_right.y = height / 2;

  // Adressbar
  const adressbar = new createjs.Shape();
  adressbar.graphics.beginStroke('grey');
  adressbar.graphics.setStrokeStyle(1);
  adressbar.graphics.beginFill('white').drawRect(buttons_left.getBounds().width, height / 6, (MTLG.getOptions().width - buttons_left.getBounds().width - buttons_right.getBounds().width), height / 1.5);

  // Lock
  const lock_wh = height / 2;
  const lock = createButton('lock', 0, 0, lock_wh, lock_wh);
  lock.x = lock.getBounds().width / 2 + buttons_left.getBounds().width + space;
  lock.y = lock.getBounds().height / 2 + height / 4;

  // URL
  url = createText(link, height / 2);
  url.name = "url";
  url.x = url.getBounds().width / 2 + buttons_left.getBounds().width + lock.getBounds().width + 2 * space;
  url.y = url.getBounds().height / 2 + height * (1 / 5);

  // Navbar container
  const navbar = new createjs.Container();
  navbar.name = "navbar";
  navbar.y = MTLG.getOptions().height * 0.05 + space / 2;
  navbar.addChild(navbar_background, buttons_left, adressbar, buttons_right, lock, url);

  return navbar;
};

/* Create the standard navbar with standard buttons (left buttons: back, forward, refresh, home;
right buttons: menu) */
const createStandardNavbar = function (link, space = MTLG.getOptions().width * 0.01, width = MTLG.getOptions().width, height = MTLG.getOptions().height * 0.06) {
  // Buttons (left)
  const button_wh = height / 2;
  const back = createButton('arrow_back', 0, 0, button_wh, button_wh);
  back.on('pressup', function({localX, localY}){
    document.getElementById('website_frame').contentWindow.history.back();
  });
  const forward = createButton('arrow_forward', 0, 0, button_wh, button_wh);
  forward.on('pressup', function({localX, localY}){
    document.getElementById('website_frame').contentWindow.history.forward();
  });
  const refresh = createButton('refresh', 0, 0, button_wh, button_wh);
  refresh.on('pressup', function({localX, localY}){
    document.getElementById('website_frame').contentWindow.location.reload();
  });
  const home = createButton('home_button', 0, 0, button_wh, button_wh);
  home.on('pressup', function({localX, localY}){
    document.getElementById('website_frame').src = link;
  });

  const buttons_left = createButtonContainer(space, back, forward, refresh, home);

  // Buttons (right)
  const menu = createButton('menu', 0, 0, button_wh, button_wh);

  // Menu List
  let menu_open = false;
  let menu_list;

  menu.on('pressup', ({ localX, localY }) => {
    if (menu_open == false) {
      menu_list = createMenuList('Neuer Tab', 'Neues Fenster', 'Verlauf', 'Downloads', 'Lesezeichen', 'Drucken...', 'Einstellungen', 'Hilfe', 'Beenden');
      menu_list.visible = true;
      menu_open = true;
    } else {
      menu_list.visible = false;
      menu_open = false;
    }
  });

  const buttons_right = createButtonContainer(space, menu);

  const navbar = createNavbar(link, buttons_left, buttons_right, space, width, height);

  return navbar;
};

// Create the menu list
const createMenuList = function (...entries) {
  const menu = new createjs.Container();
  menu.x = MTLG.getOptions().width * 0.8;
  menu.y = MTLG.getOptions().height * 0.11;
  menu.visible = false;

  let i = 1;
  const text = [];
  const menu_entry_background = [];

  for (const entry of entries) {
    text[i] = createText(entry, MTLG.getOptions().height * 0.025);
    text[i].x = text[i].getBounds().width / 2 + MTLG.getOptions().width * 0.01;
    text[i].y = (text[i].getBounds().height / 2 + MTLG.getOptions().height * 0.01) + (i - 1) * (text[i].getBounds().height + MTLG.getOptions().height * 0.02);

    menu_entry_background[i] = new createjs.Shape();
    menu_entry_background[i].graphics.beginStroke('grey');
    menu_entry_background[i].graphics.setStrokeStyle(1);
    menu_entry_background[i].graphics.beginFill('white').drawRect(0, 0, MTLG.getOptions().width * 0.2, text[i].getBounds().height + MTLG.getOptions().height * 0.02);
    menu_entry_background[i].x = 0;
    menu_entry_background[i].y = (text[i].getBounds().height + MTLG.getOptions().height * 0.02) * (i - 1);

    menu.addChild(menu_entry_background[i], text[i]);
    i++;
  }

  getToolbarStage().addChild(menu);

  return menu;
};

// Change the URL or the text of the address bar.
const changeURL = function(url) {
  //getNavbarStage().getChildByName("navbar").getChildByName("url").text = url;Test 18.11.2020
  MTLG.getStageContainer().getChildByName("navbar").getChildByName("url").text = url;
}

module.exports = {
  createTab,
  createTabbar,
  createNavbar,
  createStandardNavbar,
  createMenuList,
  changeURL,
  url
};
