/**
 * @Date:   2018-11-21T19:00:24+01:00
 * @Last modified time: 2019-01-28T19:48:32+01:00
 */


// START - SPECIFIC FEEDBACK METHODS

/**
  * This function displays feedback for a step if a user performance has been checked and the user result changes the game progress
  * @function
  * @param {boolean} textArray Array of text to display in this dialogue
  * @param {string} textToDisplay Feedback text for the user
  * @param {function} callback Function to call after the feedback is reviewed through the user
  */
var addFeedbackForStep = function(completionBool, textToDisplay, callback){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // create overlay, so the speech has to be read
  var overlayFeedback = new createjs.Shape();
  overlayFeedback.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
  overlayFeedback.alpha = 0.5;
  overlayFeedback.addEventListener("click", function(event) {  event.preventDefault(); });
  stage.addChild(overlayFeedback);

  var feedbackContainer;

  if(completionBool){
    feedbackContainer = createFeedbackContainer(options.width * 0.3, options.height * 0.3, l('stepFeedback'));

    var completionText = MTLG.utils.gfx.getText(textToDisplay, "27px Bahnschrift", "black");
    completionText.lineWidth = 450;
    completionText.x = feedbackContainer.getBounds().width * 0.05;
    completionText.y = feedbackContainer.getBounds().height / 5;
    feedbackContainer.addChild(completionText);

    stage.addChild(feedbackContainer);

    feedbackContainer.addChild(newButton(l('okay'), 27, "rgb(6, 225, 17)", 1, feedbackContainer.getBounds().width / 2, options.height / 12, feedbackContainer.getBounds().width / 2, feedbackContainer.getBounds().height - (options.height / 10), function(){
      stage.removeChild(feedbackContainer);

      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiButton({en: curlvl + "Confirm Feedback", de: curlvl + "Feedback bestätigen"});

      callback();
    }));

  } else {
      feedbackContainer = createFeedbackContainer(options.width * 0.3, options.height * 0.3, l('stepFeedback'));

      var failedText = MTLG.utils.gfx.getText(textToDisplay, "27px Bahnschrift", "black");
      failedText.lineWidth = 450;
      failedText.x = feedbackContainer.getBounds().width * 0.05;
      failedText.y = feedbackContainer.getBounds().height / 5;
      feedbackContainer.addChild(failedText);

      stage.addChild(feedbackContainer);

      feedbackContainer.addChild(newButton(l('okay'), 27, "rgb(6, 225, 17)", 1, feedbackContainer.getBounds().width / 2, options.height / 12, feedbackContainer.getBounds().width / 2, feedbackContainer.getBounds().height - (options.height / 10), function(){
        stage.removeChild(feedbackContainer, overlayFeedback);

        // xapi call
        var curlvl = sessionControl.getCurrentLevel();
        xapiButton({en: curlvl + "Confirm Feedback", de: curlvl + "Feedback bestätigen"});

        callback();
      }));
  }

}


/**
  * This function adds feedback for the a clickable url examination in clickURL()
  * @function
  * @param {string[]} arrayFalse Array of text to display in this dialogue
  * @param {string[]} arrayFalse Array of text to display in this dialogue
  * @param {number} urlCounter Number of the actual round
  * @param {Object} url The URL object given feedback to
  * @param {string[]} correctTexts The URL parts which had to be clicked
  * @param {function} callback Function to call after the feedback is reviewed through the user
  */
var addFeedBackClickableURL = function(arrayFalse, arrayRight, urlCounter, url, correctTexts, callback){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var curlvl = sessionControl.getCurrentLevel();

  // create overlay, so the speech has to be read
  var overlayFeedback = new createjs.Shape();
  overlayFeedback.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
  overlayFeedback.alpha = 0.5;
  overlayFeedback.addEventListener("click", function(event) {  event.preventDefault(); });
  stage.addChild(overlayFeedback);

  var feedbackContainer;
  if(arrayFalse.length > 0 && arrayRight.length > 0){
    // if there are both correct and false answers
    adjustableHeightMixed = arrayFalse.length + arrayRight.length;
    feedbackContainer = createFeedbackContainer(options.width * 0.3, options.height * (0.3 + (adjustableHeightMixed * 0.1)));

    var mixedText = MTLG.utils.gfx.getText(l('mixedlevel1step3'), "27px Bahnschrift", "black", l('clickFeedback'));
    mixedText.lineWidth = 450;
    mixedText.x = feedbackContainer.getBounds().width * 0.05;
    mixedText.y = feedbackContainer.getBounds().height / 5;
    feedbackContainer.addChild(mixedText);

    // display url with visual marks for wrong and required clicked elements
    var urlDisplayMixed = addMarkedURL(url, arrayFalse, arrayRight, correctTexts);
    stage.addChild(urlDisplayMixed);


    feedbackContainer.addChild(newButton(l('okay'), 27, "rgb(6, 225, 17)", 1, feedbackContainer.getBounds().width / 2, options.height / 12, feedbackContainer.getBounds().width / 2, feedbackContainer.getBounds().height - (options.height / 10), function(){
      stage.removeChild(feedbackContainer, overlayFeedback, urlDisplayMixed);

      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiButton({en: curlvl + "Confirm Feedback", de: curlvl + "Feedback bestätigen"});

      callback((urlCounter + 1));
    }));

  } else if(arrayFalse.length > 0){

      // create new feedbackContainer
      var adjustableHeightFalse = arrayFalse.length;
      feedbackContainer = createFeedbackContainer(options.width * 0.3, options.height * (0.3 + (adjustableHeightFalse * 0.1)), l('clickFeedback'));

      var falseText = MTLG.utils.gfx.getText(l('falselevel1step3'), "27px Bahnschrift", "black");
      falseText.lineWidth = 450;
      falseText.x = feedbackContainer.getBounds().width * 0.05;
      falseText.y = feedbackContainer.getBounds().height / 5;
      feedbackContainer.addChild(falseText);

      // display url with visual marks for wrong and required clicked elements
      var urlDisplayFalse = addMarkedURL(url, arrayFalse, arrayRight, correctTexts);
      stage.addChild(urlDisplayFalse);

      feedbackContainer.addChild(newButton(l('okay'), 27, "rgb(6, 225, 17)", 1, feedbackContainer.getBounds().width / 2, options.height / 12, feedbackContainer.getBounds().width / 2, feedbackContainer.getBounds().height - (options.height / 10), function(){
        stage.removeChild(feedbackContainer, overlayFeedback, urlDisplayFalse);

        // xapi call
        xapiButton({en: curlvl + "Confirm Feedback", de: curlvl + "Feedback bestätigen"});

        callback((urlCounter + 1));
      }));

    } else{

        // create new feedbackContainer
        feedbackContainer = createFeedbackContainer(options.width * 0.3, options.height * 0.3, l('clickFeedback'));

        var namesToAlias = [];
        for(let n = 0;n < correctTexts.length; n++){
          namesToAlias.push(getTextToAlias(correctTexts[n]));
        }

        // xapi call
        xapiStepResult({en: curlvl + "URL-No.: " + (urlCounter+1) + " - " + namesToAlias.toString(), de: curlvl + "URL-Nr.: " + (urlCounter+1) + " - " + namesToAlias.toString()}, true);

        var correctText = MTLG.utils.gfx.getText(l('correctlevel1step3') + namesToAlias.toString() + ".", "27px Bahnschrift", "black");
        correctText.lineWidth = 450;
        correctText.x = feedbackContainer.getBounds().width * 0.05;
        correctText.y = feedbackContainer.getBounds().height / 5;
        feedbackContainer.addChild(correctText);

        // display url with visual marks for wrong and required clicked elements
        var urlDisplayRight = addMarkedURL(url, arrayFalse, arrayRight, correctTexts);
        stage.addChild(urlDisplayRight);

        feedbackContainer.addChild(newButton(l('okay'), 27, "rgb(6, 225, 17)", 1, feedbackContainer.getBounds().width / 2, options.height / 12, feedbackContainer.getBounds().width / 2, feedbackContainer.getBounds().height - (options.height / 10), function(){
          stage.removeChild(feedbackContainer, overlayFeedback, urlDisplayRight);

          // xapi call
          var curlvl = sessionControl.getCurrentLevel();
          xapiButton({en: curlvl + "Confirm Feedback", de: curlvl + "Feedback bestätigen"});

          callback((urlCounter + 1));
        }));

  }

  // ADD THE FEEDBACK CONTAINER TO STAGE
  stage.addChild(feedbackContainer);

  // END OF FUNCTIONALITY addFeedBackClickableURL()


  // START - SERVICE FUNCTIONS FOR addFeedBackClickableURL()

  // service function for correct textual representation for clicked elements in the feedback text
  function getTextToAlias(element){
    if(element.includes("toplevel")){
      return l('topleveldomain');
    }
    if(element.includes("secondleveldomain")){
      return l('secondleveldomain');
    }
    if(element.includes("thirdleveldomain")){
      return l('thirdleveldomain');
    }
    if(element.includes("fourthleveldomain")){
      return l('fourthleveldomain');
    }
    if(element.includes("fifthleveldomain")){
      return l('fifthleveldomain');
    }
    if(element.includes("sixthleveldomain")){
      return l('sixthleveldomain');
    }
    if(element.includes("protocol")){
      return l('protocol');
    }
    if(element.includes("path")){
      // find out which path part
      var length = element.length;
      return (element.substring(length - 1) + ". " + l('path'));
    }
    if(element.includes(".")){
      return l('point');
    }
    if(element.includes("slash")){
      return l('slash');
    }
    return "";
  }


  // service function for correct displaying false and right clicked elements
  function addMarkedURL(url, arrayFalse, arrayRight, correctTexts){
    // display correct and wrong parts of url
    var urlDisplay = addURL([url], 3, 0);
    urlDisplay.getChildByName("wrapperURL").y = 0;

    var urlTextContainer = urlDisplay.getChildByName("wrapperURL").getChildByName("containerURL").getChildByName("text");

    // clicked elements were all wrong or mixed
    for(let i = 0; i < arrayFalse.length; i++){
      var elementFalseText = getTextToAlias(arrayFalse[i][1]);

      // xapi call
      xapiStepResult({en: curlvl + "URL-No.: " + (urlCounter+1) + " - " + elementFalseText, de: curlvl + "URL-Nr.: " + (urlCounter+1) + " - " + elementFalseText}, false);

      var itemText = MTLG.utils.gfx.getText(arrayFalse[i][0] + ": " + elementFalseText + l('level1step3false1'), "27px Bahnschrift", "black");
      itemText.lineWidth = 450;
      itemText.x = feedbackContainer.getBounds().width * 0.05;
      itemText.y = feedbackContainer.getBounds().height / 5 * (i + 2.5);
      feedbackContainer.addChild(itemText);

      // mark wrong clicked elements
      for(let k = 0; k < urlTextContainer.children.length; k++){
        var highlightShape = urlTextContainer.children[k].getChildByName("highlightShape");
        var boundWidth = highlightShape.getBounds().width;
        var boundHeight = highlightShape.getBounds().height;

        if(urlTextContainer.children[k].text === arrayFalse[i][0] && urlTextContainer.children[k].name === arrayFalse[i][1]){

          var falseShape = new createjs.Shape();
          falseShape.graphics.beginFill("rgb(225, 74, 79)").drawRoundRect(0, 0, boundWidth, boundHeight, 5);
          urlTextContainer.children[k].addChild(falseShape);
          urlTextContainer.children[k].setChildIndex(falseShape, 0);

          urlTextContainer.children[k].removeChild(highlightShape);
        }
        for(var m = 0;m < correctTexts.length; m++){
          if(urlTextContainer.children[k].name === correctTexts[m]){
            var requiredShape = new createjs.Shape();
            requiredShape.graphics.beginFill("rgb(6, 225, 17)").drawRoundRect(0, 0, boundWidth, boundHeight, 5);
            urlTextContainer.children[k].addChild(requiredShape);
            urlTextContainer.children[k].setChildIndex(requiredShape, 0);

            urlTextContainer.children[k].removeChild(highlightShape);
          }
        }
      }
    }
    // all clicked elements were right
    if(arrayFalse.length === 0 && arrayRight.length > 0){
      for(let j = 0; j < urlTextContainer.children.length; j++){
        var highlightShapeRight = urlTextContainer.children[j].getChildByName("highlightShape");
        var boundWidthRight = highlightShapeRight.getBounds().width;
        var boundHeightRight = highlightShapeRight.getBounds().height;

        for(let m = 0;m < correctTexts.length; m++){
          if(urlTextContainer.children[j].name === correctTexts[m]){
            var rightShape = new createjs.Shape();
            rightShape.graphics.beginFill("rgb(6, 225, 17)").drawRoundRect(0, 0, boundWidthRight, boundHeightRight, 5);
            urlTextContainer.children[j].addChild(rightShape);
            urlTextContainer.children[j].setChildIndex(rightShape, 0);

            urlTextContainer.children[j].removeChild(highlightShapeRight);
          }
        }
      }
    }
    return urlDisplay;
  }

  // END - SERVICE FUNCTIONS FOR addFeedBackClickableURL()

}

// END - SPECIFIC FEEDBACK METHODS


// START - SERVICE FUNCTIONS FOR THE SPECIFIC FEEDBACK FUNCTIONS ABOVE

/**
  * service function to create the feedback container displayed on stage
  * special feedback methods use this function for the visual representation
  * only for internal use
  */
var createFeedbackContainer = function(width, height, headline, text){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // feedback container for text
  var feedbackContainer = new createjs.Container();
  feedbackContainer.x = options.width / 2;
  feedbackContainer.y = options.height / 2;
  feedbackContainer.regX = width / 2;
  feedbackContainer.regY = height / 2;

  feedbackContainer.setBounds((options.width / 2) - (width / 2),(options.height / 2) - (height / 2), width, height);


  // define shape
  var feedbackShape = new createjs.Shape();
  feedbackShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, width, height, 5);
  feedbackContainer.addChild(feedbackShape);

  // headline
  var headlineFeedback = MTLG.utils.gfx.getText('Feedback', "32px Bahnschrift", "black");
  headlineFeedback.textAlign = 'center';
  headlineFeedback.textBaseline = "middle";
  headlineFeedback.x = feedbackContainer.getBounds().width / 2;
  headlineFeedback.y = feedbackContainer.getBounds().height / 10;

  feedbackContainer.addChild(feedbackShape, headlineFeedback);

  return feedbackContainer;

}

// END - SERVICE FUNCTIONS FOR THE SPECIFIC FEEDBACK FUNCTIONS

// Export the functions
module.exports = {
  addFeedbackForStep,
  addFeedBackClickableURL
};
