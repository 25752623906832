/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T13:29:30+02:00
 */



function tclevel5_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawTclevel5();
}

// check wether tclevel 5 is choosen or not
function checkTclevel5(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "tclevel5"){
    return 1;
  }
  return 0;
}

/**
 * This function contains the drawing of the fifth level of the trainingscenter
 * @function
 */
function drawTclevel5(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // clear stage rom all objects and listeners
  stage.removeAllEventListeners();
  stage.removeAllChildren();

  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  // Demo Delfi buttons
  if(phishingAcademy.demoMode === true){
    demoButtons();
  }

  // LOGICAL PART DETERMINATING LEVELPROGRESS

  // check which step is the next to draw
  function level5Control(){
    // function returns an array
    var levelProgress = sessionControl.determineLevelProgress(5);
    /**
      * because there not that much steps, call them directly instead of searching per recursion
      * next Step to do is stored in levelProgress[3] due to the return array
      */
    switch (levelProgress[3]){
      case 1:
        drawStep1();
        break;
      case 2:
        drawStep2();
        break;
      case 3:
        drawStep3();
        break;
      default:
        // xapi call
        xapiLevelEnd({en:"Trainingscenter - Level 5", de:"Trainingscenter - Level 5"});
        sessionControl.levelComplete(5);
        sessionControl.storeSaveGame();
        MTLG.lc.levelFinished({
          nextLevel: "trainingscenter"
        });
        break;
    }
  }

  // END LOGICAL PART DETERMINATING LEVELPROGRESS

  // GENERAL BUTTONS
  stage.addChild(levelChoiceButton());


  // CONTENT PART OF THE LEVEL - EACH STEP IS ONE INTERNAL FUNCTION

  // FIRST STEP
  function drawStep1(){

    background.visible = true;

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 5 - Step 1 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 5 - Step 1", de:"Trainingscenter - Level 5 - Step 1"});

    // two urls to display
    var ipURL = JSON.parse('[{"urltext" : {'
        + '"full": "www.google.com"'
      + '},'
        + '"urldescription": {'
            + '"de":{'
              + '"full": "172.217.18.174"'
            + '},'
            + '"en":{'
              + '"full": "172.217.18.174"'
            + '}'
        +'}'
      + '}, {"urltext" : {'
        + '"full": "www.tagesschau.de"'
      + '},'
        + '"urldescription": {'
            + '"de":{'
              + '"full": "88.215.213.26"'
            + '},'
            + '"en":{'
              + '"full": "172.217.18.174"'
            + '}'
        +'}'
      + '}, {"urltext" : {'
        + '"full": "www.netflix.de"'
      + '},'
        + '"urldescription": {'
            + '"de":{'
              + '"full": "44.226.113.145"'
            + '},'
            + '"en":{'
              + '"full": "44.226.113.145"'
            + '}'
        +'}'
    + '}]');

    // add the urls to stage
    var urlDisplay = addURL(ipURL, 4, 0, 0);
    urlDisplay.getChildByName("wrapperURL").x = 0;
    urlDisplay.getChildByName("wrapperURL").y = - options.height / 7;
    var urlContainer = urlDisplay.getChildByName("wrapperURL").children;

    // shorten the description shapes, center the text and sum up the textlengths
    var completeLength = 0;
    for(var i = 0;i < urlContainer.length; i++){
      completeLength += urlContainer[i].getChildByName("text").textLength;
      for(var j = 0; j < urlContainer[i].getChildByName("text").children.length; j++){
        if(urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription") != undefined){
          var partShape = urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription").getChildByName("partShape");
          // shorten the description shapes
          var boundHeight = partShape.getBounds().height;
          var shorterShape = new createjs.Shape();
          shorterShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.2, boundHeight, 5);
          shorterShape.setBounds(0, 0, options.width * 0.2, boundHeight);

          let actualDescription = urlContainer[i].getChildByName("text").children[j].getChildByName("urldescription");
          actualDescription .regX = shorterShape.getBounds().width / 2;
          // center the texts
          actualDescription .getChildByName("partDescription").x = shorterShape.getBounds().width / 2;
          actualDescription .getChildByName("partDescription").textAlign = "center";
          // remove old shape and add new shape
          actualDescription .removeChild(partShape)
          actualDescription .addChild(shorterShape);
          actualDescription .setChildIndex(shorterShape, 0);
        }
      }
    }

    var spaceBetween = (options.width - completeLength) / 4;

    // determine new positions for two urls in one line
    urlContainer[0].x = spaceBetween;
    urlContainer[1].x = 2 * spaceBetween + urlContainer[0].getChildByName("text").textLength;
    urlContainer[1].y = 0;
    urlContainer[2].x = 3 * spaceBetween + urlContainer[0].getChildByName("text").textLength + urlContainer[1].getChildByName("text").textLength;
    urlContainer[2].y = 0;

    stage.setChildIndex(urlDisplay, 2);

    // INFOBOXES

    // INFOBOX 1
    var explanationContainer1 = new createjs.Container();
    var explanationShape1 = new createjs.Shape();
    explanationShape1.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape1.x = 0;
    explanationShape1.y = 0;
    explanationShape1.graphics.setStrokeStyle(1);
    explanationShape1.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape1.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer1.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer1.x = (options.width-explanationContainer1.getBounds().width) / 2;
    explanationContainer1.y = options.height * 0.35;

    // content of speechbubble
    var explanationText1 = MTLG.utils.gfx.getText(l('level5step1explanation'), "27px Arial", "black");
    explanationText1.lineWidth = 720;
    explanationText1.lineHeight = 35;
    explanationText1.x =  15;
    explanationText1.y = 15;

    // add domain resolve tree bitmap
    var dnsTranslate = MTLG.assets.getBitmap('img/phishing/dns_server.png');
    dnsTranslate.scale =  0.225;
    dnsTranslate.x = explanationContainer1.getBounds().width * 0.65;
    dnsTranslate.y = (explanationContainer1.getBounds().height * 0.025);

    explanationContainer1.addChild(explanationShape1, explanationText1, dnsTranslate);

    // INFOBOX 2
    var explanationContainer2 = new createjs.Container();
    var explanationShape2 = new createjs.Shape();
    explanationShape2.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape2.x = 0;
    explanationShape2.y = 0;
    explanationShape2.graphics.setStrokeStyle(1);
    explanationShape2.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape2.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer2.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer2.x = (options.width-explanationContainer2.getBounds().width) / 2;
    explanationContainer2.y = options.height * 0.35;

    // content of speechbubble
    var explanationText2 = MTLG.utils.gfx.getText(l('level5step1explanation2'), "27px Arial", "black");
    explanationText2.lineWidth = 650;
    explanationText2.lineHeight = 35;
    explanationText2.x =  15;
    explanationText2.y = 15;

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();
    noteContainer.x = explanationContainer2.getBounds().width * 0.535;
    noteContainer.y = 0;

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scaleX = 0.3;
    noteBitmap.scaleY =0.3;
    noteBitmap.alpha = 1;
    noteContainer.addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level5step1noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 490;
    noteHeadline.textAlign = "center";

    var textLengthHeadline = noteHeadline.getBounds().width;
    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(45, options.height * 0.06).lt(noteContainer.getBounds().width - 45, options.height * 0.06);

    // rule
    var noteText = MTLG.utils.gfx.getText(l('level5step1note'), "27px Arial", "black");
    noteText.lineWidth = 490;
    noteText.x = 45;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);
    explanationContainer2.addChild(explanationShape2, explanationText2, noteContainer);

    // call multipleInfoboxes() for displaying the infoboxes
    multipleInfoboxes([explanationContainer1, explanationContainer2]);

    // add gotIt-button
    var gotItButton = newButton(l('level5step1gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // save game progress
      sessionControl.stepComplete(5,1);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 5 - Step 1 - Got it", de:"Trainingscenter - Level 5 - Step 1 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 5 - Step 1", de:"Trainingscenter - Level 5 - Step 1"});

      MTLG.lc.levelFinished({
        nextLevel: "tclevel5"
      });
    });

    // add the elements to the stage
    stage.addChild(gotItButton, urlDisplay);

    // step dialogs
    var completeStepDialogs = [l('level5step1dialog1'), l('level5step1task')];
    var stepTask = l('level5step1task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);
  }

  // SECOND STEP
  function drawStep2(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 5 - Step 2 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 5 - Step 2", de:"Trainingscenter - Level 5 - Step 2"});

    var urlObject = JSON.parse('[{"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "disney",'
        + '"toplevel": "com",'
        + '"firstSlash": "/",'
        + '"path1domain": "www",' //domain is needed because there is only a divide point, if the key includes the substring domain
        + '"path2domain": "amazon",'//domain is needed because there is only a divide point, if the key includes the substring domain
        + '"path3": "de"'
      + '},'
        + '"urldescription": {}'
      + '}]');

    var textToBeClicked = ["secondleveldomain"];

    // amount of rounds to be played
    let rounds = 1;

    function callbackPassed(){
      addFeedbackForStep(true, l('level5step2complete'), function(){
        // save progress
        sessionControl.stepComplete(5,2);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 5 - Step 2", de:"Trainingscenter - Level 5 - Step 2"});
        xapiStepResult({en:"Trainingscenter - Level 5 - Step 2", de:"Trainingscenter - Level 5 - Step 2"}, true);
        // go to level 2
        MTLG.lc.levelFinished({
          nextLevel: "tclevel5"
        });
      });
    }

    function callbackFailed(){
      // user solution was false
      addFeedbackForStep(false, l('level5step2failed'), function(){
        // save progress
        sessionControl.stepComplete(5,2);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 5 - Step 2", de:"Trainingscenter - Level 5 - Step 2"});
        xapiStepResult({en:"Trainingscenter - Level 5 - Step 2", de:"Trainingscenter - Level 5 - Step 2"}, false);
        // go to level 2
        MTLG.lc.levelFinished({
          nextLevel: "tclevel5"
        })
      });
    }

    // call the oursourced examination
    clickURL(0, rounds, urlObject, textToBeClicked, callbackPassed, callbackFailed);


    // step dialogs
    var completeStepDialogs = [l('level5step2dialog1'), l('level5step2task')];
    var stepTask = l('level5step2task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  function drawStep3(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 5 - Step 3 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 5 - Step 3", de:"Trainingscenter - Level 5 - Step 3"});

    var urlObject = JSON.parse('[{"urltext" : {'
        + '"protocol": "https://",'
        + '"thirdleveldomain": "www",'
        + '"secondleveldomain": "disney",'
        + '"toplevel": "com",'
        + '"firstSlash": "/",'
        + '"path1domain": "www",' //domain is needed because there is only a divide point, if the key includes the substring domain
        + '"path2domain": "amazon",'//domain is needed because there is only a divide point, if the key includes the substring domain
        + '"path3": "de"'
      + '},'
        + '"urldescription": {}'
      + '}]');

    var urlDisplay = addURL([urlObject[0]], 3, 0, 0);
    urlDisplay.getChildByName("wrapperURL").y = 0;
    stage.addChild(urlDisplay);
    stage.setChildIndex(urlDisplay, 1);

    var explanationContainer = new createjs.Container();
    var explanationShape = new createjs.Shape();
    explanationShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    explanationShape.x = 0;
    explanationShape.y = 0;
    explanationShape.graphics.setStrokeStyle(1);
    explanationShape.graphics.beginStroke("rgb(7, 103, 152)");
    explanationShape.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    explanationContainer.setBounds(0,0, options.width * 0.65, options.height * 0.5);
    explanationContainer.x = (options.width-explanationContainer.getBounds().width) / 2;
    explanationContainer.y = options.height * 0.35;

    // content of speechbubble
    var explanationText = MTLG.utils.gfx.getText(l('level5step3explanation'), "27px Arial", "black");
    explanationText.lineWidth = 650;
    explanationText.lineHeight = 35;
    explanationText.x =  15;
    explanationText.y = 15;

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();
    noteContainer.x = explanationContainer.getBounds().width * 0.535;
    noteContainer.y = 0;

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scaleX = 0.3;
    noteBitmap.scaleY =0.3;
    noteBitmap.alpha = 1;
    noteContainer .addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level5step3noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 490;
    noteHeadline.textAlign = "center";

    var textLengthHeadline = noteHeadline.getBounds().width;
    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(45, options.height * 0.06).lt(noteContainer.getBounds().width - 45, options.height * 0.06);

    // rule
    var noteText = MTLG.utils.gfx.getText(l('level5step3note'), "27px Arial", "black");
    noteText.lineWidth = 490;
    noteText.lineHeight = 35;
    noteText.x = 45;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);
    explanationContainer.addChild(explanationShape, explanationText, noteContainer);


    // add gotIt-button
    var gotItButton = newButton(l('level5step3gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // level is complete
      detectiveSpeech([l('level5complete1'), l('level5complete2')], true, function(){
        // save progress
        sessionControl.stepComplete(5, 3);

        // xapi call
        xapiButton({en:"Trainingscenter - Level 5 - Step 3 - Got it", de:"Trainingscenter - Level 5 - Step 3 - Verstanden"});
        xapiStepEnd({en:"Trainingscenter - Level 5 - Step 3", de:"Trainingscenter - Level 5 - Step 3"});

        MTLG.lc.levelFinished({
          nextLevel: "tclevel5"
        })});
    });

    stage.addChild(explanationContainer, gotItButton);

    // step dialogs
    var completeStepDialogs = [l('level5step3dialog1'), l('level5step3task')];
    var stepTask = l('level5step3task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);


  }


  // SERVICE FUNCTION OF THE LEVEL - ONLY INTERNAL USE

  // call for displaying more than one box with textual informations
  function multipleInfoboxes(containerArray){
    // how many infoboxes should be displayed on one page
    var displayLimit = 1;

    // amount of infoboxes
    var amountInfoboxes = containerArray.length;

    function drawInfobox(index){
      return containerArray[index];
    }
    var startPositionElements = {x: 0, y: 0};
    var startPositionNavigation = {leftx: options.width / 8, lefty: options.height * 0.4, rightx: options.width - (options.width / 8), righty: options.height * 0.4};
    var startPositionPageDisplay ={x: options.width / 2, y: options.height - (options.height / 6)};
    carousel(amountInfoboxes, displayLimit, startPositionElements, startPositionNavigation, startPositionPageDisplay, drawInfobox, stage)
  }

  // END CONTENT PART OF THE LEVEL


  //********** START THE LEVEL **********
    // Call levelcontrol to begin the level
    level5Control();
}
