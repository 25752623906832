/**
 * @Date:   2018-10-04T17:09:14+02:00
 * @Last modified time: 2018-11-29T15:32:25+01:00
 */



MTLG.loadOptions({
  "width":1920, //game width in pixels
  "height":1080, //game height in pixels
  "languages":["de","en"], //Supported languages. First language should be the most complete.
  "countdown":660, //idle time countdown
  "fps":"60", //Frames per second
  "playernumber":4, //Maximum number of supported players
  "FilterTouches": true, //Tangible setting: true means a tangible is recognized as one touch event. False: 4 events.
  "webgl": false, //Using webgl enables using more graphical effects
  "passphrase_demo_mode": "demodelfi",
  "passphrase_emergency": "Schule",
   "xapi":{//Configurations for the xapidatacollector. Endpoint and auth properties are required for the xapidatacollector module to work.
      "endpoint": "https://lrs.elearn.rwth-aachen.de/data/xAPI/", //The endpoint of the LRS where XAPI statements are send
      "auth": "Basic YTkxMDE4ODRhODUyOTExYmEwYmE1YmFlNTkzODNkOTQ5ODUyZmQ0NDplMmRlY2NiZjUwZWM0ZmZhNmU3YTY4NGYwMWFmZWU1N2Q4YTNiZmUy", //Authentification token for LRS
      "gamename": "Phishing Academy", //The name of the game can be transmitted as the platform attribute
      "actor": { //A default actor can be specified to be used when no actor is present for a statement
          objectType : "Agent",
          id : "mailto:defaultActor@test.com",
          name : "DefaultActor"
		}
    },
});
