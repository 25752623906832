/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T13:29:09+02:00
 */



function tclevel2_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawTclevel2();
}

// check wether tclevel 2 is choosen or not
function checkTclevel2(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "tclevel2"){
    return 1;
  }
  return 0;
}


// START DRAWING LEVEL 2

/**
 * This function contains the drawing of the second level of the trainingscenter
 * @function
 */
function drawTclevel2(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // clear stage rom all objects and listeners
  stage.removeAllEventListeners();
  stage.removeAllChildren();

  // background
  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  // Demo Delfi buttons
  if(phishingAcademy.demoMode === true){
    demoButtons();
  }

  // LOGICAL PART DETERMINATING LEVELPROGRESS

  // check which step is the next to draw
  function level2Control(){

    // function returns an array
    var levelProgress = sessionControl.determineLevelProgress(2);
    /**
      * because there not that much steps, call them directly instead of searching per recursion
      * next Step to do is stored in levelProgress[3] due to the return array
      */
    switch (levelProgress[3]){
      case 1:
        drawStep1();
        break;
      case 2:
        drawStep2();
        break;
      case 3:
        drawStep3();
        break;
      case 4:
        drawStep4();
        break;
      default:
        sessionControl.levelComplete(2);
        // xapi call
        xapiLevelEnd({en:"Level 2", de:"Level 2"});
        MTLG.lc.levelFinished({
          nextLevel: "trainingscenter"
        });
        break;
    }
  }

  // END LOGICAL PART DETERMINATING LEVELPROGRESS

  // GENERAL BUTTONS
  stage.addChild(levelChoiceButton());


  // CONTENT PART OF THE LEVEL - EACH STEP IS ONE INTERNAL FUNCTION

  // FIRST STEP
  function drawStep1(){
    var urlObject = require('../../urllevel2.json');
    var textToBeClicked = ["toplevel"];

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 2 - Step 1 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 2 - Step 1", de:"Trainingscenter - Level 2 - Step 1"});

    // amount of rounds to be played
    let rounds = 1;

    function callbackPassed(){
      addFeedbackForStep(true, l('level2step1complete'), function(){
        // save progress
        sessionControl.stepComplete(2,1);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 2 - Step 1", de:"Trainingscenter - Level 2 - Step 1"});
        xapiStepResult({en:"Trainingscenter - Level 2 - Step 1", de:"Trainingscenter - Level 2 - Step 1"}, true);
        // go to level 2
        MTLG.lc.levelFinished({
          nextLevel: "tclevel2"
        });
      });
    }

    function callbackFailed(){
      // user solution was false
      addFeedbackForStep(false, l('level2step1failed'), function(){
        // save progress
        sessionControl.stepComplete(2,1);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 2 - Step 1", de:"Trainingscenter - Level 2 - Step 1"});
        xapiStepResult({en:"Trainingscenter - Level 2 - Step 1", de:"Trainingscenter - Level 2 - Step 1"}, false);
        // go to level 2
        MTLG.lc.levelFinished({
          nextLevel: "tclevel2"
        })
      });
    }

    // call the oursourced examination
    clickURL(0, rounds, urlObject.step1, textToBeClicked, callbackPassed, callbackFailed);

    // step dialogs
    var completeStepDialogs = [l('level2step1dialog1'), l('level2step1task')];
    var stepTask = l('level2step1task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // SECOND STEP
  function drawStep2(){
    var urlObject = require('../../urllevel2.json');

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 2 - Step 2 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 2 - Step 2", de:"Trainingscenter - Level 2 - Step 2"});

    // add https example
    var httpsExample = MTLG.assets.getBitmap('img/phishing/https-example.png');
    httpsExample.scale = 0.95;
    var containerHttpsExample = new createjs.Container();
    containerHttpsExample.addChild(httpsExample);

    // arrow read direction
    var signHttpsExample= new Sign(containerHttpsExample, "above", ["protocol"], 1, 7, (options.width - httpsExample.getBounds().width * 0.95) / 2, options.height / 12, false);

    // save all signs in an array
    var signs = [signHttpsExample];

    // draw complete url and add arrow over the url
    var urlDisplay = addURL(urlObject.step2, 5, 0, signs);

    stage.addChild(urlDisplay);
    stage.setChildIndex(urlDisplay, 1);


    // add gotIt-button
    stage.addChild(newButton(l('protokollVerstanden'), 27, "rgb(6, 225, 17)", 1, options.width / 3, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
      // save progress
      sessionControl.stepComplete(2,2);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 2 - Step 2 - Got it", de:"Trainingscenter - Level 2 - Step 2 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 2 - Step 2", de:"Trainingscenter - Level 2 - Step 2"});

      stage.removeChild(urlDisplay);
      // go to step 3
      MTLG.lc.levelFinished({
        nextLevel: "tclevel2"
      });
    }));

    // step dialogs
    var completeStepDialogs = [l('level2step2task')];
    var stepTask = l('level2step2task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // THIRD STEP
  function drawStep3(){
    var urlObject = require('../../urllevel2.json');

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 2 - Step 3 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 2 - Step 3", de:"Trainingscenter - Level 2 - Step 3"});

    // add graphics to show
    var arrowRead = MTLG.assets.getBitmap('img/phishing/arrow-readdirection-both.png');
    var containerArrow = new createjs.Container();
    containerArrow.addChild(arrowRead);

    // arrow read direction
    var signArrow = new Sign(containerArrow, "above",["path", "query"], undefined, undefined, 180, 160, false, 0.225);  //1, 6);

    // info box
    var infoBoxContainer = new createjs.Container();
    var boxShape = new createjs.Shape();
    boxShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.65, options.height * 0.5, 5);
    boxShape.x = 0;
    boxShape.y = 0;
    boxShape.graphics.setStrokeStyle(1);
    boxShape.graphics.beginStroke("rgb(7, 103, 152)");
    boxShape.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    infoBoxContainer.setBounds(0,0, options.width * 0.65, options.height * 0.5);

    infoBoxContainer.addChild(boxShape);

    var signInfoBox = new Sign(infoBoxContainer, "under", ["all"]);

    // save all signs in an array
    var signs = [signArrow];
    // draw complete url and add arrow over the url
    var urlDisplay = addURL(urlObject.step3, 5, 0, signs);
    stage.addChild(urlDisplay);
    stage.setChildIndex(urlDisplay, 1);

    // add gotIt-button
    stage.addChild(newButton(l('pathAufbauVerstanden'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // save progress
      sessionControl.stepComplete(2,3);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 2 - Step 3 - Got it", de:"Trainingscenter - Level 2 - Step 3 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 2 - Step 3", de:"Trainingscenter - Level 2 - Step 3"});

      stage.removeChild(urlDisplay);
      // go to step 4
      MTLG.lc.levelFinished({
        nextLevel: "tclevel2"
      });
    }));

    // step dialogs
    var completeStepDialogs = [l('level2step3dialog1'), l('level2step3dialog2'), l('level2step3task')];
    var stepTask = l('level2step3task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // FOURTH STEP
  function drawStep4(){
    var urlObject = require('../../urllevel2.json');
    var textToBeClicked = ["path1"];

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 2 - Step 4 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 2 - Step 4", de:"Trainingscenter - Level 2 - Step 4"});

    // amount of rounds to be played
    let rounds = 3;

    function callbackPassed(){
      addFeedbackForStep(true, l('level2step4complete'), function(){
        // add detective speech for successful level
        detectiveSpeech([l('level2complete')], true, function(){
          // save progress
          sessionControl.stepComplete(2,4);
          // xapi call
          xapiStepEnd({en:"Trainingscenter - Level 2 - Step 4", de:"Trainingscenter - Level 2 - Step 4"});
          xapiStepResult({en:"Trainingscenter - Level 2 - Step 4", de:"Trainingscenter - Level 2 - Step 4"}, true);
          // go to level 2
          MTLG.lc.levelFinished({
            nextLevel: "tclevel2"
          });
        });
      });
    }

    function callbackFailed(){
      // user perfomance was not good enough
      addFeedbackForStep(false, l('level2step4failed'), function(){
        // remove the completion of the step before
        sessionControl.resetStep(2,3);
        // xapi call
        xapiStepEnd({en:"Trainingscenter - Level 2 - Step 4", de:"Trainingscenter - Level 2 - Step 4"});
        xapiStepResult({en:"Trainingscenter - Level 2 - Step 4", de:"Trainingscenter - Level 2 - Step 4"}, false);
        // start level new to go to step 3
        MTLG.lc.levelFinished({
          nextLevel: "tclevel2"
        })
      });
    }

    // call the oursourced examination
    clickURL(0, rounds, urlObject.step4, textToBeClicked, callbackPassed, callbackFailed);

    // step dialogs
    var completeStepDialogs = [l('level2step4dialog1'), l('level2step4task')];
    var stepTask = l('level2step4task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // END CONTENT PART OF THE LEVEL


  //********** START THE LEVEL **********
    // Call levelcontrol to begin the level
    level2Control();
}
