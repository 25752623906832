/**
 * @Date:   2018-11-27T13:01:52+01:00
 * @Last modified time: 2019-01-28T20:35:11+01:00
 */


/**
  * This function displays directly on stage a Drag & Drop Interface for an URL (No return container etc.)
  * @function
  * @param {string[]} domainParts Array of elements to drag
  * @param {function} callbackRight Function transmitted to call if the user solution is right
  * @param {function} callbackFalse Function transmitted to call if the user solution is right
  */
var addDragAndDropUrl = function(domainParts, callbackRight, callbackFalse){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // add check button
  var checkButton = newButton(l('urlZuordnungPruefen'), 27, "rgb(6, 225, 17)", 1, options.width / 4, options.height / 12, options.width / 2, options.height - (options.height / 6), function(){
    if(checkSolution() === true){
      addFeedbackForStep(true, l('dragAndDropComplete'), callbackRight);
    } else {
      addFeedbackForStep(false, l('dragAndDropFailed'), callbackFalse);
    }
  });

  checkButton.visible = false;
  stage.addChild(checkButton);

  // add new properties to container by prototype for easy check of solution
  createjs.Container.prototype.textDrag = "";
  createjs.Container.prototype.textSolution = "";
  createjs.Container.prototype.textDropped = "";

  // container for all dropzones
  var containerDropZones = new createjs.Container();
  containerDropZones.y = options.height / 3;
  containerDropZones.x = options.width / 2 ;

  // drag and drop
  var draggableArray = [];
  var dropZoneArray = [];

  // set standard length for dragzone
  var ddElementLength = options.width / 7;

  // add variable for complete length of all dropzone containers
  var completeLength = 0;

  // START - ADDING THE ELEMENTS TO STAGE

  // add containers for the three drag zones and two points and one slash
  for(var i = 0; i < domainParts.length; i++){

    var dropzone = createDropZone();
    dropzone.textSolution = domainParts[i];
    dropZoneArray.push(dropzone);
    var divider = createDropDivider(i);
    containerDropZones.addChild(dropzone, divider);

  }

  // add complete container to stage
  stage.addChild(containerDropZones);

  // add containers and shapes and text for the draggable elements
  for(var i = 0; i < domainParts.length; i++){
    var container = createDomainPart(domainParts[i]);
    container.textDrag = domainParts[i];
    draggableArray.push(container);
    stage.addChild(container);
  }

  // END - ADDING THE ELEMENTS TO STAGE

  // INITIAL CALL FOR REPLACING THE CONTAINERS
  replaceContainers();


  // START - SERVICE FUNCTIONS
  // checkCompletness()
  // checkSolution()
  // replaceContainers()
  // createDomainPart()
  // createDropZone()
  // createDropDivider()

  // service function to check completness for check-button - only internal use
  function checkCompletness(){
    var complete = true;
    checkButton.visible = false;
    for(var i = 0; i < draggableArray.length; i++){
      if(stage.getChildIndex(draggableArray[i]) !== -1){
        complete = false;
      }
    }
    if(complete === true){
      checkButton.visible = true;
    }
    return complete
  }

  // service function to evaluate the user solution, return true for correct solution, else false - only internal use
  function checkSolution(){
    var result = true;
    for(var i = 0; i < dropZoneArray.length; i++){
      if(dropZoneArray[i].textSolution !== dropZoneArray[i].textDropped){
        result = false;
      }
    }
    return result;
  }


  // service function to resize all containers if a draggable element was dropped - only internal use
  function replaceContainers(){
    completeLength = 0;
    // resize all children
    var containerChildren = containerDropZones.children;
    for(var i = 0; i < containerChildren.length; i++){

      completeLength += containerChildren[i].getBounds().width;
      // set new position for all containers
      if(i+1 !== containerChildren.length){
        // ctach slash for more space
        if(i+1 === containerChildren.length - 1){
          containerChildren[i+1].x = completeLength + 10;
        } else{
          containerChildren[i+1].x = completeLength;
        }
      }
    }

    containerDropZones.x = options.width / 2 - (completeLength / 2);

    // check completness and solution after each drag
    checkCompletness()
    checkSolution()


  }

  // service function to create a new draggable domainPart - only internal use
  function createDomainPart(text){
    var containerDomainPart = new createjs.Container();
    containerDomainPart.x = (options.width * 0.25) + (Math.random() * (options.width * 0.4));
    containerDomainPart.y = (options.height * 0.66) + (Math.random() * (options.height * 0.25));

    var domainPart = MTLG.utils.gfx.getText(text, "50px Arial", "black");
    var textLength = domainPart.getBounds().width;
    var highlightPart = new createjs.Shape();
    var changeColor = highlightPart.graphics.beginFill("rgb(170, 203, 242)").command;
    highlightPart.graphics.drawRoundRect(0, 0, (textLength + 30), 60, 5);
    highlightPart.setBounds(0, 0, (textLength + 30), 60);
    domainPart.x = (highlightPart.getBounds().width - textLength) / 2;
    domainPart.y = 5;


    containerDomainPart.addChild(highlightPart, domainPart);

    containerDomainPart.on('pressmove', function(evt){
      if(stage.getChildIndex(containerDomainPart) === -1){
        containerDomainPart.parent.removeChild(containerDomainPart);
        stage.addChild(containerDomainPart);
      }
      var newPosition = containerDomainPart.localToLocal(evt.localX,evt.localY, containerDomainPart.parent);
      containerDomainPart.x = newPosition.x;
      containerDomainPart.y = newPosition.y;

      replaceContainers();
    });
    containerDomainPart.on('pressup', function(evt){
      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiDraggable({en: curlvl + "Item " + containerDomainPart.textDrag, de: curlvl + "Item " + containerDomainPart.textDrag});


      if(stage.getChildIndex(containerDomainPart) === -1){
        var p = containerDomainPart.parent.localToGlobal(containerDomainPart.x, containerDomainPart.y);
        containerDomainPart.x = p.x;
        containerDomainPart.y = p.y;
        changeColor.style = "rgb(170, 203, 242)";
        stage.addChild(containerDomainPart);
      }

      for(var i = 0; i < dropZoneArray.length; i++){
        var p = stage.localToLocal(containerDomainPart.x, containerDomainPart.y, dropZoneArray[i]);
        if (dropZoneArray[i].hitTest(p.x, p.y)) {
          if(dropZoneArray[i].numChildren <= 1){
            stage.removeChild(containerDomainPart);
            containerDomainPart.x = 0;
            containerDomainPart.y = 0;

            // remove all childs of container and add new shape in which the drop element fits
            dropZoneArray[i].removeAllChildren();
            var widthDomainPart = containerDomainPart.getBounds().width;
            var newShapeDrop = new createjs.Shape();
            newShapeDrop.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, widthDomainPart + 40, 100, 5);
            newShapeDrop.setBounds(0, 0, widthDomainPart + 40, 100);

            // centralize the drop element
            containerDomainPart.x = 20;
            containerDomainPart.y = 20;

            dropZoneArray[i].addChild(newShapeDrop, containerDomainPart)

            // add text of dropped element to the container for easy solution check afterwards
            dropZoneArray[i].textDropped = containerDomainPart.textDrag;

            changeColor.style = "rgb(234, 234, 234)";

            // xapi call
            xapiCombineDragDrop({en: curlvl + "Item: " + containerDomainPart.textDrag, de: curlvl + "Item: " + containerDomainPart.textDrag},{en: curlvl + "Zone: " +   dropZoneArray[i].textSolution, de: curlvl + "Zone: " + dropZoneArray[i].textSolution});


            }
        } else {
            dropZoneArray[i].removeChild(containerDomainPart);
            if(dropZoneArray[i].numChildren <= 1){
              dropZoneArray[i].removeAllChildren();
              dropZoneArray[i].textDropped = "";

              // readd standard shape
              var standardShape = new createjs.Shape();
              standardShape.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, ddElementLength, 100, 5);
              standardShape.setBounds(0, 0, ddElementLength, 100);
              dropZoneArray[i].addChild(standardShape);
            }
          }
      }
      // change color back, if url part is a normal stage object
      if(containerDomainPart.parent === stage){
        changeColor.style = "rgb(170, 203, 242)";
      }

      replaceContainers();
    });

    return containerDomainPart
  }

  // service function to create a new dropzone - only internal use
  function createDropZone(){
    var containerDropZone = new createjs.Container();
    containerDropZone.x = completeLength ;
    containerDropZone.y = 0;

    // add width to completeLength
    completeLength += ddElementLength;

    var highlightPart = new createjs.Shape();
    var changeColor = highlightPart.graphics.beginFill("rgb(170, 203, 242)").command;
    highlightPart.graphics.drawRoundRect(0, 0, ddElementLength, 100, 5);
    highlightPart.setBounds(0, 0, ddElementLength, 100);

    containerDropZone.addChild(highlightPart);

    return containerDropZone
  }

  // service function to create a new dropzone divider like a Dot or at the end of the URL a slash - only internal use
  function createDropDivider(i){
    var containerDivideSymbol = new createjs.Container();
    if(i <= (domainParts.length - 1)){

      containerDivideSymbol.x = completeLength + 15;
      // set y different, because the shapes od drop zones are about 100px height
      containerDivideSymbol.y = 15;

      var text;
      if(i < (domainParts.length - 1)){
        text = ".";
      } else {
        text = "/";
        containerDivideSymbol.x = completeLength + 25;
      }

      var divideSymbol = MTLG.utils.gfx.getText(text, "60px Arial", "black");
      var textLength = divideSymbol.getBounds().width;
      completeLength += textLength;

      containerDivideSymbol.addChild(divideSymbol);
      return containerDivideSymbol
    }
  }

  // END - SERVICE FUNCTIONS

}

// Export the functions
module.exports = {
  addDragAndDropUrl,
};
