function pretest_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  pretestControl();
}

// check wether tclevel 1 is choosen or not
function checkPretest(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "pretest"){
    return 1;
  }
  return 0;
}

// LOGICAL PART DETERMINATING LEVELPROGRESS

// check which step is the next to draw
function pretestControl(){

  // check if the pretest ist already completed
  var flag = sessionControl.getValueToKey("completionPretest");

  if(flag === 0){
    drawPretest();
  } else {
    MTLG.lc.levelFinished({
      nextLevel: "introduction"
    });
  }

}

// START CONTENT OF PRETEST

/**
 * This function draws the pretest, all functions inside are only for internal use
 * @function
 */
function drawPretest(){

  // set currentLevel
  sessionControl.setCurrentLevel("Pretest -");

  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // variables to save round progress
  let questionsToPlay = 24;
  let questionsAlreadyPlayed = 0;
  let questionArray = [];
  let randomIdArray = shuffleArray(generateArray(questionsToPlay)); //[];
  let correctCount = 0;

  // LOAD CURRENT JSON FILE WITH URLs
  var allURLs = require('../../urlpretest.json');

  // CREATE THE QUESTION OBJECTS
  for(var i = 0; i < questionsToPlay;i++){
    let newRandom = randomIdArray[i];
    if(newRandom !== null){
      let randomURL;
      allURLs.urls.forEach(function(key, index, array){
        if(array[index].id === newRandom){
            randomURL = array[index];
        }
      });
      var question = new Question(newRandom, randomURL.url, randomURL.target, randomURL.reason, randomURL.phishing)
      questionArray.push(question);
    }
  }

  // CREATE EXAM OBJECT
  var exam = new Exam(questionArray);

  // DRAW FUNCTION FOR START SCREEN

  function drawPretestScreen(){
    // get stage, options, store location and set language shortie
    var stage = MTLG.getStageContainer();
    var options = MTLG.getOptions();
    var l = MTLG.lang.getString;

    // background
    var background = new createjs.Shape();
    background.graphics.beginFill("rgb(197, 235, 245)");
    background.graphics.drawRect(0, 0, options.width, options.height);
    stage.addChild(background);

    // Demo Delfi buttons
    if(phishingAcademy.demoMode === true){
      demoButtons();
    }

    // container for pretest details
    var pretestContainer = new createjs.Container();
    // place container
    pretestContainer.x = options.width * 0.25;
    pretestContainer.y = options.height * 0.25;

    var pretestHeadline = MTLG.utils.gfx.getText(l('pretestHeadline'), "36px Bahnschrift", "black");
    pretestHeadline.textAlign = "center";
    pretestHeadline.x = options.width / 4;
    pretestHeadline.y = 20;

    var pretestExplanation = MTLG.utils.gfx.getText(l('pretestExplanation'), "27px Arial", "black");
    pretestExplanation.lineWidth = 940;
    pretestExplanation.lineHeight = 30;
    pretestExplanation.x = 15;
    pretestExplanation.y = pretestHeadline.getMeasuredHeight() + 35;

    // shape for headline
    var headlineShape = new createjs.Shape();
    headlineShape.x = 15;
    headlineShape.y = 15;
    headlineShape.graphics.s("black").mt(0, options.height * 0.04).lt(options.width / 2 - 30, options.height * 0.04);

    // general background shape
    var backgroundShape = new createjs.Shape();
    backgroundShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width / 2, options.height / 2, 5);
    backgroundShape.x = 0;
    backgroundShape.y = 0;

    // button to start
    var startButton = newButton(l('btPretest'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.25, 430, function(){
      stage.removeChild(pretestContainer);
      //xapi call
      xapiButton({en: "Pretest - Test start", de: "Pretest - Test starten"});

      drawExam();
    });

    pretestContainer.addChild(backgroundShape, headlineShape, pretestHeadline, pretestExplanation, startButton);
    stage.addChild(pretestContainer);

  }

  function drawExam(){

    if(questionsAlreadyPlayed <= exam.questions.length - 1){
      // display the urls in the given order by the array
      drawQuestion(exam.questions[questionsAlreadyPlayed])
      showProgress()
      questionsAlreadyPlayed +=1;
    } else{

      // evaluate correctness for each url
      /*var correctCount;
      exam.questions.forEach(function(question){
        if(question.userAnswer === question.phishingAnswer){
          question.userCorrect = true;
        } else{
          question.userCorrect = false;
        }
        if(question.userCorrect === true){
          correctCount += 1;
        }
      });*/
      exam.userScore = correctCount;

      // if all urls done log the events in sessioncontrol, show short ending text and start into the game
      sessionControl.setValueToKey("pretest", sessionControl.getValueToKey("pretest").concat(exam));
      sessionControl.setValueToKey("completionPretest", 1);
      stage.removeChild(stage.getChildByName("progressText"));

      // xapi call
      xapiTestResult({en:"Pretest", de:"Pretest"}, correctCount);

      drawPretestEnd()
    }


  }


  // DRAW FUNCTION FOR THE PRETEST URLS

  function drawQuestion(question){
    var url = question.urlText;
    // container
    var urlContainer = new createjs.Container();
    urlContainer.x = 0;
    urlContainer.y = options.height / 3;

    drawURL();

    function drawURL(){

      // background shape
      var backgroundShape = new createjs.Shape();
      backgroundShape.graphics.beginFill("rgb(234, 234, 234)").drawRect(0, 0, options.width, options.height / 3);

      // url
      var urlText = MTLG.utils.gfx.getText(url, "32px Arial", "black");
      urlText.textAlign = 'center';
      //console.log(urlText.getBounds());
      //urlText.maxWidth = urlText.getBounds().width - 100;
      //urlText.scaleX = 0.85;
      urlText.x = options.width / 2;
      urlText.y = 30;

      // question text
      var urlTarget = MTLG.utils.gfx.getText(l('target') + ": " + question.target, "32px Arial", "black");
      urlTarget.textAlign = 'center';
      urlTarget.x = options.width / 2;
      urlTarget.y = 110;

      // question text
      var urlQuestion = MTLG.utils.gfx.getText(l('preQuestionPhishing'), "27px Arial", "black");
      urlQuestion.textAlign = 'center';
      urlQuestion.x = options.width / 2;
      urlQuestion.y = 190;

      // answer button
      var phishingTrueButton = newButton(l('phishingTrueButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.35, 250, function(){

        for(var j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === question.urlId){
            exam.questions[j].userAnswer = true;
            if(question.userAnswer === question.phishingAnswer){
              question.userCorrect = true;
              exam.questions[j].userCorrect = true;
            } else{
              question.userCorrect = false;
              exam.questions[j].userCorrect = false;
            }
            if(question.userCorrect === true){
              correctCount += 1;
            }
          }
        }

        // xapi call
        xapiURLResult({en:"Pretest Phishing - URL-ID: " + question.urlId, de:"Pretest Phishing - URL-ID: " + question.urlId}, question.userCorrect);

        // draw confidence
        urlContainer.removeChild(urlQuestion, phishingTrueButton, phishingFalseButton)

        // call confidence
        drawConfidence();
      });

      var phishingFalseButton = newButton(l('phishingFalseButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.65, 250, function(){

        for(let j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === question.urlId){
            exam.questions[j].userAnswer = false;
            if(question.userAnswer === question.phishingAnswer){
              question.userCorrect = true;
              exam.questions[j].userCorrect = true;
            } else{
              question.userCorrect = false;
              exam.questions[j].userCorrect = false;
            }
            if(question.userCorrect === true){
              correctCount += 1;
            }
          }
        }

        // xapi call
        xapiURLResult({en:"Pretest Phishing - URL-ID: " + question.urlId, de:"Pretest Phishing - URL-ID: " + question.urlId}, question.userCorrect);

        // draw confidence
        urlContainer.removeChild(urlQuestion, phishingTrueButton, phishingFalseButton)

        // call confidence
        drawConfidence();
      });


      // add all elements to container
      urlContainer.addChild(backgroundShape, urlText, urlTarget, urlQuestion, phishingTrueButton, phishingFalseButton);
      stage.addChild(urlContainer);
    }

    function drawConfidence(){
      // question confidence
      var confidenceQuestion = MTLG.utils.gfx.getText(l('preQuestionConfidence'), "27px Arial", "black");
      confidenceQuestion.textAlign = 'center';
      confidenceQuestion.x = options.width / 2;
      confidenceQuestion.y = 180;

      // answer button
      var confidence1 = newButton(l('confidence1'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 5 / 42, 250, function(){
        setConfidence(1);
      });
      var confidence2 = newButton(l('confidence2'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 13 / 42, 250, function(){
        setConfidence(2);
      });
      var confidence3 = newButton(l('confidence3'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 0.5, 250, function(){
        setConfidence(3);
      });
      var confidence4 = newButton(l('confidence4'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 8 / 14 + options.width * 5 / 42, 250, function(){
        setConfidence(4);
      });
      var confidence5 = newButton(l('confidence5'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 8 / 14  + options.width * 13 / 42, 250, function(){
        setConfidence(5);
      });

      urlContainer.addChild(confidenceQuestion, confidence1, confidence2, confidence3, confidence4, confidence5);

      function setConfidence(confidence){
        for(var j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === question.urlId){
            exam.questions[j].confidenceLevel = confidence;
          }
        }

        // xapi call
        xapiConfidenceResult({en:"Pretest Confidence - URL-ID: " + question.urlId, de:"Pretest Confidence - URL-ID: " + question.urlId}, confidence);

        stage.removeChild(urlContainer);

        // draw next url
        drawExam();
      }

    }


  }

  function drawPretestEnd(){
    sessionControl.setValueToKey("completionPretest", 1);
    //draw small text and display start button for game

    var mainText = MTLG.utils.gfx.getText(l('afterPreMainText'), "32px Arial", "black");
    mainText.textAlign = 'center';
    mainText.x = options.width / 2;
    mainText.y = 380;

    // button for game start
    var gameButton = newButton(l('btPretest'), 27, "rgb(170, 203, 242)", 1, (options.width / 6) - 30, 60, options.width / 2, options.height / 2 , function(){
      // xapi call
      xapiButton({en: "Pretest - Start Game", de: "Evaluation - Spiel starten"});

      MTLG.lc.levelFinished({
        nextLevel: "introduction"
      });
    });

    stage.addChild(mainText, gameButton);
  }

  // Function to show the number of answered URLs
  function showProgress(){
    var progressText = stage.getChildByName("progressText");
    if(progressText === null){
      progressText = MTLG.utils.gfx.getText("Fortschritt:\n" + (questionsAlreadyPlayed) + " / " + questionsToPlay, "32px Arial", "black");
      progressText.name = "progressText";
      progressText.textAlign = 'center';
      progressText.x = options.width / 2;
      progressText.y = options.height * 0.75;
      stage.addChild(progressText);
    } else {
      progressText.text = "Fortschritt:\n" + (questionsAlreadyPlayed) + " / " + questionsToPlay;
    }
  }

  //********** START PRETEST **********
    // Call the start screen for pretest to begin
    drawPretestScreen();



}
