// define xapi-functions for the most used statements

function xapiButton(buttonName){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/multitouch/verbs/clicked",
          display : {en : "clicked", de: "klickte"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/multitouch/activities/button",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = buttonName;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiDraggable(draggableName){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/multitouch/verbs/dragged",
          display : {en : "dragged", de: "zog"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/multitouch/activities/draggable",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = draggableName;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiCombineDragDrop(combineDrag, combineDropZone){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/verbs/combined",
          display : {en : "combined", de: "kombinierte"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/multitouch/activities/draggable",
          name: {en : " ", de: " "}
      },
      context: {
        contextActivities: {
            category: [{
                id: "https://xapi.elearn.rwth-aachen.de/definitions/multitouch/activities/interactable",
                objectType: "Activity",
                definition: {
			            name: {
			                en: {en : " ", de: " "}
			            }
                }
            }],
          }
      }

  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = combineDrag;
  console.log(stmt.context.contextActivities.category[0]);
  stmt.context.contextActivities.category[0].definition.name = combineDropZone;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiLevelStart(levelName){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/verbs/started",
          display : {en : "started", de: "startete"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/activity/level",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = levelName;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiLevelEnd(levelName){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/verbs/finished",
          display : {en : "finished", de: "schloss ab"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/activity/level",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = levelName;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiLevelCancel(levelName){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/verbs/canceled",
          display : {en : "canceled", de: "brach ab"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/activity/level",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = levelName;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiStepStart(stepName){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/verbs/started",
          display : {en : "started", de: "startete"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/activity/stage",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = stepName;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiStepEnd(stepName){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/verbs/finished",
          display : {en : "finished", de: "schloss ab"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/activity/stage",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = stepName;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiStepResult(stepName, result, answer = ""){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/verbs/answered",
          display : {en : "answered", de: "beantwortete"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/activities/task",
          name: {en : " ", de: " "}
      },
      result: {
        completion: true,
        success: true,
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = stepName;
  stmt.result.success = result;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiURLResult(testID, result){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/verbs/answered",
          display : {en : "answered", de: "beantwortete"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/activities/task",
          name: {en : " ", de: " "}
      },
      result: {
        completion: true,
        success: true,
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = testID;
  stmt.result.success = result;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiConfidenceResult(testID, confidenceLevel){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/verbs/answered",
          display : {en : "answered", de: "beantwortete"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/activities/task",
          name: {en : " ", de: " "}
      },
      result: {
        completion: true,
        score:{
          raw: 0
        }
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = testID;
  stmt.result.score.raw = confidenceLevel;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiTestStart(testID){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/verbs/started",
          display : {en : "started", de: "startete"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/activity/level",
          name: {en : " ", de: " "}
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = testID;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}

function xapiTestResult(testID, score){
  // xAPI-Statement
  let stmt = {
      actor: {
        objectType: "Agent",
        id: "mailto:user@phishing-academy.de",
        name: "user"
      },
      verb: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/generic/verbs/finished",
          display : {en : "finished", de: "schloss ab"}
      },
      object: {
          id : "https://xapi.elearn.rwth-aachen.de/definitions/seriousgames/activity/level",
          name: {en : " ", de: " "}
      },
      result: {
        completion: true,
        score:{
          raw: 0
        }
      }
  };
  var actorID = sessionControl.getValueToKey("userID");
  if(actorID){
    stmt.actor.name = actorID;
    stmt.actor.id = "mailto:" + actorID + "@phishing-academy.de";
  }
  stmt.object.name = testID;
  stmt.result.score.raw = score;

  console.log(stmt);

  MTLG.xapidatacollector.sendStatement(stmt).then(function (stmtID) {
      console.log(stmtID);
  }).catch(function (error){
      console.log(error.message);
  });
}
