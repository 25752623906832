/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-03T14:06:08+02:00
 */



function tclevel6_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawTclevel6();
}

// check wether tclevel 6 is choosen or not
function checkTclevel6(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "tclevel6"){
    return 1;
  }
  return 0;
}

/**
 * This function contains the drawing of the sixth level of the trainingscenter
 * @function
 */
function drawTclevel6(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // clear stage rom all objects and listeners
  stage.removeAllEventListeners();
  stage.removeAllChildren();

  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);


  // LOGICAL PART DETERMINATING LEVELPROGRESS

  // check which step is the next to draw
  function level6Control(){
    // function returns an array
    var levelProgress = sessionControl.determineLevelProgress(6);
    /**
      * because there not that much steps, call them directly instead of searching per recursion
      * next Step to do is stored in levelProgress[3] due to the return array
      */
    switch (levelProgress[3]){
      case 1:
        drawStep1();
        break;
      case 2:
        drawStep2();
        break;
      case 3:
        drawStep3();
        break;
      default:
        // xapi call
        xapiLevelEnd({en:"Trainingscenter - Level 6", de:"Trainingscenter - Level 6"});
        sessionControl.levelComplete(6);
        sessionControl.storeSaveGame();
        MTLG.lc.levelFinished({
          nextLevel: "trainingscenter"
        });
        break;
    }
  }

  // END LOGICAL PART DETERMINATING LEVELPROGRESS

  // GENERAL BUTTONS
  stage.addChild(levelChoiceButton());


  // CONTENT PART OF THE LEVEL - EACH STEP IS ONE INTERNAL FUNCTION

  // FIRST STEP
  function drawStep1(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 6 - Step 1 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 6 - Step 1", de:"Trainingscenter - Level 6 - Step 1"});

    // LINK DISPLAY ON MAIL PROGRAMM AND MESSENGER

    // MAIL PROGRAMM
    var explanationContainerMail = new createjs.Container();

    var explanationShapeMail = new createjs.Shape();
    explanationShapeMail.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.4, options.height * 0.5, 5);
    explanationShapeMail.x = 0;
    explanationShapeMail.y = 0;
    explanationShapeMail.setBounds(0,0, options.width * 0.4, options.height * 0.5);

    // set position and bounds for container
    explanationContainerMail.setBounds(0,0, options.width * 0.4, options.height * 0.5);
    explanationContainerMail.x = options.width * 0.05;
    explanationContainerMail.y = options.height * 0.25;

    var mailDesktop = MTLG.assets.getBitmap("img/phishing/linkHover_mail.png");
    mailDesktop.scale = 0.75;
    mailDesktop.x = (explanationShapeMail.getBounds().width - mailDesktop.getBounds().width * mailDesktop.scale) / 2;
    mailDesktop.y = options.height * 0.06;

    // text content of box
    var explanationTextMail = MTLG.utils.gfx.getText(l('level6step1mail'), "27px Arial", "black");
    explanationTextMail.lineWidth = 700;
    explanationTextMail.lineHeight = 35;
    explanationTextMail.x = 25;
    explanationTextMail.y = options.height * 0.33;

    var mailHeadline = MTLG.utils.gfx.getText(l('level6step1MailHeadline'), "32px Bahnschrift", "black");
    mailHeadline.lineWidth = 700;
    mailHeadline.textAlign = "center";

    mailHeadline.x = explanationContainerMail.getBounds().width / 2;
    mailHeadline.y = 25;

    explanationContainerMail.addChild(explanationShapeMail, mailHeadline, explanationTextMail, mailDesktop);

    // MESSENGER
    var explanationContainerMessenger = new createjs.Container();

    var explanationShapeMessenger = new createjs.Shape();
    explanationShapeMessenger.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.4, options.height * 0.5, 5);
    explanationShapeMessenger.x = 0;
    explanationShapeMessenger.y = 0;
    explanationShapeMessenger.setBounds(0,0, options.width * 0.4, options.height * 0.5);

    // set position and bounds for container
    explanationContainerMessenger.setBounds(0,0, options.width * 0.4, options.height * 0.5);
    explanationContainerMessenger.x = options.width * 0.55;
    explanationContainerMessenger.y = options.height * 0.25;

    var linkMessenger = MTLG.assets.getBitmap("img/phishing/linkDisplay_whatsapp.png");
    linkMessenger.scale = 0.50;
    linkMessenger.x = (explanationShapeMessenger.getBounds().width - linkMessenger.getBounds().width * linkMessenger.scale) / 2;
    linkMessenger.y = options.height * 0.06;

    // text content of box
    var explanationTextMessenger = MTLG.utils.gfx.getText(l('level6step1Messenger'), "27px Arial", "black");
    explanationTextMessenger.lineWidth = 700;
    explanationTextMessenger.lineHeight = 35;
    explanationTextMessenger.x = 25;
    explanationTextMessenger.y = options.height * 0.33;

    var messengerHeadline = MTLG.utils.gfx.getText(l('level6step1MessengerHeadline'), "32px Bahnschrift", "black");
    messengerHeadline.lineWidth = 700;
    messengerHeadline.textAlign = "center";

    messengerHeadline.x = explanationContainerMessenger.getBounds().width / 2;
    messengerHeadline.y = 25;

    explanationContainerMessenger.addChild(explanationShapeMessenger, messengerHeadline, explanationTextMessenger, linkMessenger);


    // add gotIt-button
    var gotItButton = newButton(l('level6step1gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // save game progress
      sessionControl.stepComplete(6,1);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 6 - Step 1 - Got it", de:"Trainingscenter - Level 6 - Step 1 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 6 - Step 1", de:"Trainingscenter - Level 6 - Step 1"});

      MTLG.lc.levelFinished({
        nextLevel: "tclevel6"
      });
    });

    stage.addChild(gotItButton, explanationContainerMail, explanationContainerMessenger);

    // Demo Delfi buttons
    if(phishingAcademy.demoMode === true){
      demoButtons();
    }

    // step dialogs
    var completeStepDialogs = [l('level6step1dialog1'), l('level6step1task')];
    var stepTask = l('level6step1task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // SECOND STEP
  function drawStep2(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 6 - Step 3 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 6 - Step 2", de:"Trainingscenter - Level 6 - Step 2"});

    // LINK DISPLAY ON BROWSERS FOR PLATFORMS ON DESKTOP, IOS AND ANDROID

    // BROWSER WINDOWS
    var explanationContainerBrowser = new createjs.Container();

    var explanationShapeBrowser = new createjs.Shape();
    explanationShapeBrowser.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.60, options.height * 0.4, 5);
    explanationShapeBrowser.x = 0;
    explanationShapeBrowser.y = 0;
    explanationShapeBrowser.setBounds(0,0, options.width * 0.60, options.height * 0.4);

    // set position and bounds for container
    explanationContainerBrowser.setBounds(0,0, options.width * 0.60, options.height * 0.4);
    explanationContainerBrowser.x = options.width * 0.03;
    explanationContainerBrowser.y = options.height * 0.025;

    var browserDesktop = MTLG.assets.getBitmap("img/phishing/linkHover_desktop.png");
    browserDesktop.scale = 0.87;
    browserDesktop.x = (explanationShapeBrowser.getBounds().width - browserDesktop.getBounds().width * browserDesktop.scale) / 2;
    browserDesktop.y = options.height * 0.06;

    // text content of box
    var explanationTextBrowser = MTLG.utils.gfx.getText(l('level6step2Browser'), "27px Arial", "black");
    explanationTextBrowser.lineWidth = 1100;
    explanationTextBrowser.lineHeight = 35;
    explanationTextBrowser.x = 25;
    explanationTextBrowser.y = options.height * 0.3;

    var browserHeadline = MTLG.utils.gfx.getText(l('level6step2BrowserHeadline'), "32px Bahnschrift", "black");
    browserHeadline.lineWidth = 1100;
    browserHeadline.textAlign = "center";

    browserHeadline.x = explanationContainerBrowser.getBounds().width / 2;
    browserHeadline.y = 25;

    explanationContainerBrowser.addChild(explanationShapeBrowser, browserHeadline, explanationTextBrowser, browserDesktop);

    // ANDROID
    var explanationContainerAndroid = new createjs.Container();

    var explanationShapeAndroid = new createjs.Shape();
    explanationShapeAndroid.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.31, options.height * 0.825, 5);
    explanationShapeAndroid.x = 0;
    explanationShapeAndroid.y = 0;
    explanationShapeAndroid.setBounds(0,0, options.width * 0.3, options.height * 0.7);

    // set position and bounds for container
    explanationContainerAndroid.setBounds(0,0, options.width * 0.3, options.height * 0.7);
    explanationContainerAndroid.x = options.width * 0.66;
    explanationContainerAndroid.y = options.height * 0.025;

    var linkAndroid = MTLG.assets.getBitmap("img/phishing/linkHover_android.png");
    linkAndroid.scale = 0.275;
    linkAndroid.x = (explanationShapeAndroid.getBounds().width - linkAndroid.getBounds().width * linkAndroid.scale) / 2;
    linkAndroid.y = options.height * 0.06;

    // text content of box
    var explanationTextAndroid = MTLG.utils.gfx.getText(l('level6step2Android'), "27px Arial", "black");
    explanationTextAndroid.lineWidth = 540;
    explanationTextAndroid.lineHeight = 35;
    explanationTextAndroid.x = 25;
    explanationTextAndroid.y = options.height * 0.675;

    var androidHeadline = MTLG.utils.gfx.getText(l('level6step2AndroidHeadline'), "32px Bahnschrift", "black");
    androidHeadline.lineWidth = 540;
    androidHeadline.textAlign = "center";

    androidHeadline.x = explanationContainerAndroid.getBounds().width / 2;
    androidHeadline.y = 25;

    explanationContainerAndroid.addChild(explanationShapeAndroid, androidHeadline, explanationTextAndroid, linkAndroid);

    // IOS
    var explanationContainerIos = new createjs.Container();

    var explanationShapeIos = new createjs.Shape();
    explanationShapeIos.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.60, options.height * 0.4, 5);
    explanationShapeIos.x = 0;
    explanationShapeIos.y = 0;
    explanationShapeIos.setBounds(0,0, options.width * 0.6, options.height * 0.4);

    // set position and bounds for container
    explanationContainerIos.setBounds(0,0, options.width * 0.65, options.height * 0.4);
    explanationContainerIos.x = options.width * 0.03;
    explanationContainerIos.y = options.height * 0.45;

    var linkIos = MTLG.assets.getBitmap("img/phishing/linkHover_ios.PNG");
    linkIos.scale = 0.475;
    linkIos.x = options.width * 0.325;
    linkIos.y = options.height * 0.06;

    // text content of box
    var explanationTextIos = MTLG.utils.gfx.getText(l('level6step2Ios'), "27px Arial", "black");
    explanationTextIos.lineWidth = 520;
    explanationTextIos.lineHeight = 35;
    explanationTextIos.x = 25;
    explanationTextIos.y = options.height * 0.06;

    var iosHeadline = MTLG.utils.gfx.getText(l('level6step2IosHeadline'), "32px Bahnschrift", "black");
    iosHeadline.lineWidth = 1100;
    iosHeadline.textAlign = "center";

    iosHeadline.x = explanationContainerIos.getBounds().width / 2;
    iosHeadline.y = 25;

    explanationContainerIos.addChild(explanationShapeIos, iosHeadline, explanationTextIos, linkIos);

    // add gotIt-button
    var gotItButton = newButton(l('level6step1gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // save game progress
      sessionControl.stepComplete(6,2);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 6 - Step 2 - Got it", de:"Trainingscenter - Level 6 - Step 2 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 6 - Step 2", de:"Trainingscenter - Level 6 - Step 2"});

      MTLG.lc.levelFinished({
        nextLevel: "tclevel6"
      });
    });

    stage.addChild(gotItButton, explanationContainerBrowser, explanationContainerAndroid, explanationContainerIos);

    // Demo Delfi buttons
    if(phishingAcademy.demoMode === true){
      demoButtons();
    }

    // step dialogs
    var completeStepDialogs = [l('level6step2dialog1'), l('level6step2task')];
    var stepTask = l('level6step2task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // THIRD STEP
  function drawStep3(){

    // set currentLevel
    sessionControl.setCurrentLevel("Trainingscenter - Level 6 - Step 3 -");

    // xapi call
    xapiStepStart({en:"Trainingscenter - Level 6 - Step 3", de:"Trainingscenter - Level 6 - Step 3"});

    // SHORT LIST OF TRICKS
    // ADVICE ON XSS AND OTHER SECURITY ISSUES RELATED TO PHISHING

    // add notepaper for detection rule
    var noteContainer = new createjs.Container();

    var noteBitmap = MTLG.assets.getBitmap("img/phishing/note.png");
    noteBitmap.x = 0;
    noteBitmap.y = 0;
    noteBitmap.scale = 0.475;
    noteBitmap.alpha = 1;
    noteContainer .addChild(noteBitmap);

    var noteHeadline = MTLG.utils.gfx.getText(l('level6step3noteHeadline'), "32px Bahnschrift", "black");
    noteHeadline.lineWidth = 730;
    noteHeadline.textAlign = "center";

    noteHeadline.x = noteContainer.getBounds().width / 2;
    noteHeadline.y = 25;

    // shape for headline
    var noteHeadlineShape = new createjs.Shape();
    noteHeadlineShape.graphics.s("black").mt(80, options.height * 0.06).lt(noteContainer.getBounds().width - 60, options.height * 0.06);

    // summary
    var noteText = MTLG.utils.gfx.getText(l('level6step3note'), "27px Arial", "black");
    noteText.lineWidth = 730;
    noteText.lineHeight = 35;
    noteText.x = 80;
    noteText.y = options.height * 0.06 + 15;

    noteContainer.addChild(noteBitmap, noteText, noteHeadlineShape, noteHeadline);

    // set container position
    noteContainer.x = (options.width - noteContainer.getBounds().width) / 2;
    noteContainer.y = options.height / 10;

    // add gotIt-button
    var gotItButton = newButton(l('level6step1gotIt'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 12, options.width / 2, options.height - (options.height / 9), function(){
      // save game progress
      sessionControl.stepComplete(6,3);

      // xapi call
      xapiButton({en:"Trainingscenter - Level 6 - Step 3 - Got it", de:"Trainingscenter - Level 6 - Step 3 - Verstanden"});
      xapiStepEnd({en:"Trainingscenter - Level 6 - Step 3", de:"Trainingscenter - Level 6 - Step 3"});

      detectiveSpeech([l('level6complete1'), l('level6complete2'), l('level6complete3')], true, function(){
        // callback right
        MTLG.lc.levelFinished({
          nextLevel: "tclevel6"
        })});
    });

    stage.addChild(noteContainer, gotItButton);

    // step dialogs
    var completeStepDialogs = [l('level6step3task')];
    var stepTask = l('level6step3task');

    // add detective speech
    detectiveSpeech(completeStepDialogs, true);
    // add step task
    addStepTask(stepTask, completeStepDialogs);

  }

  // END CONTENT PART OF THE LEVEL


  //********** START THE LEVEL **********
    // Call levelcontrol to begin the level
    level6Control();
}
