/**
 * @Date:   2018-10-04T17:09:14+02:00
 * @Last modified time: 2019-01-27T00:46:34+01:00
 */



let toolbarStage; let hotspotStage; let navbarStage; let layerStage; let
  mode;

// Add a new canvas
const addCanvas = function (id, nextStage) {
  const newNode = nextStage.canvas.cloneNode(true);
  newNode.setAttribute('id', id);
  nextStage.canvas.parentNode.insertBefore(newNode, nextStage.canvas.nextSibling);
  return newNode;
};

// Create canvas stages
const createStages = function () {
  const stage = MTLG.getStage();
  stage.nextStage = MTLG.getBackgroundStage();

  layerStage = new createjs.Stage(addCanvas('layer', stage));
  createjs.Touch.enable(layerStage);
  layerStage.nextStage = stage;
  layerStage.enableMouseOver();
  createjs.Ticker.addEventListener('tick', layerStage);

  navbarStage = new createjs.Stage(addCanvas('navbar', layerStage));
  createjs.Touch.enable(navbarStage);
  navbarStage.nextStage = layerStage;
  navbarStage.enableMouseOver();
  createjs.Ticker.addEventListener('tick', navbarStage);

  hotspotStage = new createjs.Stage(addCanvas('hotspots', navbarStage));
  createjs.Touch.enable(hotspotStage);
  hotspotStage.nextStage = navbarStage;
  hotspotStage.enableMouseOver();
  createjs.Ticker.addEventListener('tick', hotspotStage);

  toolbarStage = new createjs.Stage(addCanvas('toolbar', hotspotStage));
  createjs.Touch.enable(toolbarStage);
  toolbarStage.nextStage = hotspotStage;
  toolbarStage.enableMouseOver();
  createjs.Ticker.addEventListener('tick', toolbarStage);

  MTLG.menu.getMenuStage().nextStage = toolbarStage;

  // Create the iframe for the web pages
  createWebsiteFrame();
};

const getLayerStage = function () {
  return layerStage;
};

const getNavbarStage = function () {
  return navbarStage;
};

const getHotspotStage = function () {
  return hotspotStage;
};

const getToolbarStage = function () {
  return toolbarStage;
};

const getWebsiteFrame = function() {
  return document.getElementById('website_frame');
}

// Create a button (name = name of the image)
const createButton = function (name, x = 0, y = 0, w = -1, h = -1) {
  const button = MTLG.assets.getBitmap(`img/${name}.png`);
  button.regX = button.image.width / 2;
  button.regY = button.image.height / 2;
  button.x = x;
  button.y = y;

  if (w != -1) { button.scaleX = w / button.image.width; }
  if (h != -1) button.scaleY = h / button.image.height;

  if (w != -1 && h != -1) button.setBounds(x, y, button.image.width * (w / button.image.width), button.image.height * (h / button.image.height));
  else if (w != -1 && h == -1) button.setBounds(x, y, button.image.width * (w / button.image.width), button.image.height);
  else if (w == -1 && h != -1) button.setBounds(x, y, button.image.width, button.image.height * (h / button.image.height));
  else button.setBounds(x, y, button.image.width, button.image.height);

  const hit_button = new createjs.Shape();
  hit_button.graphics.beginFill('#000').drawRect(0, 0, button.image.width, button.image.height);
  button.hitArea = hit_button;

  return button;
};

// Create a container for the left and right buttons of the navbar
const createButtonContainer = function (space = 0, ...buttons) {
  let x = 0;
  const button_container = new createjs.Container();
  let button_container_width = space;
  let button_container_height = 0;

  if (buttons.length == 0) {
    const empty = createEmptyShape();
    button_container.addChild(empty);
  } else {
    for (const button of buttons) {
      button.x = x + button.getBounds().width / 2;
      button_container.addChild(button);
      if (button_container_height < button.getBounds().height) button_container_height = button.getBounds().height;
      x = x + button.getBounds().width + space;
    }
    button_container_width += x;
    button_container.setBounds(0, 0, button_container_width, button_container_height);
  }

  return button_container;
};

// Create an optimized text
const createText = function (template, textSize, x = 0, y = 0, color = 'black') {
  const text = new createjs.Text(template, `${textSize}px Arial`, color);
  text.regX = text.getTransformedBounds().width / 2;
  text.regY = text.getTransformedBounds().height / 2;
  text.x = x;
  text.y = y;
  text.text.textBaseline = 'middle';

  return text;
};

/* Create an empty shape. This is useful useful, for example, if you don't want
to have buttons on one side of the navbar. In this case put this empy shape
in the left or right button container instead of real buttons. */
const createEmptyShape = function (space = MTLG.getOptions().width * 0.01) {
  const empty = new createjs.Shape();
  empty.graphics.beginFill('white').drawRect(0, 0, 0, 0);
  empty.setBounds(0, 0, space, 1);

  return empty;
};

// Create a gray layer to show that, for example, the underlying web page is not usable.
const createLayer = function() {
  var layer = new createjs.Shape();
  layer.name = "layer";
  layer.graphics.beginFill('black').drawRect(0, MTLG.getOptions().height*0.12-2, MTLG.getOptions().width, MTLG.getOptions().height*0.88+2);
  layer.alpha = 0.5;
  getLayerStage().addChild(layer);
  activateStageInput();
}

// Delete the grey layer
const deleteLayer = function() {
  getLayerStage().removeAllChildren();
}

// Create the iframe for the web pages
const createWebsiteFrame = function() {
  const canvas = document.getElementById('canvasObject');

  var style = window.getComputedStyle(canvas);
  var marginTop = MTLG.getOptions().height * 0.12 - 2;
  var width = style.getPropertyValue('width');
  var height = style.getPropertyValue('height').match(/\d+/);
  document.getElementById("background").style.position = "absolute";
  document.getElementById("background").style.zIndex = "-2";

  var ifrm = document.createElement("iframe");
  ifrm.setAttribute("src", "");
  ifrm.setAttribute("frameborder", "0");
  ifrm.style.width = MTLG.getOptions().width + "px";
  ifrm.style.height = (height - MTLG.getOptions().height * 0.12 + 2)+ "px";
  ifrm.style.marginTop = marginTop+ "px";
  ifrm.style.position = "absolute";
  ifrm.style.zIndex = "-1";
  ifrm.id = "website_frame";
  document.body.insertBefore(ifrm, canvas);
}

// Change the current website
const changeWebsite = function(link) {
  var ifrm = getWebsiteFrame();
  ifrm.setAttribute("src", link);
}

// Delete the iframe for the web pages
const deleteWebsiteFrame = function() {
  var ifrm = getWebsiteFrame();
  ifrm.remove();
}

/* Deactivate all kinds of inputs (touch and mouse inputs) on all stages to
enable inputs on the iframe. */
const deactivateStageInput = function() {
  document.getElementById("menu").style.pointerEvents = "none";
  document.getElementById("toolbar").style.pointerEvents = "none";
  document.getElementById("hotspots").style.pointerEvents = "none";
  document.getElementById("navbar").style.pointerEvents = "none";
  document.getElementById("layer").style.pointerEvents = "none";
  document.getElementById("canvasObject").style.pointerEvents = "none";
  document.getElementById("background").style.pointerEvents = "none";
}

// Activate inputs on all stages and therefore disable inputs on the iframe
const activateStageInput = function() {
  document.getElementById("menu").style.pointerEvents = "auto";
  document.getElementById("toolbar").style.pointerEvents = "auto";
  document.getElementById("hotspots").style.pointerEvents = "auto";
  document.getElementById("navbar").style.pointerEvents = "auto";
  document.getElementById("layer").style.pointerEvents = "auto";
  document.getElementById("canvasObject").style.pointerEvents = "auto";
  document.getElementById("background").style.pointerEvents = "auto";
}

/* Compute the global bounds of shapes with local bounds
(for example children of a container) */
const getGlobalBounds = function(child) {
  var tl = child.localToGlobal(0, 0);
  var tr = child.localToGlobal(child.getBounds().width, 0);
  var br = child.localToGlobal(child.getBounds().width, child.getBounds().height);
  var bl = child.localToGlobal(0, child.getBounds().height);

  var minX = Math.min(tl.x, tr.x, br.x, bl.x);
  var maxX = Math.max(tl.x, tr.x, br.x, bl.x);
  var minY = Math.min(tl.y, tr.y, br.y, bl.y);
  var maxY = Math.max(tl.y, tr.y, br.y, bl.y);

  return new createjs.Rectangle(minX, minY, maxX-minX, maxY-minY);
}

module.exports = {
  createStages,
  createButton,
  createButtonContainer,
  createText,
  createEmptyShape,
  createLayer,
  deleteLayer,
  createWebsiteFrame,
  changeWebsite,
  deleteWebsiteFrame,
  activateStageInput,
  deactivateStageInput,
  getGlobalBounds,
  getLayerStage,
  getNavbarStage,
  getHotspotStage,
  getToolbarStage,
  getWebsiteFrame,
  mode,
};
