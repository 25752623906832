/**
 * @Date:   2018-11-21T18:50:10+01:00
 * @Last modified time: 2019-01-28T00:41:57+01:00
 */


/**
 * This function creates a Multiple-Choice Question object
 * @Constructor
 * @param {string} question Question that should be displayed
 * @param {string[]} possibleAnswers The possible answers (maximum 4)
 * @param {string} correctAnswer The correct Answer (must be exact identically to the one in the possibleAnswers)
 * @param {string} reasonRight Reason for the correct answer
 * @param {string} reasonFalse Reason for the wrong answer, if the user did not choose not the correct one
 * @return {Object} Returns the object created
 */
function MultipleChoiceQuestion(question, possibleAnswers, correctAnswer, reasonRight, reasonFalse){
  this.question = question;
  this.possibleAnswers = possibleAnswers;
  this.correctAnswer = correctAnswer;
  this.reasonRight = reasonRight;
  this.reasonFalse = reasonFalse;
}


/**
 * This function adds a Multiple-Choice Question object to the stage inside a level at the trainingscenter
 * @function
 * @param {Object} questionObject Question object that contains the question
 * @param {function} text The text to be displayed as task
 * @param {function} detectiveSpeechStep Array of the texts to show if the user wants to display the Detectiv dialogue again
 */
var addMultipleChoice = function(questionObject, callbackRight, callbackFalse){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  var multipleChoiceContainer = new createjs.Container();

  var questionText = MTLG.utils.gfx.getText(questionObject.question, "27px Arial", "black");
  questionText.lineWidth = 800;

  var textLength = questionText.getBounds().width;
  var textHeight = questionText.getBounds().height + 27;
  questionText.regX = questionText.getBounds().width / 2;

  // shape for question

  // set minimal width
  var questionShapeLength = 0;
  if(textLength + 30 < 500){
    questionShapeLength = 500;
  } else{
    questionShapeLength = textLength + 30;
  }

  var questionShape = new createjs.Shape();
  questionShape.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, questionShapeLength, textHeight, 5);
  questionShape.setBounds(0, 0, questionShapeLength, textHeight);

  var backgroundShape = new createjs.Shape();
  backgroundShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, questionShape.getBounds().width + 30, (textHeight + (2 * options.height / 12) + (4 * 15)), 5);
  backgroundShape.setBounds(0, 0, questionShape.getBounds().width + 30, (textHeight + (2 * options.height / 12) + (4 * 15)));

  questionText.x = (textLength + 60) / 2;
  questionText.y = 30;
  questionShape.x = 15;
  questionShape.y = 15;
  backgroundShape.x = 0;
  backgroundShape.y = 0;

  multipleChoiceContainer.addChild(backgroundShape, questionShape, questionText);

  var buttonSizeX = (questionShape.getBounds().width - 15) / 2;
  // button for each possible answer
  for(let i = 0; i < questionObject.possibleAnswers.length; i++){
    let distanceShapesX = (buttonSizeX + 15) * (i % 2) + 15;
    let distanceShapesY = questionShape.getBounds().height + 15 + 15;
    if(i > 1){
      distanceShapesY = questionShape.getBounds().height + 15 + 15 + 15 + options.height / 12;
    }
    // maximum four possible answers
    if(i <= 3){

      var newChoiceButton = newButton(questionObject.possibleAnswers[i], 27, "rgb(6, 225, 17)", 1, buttonSizeX, options.height / 12, distanceShapesX + (buttonSizeX / 2), distanceShapesY, function(){
        // xapi call
        var curlvl = sessionControl.getCurrentLevel();
        xapiButton({en: curlvl + "Multiple-Choice: " + questionObject.possibleAnswers[i], de: curlvl + "Multiple-Choice: " + questionObject.possibleAnswers[i]});

        checkMultipleChoiceAnswer(questionObject.possibleAnswers[i]);
      });
      multipleChoiceContainer.addChild(newChoiceButton);
    }
  }

  multipleChoiceContainer.x = (options.width - backgroundShape.getBounds().width) / 2;
  multipleChoiceContainer.y = options.height * 0.25;

  stage.addChild(multipleChoiceContainer);

  function checkMultipleChoiceAnswer(userAnswer){
    if(userAnswer === questionObject.correctAnswer){
      addFeedbackForStep(true, l('correctMulti') + questionObject.reasonRight, callbackRight);

    } else{
        addFeedbackForStep(false, l('falseMulti') + questionObject.reasonFalse, callbackFalse);
      }
  }

}


module.exports = {
  addMultipleChoice,
  MultipleChoiceQuestion
}
