/**
 * @Date:   2018-12-06T02:59:02+01:00
 * @Last modified time: 2019-01-28T19:49:12+01:00
 */


/**
 * This function adds the step task for each level inside the trainingscenter to stage
 * @function
 * @param {string} text The text to be displayed as task
 * @param {string[]} detectiveSpeechStep Array of the texts to show if the user wants to display the Detectiv dialogue again
 */
var addStepTask = function(task, detectiveSpeechStep){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // container for text and headline and button
  var stepTaskContainer = new createjs.Container();
  stepTaskContainer.visible = false;

  var stepTaskHeadline = MTLG.utils.gfx.getText(l('stepTaskHeadline'), "32px Bahnschrift", "black");
  stepTaskHeadline.lineWidth = 400;
  stepTaskHeadline.textAlign = "center";

  var stepTaskText = MTLG.utils.gfx.getText(task, "27px Arial", "black");
  stepTaskText.lineWidth = 380;

  var textLength = stepTaskHeadline.getBounds().width;

  // shape for headline
  var stepTaskShape = new createjs.Shape();
  stepTaskShape.graphics.s("black").mt(0, options.height * 0.05).lt(textLength + 30, options.height * 0.05);
  stepTaskShape.setBounds(0, 0, textLength + 30, options.height * 0.05);

  // general background shape
  var backgroundStepTask = new createjs.Shape();
  backgroundStepTask.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, stepTaskShape.getBounds().width + 30, ((options.height * 0.15) + stepTaskText.getBounds().height + 27 + (3 * 15)), 5);
  backgroundStepTask.setBounds(0, 0, stepTaskShape.getBounds().width + 30, ((options.height * 0.15) + stepTaskText.getBounds().height + 27 + (3 * 15)));

  stepTaskHeadline.x = (textLength + 60) / 2;
  stepTaskHeadline.y = 30;
  stepTaskShape.x = 15;
  stepTaskShape.y = 15;
  backgroundStepTask.x = 0;
  backgroundStepTask.y = 0;
  stepTaskText.x = 15;
  stepTaskText.y = stepTaskShape.getBounds().height + 30;

  // place container
  stepTaskContainer.x = options.width * 0.0175;
  stepTaskContainer.y = options.height * 0.9 - backgroundStepTask.getBounds().height;

  stepTaskContainer.addChild(backgroundStepTask, stepTaskShape, stepTaskHeadline, stepTaskText);
  stage.addChild(stepTaskContainer);

  // button for detectiveSpeech
  var repeatSpeechButton = newButton(l('showDetectiveSpeech'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, backgroundStepTask.getBounds().width / 2, backgroundStepTask.getBounds().height - (options.height / 15) - 15, function(){
    // xapi call
    var curlvl = sessionControl.getCurrentLevel();
    xapiButton({en: curlvl + "Repeat Detective-Dialog", de: curlvl + "Wiederhole Detektiv-Dialog"});

    detectiveSpeech(detectiveSpeechStep, true);
  });
  repeatSpeechButton.regX = 0;
  stepTaskContainer.addChild(repeatSpeechButton);

  // button for opening task
  var taskButton = newButton(l('stepTaskButtonShow'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.1, options.height - (options.height / 12), function(){
    if(stepTaskContainer.visible === false){
      stepTaskContainer.visible = true;
      taskButton.getChildByName("buttonText").text = l('stepTaskButtonRemove');

      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiButton({en: curlvl + "Show Task", de: curlvl + "Aufgabe ansehen"});

    } else {
      stepTaskContainer.visible = false;
      taskButton.getChildByName("buttonText").text = l('stepTaskButtonShow');

      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiButton({en: curlvl + "Close Task", de: curlvl + "Aufgabe schließen"});
    }

  });

  stage.addChild(taskButton);
  stage.setChildIndex(taskButton, 1);

}
