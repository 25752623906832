/**
 * @Date:   2018-11-14T18:24:38+01:00
 * @Last modified time: 2019-01-28T22:25:28+01:00
 */


/**
  * This function adds the detective sprite together with a speechbubble to the stage
  * character-limit for each speechbubble around 170-190 characters. Manual adjustement through the developer is necessary
  * @function
  * @param {string[]} textArray Array of text to display in this dialogue
  * @param {boolean} clickable True: You can click through the dialogue, False: the first speechbubble is displayed till a manual removal
  */
function detectiveSpeech(textArray, clickable, callback){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // create container for speech
  var wrapperContainer = new createjs.Container();
  wrapperContainer.x = 0;
  wrapperContainer.y = 0;
  stage.addChild(wrapperContainer);

  // create overlay, so the speech has to be read
  var overlaySpeech = new createjs.Shape();
  overlaySpeech.graphics.setStrokeStyle(1);
  overlaySpeech.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
  overlaySpeech.visible;
  overlaySpeech.alpha = 0.5;
  overlaySpeech.addEventListener("click", function(event) {event.stopPropagation();});
  wrapperContainer.addChild(overlaySpeech);

  // create a singleton for detective
  function Detective() {
    // do we have an existing instance?
    if (typeof Detective.instance === 'object') {
        return Detective.instance;
    }
    // assign the Detective a key, if he is active on the screen
    this.isActive = 0;
    // cache
    Detective.instance = this;

    // return is implicit
  }

  var detective = new Detective();

  // detective sprite
  var detectiveSprite = MTLG.assets.getBitmap("img/detective.png");
  detectiveSprite.x = -500;
  detectiveSprite.y = options.height * 0.8;
  detectiveSprite.scaleX = .6;
  detectiveSprite.scaleY = .6;
  detective.alpha = 0 ;

  let progressClicked = 0;
  // add listener to the detetctives body, so the user can progress in dialogue if wanted
  detectiveSprite.addEventListener('click', function(){
    if(clickable && progressClicked < textArray.length){

      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiButton({en: curlvl + "Detective Dot", de: curlvl + "Detektiv Dot"});

      updateSpeechbubble(textArray[progressClicked]);
      progressClicked += 1;
    } else if(clickable && progressClicked >= textArray.length){
      // remove event listener
      detectiveSprite.removeAllEventListeners();
      exitDetective(detectiveSprite, bubbleContainer);
    }
  });

  wrapperContainer.addChild(detectiveSprite);

  // SPEECHBUBBLE CREATION

  // speechbubble
   var bubbleContainer = new createjs.Container();
  bubbleContainer.x = options.width * 0.2;
  bubbleContainer.y = options.height * 0.6;
  bubbleContainer.alpha = 0;

  var speechSprite = MTLG.assets.getBitmap("img/phishing/speechbubble.png");
  speechSprite.x = 0;
  speechSprite.y = 0;
  speechSprite.scaleX = .32;
  speechSprite.scaleY = .32;
  speechSprite.alpha = 1;
  bubbleContainer.addChild(speechSprite);

  // content of speechbubble
  var speechText = MTLG.utils.gfx.getText(textArray[progressClicked], "27px Arial", "black");
  speechText.textAlign = 'center';
  speechText.textBaseline = "middle";
  speechText.lineWidth = 340;
  speechText.x = bubbleContainer.getBounds().width / 2;
  speechText.y = (bubbleContainer.getBounds().height / 10) ;
  speechText.alpha = 1;


  bubbleContainer.addChild(speechText);
  wrapperContainer.addChild(bubbleContainer);

  // TWEEENING OF DETECTIVE AND SPEECHBUBBLE

  // function to tween detective and speechbubble to stage
  function introDetective(character, bubbleContainer) {
    detective.active = 1;
    createjs.Tween.get(character)
      .to({
        alpha: 1,
        x: options.width * 0.2,
        y: options.height * 0.75
      }, 600, createjs.Ease.linear)
      .to({
        x: options.width * 0.05,
        y: options.height * 0.75
      }, 200, createjs.Ease.linear);
    createjs.Tween.get(bubbleContainer)
      .wait(700)
      .to({
        alpha: 1,
      }, 600);
  }

  // function removes detective and speechbubble from the visible stage
  function exitDetective(character, bubbleContainer) {
    console.log("disable");
    createjs.Tween.get(bubbleContainer)
      .to({
        alpha: 0,
      }, 600);
    detective.active = 0;
    createjs.Tween.get(character)
      .wait(300)
      .to({
        x: -500,
        y: 650
      }, 800, createjs.Ease.linear);
      setTimeout(function(){stage.removeChild(wrapperContainer); if(callback != undefined){callback();};}, 800)
  }

  // functions updates the text inside the speechbubble
  function updateSpeechbubble(text){
    speechText.text = text;
  }

  // define the actions and tweens according to the actual properties
  if(detective.isActive === 0){
    introDetective(detectiveSprite, bubbleContainer);
    progressClicked += 1;
  }

}

// Export the functions
module.exports = {
  detectiveSpeech
};
