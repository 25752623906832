/**
 * @Date:   2018-11-17T21:39:54+01:00
 * @Last modified time: 2019-01-28T22:24:50+01:00
 */


 /**
  * This function creates a sign object (picture or somethins else) to add this to a displayed URL in mode 5
  * @Constructor
  * @param {Object} content Something what is addable to a stage
  * @param {string} displayMode The display mode choosen: "under" the URL oder "above" the URL (please use suitable sizes of your display object)
  * @param {string[]} showOn On which URL party should the Sign be shown, when the mag glass hits the parts
  * @param {number} startPart Above/under which part should the display of the Sign begin
  * @param {number} endPart Above/under which part should the display of the Sign end
  * @param {number} individualX Give a x position if you want an individual place (only together with individualY)
  * @param {number} individualY Give a y position if you want an individual place (only together with individualX)
  * @param {number} autoscaling Number between 0 and 1, if you want to scale the Sign (use undefined for individual position, if you need scaling only)
  * @return {Object} Returns he object created
  */
function Sign(content, displayMode, showOn, startPart, endPart, individualX, individualY, autoscaling, scalingfactor){
  this.sign = content;
  this.mode = displayMode;
  this.showOn = showOn;
  this.start = startPart;
  this.end = endPart;
  this.visibleCheck = false;
  this.individualX = undefined;
  this.individualY = undefined;
  this.scaling = true;
  // if an oher position is needed
  if(individualX != undefined && individualY != undefined){
    this.individualX = individualX;
    this.individualY = individualY;
  }
  if(autoscaling != undefined){
    this.scaling = autoscaling;
  }
  if(scalingfactor != undefined){
    this.sign.scale = scalingfactor;
  }
}

// START OF CREATING AN URL FOR DISPLAYING ON STAGE

// CALL ORDER OF FUNCTIONS FOR A PACKAGE OF URLs:
// addURL() --> createURL() (for each URL passed) --> createURLPart() (for each part of each URL)

/**
 * This function displays the urls and adds the passed texts to the url-parts or events.
 * mode: 1: url + description + browserbar (only level1) 2: url + description, 3: url + clickable, 4: more than one url + description, 5: url + signs above
 * @function
 * @param {Array} urlObject An array with the parsed URLs inside
 * @param {number} mode The display mode choosen as described above
 * @param {number} amountOfTextToBeClicked How many URL elements should be clickable in mode 3 (optional in any other case)
 * @param {Signs[]} signs Array of Signs objects to display above or under the URL in mode 5 (optional in any other case)
 * @return {Container} Finished container, can be directly added to stage
 */
var addURL = function(urlObject, mode, amountOfTextToBeClicked, signs){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // add new properties to container by prototype for displaying signs and to save the textLength
  createjs.Container.prototype.text = "";
  createjs.Container.prototype.textLength = 0;

  // wrapper for all stuff including url and mag glass
  var wrapperContainer = new createjs.Container();
  wrapperContainer.x = 0;
  wrapperContainer.y = 0;
  wrapperContainer.setBounds(0, 0, options.width, options.height);

  // container for all URL stuff only
  var wrapperURL = new createjs.Container();
  wrapperURL.name = "wrapperURL";
  wrapperContainer.addChild(wrapperURL);

  // set the position and bounds for the URL container
  wrapperURL.x = options.width / 6;
  wrapperURL.y = options.height / 10;
  wrapperURL.setBounds(options.width / 6, options.height / 10, 4 * (options.width / 6), 4 * (options.height / 6));

  // add variable to save the display length of each url text to centralize the url at the end
  this.displayLength = 0;

  // get and set for displayLength
  function getDisplayLength(){
    return this.displayLength;
  };
  function setDisplayLength(value){
    this.displayLength = value ;
  }

  // add variable to save the the selected display mode
  // no setter needed because there is no need to set the mode new
  this.mode = mode;

  function getMode(){
    return this.mode;
  };

  // add the magnifying glass if mode 1, 2 or 4 is selected
  if(mode === 1 || mode === 2 || mode === 4 || mode === 5){

    // add bitmap for magnifying glass
    var magGlass = MTLG.assets.getBitmap('img/search.png');
    magGlass.x = options.width * 0.935;
    magGlass.y = options.height - (options.height / 5) - 25;
    magGlass.regX = magGlass.getBounds().width / 2;
    magGlass.regY = magGlass.getBounds().height / 2 - 25;
    magGlass.scaleX = 1.2;
    magGlass.scaleY = 1.2;
    magGlass.on('pressmove', function(evt){
      var newPosition = magGlass.localToLocal(evt.localX, evt.localY, magGlass.parent);
      magGlass.x = newPosition.x;
      magGlass.y = newPosition.y;

    });

    magGlass.on('pressup', function(evt){
      // xapi call
      var curlvl = sessionControl.getCurrentLevel();
      xapiDraggable({en: curlvl + "Mag Glass", de: curlvl + "Lupe"});
    });

    function getPositionMagGlass(){
      return {x: magGlass.x, y: magGlass.y}
    }

    // advice for mag-glass
    // speechbubble
     var bubbleContainerGlass = new createjs.Container();
    bubbleContainerGlass.x = options.width * 0.835;
    bubbleContainerGlass.y = options.height - (options.height / 2);

    var thinkbubble = MTLG.assets.getBitmap("img/phishing/thinkbubble2.png");
    thinkbubble.x = 0;
    thinkbubble.y = 0;
    thinkbubble.scaleX = .36;
    thinkbubble.scaleY = .36;
    thinkbubble.alpha = 1;
    bubbleContainerGlass.addChild(thinkbubble);

    // content of speechbubble
    var thinkbubbleText = MTLG.utils.gfx.getText(l('magGlassAdvice'), "25px Arial", "black");
    thinkbubbleText.textAlign = 'center';
    thinkbubbleText.textBaseline = "middle";
    thinkbubbleText.lineWidth = 180;
    thinkbubbleText.x = bubbleContainerGlass.getBounds().width / 2;
    thinkbubbleText.y = (bubbleContainerGlass.getBounds().height * 0.275) ;
    thinkbubbleText.alpha = 1;


    bubbleContainerGlass.addChild(thinkbubbleText);

    // area for magnifying glass
    var magGlassArea = new createjs.Shape();
    magGlassArea.color = magGlassArea.graphics.s("rgb(170, 203, 242)").command;
    magGlassArea.graphics.ss(3, "round").sd([12,12]).f("rgb(170, 203, 242)").dr(options.width * 0.935, options.height - (options.height / 5), (magGlass.getBounds().width * 1.2) + 20, (magGlass.getBounds().height * 1.2) + 20);
    magGlassArea.regX = (magGlass.getBounds().width * 1.2) / 2;
    magGlassArea.regY = (magGlass.getBounds().height * 1.2) / 2;

    // create a ticker for the advice
    createjs.Ticker.on("tick", tickGlass);

    function tickGlass(event) {
    bubbleContainerGlass.visible = false;
      var p = wrapperContainer.localToLocal(getPositionMagGlass().x,getPositionMagGlass().y, magGlassArea);
      if (magGlassArea.hitTest(p.x, p.y)) {
          bubbleContainerGlass.visible = true;
      }
      MTLG.getStage().update(event);
    }

    wrapperContainer.addChild(magGlassArea, bubbleContainerGlass, magGlass);
  }


  // CREATE FOR EACH URL THE VISUAL REPRESENTATION
  for (var url of urlObject){
    //reset displayLength for each URL
    setDisplayLength(0);
    // create the URL
    indexURL = urlObject.indexOf(url);
    wrapperURL.addChild(createURL(url, getMode(), indexURL));
  }

  // END OF FUNCTIONALITY addURL() EXCEPT FOR CONTAINER RETURN AT THE END



  // START - SERVICE FUNCTIONS FOR addURL()

  /**
   * This function creates the entire url
   * return: cotainer with the URL to display
   */
  function createURL(url, mode, index){
    // create new container for each url
    var containerURL = new createjs.Container();
    containerURL.name = "containerURL";

    // reset the arrays
    phishingAcademy.arrayOfUserClickedElements = [];
    phishingAcademy.arrayFalse = [];
    phishingAcademy.arrayRight = [];
    phishingAcademy.arrayURLparts = []
    phishingAcademy.arrayShownOnHover = {};

    // catch empty array
    if(typeof url.urltext !== 'undefined'){
      var containerURLsigns = new createjs.Container();
      containerURLsigns.name = "signs";
      var containerURLtext = new createjs.Container();
      containerURLtext.name = "text";

      // set positions
      containerURL.x = 0;
      containerURL.y = index * (2 * (options.height / 6));
      containerURLtext.x = 0;
      containerURLtext.y = options.height / 6;
      containerURLsigns.x = 0;
      containerURLsigns.y = 0;

      containerURL.addChild(containerURLsigns, containerURLtext);

      // create for all parts of the trasmitted URL
      Object.keys(url.urltext).forEach(function(key,index){
        // check the language to load the correct language
        var langString = MTLG.lang.getLanguage();

        // get actual length of all URL party combined and the display mode
        let displayLength = getDisplayLength();
        let mode = getMode();

        // create the fitting URL part depending on the choosen mode
        var returned;
        //let mode = getMode();
        let actualDisplayLength = getDisplayLength();

        if(getMode() !== 3){
          // check for different language solutions
          if(url.urldescription[langString] != undefined){
            // check if there is something to display
            if(url.urldescription[langString][key] != undefined){
              returned = createURLPart(url.urltext[key], url.urldescription[langString][key], actualDisplayLength, mode, key);
              //returned[0].name = key;
            }
            else {
              returned = createURLPart(url.urltext[key], undefined, actualDisplayLength, mode, key);
              //returned[0].name = key;
            }
          }
          // onlfy one language, check if there is something to display
          else if (url.urldescription[key] != undefined){
            returned = createURLPart(url.urltext[key], url.urldescription[key], actualDisplayLength, mode, key);
            //returned[0].name = key;
          }
          //
          else {
            returned = createURLPart(url.urltext[key], undefined, actualDisplayLength, mode, key);
            //returned[0].name = key;
          }
        }
        else { // this is mode 3
          returned = createURLPart(url.urltext[key]," ", actualDisplayLength, mode, key);
          //returned[0].name = key;
        }

        // check if the part got succesfully created, then add to the container
        if(returned != undefined){
          setDisplayLength(returned[1]);
          containerURLtext.addChild(returned[0]);
        }

        // if the part was a part of the host, add a dot afterwards
        if(key.includes("domain")){
          var returned2;
          if(mode === 1){
            returned2 = createURLPart(".", l('urlDot'), getDisplayLength(), mode, "dot");
            //returned2[0].name = ".";
          } else {
            returned2 = createURLPart(".", undefined, getDisplayLength(), mode, "dot");
            //returned2[0].name = ".";
          }
          // check if creation was succesfull
          if(returned2 != undefined){
            setDisplayLength(returned2[1]);
            containerURLtext.addChild(returned2[0]);
          }
        }
        containerURLtext.textLength = getDisplayLength();
      });

      // CREATE ALL THE OTHER STUFF DEPENDING ON MODE
      // browserbar or transmitted Signs

      // calculate horizontal postion for central align
      containerURL.x = (wrapperURL.getBounds().width - getDisplayLength()) / 2;

      // if mode 1 is selected add browserbar
      if(mode === 1){
        var browserBar = MTLG.assets.getBitmap('img/phishing/browserbar.png');
        browserBar.regX = browserBar.getBounds().width / 2;
        browserBar.regY = browserBar.getBounds().height / 2;
        browserBar.scaleX = 1.35;
        browserBar.scaleY = 1.35;
        browserBar.x = (wrapperURL.getBounds().width - getDisplayLength()) * 0.65;
        browserBar.y = (options.height / 6) - (options.height * 0.009);
        containerURL.addChild(browserBar)
        containerURL.setChildIndex(browserBar,0);
      }

      // if mode 5 is selected add the given sign
      if(mode === 5){
        for(var i = 0; i < signs.length; i++){
          var containerSign = signs[i].sign;
          if(signs[i].mode === "above"){
            var containerChildren = containerURLtext.children;
            let length = 0;
            let scalingFlag = 0;
            console.log(signs[i].start, signs[i].endPart);
            if(signs[i].start !== undefined && signs[i].end !== undefined){
              var startPart = signs[i].start;
              var endPart = signs[i].end;

              for(var j = startPart - 1; j <= endPart - 1 ; j++){
                if(j <= containerChildren.length - 1){
                  length +=containerChildren[j].getBounds().width;
                  console.log(length);
                }
              }
              if(signs[i].scaling === true){
                var oldWidth = containerSign.getBounds().width;
                var newWidth = length;
                containerSign.scale = (newWidth / oldWidth);
                console.log(oldWidth);
                scalingFlag = 1;
              }
              containerSign.x = containerChildren[startPart - 1].x;
              containerSign.y = (options.height / 6) - ((containerSign.getBounds().height * (newWidth / oldWidth)));
            }
            if(signs[i].scaling === true && scalingFlag === 0){
              var oldWidth = containerSign.getBounds().width;
              var newWidth = length;
              containerSign.scale = (newWidth / oldWidth);
            }
            // check for indivual position
            if(signs[i].individualX != undefined && signs[i].individualY != undefined){
              var localPoint = wrapperURL.globalToLocal(signs[i].individualX, signs[i].individualY);
              containerSign.x = localPoint.x - containerURLsigns.parent.x;
              containerSign.y = localPoint.y - containerURLsigns.parent.y;
              console.log(containerSign.x, containerSign.y);
            }
            containerSign.visible = false; // neu
            containerURLsigns.addChild(containerSign);
            console.log(containerSign.x, containerSign.y, containerSign.getBounds().width);
          }
          if(signs[i].mode === "under"){
            containerSign.x = 0 - ((containerSign.getBounds().width - containerURL.getBounds().width) / 2);
            containerSign.y = wrapperURL.getBounds().height * 0.4;
            // check for indivual position
            if(signs[i].individualX != undefined && signs[i].individualY != undefined){
              var localPoint = wrapperURL.globalToLocal(signs[i].individualX, signs[i].individualY);
              containerSign.x = localPoint.x - containerURLsigns.parent.x;
              containerSign.y = localPoint.y - containerURLsigns.parent.x;
            }
            containerURLsigns.addChild(containerSign);
          }
        }

        // create ticker for displaying the sign
        createjs.Ticker.on("tick", tickSign);

        function tickSign(event) {
          for(var l = 0; l < signs.length; l++){
            signs[l].sign.visible = false;
            signs[l].visibleCheck = false;
            var hitTriggered = [];

            for(let j = 0; j < containerURLtext.children.length; j++){ //var
              var p = wrapperContainer.localToLocal(getPositionMagGlass().x,getPositionMagGlass().y, containerURLtext.children[j]);
              if (containerURLtext.children[j].children[0].hitTest(p.x, p.y)) {
                signs[l].visibleCheck = true;
                hitTriggered.push(containerURLtext.children[j].name);

              }
            }
            if(hitTriggered.length > 0 && signs[l].visibleCheck === true){
              if(signs[l].showOn.indexOf("all") !== -1){
                signs[l].sign.visible = true;
              }
              for(var k = 0; k < hitTriggered.length; k++){
                if(signs[l].showOn.indexOf(hitTriggered[k]) !== -1){
                  signs[l].sign.visible = true;
                }
              }
            }
          }
          MTLG.getStage().update(event);
        }

      }
    }

    // RETURN THE URL CONTAINER TO addURL()

    return containerURL;

    //END OF FUNCTIONALITY of createURL()



    // START - SERVICE FUNCTIONS FOR createURL()

    /**
     * service function to create an URL part
     * return: [containerURLPart, lengthOfparts]
     */
    function createURLPart(text, textDescription, actualLength, mode, key = ""){
      var containerURLPart = new createjs.Container();
      containerURLPart.name = key;
      containerURLPart.x = actualLength + 10;
      containerURLPart.y = 0;
      containerURLPart.text = text;
      if(key !== "dot"){
        phishingAcademy.arrayShownOnHover[key] = false;
      }
      // text of url-part
      var urlPart = MTLG.utils.gfx.getText(text, "60px Arial", "black");
      urlPart.textBaseline = "top";
      var textLength = urlPart.getBounds().width;

      // add shape to highlight url if magnifying glass hits
      var highlightPart = new createjs.Shape();
      highlightPart.name = "highlightShape";
      var changeColor = highlightPart.graphics.beginFill("rgb(170, 203, 242)").command;
      highlightPart.graphics.drawRoundRect(0, 0, textLength, 60, 5);
      highlightPart.setBounds(0, 0, textLength, 60);
      highlightPart.visible = false;

      containerURLPart.addChild(highlightPart, urlPart);

      // add more elements needed by the given mode by switch-cases
      switch(mode){
        // url + description (browserbar for case 1 got added before, so there is only one different case necessary)
        case 1: case 2: case 4: case 5:

          if(textDescription != undefined){
            // add arrow to textbox
            var boxArrow = MTLG.assets.getBitmap('img/phishing/arrow_down.png');
            boxArrow.regX = boxArrow.getBounds().width / 2;
            boxArrow.scaleX = 0.13;
            boxArrow.scaleY = 0.13;
            boxArrow.x =  (textLength / 2);
            boxArrow.y = options.height * 0.07;
            boxArrow.visible = false;

            // text description
            var textContainer = new createjs.Container();
            textContainer.name = "urldescription";
            textContainer.x =  (textLength / 2);
            textContainer.y = options.height * 0.17;
            textContainer.visible = false;

            // content of description
            var partDescription = MTLG.utils.gfx.getText(textDescription, "27px Arial", "black");
            partDescription.name = "partDescription";
            partDescription.textBaseline = "middle";
            partDescription.lineHeight = 35;
            partDescription.lineWidth = 715;//460;


            var partShape = new createjs.Shape();
            partShape.name = "partShape";
            partShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width * 0.4, partDescription.getBounds().height + 15, 5);
            partShape.x = 0;
            partShape.y = 0;
            partShape.graphics.beginStroke("rgb(7, 103, 152)");
            partShape.graphics.setStrokeStyle(1);
            partShape.setBounds(0,0,options.width * 0.4, partDescription.getBounds().height + 15);
            textContainer.regX = partShape.getBounds().width / 2;
            textContainer.addChild(partShape);

            partDescription.x = 15;
            partDescription.y = 25;

            textContainer.addChild(partDescription);


            containerURLPart.addChild(boxArrow, textContainer);

            // create a ticker for each part
            createjs.Ticker.on("tick", tickMode1);

            function tickMode1(event) {
              textContainer.visible = false;
              highlightPart.visible = false;
              boxArrow.visible = false;
              var p = wrapperContainer.localToLocal(getPositionMagGlass().x,getPositionMagGlass().y, containerURLPart);
              if (highlightPart.hitTest(p.x, p.y)) {
                textContainer.visible = true;
                highlightPart.visible = true;
                boxArrow.visible = true;
                if(phishingAcademy.arrayShownOnHover[key] === false){
                  phishingAcademy.arrayShownOnHover[key] = true;
                }
              }
              MTLG.getStage().update(event);
            }

          }

          // check for signs, if there are no descriptions show signs on every url-part
          if(signs != undefined && Object.keys(url.urltext).length === 0){
            signs.forEach(function(sign){
              if(sign.showOn === "all" || sign.showOn.indexOf(text) !== -1){

                // create a ticker for each part
                createjs.Ticker.on("tick", tickMode5);
                function tickMode5(event) {
                  var p = wrapperContainer.localToLocal(getPositionMagGlass().x,getPositionMagGlass().y, containerURLPart);
                  if (highlightPart.hitTest(p.x, p.y)) {
                    sign.content.visible = true;
                  }
                  MTLG.getStage().update(event);
                }
              }
            });
          }
          return [containerURLPart, (actualLength + textLength)]
          break;

        // url + clickable
        case 3:
          changeColor.style = "rgb(197, 235, 245)";
          highlightPart.visible = true;
          let check = 0;
          phishingAcademy.arrayURLparts.push([text,containerURLPart.name, check]);

          containerURLPart.addEventListener('click', function(evt){
            var p = containerURLPart.globalToLocal(evt.stageX, evt.stageY);

            if (highlightPart.hitTest(p.x, p.y) && containerURLPart.name != "dot" && containerURLPart.name != "firstSlash" && amountOfTextToBeClicked != 0) {

              console.log(phishingAcademy.arrayURLparts);
              console.log(text,containerURLPart.name);
              let index = getIndex(text,containerURLPart.name);
              let partInArray = phishingAcademy.arrayURLparts[index];
              // TODO: Klicken eines anderen Elements entfernt ein einzelnes?

              if(/*check*/ partInArray[2] === 0 && phishingAcademy.arrayOfUserClickedElements.length < amountOfTextToBeClicked){
                changeColor.style = "rgb(170, 203, 242)";
                var clickedToArray1 = [text,containerURLPart.name, changeColor, index];
                phishingAcademy.arrayOfUserClickedElements.push(clickedToArray1);

                // change the check
                phishingAcademy.arrayURLparts[index][2]/*check*/ = 1;

                //xapi call
                var curlvl = sessionControl.getCurrentLevel();
                xapiButton({en: curlvl + "URL Part: " + containerURLPart.name, de: curlvl + "URL Element: " + containerURLPart.name});
              }else if(/*check*/ partInArray[2] === 0 && phishingAcademy.arrayOfUserClickedElements.length >= amountOfTextToBeClicked && amountOfTextToBeClicked != 0){

                // reset color and check for already clicked elements
                for(let k = 0; k < phishingAcademy.arrayOfUserClickedElements.length; k++){
                  phishingAcademy.arrayOfUserClickedElements[k][2].style = "rgb(197, 235, 245)";
                  phishingAcademy.arrayURLparts[phishingAcademy.arrayOfUserClickedElements[k][3]][2] = 0;
                }

                //  empty the array
                phishingAcademy.arrayOfUserClickedElements.length = 0;

                changeColor.style = "rgb(170, 203, 242)";
                var clickedToArray2 = [text,containerURLPart.name, changeColor, index];
                phishingAcademy.arrayOfUserClickedElements.push(clickedToArray2);
                phishingAcademy.arrayURLparts[index][2]/*check*/ = 1;

              }else if(/*check*/ partInArray[2]  === 1){
                changeColor.style = "rgb(197, 235, 245)";
                var findIndex = -1;

                for(var j = 0; j < phishingAcademy.arrayOfUserClickedElements.length; j++){
                  if(phishingAcademy.arrayOfUserClickedElements[j][0] === text, phishingAcademy.arrayOfUserClickedElements[j][1] === containerURLPart.name){
                    findIndex = j;
                  }
                }

                if(findIndex !== -1){
                  phishingAcademy.arrayOfUserClickedElements.splice(findIndex, 1);
                  phishingAcademy.arrayURLparts[index][2]/*check*/ = 0;
                }
              }

              console.log(phishingAcademy.arrayOfUserClickedElements);
              console.log(phishingAcademy.arrayURLparts);
            }
          });

          function getIndex(text, name){
            var indexPart;
            for(var j = 0; j < phishingAcademy.arrayURLparts.length; j++){
              if(phishingAcademy.arrayURLparts[j][0] === text, phishingAcademy.arrayURLparts[j][1] === name){
                indexPart = j;
              }
            }
            return indexPart;
          }

          return [containerURLPart, (actualLength + textLength)]
          break;
        }

      }

      // END - SERVICE FUNCTIONS FOR createURL()

  }

  // END - SERVICE FUNCTIONS FOR addURL()

  // RETURN WHOLE CONTAINER AT THE END OF addURL()
  return wrapperContainer;

}

// Export the functions
module.exports = {
  addURL,
  Sign
};
