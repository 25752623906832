function inputIdentification(dummyText, x, y, width, height){
  //this.stage = stage;
  //this.label = label;
  this.width = width;
  this.height = height;
  this.x = x;
  this.y = y;

  this._view = newBox(this.x, this.y, this.width, this.height); // sets view
  console.log(this._view);
  //this.stage.addChild(this._view);

  this._textView = newText(dummyText, {textBaseline: "top", padding: {lr: 25, tb: 16}});
  this._textView.x = this._view.x;
  this._textView.y = this._view.y;
  //this.stage.addChild(this._textView);

  //this.addedViews = [];
  this.active = false;

  this.keysPressed = [];

  /**
   * Returns text of input bar
   */
  this.getText = () => {
    return this._textView.getText();
  }

  /**
   * Resets text in input bar
   */
  this.resetText = () => {
    this._textView.resetText();
  }

  this.activate = () => {
    this._view.shadow = new createjs.Shadow('#c0c0c0', 0, 0, 50);
    this.active = true;
  }

  this.deactivate = () => {
    this._view.shadow = null;
    this.active = false;
  }

  // Activates/Deactivates the inputBarView for key press events
  this._view.addEventListener('pressup', function() {
    if (!this.active) {
      this.activate();
      this.resetText();
    } else {
      this.deactivate();
    }
  }.bind(this));

  // Handles all key up events to either add or remove character
  document.addEventListener('keyup', function (e) {

    // all invalid keys that might be pressed and now released
    let invalidKeys = [
      " ","Meta","F1","F2","F3","F4","F5","F6","F7","F8","F9","F10","F11",
      "F12","NumLock","ArrowUp","ArrowLeft","ArrowDown","ArrowRight",
      "Home","End","PageUp","PageDown","Clear","Insert","Delete","Enter",
      "PrintScreen","ScrollLock","Pause","Dead","Alt","Escape",
      "Shift","CapsLock","Control","AltGraph","Backspace","NumLock"
    ];

    // all character mappings due to AltGraph or Control+Alt
    let charMapping = new Map([
      ["<","|"],
      ["+","~"],
      ["7","{"],
      ["8","["],
      ["9","]"],
      ["0","}"],
      ["ß","\\"],
      ["e","€"],
      ["q","@"],
      ["m","µ"]]);

    let key = e.key;

    if (this.active) {
        // checks for Ctrl+Alt or Ctrl+AltGr pressed
        if (charMapping.has(key) &&
              (this.keysPressed.includes("Control") &&
                (this.keysPressed.includes("AltGraph") ||
                  this.keysPressed.includes("Alt"))
                )
              ) {
          key = charMapping.get(key);
        }

        if (key == 'Enter') {
          //console.log(this);
          this.addGeneratedUrlPart();
          return;
        }
        // console.log(key);
        if (!invalidKeys.includes(key)){
          this._textView.addChar(key);
        } else if (key == "Backspace") {
          this._textView.removeChar();
        }
    }

    if (this.keysPressed.includes(key)){
      this.keysPressed = this.keysPressed.filter((v,i,arr) => {
        return v != key;
      });
      //console.log(this.keysPressed);
    }
    // console.log(key);
  }.bind(this));

  // Stores all keys pressed
  document.addEventListener('keydown', function (e) {
    // console.log(e.key);
    // all forbidden chars/multi-key-presses
    let forbiddenChars = [
      " ","\\","{","[","]","}","~","|","³","²","€","@","µ"
    ];
    // all invalid keys which will not be logged
    let invalidKeys = [
      " ","Meta","NumLock","ArrowUp","Clear","ArrowLeft",
      "ArrowDown","ArrowRight","Home","End","PageUp","PageDown","Insert",
      "Delete","Enter","PrintScreen","ScrollLock","Pause","F1","F2","F3",
      "F4","F5","F6","F7","F8","F9","F10","F11","F12"
    ];

    if (!this.keysPressed.includes(e.key) && !forbiddenChars.includes(e.key) && !invalidKeys.includes(e.key)){
      this.keysPressed.push(e.key);
    }

    return;
  }.bind(this));

  return this;
}
