/**
 * @Date:   2019-09-02T21:33:38+02:00
 * @Last modified time: 2019-09-10T21:01:11+02:00
 */



function demoButtons(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  // container for demo buttons
  var demoButtonContainer = new createjs.Container();
  demoButtonContainer.x = options.width * 0.005;
  demoButtonContainer.y = options.height * 0.305;

  var backgroundSizeX = options.width * 0.05;
  var backgroundSizeY = options.height * 0.225;
  var background = new createjs.Shape();
  background.name = "background";

  background.color = background.graphics.beginFill("rgb(158,158,158)");
  background.graphics.drawRoundRect(0, 0, backgroundSizeX, backgroundSizeY, 10);

  // text demo only
  var demoText = new createjs.Text("Demo only", "16px Arial", "black");
  demoText.name = "buttonText";
  demoText.x = 8;
  demoText.y = 15;
  demoText.textBaseline = "middle";

  // 1. Button: Done
  var demoDoneButton = newButton(l('btDemoDone'), 18, "rgb(255,160,122)", 1, options.width / 25, options.height / 25, options.width * 0.005 + options.width / 50, options.height * 0.025, function(){
    // clear criminalcases timers and background
    clearCriminalcases();
    // start a new demo session and load the needed saveGame to the localStorage
    //sessionControl.newDemoSession(); not necessary if there is a reset button
    sessionControl.changeSaveGame(storeLocation.getItem("1-Done"));
    MTLG.lc.levelFinished({
      nextLevel: "playermenu"
    });
  });



  // 2. Button: Played
  var demoPlayedButton = newButton(l('btDemoPlayed'), 18, "rgb(255,160,122)", 1, options.width / 25, options.height / 25, options.width * 0.005 + options.width / 50, options.height * 0.075, function(){
    // clear criminalcases timers and background
    clearCriminalcases();
    // start a new demo session and load the needed saveGame to the localStorage
    //sessionControl.newDemoSession(); not necessary if there is a reset button
    sessionControl.changeSaveGame(storeLocation.getItem("2-Played"));
    MTLG.lc.levelFinished({
      nextLevel: "playermenu"
    });
  });


  // 3. Button: Empty
  var demoEmptyButton = newButton(l('btDemoEmpty'), 18, "rgb(255,160,122)", 1, options.width / 25, options.height / 25, options.width * 0.005 + options.width / 50, options.height * 0.125, function(){
    // clear criminalcases timers and background
    clearCriminalcases();
    // start a new session and add the saveGame to the localStorage
    //sessionControl.newDemoSession(); not necessary if there is a reset button
    sessionControl.changeSaveGame(storeLocation.getItem("3-Empty"));
    MTLG.lc.levelFinished({
      nextLevel: "introduction"
    });
  });


  // 4. Button: ResetSG
  var demoResetButton = newButton(l('btDemoReset'), 18, "rgb(255,160,122)", 1, options.width / 25, options.height / 25, options.width * 0.005 + options.width / 50, options.height * 0.175, function(){
    // clear criminalcases timers and background
    clearCriminalcases();
    // start a new demo session
    sessionControl.newDemoSession();
    MTLG.lc.levelFinished({
      nextLevel: "phishingmenu"
    });
  });

  demoButtonContainer.addChild(background, demoText, demoDoneButton, demoPlayedButton, demoEmptyButton, demoResetButton)

  stage.addChild(demoButtonContainer);


  // clear eventually existing intervals from criminalcases
  function clearCriminalcases(){
    for(var i = 0; i < phishingAcademy.criminalcasesIntervals.length; i++){
      clearInterval(phishingAcademy.criminalcasesIntervals[i]);
    }
    MTLG.clearBackground();
  }

}

// Export the functions
module.exports = {
  demoButtons
};
