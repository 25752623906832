/**
 * @Date:   2018-10-17T11:26:39+02:00
 * @Last modified time: 2018-11-13T23:01:50+01:00
 */



// A bool remembering if this is the first time the game is run
let firstRun = true;

function drawMainMenu(){

    // Check if this is the first time the menu is created
  // In this case we also create an overlay menu
  if (firstRun) {
    mode = 1; // Default mode
    firstRun = false;
    // Create Menu
    MTLG.menu.addSubMenu(() => {
      console.log('call game submenu');
    });
    MTLG.menu.start();
  }

  // log in demo player if not yet present
  if (!MTLG.getPlayerNumber()) {
      console.log("Logging in");
      MTLG.lc.goToLogin(); //Leave Main Menu and go to login
  }

  // get stage to add objects
  var stage = MTLG.getStageContainer();
  l = MTLG.lang.getString;

 // Draw the main menu
  // Background
  const background = new createjs.Shape();
  background.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
  background.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);

  // Headline
  const title = createText(l('settings'), 40, MTLG.getOptions().width / 6, 0);

  // Settings field1
  const settings_field1_background = new createjs.Shape();
  settings_field1_background.graphics.setStrokeStyle(2).beginStroke('lightgrey').beginFill('white').drawRect(0, 0, MTLG.getOptions().width / 3, MTLG.getOptions().height / 6);

  const magnifier_on = createText(l('magnifier_on'), 30, MTLG.getOptions().width / 12, MTLG.getOptions().height / 12);

  const switch_button = createButton('switch', MTLG.getOptions().width / 6, MTLG.getOptions().height / 12);
  if (mode == 1) {
    switch_button.rotation = 0;
  } else {
    switch_button.rotation = 180;
  }
  switch_button.on('click', (evt) => {
  	if (mode == 1) {
  		switch_button.rotation = 180;
      mode = 0;
  	} else {
  		switch_button.rotation = 0;
  		mode = 1;
    }
  });

  const magnifier_off = createText(l('magnifier_off'), 30, MTLG.getOptions().width / 4, MTLG.getOptions().height / 12);

  const settings_field1 = new createjs.Container();
  settings_field1.y = title.getBounds().height;
  settings_field1.addChild(settings_field1_background, magnifier_on, magnifier_off, switch_button);

  // Settings field2
  const settings_field2_background = new createjs.Shape();
  settings_field2_background.graphics.setStrokeStyle(2).beginStroke('lightgrey').beginFill('white').drawRect(0, 0, MTLG.getOptions().width / 3, MTLG.getOptions().height / 6);

  const settings_field2 = new createjs.Container();
  settings_field2.y = title.getBounds().height + MTLG.getOptions().height / 6;
  settings_field2.addChild(settings_field2_background);

  // Start Button
  const start = createText(l('start'), 40, MTLG.getOptions().width / 6, MTLG.getOptions().height / 12, 'white');

  const start_button_background = new createjs.Shape();
  start_button_background.graphics.beginFill('#77CA72').drawRect(0, 0, MTLG.getOptions().width / 3, MTLG.getOptions().height / 6);
  start_button_background.shadow = new createjs.Shadow('#4C4C4C', 0, 0, 4);
  start_button_background.on('click', (evt) => {
    getNavbarStage().removeAllChildren(); // Remove the navbar
    if(getWebsiteFrame() == null) {
      createWebsiteFrame();
    }
    MTLG.lc.levelFinished({
      nextLevel: "level1"//"phishingmenu" //Testweise umgebogen auf phishingmenu anstat level1
    }); // Leave Main Menu and go to login
  });

  const start_button = new createjs.Container();
  start_button.y = title.getBounds().height + 2 * MTLG.getOptions().height / 6;
  start_button.addChild(start_button_background, start);

  // Settings container
  const settings = new createjs.Container();
  settings.addChild(title, settings_field1, settings_field2, start_button);
  settings.x = MTLG.getOptions().width / 2;
  settings.y = MTLG.getOptions().height / 2;
  settings.regX = MTLG.getOptions().width / 6;
  settings.regY = title.getBounds().height / 2 + 3 * MTLG.getOptions().height / 12;
  settings.rotation = stage.rotation;

  stage.addChild(background);
  stage.addChild(settings);

  const tab1 = createTab('Hauptmenü', true);
  const tab2 = createTab('Test', false);

  const tabbar = createTabbar(MTLG.getOptions().width * 0.01, tab1, tab2);
  const custom_navbar = createNavbar(l('welcome'));

  getNavbarStage().addChild(custom_navbar, tabbar);
}

module.exports = drawMainMenu;
