/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-08T14:24:42+02:00
 */



function academymenu_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawAcademymenu();
}

// START CONTENT OF GAME MENU

/**
 * This function draws the game menu of the Phishing Academy, all functions inside are only for internal use
 * @function
 */
function drawAcademymenu(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  // make sure, that there are no old elements in the background
  // reset the general game background - to remove images etc
  MTLG.clearBackground();

  // add old standard background
  var generalBackground = new createjs.Shape();
  generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
  generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
  MTLG.getBackgroundStage().addChild(generalBackground);

  // DemoDelfi - reset the localStorage with every start and load predefined savegames
  /*if(phishingAcademy.newStart == 1){
    sessionControl.resetLocalStorage();
    sessionControl.newDemoSession();
    phishingAcademy.newStart = 0;
  }*/


  // INDIVIDUAL BACKGROUND
  var background = new createjs.Shape();
  background.graphics.beginFill("rgb(197, 235, 245)");
  background.graphics.drawRect(0, 0, options.width, options.height);
  stage.addChild(background);

  // Demo Delfi buttons - has to be checked via settings
  // demoButtons();

  // logo
  var gameLogo = MTLG.assets.getBitmap("img/phishing/phishingAcademy_logo.png");
  gameLogo.scale = .225;
  gameLogo.x = (options.width - gameLogo.getBounds().width * 0.225) / 2 - 10;
  gameLogo.y = -750;
  stage.addChild(gameLogo);

  // interface container
  var interfaceContainer = new createjs.Container();

  // menu buttons
  var newGame = newButton(l('btNewGame'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.3, options.height * 0.65, function(){
    // start a new session and add the saveGame to the localStorage
    sessionControl.newSession();
    MTLG.lc.levelFinished({
      nextLevel: "introduction"
    });
  });
  newGame.visible = false;

  var loadGame = newButton(l('btLoadGame'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.7, options.height * 0.55, function(){
    // if there is a savegame, it can be loaded
    if(storeLocation.length != 0){
      loadSaveGameCarousel();//loadSaveGame();
    }
  });

  // copyright advices, picture copyrights
  var copyrightButton = newButton(l('btCopyright'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width - options.width * 0.025 - options.width / 12, options.height - options.width * 0.025 - options.height / 15, function(){
    // shows copyright infos
    showCopyright();
    xapiButton({'en': "Button Copyright", 'de': "Button Copyright"});

  });

  // language selection
  // Für Evaluation auskommentiert - 02-12-2020
  //var languageDisplay = languageSelection();

  // game settings (demo-mode and later more)
  var gameSettings = newButton(l('btGameSettings'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width - options.width * 0.025 - options.width * 0.275, options.height - options.width * 0.025 - options.height / 15, function(){
    // show game settings
    showGameSettings();
  });

  // game settings (demo-mode and later more)
  var tabletMode = newButton(l('btTabletMode'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width - options.width * 0.025 - options.width * 0.5, options.height - options.width * 0.025 - options.height / 15, function(){
    // show game settings
    showAlertEntry()
  });

  // game evaluation
  var evaluationGame = newButton(l('btEvaluationGame'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.3, options.height * 0.55, function(){
    // start evaluation process
    startEvaluation();

    // deactivate newGame button

  });


  interfaceContainer.addChild(newGame, loadGame, copyrightButton, /*languageDisplay,*/ gameSettings, evaluationGame, tabletMode);

  // add all to stage
  stage.addChild(interfaceContainer);

  // tween logo
  tweenLogo(gameLogo)


  // service function to tween the logo
  function tweenLogo(logo) {
    createjs.Tween.get(logo)
      .to({
        alpha: 1,
        x: logo.x,
        y: options.height * 0.04
      }, 2700, createjs.Ease.quartOut)
  }

  // service function to load a stored saveGame
  let loadSaveGameCarousel = function(){
    // get stage, options, store location and set language shortie
    var stage = MTLG.getStageContainer();
    var options = MTLG.getOptions();
    var l = MTLG.lang.getString;

    // DemoDelfi - empty verified savegames
    phishingAcademy.verifiedSaveGames = [];


    // DemoDelfi - verify existing savegames and their keys, so there are no other elements displayed, which have been written in the localstorage by other modules
    for(var i = 0; i < storeLocation.length; i++){
      var saveGameKey = storeLocation.key(i);
      var saveGame = JSON.parse(storeLocation.getItem(saveGameKey));
      console.log(saveGame);
      if(saveGame != undefined && saveGame.saveName != null && saveGame.gameName == "Phishing-Game"){
        if(saveGame.demoGame === false){
          // load normal played games
          phishingAcademy.verifiedSaveGames.push(saveGame.saveName);
        }else if(saveGame.demoGame === true && phishingAcademy.demoMode === true){
          // if demo mode load also demo save games
          phishingAcademy.verifiedSaveGames.push(saveGame.saveName);
        }
      }
    }
    console.log(phishingAcademy.verifiedSaveGames);

    // number of existing saveGames
    // let amountSaveGames = storeLocation.length; - old
    let amountSaveGames = phishingAcademy.verifiedSaveGames.length;

    // how many saveGames should be displayed on one page
    var displayLimit = 5;

    // create an general accessable container for the levelchoice
    var containerSaveGameLoad = new createjs.Container();
    containerSaveGameLoad.setBounds(options.width / 3, options.height * 0.225, options.width / 3, options.height * 0.55);

    // create container for the game buttons
    var containerSaveGameButtons = new createjs.Container();

    // determine positions
    containerSaveGameLoad.x = options.width / 3;
    containerSaveGameLoad.y = options.height* 0.225;
    containerSaveGameButtons.x = 0 ;
    containerSaveGameButtons.y = 70;

    var box = new createjs.Shape();
    box.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 3, options.height * 0.55 , 10);
    box.x = 0;
    box.y = 0;

    containerSaveGameLoad.addChild(box, containerSaveGameButtons);

    // headline
    var headlineLoad = MTLG.utils.gfx.getText(l('headlineLoad'), "36px Bahnschrift", "black");
    headlineLoad.textAlign = 'center';
    headlineLoad.textBaseline = "middle";
    headlineLoad.x = containerSaveGameLoad.getBounds().width / 2;
    headlineLoad.y = containerSaveGameLoad.getBounds().height / 15;

    // cancel button for loading
    var cancelButton = newButton(l('cancelLoading'), 21, "rgb(225, 26, 66)", 1, (options.width / 6) - 30, 60, containerSaveGameLoad.getBounds().width / 2, containerSaveGameLoad.getBounds().height - (containerSaveGameLoad.getBounds().height / 8.5) , function(){
      stage.removeChild(overlayLoadSaveGame, containerSaveGameLoad);
    });

    // add headline and cancel button
    containerSaveGameLoad.addChild(headlineLoad, cancelButton);

    // Overlay
    var overlayLoadSaveGame = new createjs.Shape();
    overlayLoadSaveGame.graphics.setStrokeStyle(1);
    overlayLoadSaveGame.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
    overlayLoadSaveGame.visible;
    overlayLoadSaveGame.index=0;
    overlayLoadSaveGame.alpha = 0.85;
    overlayLoadSaveGame.addEventListener("click", function(event) {  event.preventDefault(); });

    stage.addChild(overlayLoadSaveGame, containerSaveGameLoad);

    /* old, rewritten for DemoDelfi
    // draws a button for every saveGame
    let drawSaveGameButton = function(displayNumber){
      return (newButton(storeLocation.key(displayNumber), 21, "rgb(4, 193, 37)", 1, (options.width / 6) - 30, 60, containerSaveGameLoad.getBounds().width / 2, (options.height / 13) * (displayNumber % displayLimit), function(){
        sessionControl.changeSaveGame(storeLocation.getItem(storeLocation.key(displayNumber)));
        MTLG.lc.levelFinished({
          nextLevel: "introduction"
        });
      }));
    }
    */
    let drawSaveGameButton = function(displayNumber){
      return (newButton(phishingAcademy.verifiedSaveGames[displayNumber], 21, "rgb(4, 193, 37)", 1, (options.width / 6) - 30, 60, containerSaveGameLoad.getBounds().width / 2, (options.height / 13) * (displayNumber % displayLimit), function(){
        sessionControl.changeSaveGame(storeLocation.getItem(phishingAcademy.verifiedSaveGames[displayNumber]));
        if(sessionControl.getValueToKey("evaluationGame") === true){
          MTLG.lc.levelFinished({
            nextLevel: "evaluation"
          });
        }else{
          MTLG.lc.levelFinished({
            nextLevel: "introduction"
          });
        }
      }));
    }


    var startPositionElements = {x: 0, y: 0};
    var startPositionNavigation = {leftx: containerSaveGameLoad.getBounds().width / 5, lefty: containerSaveGameLoad.getBounds().height / 2 - 100, rightx: containerSaveGameLoad.getBounds().width - (containerSaveGameLoad.getBounds().width / 5), righty: containerSaveGameLoad.getBounds().height / 2 - 100};
    var startPositionPageDisplay ={x: containerSaveGameLoad.getBounds().width / 2, y: containerSaveGameLoad.getBounds().height - (containerSaveGameLoad.getBounds().height / 6.5) - 70};

    // load the carousel
    carousel(amountSaveGames, displayLimit, startPositionElements, startPositionNavigation, startPositionPageDisplay, drawSaveGameButton, containerSaveGameButtons)

  }


//} // test internal service

// SERVICE FUNCTIONS FOR THE MENU - ONLY INTERNAL USE (NO EXPORT)

// service function for copyright
var showCopyright = function(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // create an general accessable container for the levelchoice
  var containerCopyright = new createjs.Container();
  containerCopyright.setBounds(options.width / 3, options.height * 0.225, options.width / 2, options.height * 0.75);

  // determine positions
  containerCopyright.x = options.width * 0.25;
  containerCopyright.y = options.height* 0.125;

  var box = new createjs.Shape();
  box.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 2, options.height * 0.75 , 10);
  box.x = 0;
  box.y = 0;

  // Overlay
  var overlayCopyright = new createjs.Shape();
  overlayCopyright.graphics.setStrokeStyle(1);
  overlayCopyright.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
  overlayCopyright.visible;
  overlayCopyright.index=0;
  overlayCopyright.alpha = 0.85;
  overlayCopyright.addEventListener("click", function(event) {  event.preventDefault(); });


  // headline copyright
  var headlineCopyright = MTLG.utils.gfx.getText(l('headlineCopyright'), "36px Bahnschrift", "black");
  headlineCopyright.textAlign = 'center';
  headlineCopyright.textBaseline = "middle";
  headlineCopyright.x = containerCopyright.getBounds().width / 2;
  headlineCopyright.y = containerCopyright.getBounds().height / 15;

  // cancel button for copyright
  var cancelButton = newButton(l('cancelCopyright'), 21, "rgb(225, 26, 66)", 1, (options.width / 6) - 30, 60, containerCopyright.getBounds().width / 2, containerCopyright.getBounds().height - (containerCopyright.getBounds().height / 8.5) , function(){
    stage.removeChild(overlayCopyright, containerCopyright);
  });

  // text copyright
  var copyrightText = MTLG.utils.gfx.getText(l('copyrightText'), "27px Arial", "black");
  copyrightText.lineWidth = 910;
  copyrightText.x = 25;
  copyrightText.y = containerCopyright.getBounds().height / 7;

  // creative commons logo
  var ccLogo= MTLG.assets.getBitmap('img/phishing/creativecommons-by-sa-4.png');
  ccLogo.x = (containerCopyright.getBounds().width - ccLogo.getBounds().width * 0.4) / 2;
  ccLogo.y = containerCopyright.getBounds().height * 0.425;
  ccLogo.scale = 0.35;

  // picture sources
  var pictureSources = MTLG.utils.gfx.getText(l('pictureSources'), "27px Arial", "black");
  pictureSources.lineWidth = 910;
  pictureSources.x = 25;
  pictureSources.y = containerCopyright.getBounds().height * 0.60;


  containerCopyright.addChild(box, headlineCopyright, cancelButton, copyrightText, ccLogo, pictureSources);
  stage.addChild(overlayCopyright, containerCopyright);

}

// service function for language selection
//var languageSelection = function(){
function languageSelection(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  var languageContainer = new createjs.Container();
  languageContainer.x = options.width * 0.025;
  languageContainer.y = options.height- options.width * 0.025 - options.height / 6;
  languageContainer.setBounds(0, 0, options.width / 4, options.height / 6);

  // background shape
  var backgroundShape = new createjs.Shape();
  backgroundShape.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 4, options.height / 6, 10);
  backgroundShape.setBounds(0, 0, options.width / 4, options.height / 6);

  // language flags
  var germanFlag = MTLG.assets.getBitmap("img/phishing/german_flag.png");
  germanFlag.y = options.height / 15;
  germanFlag.scale = .075;
  languageContainer.addChild(germanFlag);

  var ukFlag = MTLG.assets.getBitmap("img/phishing/uk_flag.jpg");
  ukFlag.y = options.height / 15;
  ukFlag.scale = .075;
  languageContainer.addChild(ukFlag);

  // center the flags
  var flagsLength = 0.075 * (ukFlag.getBounds().width + germanFlag.getBounds().width);
  var divideLength = (languageContainer.getBounds().width - flagsLength) / 3;
  germanFlag.x = divideLength;
  ukFlag.x = 2 * divideLength + germanFlag.getBounds().width * 0.075;

  // get actual language
  if(MTLG.lang.getLanguage() == "de"){
    germanFlag.alpha = 1;
    ukFlag.alpha = 0.5;
  } else if(MTLG.lang.getLanguage() == "en"){
      ukFlag.alpha = 1;
      germanFlag.alpha = 0.5;
  }

  // explanation language selection
  var text = MTLG.utils.gfx.getText(l('languageSelection'), "27px Arial", "black");
  text.textAlign = 'center';
  text.lineWidth = 450;
  text.x = languageContainer.getBounds().width / 2;
  text.y = languageContainer.getBounds().height / 10;

  // add listeners
  germanFlag.addEventListener("click", function(){
    MTLG.lang.setLanguage("de");
    // restart level
    MTLG.lc.levelFinished({
      nextLevel: "phishingmenu"
    });
  });

  ukFlag.addEventListener("click", function(){
    // not everything is translated for the evluation
    window.alert('Evaluation is only possible in German.');
    /*MTLG.lang.setLanguage("en");
    // restart level
    MTLG.lc.levelFinished({
      nextLevel: "phishingmenu"
    });*/

  });

  languageContainer.addChild(backgroundShape, text, germanFlag, ukFlag)
  return languageContainer;
}

// service function for game settings
var showGameSettings = function(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  // create an general accessable container for the levelchoice
  var containerSettings = new createjs.Container();
  containerSettings.setBounds(options.width / 3, options.height * 0.225, options.width / 2, options.height * 0.75);

  // determine positions
  containerSettings.x = options.width * 0.25;
  containerSettings.y = options.height* 0.125;

  var box = new createjs.Shape();
  box.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 2, options.height * 0.75 , 10);
  box.x = 0;
  box.y = 0;

  // Overlay
  var overlaySettings = new createjs.Shape();
  overlaySettings.graphics.setStrokeStyle(1);
  overlaySettings.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
  overlaySettings.visible;
  overlaySettings.index=0;
  overlaySettings.alpha = 0.85;
  overlaySettings.addEventListener("click", function(event) {  event.preventDefault(); });


  // headline copyright
  var headlineSettings = MTLG.utils.gfx.getText(l('headlineSettings'), "36px Bahnschrift", "black");
  headlineSettings.textAlign = 'center';
  headlineSettings.textBaseline = "middle";
  headlineSettings.x = containerSettings.getBounds().width / 2;
  headlineSettings.y = containerSettings.getBounds().height / 15;

  // cancel button for copyright
  var cancelButton = newButton(l('cancelSettings'), 21, "rgb(225, 26, 66)", 1, (options.width / 6) - 30, 60, containerSettings.getBounds().width / 2, containerSettings.getBounds().height - (containerSettings.getBounds().height / 8.5) , function(){
    stage.removeChild(overlaySettings, containerSettings);
  });

  // START INDIVIDUAL SETTINGS
  // demo mode text
  var demoText = MTLG.utils.gfx.getText(l('settingsDemoText'), "27px Arial", "black");
  demoText.lineWidth = 910;
  demoText.x = 25;
  demoText.y = containerSettings.getBounds().height / 7;

  // demo mode button
  var demoButton = newButton(l('btDemo'), 21, "rgb(204, 255, 204)", 1, (options.width / 6) - 30, 60, containerSettings.getBounds().width / 2, containerSettings.getBounds().height / 5 , function(){
    // check without any further anthentification for a correct passphrade to activate demo buttons
    var passphrase = MTLG.getOptions().passphrase_demo_mode;
    var check = window.prompt(l('demoCheck'));

    if(check === passphrase){
      // load predefined save games and show demo buttons
      phishingAcademy.demoMode = true;
      sessionControl.resetLocalStorage();
      sessionControl.newDemoSession();
      newGame.visible = true;
      demoButtons();
    }else{
      window.alert(l('demoCheckFail'))
    }
  });


  containerSettings.addChild(box, headlineSettings, cancelButton, demoText, demoButton);
  stage.addChild(overlaySettings, containerSettings);
}

// EVALUATION

// evaluation input bar has to be assigned before, because the eventlisteners on document would duplicate if called mor than once
//var inputBar = inputIdentification(inputBarText, (containerEvaluation.getBounds().width - options.width / 4) / 2, containerEvaluation.getBounds().height * 0.55, (options.width / 4), 60);
var inputBarText = MTLG.lang.getString('evaluationInputDummy');
console.log(inputBarText);
var inputBar = inputIdentification(inputBarText, (MTLG.getOptions().width / 2 - MTLG.getOptions().width / 4) / 2, MTLG.getOptions().height * 0.75 * 0.55, (MTLG.getOptions().width / 4), 60);

var startEvaluation = function(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  // start with prompt for individual user id
  // create an general accessable container for the levelchoice
  var containerEvaluation = new createjs.Container();
  containerEvaluation.setBounds(options.width / 3, options.height * 0.225, options.width / 2, options.height * 0.75);

  // determine positions
  containerEvaluation.x = options.width * 0.25;
  containerEvaluation.y = options.height* 0.125;

  var box = new createjs.Shape();
  box.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 2, options.height * 0.75 , 10);
  box.x = 0;
  box.y = 0;

  // Overlay
  var overlayEvaluation = new createjs.Shape();
  overlayEvaluation.graphics.setStrokeStyle(1);
  overlayEvaluation.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
  overlayEvaluation.visible;
  overlayEvaluation.index=0;
  overlayEvaluation.alpha = 0.85;
  overlayEvaluation.addEventListener("click", function(event) {  event.preventDefault(); });


  // headline evaluation
  var headlineEvaluation = MTLG.utils.gfx.getText(l('headlineEvaluation'), "36px Bahnschrift", "black");
  headlineEvaluation.textAlign = 'center';
  headlineEvaluation.textBaseline = "middle";
  headlineEvaluation.x = containerEvaluation.getBounds().width / 2;
  headlineEvaluation.y = containerEvaluation.getBounds().height / 15;

  // cancel button for evaluation
  var cancelButton = newButton(l('btcancelEvaluation'), 21, "rgb(225, 26, 66)", 1, (options.width / 6) - 30, 60, containerEvaluation.getBounds().width / 2, containerEvaluation.getBounds().height - (containerEvaluation.getBounds().height / 8.5) , function(){
    stage.removeChild(overlayEvaluation, containerEvaluation);
    // restart level
    MTLG.lc.levelFinished({
      nextLevel: "phishingmenu"
    });
    window.location.reload(false);
  });

  // START IDENTIFICATION PROCESS

  // variable for concat the user entries
  var userID = "";
  let entryProgress = 0;
  const partsOfID = 4;

  // evaluation mode start text
  var evaluationText = MTLG.utils.gfx.getText(l('evaluationMenuText'), "27px Arial", "black");
  evaluationText.x = 25;
  evaluationText.lineWidth = 930;
  evaluationText.y = containerEvaluation.getBounds().height / 8;

  var entriesText = [l("evaluationEntry1"), l("evaluationEntry2"), l("evaluationEntry3"), l("evaluationEntry4")];
  var noEntryText = newText(l("Noch nichts eingegeben!"), {textAlign: "center"});
  noEntryText.x = containerEvaluation.getBounds().width / 2;
  noEntryText.y = containerEvaluation.getBounds().height * 0.7;
  noEntryText.visible = false;

  var partText = newText(entriesText[entryProgress], {lineWidth: 970});
  partText.x = 50;
  partText.y = containerEvaluation.getBounds().height * 0.475 ;



  // evaluation mode button
  var checkEntriesButton = newButton(l('btEvaluationEntries'), 27, "rgb(204, 255, 204)", 1, (options.width / 6) - 30, 60, containerEvaluation.getBounds().width / 2, containerEvaluation.getBounds().height * 0.75 , function(){
    //check inputBar if empty, else concat with userID and load next entrieText and boxText
    let inputText = inputBar.getText().trim();

    if(inputText !== "" && inputText !== inputBarText){
      // in some steps the input has to be changed
      if((entryProgress === 1 || entryProgress === 2) && inputText.length === 1){
        inputText = "0" + inputText;
        console.log(inputText);
      }

      // convert uppercase and concat
      userID = userID.concat(inputText.toUpperCase());
      console.log(inputText.toUpperCase(),inputBar.getText());
      console.log(userID);

      // check if userID complete
      if(entryProgress === partsOfID - 1){
        // check if there is already an existing save game in local storage
        var noGame = true;
        let saveGameKey;
        let saveGame;

        for(var i = 0; i < storeLocation.length; i++){
          saveGameKey = storeLocation.key(i);
          saveGame = JSON.parse(storeLocation.getItem(saveGameKey));
          if(saveGameKey == userID && saveGame.gameName == "Phishing-Game" && saveGame.evaluationGame){
            sessionControl.changeSaveGame(storeLocation.getItem(saveGameKey));
            noGame = false;
            MTLG.lc.levelFinished({
              nextLevel: "evaluation"
            });
          }
        }

        // start evaluation and create evaluationSession if there is no other saveGame
        if(noGame){
          sessionControl.newEvaluationSession(userID);
          MTLG.lc.levelFinished({
            nextLevel: "evaluation"
          });
        }

      }

      // update partText, hide noEntryText and update inputBar
      entryProgress++;
      noEntryText.visible = false;
      partText.setText(entriesText[entryProgress]);
      inputBar.deactivate();
      inputBar.resetText();
      inputBar._textView.setText(inputBarText);

    }else{
      noEntryText.visible = true;
    }

  });


  containerEvaluation.addChild(box, headlineEvaluation, cancelButton, evaluationText, partText, inputBar._view, inputBar._textView, checkEntriesButton, noEntryText);
  stage.addChild(overlayEvaluation, containerEvaluation);

}

function showAlertEntry(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;
  var storeLocation = localStorage;

  var i = 0
  var userID = "";
  let userIDpart;

  for(var i=0; i < 4; i++){
    userIDpart = window.prompt(l('tabletIdentification' + i));

    if((i === 1 || i === 2) && userIDpart.length === 1){
      userIDpart = "0" + userIDpart;
    }

    // convert uppercase and concat
    userID = userID.concat(userIDpart.toUpperCase());

  }

  // check if there is already an existing save game in local storage
  var noGame = true;
  let saveGameKey;
  let saveGame;

  for(var i = 0; i < storeLocation.length; i++){
    saveGameKey = storeLocation.key(i);
    saveGame = JSON.parse(storeLocation.getItem(saveGameKey));
    if(saveGameKey == userID && saveGame.gameName == "Phishing-Game" && saveGame.evaluationGame){
      sessionControl.changeSaveGame(storeLocation.getItem(saveGameKey));
      noGame = false;
      MTLG.lc.levelFinished({
        nextLevel: "evaluation"
      });
    }
  }

  // start evaluation and create evaluationSession if there is no other saveGame
  if(noGame){
    sessionControl.newEvaluationSession(userID);
    MTLG.lc.levelFinished({
      nextLevel: "evaluation"
    });
  }


}

} // test internal service
// END SERVICE FUNCTIONS FOR THE MENU

// Export the functions
module.exports = {
  drawAcademymenu
};
