/**
 * @Date:   2018-11-12T16:01:26+01:00
 * @Last modified time: 2019-01-30T00:34:24+01:00
 */


 /**
  * This function contains all functions needed for the levelchoice carousel in the trainingscenter
  * @function
  */
 function levelChoice(){
   // get stage, options, store location and set language shortie
   var stage = MTLG.getStageContainer();
   var options = MTLG.getOptions();
   var l = MTLG.lang.getString;

   // number of existing levels
   let levels = phishingAcademy.tcLevelAmount;

   // how many levels should be displayed on one page
   var displayLimit = 3;

   // function to draw a levelbox in the selection carousel - given as a callback to carousel()
   var drawLevelBox = function(index){
     var levelNumber = index + 1;
     var levelHead = l('tclevelhead' + (index+1));
     var levelSubhead = l('tclevel'+ (index+1) + 'subline');
     var progressStatus = sessionControl.determineLevelProgress(index+1);

     var containerLevelBox = new createjs.Container();
     // three times 1/5 plus two times 1/30 are 2/3 of the display screen
     containerLevelBox.x = ((options.width / 5) + (options.width / 30)) * ((levelNumber-1) % displayLimit);
     //Setting box position in container
     var box = new createjs.Shape();
     box.graphics.beginFill("rgb(170, 203, 242)").drawRoundRect(0, 0, options.width / 5, options.height / 2, 10);
     containerLevelBox.addChild(box)

     // number of the explicit level
     var levelNumberText = new createjs.Text("Level " + levelNumber, "48px Arial", "black");
     levelNumberText.x = options.width / 10;
     levelNumberText.y = 40;
     levelNumberText.textBaseline = "middle";
     levelNumberText.textAlign = "center";

     // headline of the explicit level
     var headlineText = new createjs.Text(l('tclevelhead' + levelNumber), "32px Arial", "black");
     headlineText.x = options.width / 10;
     headlineText.y = 100;
     headlineText.textBaseline = "middle";
     headlineText.textAlign = "center";

     // subheadline of the explicit level
     var subheadlineText = new createjs.Text(l('tclevel' + levelNumber + 'subline'), "28px Arial", "black");
     subheadlineText.x = options.width / 10;
     subheadlineText.y = 140;
     subheadlineText.textBaseline = "middle";
     subheadlineText.textAlign = "center";

     // level progress in savePoints
     var savePointsDisplay = MTLG.utils.gfx.getText("", "18px Arial", "black");
     savePointsDisplay.textAlign = 'center';
     savePointsDisplay.textBaseline = "middle";
     savePointsDisplay.x = options.width / 10;
     savePointsDisplay.y = 350;

     containerLevelBox.addChild(levelNumberText, headlineText, subheadlineText, savePointsDisplay);

     /**
      * display progress and activate the buttons in regard to progress
      * progress is saved in progressStatus[0]
      * 1: level has been completed
      * 2: level has been played, but is not complete
      * 3: level is locked
      * undefined (default case): level is unlocked, but has not been played yet
      */
     switch(progressStatus[0]) {
     case 1:
         // logo for progress
         var checkmark = MTLG.assets.getBitmap("img/phishing/checkmark.png")
         checkmark.x = options.width / 10;
         checkmark.regX = checkmark.getBounds().width/2;
         checkmark.y = options.height / 5.5;
         checkmark.scaleX = 0.1;
         checkmark.scaleY = 0.1;
         containerLevelBox.addChild(checkmark);

         // add text to savePointsDisplay
         savePointsDisplay.text = "Checkpoints: " + progressStatus[1] + " / " + progressStatus[2];

         //Buttons
         containerLevelBox.addChild(newButton(l('btPlayLevel'), 27, "rgb(198, 198, 210)", 0, (options.width / 5) - 30, 60, options.width / 10, 385, function(){
           // disabled
         }));
         containerLevelBox.addChild(newButton(l('btPlayAgain'), 27, "rgb(4, 193, 37)", 1, (options.width / 5) - 30, 60, options.width / 10, 465, function(){
           // reset savePoints and set restart label
           sessionControl.resetLevel(levelNumber);

           // xapi call
           xapiButton({en: "Trainingscenter - Play Again Level " + levelNumber, de: "Trainingscenter - Spiele nochmal Level " + levelNumber});
           xapiLevelEnd({en: "Trainingscenter", de: "Trainingscenter"});
           xapiLevelStart({en:"Level " + levelNumber, de:"Level " + levelNumber});

           MTLG.lc.levelFinished({
             nextLevel: "tclevel" + levelNumber
           });
         }));
         break;
     case 2:
         var checkmarkIncomplete = MTLG.assets.getBitmap("img/phishing/checkmark_incomplete.png")
         checkmarkIncomplete.x = options.width / 10;
         checkmarkIncomplete.regX = checkmarkIncomplete.getBounds().width/2;
         checkmarkIncomplete.y = options.height / 5.5;
         checkmarkIncomplete.scaleX = 0.1;
         checkmarkIncomplete.scaleY = 0.1;
         containerLevelBox.addChild(checkmarkIncomplete);

         // add text to savePointsDisplay
         savePointsDisplay.text = "Checkpoints: " + progressStatus[1] + " / " + progressStatus[2];

         //Buttons
         containerLevelBox.addChild(newButton(l('btPlayOn'), 27, "rgb(4, 193, 37)", 1, (options.width / 5) - 30, 60, options.width / 10, 385, function(){
           // xapi call
           xapiButton({en: "Trainingscenter - Play on Level " + levelNumber, de: "Trainingscenter - Weiterspielen Level " + levelNumber});
           xapiLevelEnd({en: "Trainingscenter", de: "Trainingscenter"});

           MTLG.lc.levelFinished({
             nextLevel: "tclevel" + levelNumber
           });
         }));
         containerLevelBox.addChild(newButton(l('btPlayAgain'), 27, "rgb(198, 198, 210)", 0, (options.width / 5) - 30, 60, options.width / 10, 465, function(){
           //disabled
         }));
         break;
     case 3:
         var padlock = MTLG.assets.getBitmap("img/phishing/padlock.png")
         padlock.x = options.width / 10;
         padlock.regX = padlock.getBounds().width/2;
         padlock.y = options.height / 5.5;
         padlock.scaleX = 0.1;
         padlock.scaleY = 0.1;
         containerLevelBox.addChild(padlock);

         // add text to savePointsDisplay
         savePointsDisplay.text = "Checkpoints: " + progressStatus[1] + " / " + progressStatus[2];

         //Buttons
         containerLevelBox.addChild(newButton(l('btPlayLevel'), 27, "rgb(4, 193, 37)", 0, (options.width / 5) - 30, 60, options.width / 10, 385, function(){
           //disabled
         }));
         containerLevelBox.addChild(newButton(l('btPlayAgain'), 27, "rgb(198, 198, 210)", 0, (options.width / 5) - 30, 60, options.width / 10, 465, function(){
           //disabled
         }));
         break;
     default:
        // level is unlocked, but has not been played
         var padlockUnlock = MTLG.assets.getBitmap("img/phishing/padlock_unlock.png")
         padlockUnlock.x = options.width / 10;
         padlockUnlock.regX = padlockUnlock.getBounds().width/2;
         padlockUnlock.y = options.height / 5.5;
         padlockUnlock.scaleX = 0.1;
         padlockUnlock.scaleY = 0.1;
         containerLevelBox.addChild(padlockUnlock);

         // add text to savePointsDisplay
         savePointsDisplay.text = "Checkpoints: " + progressStatus[1] + " / " + progressStatus[2];

         //Buttons
         containerLevelBox.addChild(newButton(l('btPlayLevel'), 27, "rgb(4, 193, 37)", 1, (options.width / 5) - 30, 60, options.width / 10, 385, function(){
           // xapi call
           xapiButton({en: "Trainingscenter - Play Level " + levelNumber, de: "Trainingscenter - Spiele Level " + levelNumber});
           xapiLevelEnd({en: "Trainingscenter", de: "Trainingscenter"});

           MTLG.lc.levelFinished({
             nextLevel: "tclevel" + levelNumber
           });
         }));
         containerLevelBox.addChild(newButton(l('btPlayAgain'), 27, "rgb(198, 198, 210)", 0, (options.width / 5) - 30, 60, options.width / 10, 465, function(){
           //disabled
         }));
       }
     // return container
     return containerLevelBox;

   }
   var startPositionElements = {x: options.width / 6, y: options.height / 6};
   var startPositionNavigation = {leftx: options.width / 8, lefty: options.height / 2, rightx: options.width - (options.width / 8), righty: options.height / 2};
   var startPositionPageDisplay ={x: options.width / 2, y: options.height - (options.height / 4)};

   // add the carousel
   carousel(levels, displayLimit, startPositionElements, startPositionNavigation, startPositionPageDisplay, drawLevelBox, stage)
 };

// Export the functions
 module.exports = {
   levelChoice
 };
