/**
 * @Date:   2018-11-06T21:49:52+01:00
 * @Last modified time: 2019-09-10T21:04:26+02:00
 */



function criminalcases_init(){
  // initialize level 1

  console.log("This players are logged in:");
  for (var i = 0; i < MTLG.getPlayerNumber(); i++) {
    console.log(MTLG.getPlayer(i));
  }

  console.log("Thie are the available game options:");
  console.log(MTLG.getOptions());

  // esit the game.settings.js
  console.log("Thie are the available game settings:");
  console.log(MTLG.getSettings());

  drawCriminalcases();
}

// check wether level 1 is choosen or not
function checkCriminalcases(gameState){
  if(gameState && gameState.nextLevel && gameState.nextLevel == "criminalcase"){
    return 1;
  }
  return 0;
}

/**
 * This function contains the drawing of the criminalcases
 * @function
 */
function drawCriminalcases(){
  // get stage, options, store location and set language shortie
  var stage = MTLG.getStageContainer();
  var options = MTLG.getOptions();
  var l = MTLG.lang.getString;

  // variables to save round progress
  let questionsToPlay = 36;
  let questionsAlreadyPlayed = 0;
  let questionArray = [];
  let randomIdArray = shuffleArray(generateArray(questionsToPlay)); //[];
  let correctCount = 0;

  // MODE CHOOSEN: only one (1) or all (0) hotspots at once
  var modeHotspots = 1;

  // LOAD CURRENT JSON FILE WITH URLs
  var allURLs = require('../../urlcriminalcases.json');

  // set currentLevel
  sessionControl.setCurrentLevel("Kriminalfälle -");

  // xapi call
  xapiLevelStart({en: "Criminalcases", de: "Kriminalfälle"});

  /* old code for question array
  // CREATE THE QUESTION OBJECTS
  for(var i = 0; i < questionsToPlay;i++){
    var newRandom = randomId(randomIdArray, allURLs.urls.length - 1);
    if(newRandom !== null){
      var randomReturnedId = newRandom.newId;
      randomIdArray = newRandom.array;
      var randomURL;
      allURLs.urls.forEach(function(key, index, array){
        if(array[index].id === randomReturnedId){
            randomURL = array[index];
        }
      });
      var question = new Question(randomReturnedId, randomURL.url, randomURL.reason, randomURL.phishing)
      questionArray.push(question);
    }
  }*/

  // CREATE THE QUESTION OBJECTS
  for(var i = 0; i < questionsToPlay;i++){
    let newRandom = randomIdArray[i];
    if(newRandom !== null){
      let randomURL;
      allURLs.urls.forEach(function(key, index, array){
        if(array[index].id === newRandom){
            console.log(array[index].id, newRandom);
            randomURL = array[index];
        }
      });
      var question = new Question(newRandom, randomURL.url, randomURL.target, randomURL.reason, randomURL.phishing)
      questionArray.push(question);
    }
  }

  // CREATE EXAM OBJECT
  var exam = new Exam(questionArray);


  // START WITH THE DRAWING CONTENT - ALL INTERNAL FUNCTIONS DO NOT EXPORT

  // START - CRIMINALCASES OPTIONS

  function drawCriminalcaseOptions(){
    // get stage, options, store location and set language shortie
    var stage = MTLG.getStageContainer();
    var options = MTLG.getOptions();
    var l = MTLG.lang.getString;

    // background
    var background = new createjs.Shape();
    background.graphics.beginFill("rgb(197, 235, 245)");
    background.graphics.drawRect(0, 0, options.width, options.height);
    stage.addChild(background);

    // Demo Delfi buttons
    if(phishingAcademy.demoMode === true){
      demoButtons();
    }

    // container for game mode details
    var criminalcasesContainer = new createjs.Container();
    // place container
    criminalcasesContainer.x = options.width * 0.25;
    criminalcasesContainer.y = options.height * 0.25;

    // shape for headline
    var headlineShape = new createjs.Shape();
    headlineShape.x = 15;
    headlineShape.y = 15;
    headlineShape.graphics.s("black").mt(0, options.height * 0.04).lt(options.width / 2 - 30, options.height * 0.04);

    // general background shape
    var backgroundShape = new createjs.Shape();
    backgroundShape.graphics.beginFill("rgb(234, 234, 234)").drawRoundRect(0, 0, options.width / 2, options.height / 2, 5);
    backgroundShape.x = 0;
    backgroundShape.y = 0;

    // button to start
    var startButton = newButton(l('startButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.25, 430, function(){

      // Check if crikinalcases has been alredy played in evaluation mode
      if(sessionControl.getValueToKey("completionPosttest") === 0){
        // xapi call
        xapiButton({en: "Criminalcases - Start Criminalcases Test", de: "Kriminallfälle - Starte Kriminalfälle Test"});

        stage.removeAllChildren();
        modeControl(modeHotspots);
      } else{

        // xapi call
        xapiLevelEnd({en: "Criminalcases", de: "Kriminalfälle"});

        detectiveSpeech([l('criminalcasesNotPlayable')], true, function(){
          MTLG.lc.levelFinished({
          nextLevel: "playermenu"
          });
        });
      }
    });
    startButton.visible = false;

    // general back buttons
    stage.addChild(newButton(l('btPhishingStart'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.60, options.height - (options.height / 12), function(){
      // xapi call
      xapiButton({en: "Criminalcases - Menu", de: "Kriminallfälle - Hauptmenü"});
      xapiLevelEnd({en: "Criminalcases", de: "Kriminalfälle"});

      MTLG.lc.levelFinished({
        nextLevel: "phishingmenu"
      });
    }));

    var modeHeadline = MTLG.utils.gfx.getText(" ", "36px Bahnschrift", "black");
    modeHeadline.textAlign = "center";
    modeHeadline.x = options.width / 4;
    modeHeadline.y = 20;

    var modeExplanation = MTLG.utils.gfx.getText(" ", "27px Arial", "black");
    modeExplanation.lineWidth = 940;
    modeExplanation.lineHeight = 30;
    modeExplanation.x = 15;
    modeExplanation.y = modeHeadline.getMeasuredHeight() + 35;

    // differ headlines and buttons if in evaluation mode
    if(sessionControl.getValueToKey("evaluationGame")){

      modeHeadline.text = l('evalcriminalcasesHeadline');
      modeExplanation.text = l('evalcriminalcasesExplanation');
      startButton.visible = true;

      criminalcasesContainer.addChild(backgroundShape, headlineShape, modeHeadline, modeExplanation, startButton);

    } else{

      modeHeadline.text = l('criminalcasesHeadline');
      modeExplanation.text = l('criminalcasesExplanation');

      // buttons to choose the mode
      var onlyOneHotspotButton = newButton(l('onlyOneHotspotButton'), 27, "rgb(130, 255, 194)", 1, options.width / 5, options.height / 15, options.width * 0.125, 330, function(){
        modeHotspots = 1;
        onlyOneHotspotButton.getChildByName("button").color.style = "rgb(0, 167, 157)";
        onlyOneHotspotButton.alpha = 1;
        allHotspotButton.alpha = 0.9;
        allHotspotButton.getChildByName("button").color.style = "rgb(130, 255, 194)";
        startButton.visible = true;

        // xapi call
        xapiButton({en: "Criminalcases - Only One Hotspot", de: "Kriminallfälle - Einzelne Hotspots"});
        xapiLevelEnd({en: "Criminalcases", de: "Kriminalfälle"});
      });

      var allHotspotButton = newButton(l('allHotspotButton'), 27, "rgb(130, 255, 194)", 1, options.width / 5, options.height / 15, options.width * 0.375, 330, function(){
        modeHotspots = 0;
        allHotspotButton.getChildByName("button").color.style = "rgb(0, 167, 157)";
        allHotspotButton.alpha = 1;
        onlyOneHotspotButton.alpha = 0.9;
        onlyOneHotspotButton.getChildByName("button").color.style = "rgb(130, 255, 194)";
        startButton.visible = true;

        // xapi call
        xapiButton({en: "Criminalcases - All Hotspots", de: "Kriminallfälle - Alle Hotspots"});
        xapiLevelEnd({en: "Criminalcases", de: "Kriminalfälle"});
      });

      criminalcasesContainer.addChild(backgroundShape, headlineShape, modeHeadline, modeExplanation, startButton, onlyOneHotspotButton, allHotspotButton);
    }


    stage.addChild(newButton(l('btPlayerMenu'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width * 0.8, options.height - (options.height / 12), function(){
      // xapi call
      xapiButton({en: "Criminalcases - Playermenu", de: "Kriminallfälle - Spielermenü"});
      xapiLevelEnd({en: "Criminalcases", de: "Kriminalfälle"});

      MTLG.lc.levelFinished({
        nextLevel: "playermenu"
      });
    }));


    stage.addChild(criminalcasesContainer);

    if(sessionControl.getValueToKey("firstStartCriminalcases") === 0){
      var criminalcasesDialogs = [l('criminalcasesStartDialog1')];
      detectiveSpeech(criminalcasesDialogs, true, function(){
        sessionControl.setValueToKey("firstStartCriminalcases", 1);
      });
    }
  }

  // FUNCTION FOR EASY ADDITING OF POSSIBLE FUTURE GAMEMODES
  // mode one has been choosen at the beginning
  function modeControl(mode){
    if(mode === 1 || mode === 0){
      drawExam();
    } else{
      drawExam();
    }
  }

  // END - CRIMINALCASES OPTIONS



  // START - CRIMINALCASES EXAM

  // function is responsible for the whole drawing
  function drawExam(){

    // add standard background
    var generalBackground = new createjs.Shape();
    generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(generalBackground);

    // set background image
    MTLG.setBackgroundImageFill('phishing/cc_background1.png');

    // xapi call
    xapiTestStart({en: "Criminalcases Test", de: "Kriminalfälle Test"});

    // Demo Delfi buttons
    if(phishingAcademy.demoMode === true){
      demoButtons();
    }

    // create the hotspots
    let hotspotsToDisplay = [];
    for (let i = 0; i < exam.questions.length; i++) {
      let hotspot_x =  MTLG.getOptions().width * 0.1 + Math.floor(Math.random() * MTLG.getOptions().width * 0.1 * 2) + Math.floor(Math.random() *  MTLG.getOptions().width * 0.25) * 2;
      let hotspot_y = MTLG.getOptions().height * 0.15 + Math.floor(Math.random() * MTLG.getOptions().height * 0.5);
      let hotspot = {visual: createHotspot(hotspot_x, hotspot_y, modeHotspots), question: exam.questions[i]};

      hotspotsToDisplay.push(hotspot)

      hotspot.visual.on('pressup', ({ localX, localY }) => {
        hotspot.visual.alpha = 1;
        drawHotspotURL(hotspot);
      });
    }

    // create countdown timer
    var countdownTime = 1000;
    var timer = setInterval(function() {

      // Find the distance between now and the count down date
      countdownTime -= 1;
      showCountdown(countdownTime);

      // If the count down is finished, write some text
      if (countdownTime <= 0) {
        clearInterval(timer);
        hotspotsToDisplay = [];
        drawExamFeedback();
      }
    }, 1000);

    // add countdown timer to global interval array
    phishingAcademy.criminalcasesIntervals.push(timer);

    // Button for stopping current exam round and return to playermenu if not in examination mode
    if(sessionControl.getValueToKey("evaluationGame") === false){
      stage.addChild(newButton(l('criminalcasesStopButton'), 27, "rgb(170, 203, 242)", 1, options.width / 6, options.height / 15, options.width / 12 + options.width * 0.025, options.height * 0.925, function(){
        // clear criminalcases timers
        for(var i = 0; i < phishingAcademy.criminalcasesIntervals.length; i++){
          clearInterval(phishingAcademy.criminalcasesIntervals[i]);
        }
        // clear background
        MTLG.clearBackground();
        // go back to playermenu
        MTLG.lc.levelFinished({
          nextLevel: "playermenu"
        });
      }));
    }

    // create magGlass
    createMagGlassHotspot(hotspotsToDisplay);

    // INITIAL CALL IF THERE ARE HOTSPOTS TO DISPLAY
    if(hotspotsToDisplay.length > 0){
      drawHotspot();
    }

    // function to draw a hotspot - internal use only
    function drawHotspot(){
      //show progress to the user
      showProgress(hotspotsToDisplay);

      if(hotspotsToDisplay.length > 0 && modeHotspots === 1){
        // there are hotspots left
        var random = Math.floor(Math.random() * (hotspotsToDisplay.length - 1));
        var randomHotspot = hotspotsToDisplay[random].visual;
        stage.addChild(randomHotspot);
        stage.setChildIndex(randomHotspot, 1);
      } else if(hotspotsToDisplay.length > 0 && modeHotspots === 0){
        // draw all hotspots at one time
        hotspotsToDisplay.forEach(function(hotspot){
          if(stage.getChildIndex(hotspot.visual) === -1){
            stage.addChild(hotspot.visual);
            stage.setChildIndex(hotspot.visual, 1);
          }
        });
      } else{
        // all hotspots answered by user
        clearInterval(timer);

        // xapi call
        xapiTestResult({en: "Criminalcases Test", de: "Kriminalfälle Test"}, correctCount);

        drawExamFeedback();
      }
    }

    // function to draw to URL if a hotspot got clicked - internal use only
    function drawHotspotURL(hotspot){
      var url = hotspot.question.urlText;

      // container
      var urlContainer = new createjs.Container();
      urlContainer.x = 0;
      urlContainer.y = options.height / 3;

      // overlay
      var overlay = new createjs.Shape();
      overlay.graphics.beginFill('white').drawRect(0,0,options.width,options.height);
      overlay.alpha = 0.5;
      overlay.addEventListener("click", function(event) {  event.preventDefault(); });
      stage.addChild(overlay);

      // background shape
      var backgroundShape = new createjs.Shape();
      backgroundShape.graphics.beginFill("rgb(234, 234, 234)").drawRect(0, 0, options.width, options.height / 3);

      // url
      var urlText = MTLG.utils.gfx.getText(url, "32px Arial", "black");
      urlText.textAlign = 'center';
      urlText.x = options.width / 2;
      urlText.y = 30;

      // question text
      var urlTarget = MTLG.utils.gfx.getText(l('target') + ": " + hotspot.question.target, "32px Arial", "black");
      urlTarget.textAlign = 'center';
      urlTarget.x = options.width / 2;
      urlTarget.y = 110;

      // fragetext
      var urlQuestion = MTLG.utils.gfx.getText(l('preQuestionPhishing'), "27px Arial", "black");
      urlQuestion.textAlign = 'center';
      urlQuestion.x = options.width / 2;
      urlQuestion.y = 190;

      // answer button
      var phishingTrueButton = newButton(l('phishingTrueButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.35, 250, function(){

        /*for(var j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === hotspot.question.urlId){
            exam.questions[j].userAnswer = true;
          }
        }*/

        for(var j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === hotspot.question.urlId){
            exam.questions[j].userAnswer = true;
            if(hotspot.question.userAnswer === hotspot.question.phishingAnswer){
              hotspot.question.userCorrect = true;
              exam.questions[j].userCorrect = true;
            } else{
              hotspot.question.userCorrect = false;
              exam.questions[j].userCorrect = false;
            }
            if(hotspot.question.userCorrect === true){
              correctCount += 1;
            }
          }
        }

        // xapi call
        xapiURLResult({en:"Criminalcases Test - URL-ID: " + hotspot.question.urlId, de:"Kriminalfälle Test - URL-ID: " + hotspot.question.urlId}, hotspot.question.userCorrect);


        // call function for confidence level
        urlContainer.removeChild(urlQuestion, phishingTrueButton, phishingFalseButton);
        drawConfidence(hotspot.question);

      });

      var phishingFalseButton = newButton(l('phishingFalseButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.65, 250, function(){

        /*for(let j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === hotspot.question.urlId){
            exam.questions[j].userAnswer = false;
          }
        }*/

        for(let j = 0;j < exam.questions.length;j++){
          if(exam.questions[j].urlId === hotspot.question.urlId){
            exam.questions[j].userAnswer = false;
            if(hotspot.question.userAnswer === hotspot.question.phishingAnswer){
              hotspot.question.userCorrect = true;
              exam.questions[j].userCorrect = true;
            } else{
              hotspot.question.userCorrect = false;
              exam.questions[j].userCorrect = false;
            }
            if(hotspot.question.userCorrect === true){
              correctCount += 1;
            }
          }
        }

        // xapi call
        xapiURLResult({en:"Criminalcases Test Phishing - URL-ID: " + hotspot.question.urlId, de:"Kriminalfälle Test Phishing - URL-ID: " + hotspot.question.urlId}, hotspot.question.userCorrect);


        // call function for confidence level
        urlContainer.removeChild(urlQuestion, phishingTrueButton, phishingFalseButton);
        drawConfidence(hotspot.question);

      });

      // back button if mode 0 is choosen to answer another url first
      var answerLaterButton = newButton(l('answerLaterButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.875, 250, function(){

        // remove question
        stage.removeChild(overlay);
        stage.removeChild(urlContainer);
      });

      // check for condition of displaying the answerLaterButton
      if(modeHotspots === 0){
        answerLaterButton.visible = true;
      } else {
        answerLaterButton.visible = false;
      }

      function drawConfidence(question){
        // question confidence
        var confidenceQuestion = MTLG.utils.gfx.getText(l('preQuestionConfidence'), "27px Arial", "black");
        confidenceQuestion.textAlign = 'center';
        confidenceQuestion.x = options.width / 2;
        confidenceQuestion.y = 180;

        // answer button
        var confidence1 = newButton(l('confidence1'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 5 / 42, 250, function(){
          setConfidence(1);
        });
        var confidence2 = newButton(l('confidence2'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 13 / 42, 250, function(){
          setConfidence(2);
        });
        var confidence3 = newButton(l('confidence3'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 0.5, 250, function(){
          setConfidence(3);
        });
        var confidence4 = newButton(l('confidence4'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 8 / 14 + options.width * 5 / 42, 250, function(){
          setConfidence(4);
        });
        var confidence5 = newButton(l('confidence5'), 27, "rgb(6, 225, 17)", 1, options.width / 7, options.height / 15, options.width * 8 / 14  + options.width * 13 / 42, 250, function(){
          setConfidence(5);
        });

        urlContainer.addChild(confidenceQuestion, confidence1, confidence2, confidence3, confidence4, confidence5);

        function setConfidence(confidence){
          for(var j = 0;j < exam.questions.length;j++){
            if(exam.questions[j].urlId === question.urlId){
              exam.questions[j].confidenceLevel = confidence;
            }
          }

          // xapi call
          xapiConfidenceResult({en:"Criminalcases Test Confidence- URL-ID: " + question.urlId, de:"Kriminalfälle Test Confidence- URL-ID: " + question.urlId}, confidence);


          // delete hotspot from the hotspotsToDisplay array
          hotspotsToDisplay.splice(hotspotsToDisplay.indexOf(hotspot), 1);
          stage.removeChild(overlay);
          stage.removeChild(urlContainer);
          stage.removeChild(hotspot.visual);

          // draw next hotspot
          drawHotspot();
        }

      }

      // add all elements to container
      urlContainer.addChild(backgroundShape, urlText, urlTarget, urlQuestion, phishingTrueButton, phishingFalseButton, answerLaterButton);
      stage.addChild(urlContainer);
    }

  }

  // END - CRIMINALCASES EXAM


  // START - SERVICE FUNCTIONS FOR drawExam():
  // showProgress()
  // showCountdown()
  // drawExamFeedback()

  // Function to show the number of answered URLs
  function showProgress(hotspotsToDisplay){
    var progressText = stage.getChildByName("progressText");
    if(progressText === null){
      progressText = MTLG.utils.gfx.getText("Fortschritt:\n" + (questionsToPlay - hotspotsToDisplay.length) + " / " + questionsToPlay, "32px Arial", "black");
      progressText.name = "progressText";
      progressText.textAlign = 'center';
      progressText.x = options.width / 7;
      progressText.y = 20;
      stage.addChild(progressText);
    } else {
      progressText.text = "Fortschritt:\n" + (questionsToPlay - hotspotsToDisplay.length) + " / " + questionsToPlay;
    }
  }

  // Function to show the ticking countdown
  function showCountdown(countdownTime){
    var countdownText = stage.getChildByName("countdownText");
    if(countdownText === null){
      countdownText = MTLG.utils.gfx.getText("Spielende in:\n" + countdownTime + "s", "32px Arial", "black");
      countdownText.name = "countdownText";
      countdownText.textAlign = 'center';
      countdownText.x = options.width - options.width / 7;
      countdownText.y = 20;
      stage.addChild(countdownText);
    } else {
      countdownText.text = "Spielende in:\n" + countdownTime + "s";
    }
  }

  // Function to draw the complete Feedback
  function drawExamFeedback(){
    // remove all old things from the stages
    stage.removeAllChildren();

    // reset the general game background - to remove the image
    MTLG.clearBackground();

    // add old standard background
    var generalBackground = new createjs.Shape();
    generalBackground.graphics.beginLinearGradientFill(['#f2f2f2', '#d9d9d9'], [0, 1], 0, 0, 0, MTLG.getOptions().height);
    generalBackground.graphics.drawRect(0, 0, MTLG.getOptions().width, MTLG.getOptions().height);
    MTLG.getBackgroundStage().addChild(generalBackground);

    // individual background of exam feedback
    var background = new createjs.Shape();
    background.graphics.beginFill("rgb(197, 235, 245)");
    background.graphics.drawRect(0, 0, options.width, options.height);
    stage.addChild(background);

    // Demo Delfi buttons
    if(phishingAcademy.demoMode === true){
      demoButtons();
    }

    // feedback container
    var feedbackContainer = new createjs.Container();
    feedbackContainer.x = options.width * 0.025;
    feedbackContainer.y = options.height * 0.25;

    // save the used lang key
    var langString = MTLG.lang.getLanguage();
    var count = 0;
    var previousHeight = 80;

    // evaluate the user solutions
    /*exam.questions.forEach(function(question){
      if(question.userAnswer === question.phishingAnswer){
        question.userCorrect = true;
      } else{
        question.userCorrect = false;
      }
      if(question.userCorrect === true){
        correctCount += 1;
      }
    });*/

    // Function to display the feedback of each url - internal use only in drawExamFeedback()
    function drawQuestionFeedback(index){
      var question = exam.questions[index];
      var number = index + 1;

      var correctAnswer;
      if(question.phishingAnswer === true){
        correctAnswer = l('phishingTrue');
      } else{
        correctAnswer = l('phishingFalse');
      }

      var userAnswer;
      if(question.userAnswer === true){
        userAnswer = l('phishingTrue');
      } else if(question.userAnswer === false){
        userAnswer = l('phishingFalse');
      } else {
        userAnswer = l('noUserAnswer');
      }

      // url carousel container
      var urlContainer = new createjs.Container

      // url feedback as one text
      var urlFeedback = MTLG.utils.gfx.getText(number + ". " + question.urlText + "\nZiel: " + question.target + "\n" + question.reason[langString] + "\n" + l('correctAnswer') + correctAnswer + "       " + l('userAnswer') + userAnswer, "27px Arial", "black");
      urlFeedback.lineHeight = 30;
      urlFeedback.lineWidth = 1450;
      urlFeedback.x = 15;
      urlFeedback.y = 15;

      // background shape
      var backgroundShape = new createjs.Shape();
      backgroundShape.graphics.beginFill("rgb(106, 198, 247)");
      backgroundShape.graphics.drawRoundRect(0, 0, options.width * 0.78, urlFeedback.getMeasuredHeight() + 25, 5);

      urlContainer.addChild(backgroundShape, urlFeedback);
      return urlContainer

    }

    var startPositionElements = {x: options.width * 0.085, y: 120};
    var startPositionNavigation = {leftx: options.width * 0.065, lefty: 160, rightx: options.width * 0.885, righty: 160};
    var startPositionPageDisplay ={x: options.width * 0.475, y: 400};
    carousel(exam.questions.length, 1, startPositionElements, startPositionNavigation, startPositionPageDisplay, drawQuestionFeedback, feedbackContainer)

    // add score to exam and save the exam
    exam.userScore = correctCount;
    sessionControl.setValueToKey("criminalcases", sessionControl.getValueToKey("criminalcases").concat(exam));

    // mark completetion postttest in save game and send first try to the LRS
    if(sessionControl.getValueToKey("completionPosttest") === 0){
      sessionControl.setValueToKey("completionPosttest", 1);

    }

    // exam feedback headline
    var examHeadline = MTLG.utils.gfx.getText(l('examFeedbackHeadline'), "36px Bahnschrift", "black");
    examHeadline.textAlign = 'center';
    examHeadline.x = options.width * 0.475;
    examHeadline.y = 15;

    // exam score
    var examScore = MTLG.utils.gfx.getText(l('examScore') + correctCount + " / " + questionsToPlay, "32px Arial", "black");
    examScore.textAlign = 'center';
    examScore.x = options.width * 0.475;
    examScore.y = 65;

    // exit button
    var exitFeedbackButton = newButton(l('exitFeedbackButton'), 27, "rgb(6, 225, 17)", 1, options.width / 5, options.height / 15, options.width * 0.475, 440, function(){
      // xapi call
      xapiButton({en: "Criminalcases - Close Feedback", de: "Kriminallfälle - Feedback schließen"});
      xapiLevelEnd({en: "Criminalcases", de: "Kriminalfälle"}, correctCount);

      function callbackScore(){
        MTLG.lc.levelFinished({
          nextLevel: "playermenu"
        });
      }

      if(sessionControl.getValueToKey("evaluationGame")){
          detectiveSpeech([l('criminalcasesEndDialogEvaluation')], true, callbackScore);
      }else{
        if(correctCount <= 4){
          detectiveSpeech([l('criminalcasesEndDialogMaxFour')], true, callbackScore);
        } else if(correctCount <= 6){
            detectiveSpeech([l('criminalcasesEndDialogMaxSix')], true, callbackScore);
        } else if(correctCount <= 8){
            detectiveSpeech([l('criminalcasesEndDialogMaxEight')], true, callbackScore);
        } else if(correctCount <= 9){
            detectiveSpeech([l('criminalcasesEndDialogMaxNine')], true, callbackScore);
        } else if(correctCount <= 10){
            detectiveSpeech([l('criminalcasesEndDialogMaxTen')], true, callbackScore);
        }
      }
    });

    // background shape
    var backgroundShape = new createjs.Shape();
    backgroundShape.graphics.beginFill("rgb(234, 234, 234)");
    backgroundShape.graphics.drawRect(0, 0, options.width * 0.95, options.height * 0.5);

    feedbackContainer.addChild(backgroundShape, examHeadline, examScore, exitFeedbackButton);
    feedbackContainer.setChildIndex(backgroundShape, 0);

    // add container to stage
    stage.addChild(feedbackContainer);
  }

  // END - SERVICE FUNCTIONS FOR drawExam()

  // END - DRAWING CONTENT PART OF CRIMINALCASES


  //********** START CRIMINALCASES **********
    // Call the options menu for the criminalcases to begin
    drawCriminalcaseOptions();

}
